import { Box } from "@mui/material";
import React from "react";

import { ReactComponent as PaperIcon } from "assets/icons/call-notes.svg";
import { ReactComponent as GithubIcon } from "assets/icons/github.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedin-logo-placeholder.svg";
import { ReactComponent as WebsiteIcon } from "assets/icons/website.svg";
import { CandidateBioSocialLinkLinkTypeEnum } from "services/openapi";

const SocialLinkIcon = ({ style, linkType }: { style?: Object; linkType: string }): React.ReactElement => {
  switch (linkType) {
    case CandidateBioSocialLinkLinkTypeEnum.Linkedin:
      // TODO: replace width with style prop once LinkedIn icon is updated per their branding guidelines
      return <LinkedInIcon width={42} />;
    case CandidateBioSocialLinkLinkTypeEnum.Github:
      return <GithubIcon style={style} />;
    case CandidateBioSocialLinkLinkTypeEnum.Resume:
      return <PaperIcon style={style} />;
  }
  return <WebsiteIcon style={style} />;
};

interface Props {
  linkType: string;
  url: string;
  style?: Object;
}
export const SocialLink = ({ linkType, url, style }: Props): React.ReactElement => {
  const getClickableLink = (link: string): string => {
    return link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;
  };

  return (
    <a href={getClickableLink(url)} rel="noopener noreferrer" target="_blank">
      <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
        <SocialLinkIcon style={style} linkType={linkType} />
      </Box>
    </a>
  );
};
