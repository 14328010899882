import { Button } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { BooleanParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import { ReactComponent as PlusIcon } from "assets/icons/add.svg";
import { ButtonProps } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { ADD_CANDIDATE_DRAWER_QUERY_PARAM, FLOW_TYPE_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { FlowTypeParam, FlowTypes } from "sections/addcandidate/types";
import { addCandidateV2DrawerAtom } from "sections/addCandidateV2";
import { colors } from "styles/theme";

//------------------------------------------------------------------------------
// Component

export const useHandleAddCandidate = (): (() => void) => {
  const useNewAddCandidate = useFeatureFlag(FeatureFlag.SimplifiedAddCandidate);
  const { open } = useModal(addCandidateV2DrawerAtom);

  const [, setIsOpenAddCandidateDrawerParam] = useQueryParam(
    ADD_CANDIDATE_DRAWER_QUERY_PARAM,
    withDefault(BooleanParam, false)
  );
  const [, setFlowTypeParam] = useQueryParam(FLOW_TYPE_QUERY_PARAM, FlowTypeParam);

  const addCandidateLegacy = useCallback((): void => {
    setFlowTypeParam(FlowTypes.INTERVIEW_PIPELINE);
    setIsOpenAddCandidateDrawerParam(true);
  }, [setFlowTypeParam, setIsOpenAddCandidateDrawerParam]);

  const addCandidateNew = useCallback((): void => {
    open({});
  }, [open]);

  return useNewAddCandidate ? addCandidateNew : addCandidateLegacy;
};

interface AddCandidateButtonProps extends Pick<ButtonProps, "removePadding" | "removeOutline"> {
  iconOnly?: boolean;
  hiringPipelineStageId?: string;
}

const AddCandidateButton = ({
  removePadding,
  removeOutline,
  iconOnly,
  hiringPipelineStageId,
}: AddCandidateButtonProps): React.ReactElement => {
  const handleAddCandidate = useHandleAddCandidate();
  const [, setHpsIdParam] = useQueryParam("hpsId", StringParam);

  //-----------------------------------------------------------------------------
  // Render

  return (
    <Tooltip title="Add candidate" placement="top" disabled={!iconOnly}>
      <div>
        <Button
          style={{ height: "100%" }}
          variant={removeOutline ? "ghost" : "outlined"}
          p={removePadding ? 0 : 0.5}
          icon={{ Icon: PlusIcon }}
          onPress={(): void => {
            if (hiringPipelineStageId) {
              setHpsIdParam(hiringPipelineStageId);
            }

            handleAddCandidate();
          }}
        >
          {!iconOnly && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <BodySmall color={colors.grayscale.gray600}>Add Candidate</BodySmall>
            </Stack>
          )}
        </Button>
      </div>
    </Tooltip>
  );
};

export default AddCandidateButton;
