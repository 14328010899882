/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePersonalizedContentRequest
 */
export interface CreatePersonalizedContentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalizedContentRequest
     */
    candidateId: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalizedContentRequest
     */
    tone: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalizedContentRequest
     */
    customOptions: string | null;
}

export function CreatePersonalizedContentRequestFromJSON(json: any): CreatePersonalizedContentRequest {
    return CreatePersonalizedContentRequestFromJSONTyped(json, false);
}

export function CreatePersonalizedContentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePersonalizedContentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': json['candidate_id'],
        'tone': json['tone'],
        'customOptions': json['custom_options'],
    };
}

export function CreatePersonalizedContentRequestToJSON(value?: CreatePersonalizedContentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
        'tone': value.tone,
        'custom_options': value.customOptions,
    };
}


