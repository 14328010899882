import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import TrashIconSVG from "assets/icons/trash.svg";
import Confirm from "components/Modal/ConfirmModal";
import { useDeleteCampaignMutation } from "services/doverapi/endpoints/campaign";
import { ListCampaign } from "services/openapi";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";

const StyledDeleteButton = styled.div`
  width: 48px;
  height: 46px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grayscale.gray300};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    border-color: ${colors.grayscale.gray400};
    box-shadow: 2px 2px 6px ${colors.grayscale.gray100};
  }
`;

const DeleteCampaignButton = ({ campaign }: { campaign: ListCampaign }): React.ReactElement => {
  const [deleteCampaign, { isLoading }] = useDeleteCampaignMutation();

  const handleDeleteCampaign = async (): Promise<void> => {
    await deleteCampaign({
      id: campaign.id!,
      name: campaign.name!,
    }).unwrap();
  };

  return (
    <Confirm
      title="Delete campaign"
      content={<B2_doNotUse>{`Are you sure you want to delete ${campaign.name ?? "this campaign"}?`}</B2_doNotUse>}
      useProcessing
      processing={isLoading}
      submitText="Delete"
      submitVariant="critical"
    >
      {(confirm: any): React.ReactNode => (
        <StyledDeleteButton onClick={confirm((): Promise<void> => handleDeleteCampaign())}>
          <ReactSVG src={TrashIconSVG} />
        </StyledDeleteButton>
      )}
    </Confirm>
  );
};

export default DeleteCampaignButton;
