import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { CANDIDATE_BIO } from "services/doverapi/endpointTagsConstants";
import { BaseCandidateActionResponseError, Email } from "services/openapi";

interface DeleteEmailArgs {
  emailId: string;
  candidateId: string;
}

const candidateEmailsEndpoint = doverApi.injectEndpoints({
  endpoints: build => ({
    deleteCandidateEmail: build.mutation<void, DeleteEmailArgs>({
      queryFn: async deleteEmailArgs => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.deleteEmail({ id: deleteEmailArgs.emailId });
        return { data };
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: CANDIDATE_BIO, id: args.candidateId }];
      },
    }),
    addCandidateEmail: build.mutation<Email, Email>({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        const result: Email = await client.addEmail({ data });
        return { data: result };
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: CANDIDATE_BIO, id: args.candidateId }];
      },
    }),
    updateCandidateEmail: build.mutation<Email, Email>({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        const result: Email = await client.updateEmail({
          id: data.id!,
          data,
        });
        return { data: result };
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: CANDIDATE_BIO, id: args.candidateId }];
      },
    }),
    validateEmail: build.query<BaseCandidateActionResponseError, Email>({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        const result: BaseCandidateActionResponseError = await client.validateEmail({
          data,
        });
        return { data: result };
      },
    }),
  }),
});

export const {
  useUpdateCandidateEmailMutation,
  useAddCandidateEmailMutation,
  useDeleteCandidateEmailMutation,
  useLazyValidateEmailQuery,
} = candidateEmailsEndpoint;
