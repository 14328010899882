import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { CandidateBioContactInfo } from "services/openapi";

export const useContactInfo = (
  candidateId: string
): { data: CandidateBioContactInfo[] | undefined; isFetching: boolean } => {
  return useGetCandidateBioQuery(candidateId, {
    selectFromResult: ({ data, isFetching }) => ({ data: data?.contactInfo, isFetching }),
  });
};
