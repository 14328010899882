import { Accordion, AccordionSummary, AccordionDetails, Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { ReactComponent as BriefcaseIconSVG } from "assets/icons/briefcase.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as CheckedMailIcon } from "assets/icons/email_with_check.svg";
import { ReactComponent as GithubIcon } from "assets/icons/github.svg";
import { ReactComponent as GraduateIconSVG } from "assets/icons/graduate.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedin-logo-placeholder.svg";
import { ReactComponent as MapPinSVG } from "assets/icons/map-pin.svg";
import { ReactComponent as WebsiteIcon } from "assets/icons/website.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Body, Heading } from "components/library/typography";
import { useShouldShowRealProfiles } from "services/doverapi/endpoints/client/hooks";
import { PositionInfo } from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";

const NUM_JOBS_SHOWN_DEFAULT = 2;

const PADDING_INFO_SECTION = "10px";

export interface SocialLinks {
  linkedinLink?: string;
  gitHubLink?: string;
  personalWebsiteLink?: string;
}

interface CandidateInfoProps {
  fullName: string;
  socialLinks: SocialLinks;
  recentPositionsInfo: PositionInfo[];
  headlineEducation: string | null;
  totalYoe: string;
  specificYoe: string | null;
  location: string | null;
  candidateCardExpanded: boolean;
  hasEmail: boolean;
}
const CandidateInfo = React.memo(
  ({
    fullName,
    socialLinks,
    recentPositionsInfo,
    headlineEducation,
    totalYoe,
    specificYoe,
    location,
    candidateCardExpanded,
    hasEmail,
  }: CandidateInfoProps): React.ReactElement => {
    const muiTheme = useTheme();
    const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptopM));
    const shouldShowRealProfiles = useShouldShowRealProfiles();
    const calendlyUrl = useGetCalendlyUrl("app", "saap", "tooltip");

    const nameSection = React.useMemo(() => {
      const openLinkInNewTab = (link: string | undefined): void => {
        if (!link || !shouldShowRealProfiles) {
          return;
        }
        window.open(link, "_blank", "noopener noreferrer");
      };

      const freeCustomerTooltip = (
        <span>
          Candidate details available on paid plans.{" "}
          <a href={calendlyUrl} rel="noopener noreferrer" target="_blank">
            Book a call
          </a>{" "}
          with our team to learn more.
        </span>
      );

      return (
        <Tooltip placement="top-start" title={!shouldShowRealProfiles ? freeCustomerTooltip : undefined}>
          <Stack
            direction="row"
            width="max-content"
            spacing={1}
            alignItems="center"
            padding={`0px ${PADDING_INFO_SECTION}`}
            onClick={(event: React.MouseEvent): void => event.stopPropagation()}
          >
            <Heading weight="700" style={{ filter: !shouldShowRealProfiles ? "blur(5px)" : undefined }}>
              {fullName}
            </Heading>
            {socialLinks.linkedinLink && (
              <LinkedInIcon onClick={(): void => openLinkInNewTab(socialLinks.linkedinLink)} />
            )}
            {socialLinks.personalWebsiteLink && (
              <WebsiteIcon onClick={(): void => openLinkInNewTab(socialLinks.personalWebsiteLink)} />
            )}
            {socialLinks.gitHubLink && <GithubIcon onClick={(): void => openLinkInNewTab(socialLinks.gitHubLink)} />}
            {hasEmail && <CheckedMailIcon height="16px" width="16px" />}
          </Stack>
        </Tooltip>
      );
    }, [
      fullName,
      hasEmail,
      shouldShowRealProfiles,
      socialLinks.gitHubLink,
      socialLinks.linkedinLink,
      socialLinks.personalWebsiteLink,
      calendlyUrl,
    ]);

    const locationAndYOESection = React.useMemo(() => {
      if (!location && !specificYoe && totalYoe.length === 0) {
        return <></>;
      }

      return (
        <Stack direction="row" spacing={3} alignItems="center" padding={`0px ${PADDING_INFO_SECTION}`}>
          {location && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MapPinSVG className="svg-color" color={colors.grayscale.gray400} height="20px" width="17px" />
              <Body weight="300" color={colors.grayscale.gray700}>
                {location}
              </Body>
            </Stack>
          )}
          <Stack direction="row" spacing={1} alignItems="center">
            <ClockIcon className="svg-color" color={colors.grayscale.gray400} height="20px" />
            <Body weight="300" color={colors.grayscale.gray700}>
              {totalYoe}
            </Body>
            {specificYoe && (
              <>
                <Body color={colors.grayscale.gray300}>|</Body>
                <Body weight="300" color={colors.grayscale.gray700}>
                  {specificYoe}
                </Body>
              </>
            )}
          </Stack>
        </Stack>
      );
    }, [location, specificYoe, totalYoe]);

    const buildJobSection = React.useCallback(
      ({
        jobTitle,
        jobCompanyName,
        jobDuration,
      }: {
        jobTitle: string | null;
        jobCompanyName: string | null;
        jobDuration: string | null;
      }): React.ReactElement => {
        if (!jobTitle) {
          return <></>;
        }

        return (
          <Stack direction="row" spacing={0.5} alignItems="start">
            <Stack sx={{ padding: "0px", margin: "3px 0px 0px 0px" }}>
              <BriefcaseIconSVG className="svg-color" color={colors.grayscale.gray400} />
            </Stack>
            <Stack
              direction={isSmallScreen ? "column" : "row"}
              spacing={isSmallScreen ? 0 : 0.5}
              alignItems="start"
              justifyContent="start"
              width="100%"
            >
              <Body weight="300" color={colors.grayscale.gray700}>
                {jobCompanyName ? `${jobTitle} at ` : jobTitle}
              </Body>
              <Stack direction="row" spacing={0.5} alignItems="center">
                {jobCompanyName && (
                  <Body weight="500" color={colors.grayscale.gray700}>
                    {jobCompanyName}
                  </Body>
                )}
                <BodySmall color={colors.grayscale.gray500}>{jobDuration}</BodySmall>
              </Stack>
            </Stack>
          </Stack>
        );
      },
      [isSmallScreen]
    );

    const allPastJobsSection = React.useMemo(() => {
      // dont want to show accordion if there is no past jobs info
      if (recentPositionsInfo.length === 0) {
        return <></>;
      }

      return (
        <Accordion
          expanded={candidateCardExpanded}
          style={{ boxShadow: "none", borderRadius: "4px", border: "none" }}
          sx={{
            "&:before": {
              display: "none",
            },
            backgroundColor: "inherit",
            padding: `0px ${PADDING_INFO_SECTION}`,
          }}
          disableGutters={true}
        >
          <AccordionSummary sx={{ padding: "0px", margin: "0px" }}>
            <Stack>
              {recentPositionsInfo.slice(0, NUM_JOBS_SHOWN_DEFAULT).map(position => {
                return buildJobSection({
                  jobTitle: position.title,
                  jobCompanyName: position.companyName,
                  jobDuration: position.duration,
                });
              })}
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px" }}>
            {recentPositionsInfo.slice(NUM_JOBS_SHOWN_DEFAULT).map(position => {
              return buildJobSection({
                jobTitle: position.title,
                jobCompanyName: position.companyName,
                jobDuration: position.duration,
              });
            })}
          </AccordionDetails>
        </Accordion>
      );
    }, [buildJobSection, candidateCardExpanded, recentPositionsInfo]);

    const educationSection = React.useMemo(() => {
      if (!headlineEducation) {
        return <></>;
      }
      return (
        <Stack direction="row" spacing={0.5} alignItems="center" padding={`0px ${PADDING_INFO_SECTION}`}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <GraduateIconSVG />
          </Box>
          <Body weight="300" color={colors.grayscale.gray700}>
            {headlineEducation}
          </Body>
        </Stack>
      );
    }, [headlineEducation]);

    return (
      <Stack spacing={1}>
        {nameSection}
        {locationAndYOESection}
        {allPastJobsSection}
        {educationSection}
      </Stack>
    );
  }
);

export default CandidateInfo;
