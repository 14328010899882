/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UnsubscribeFooter,
    UnsubscribeFooterFromJSON,
    UnsubscribeFooterFromJSONTyped,
    UnsubscribeFooterToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20070
 */
export interface InlineResponse20070 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20070
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20070
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20070
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<UnsubscribeFooter>}
     * @memberof InlineResponse20070
     */
    results: Array<UnsubscribeFooter>;
}

export function InlineResponse20070FromJSON(json: any): InlineResponse20070 {
    return InlineResponse20070FromJSONTyped(json, false);
}

export function InlineResponse20070FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20070 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(UnsubscribeFooterFromJSON)),
    };
}

export function InlineResponse20070ToJSON(value?: InlineResponse20070 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(UnsubscribeFooterToJSON)),
    };
}


