import { Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useEffect } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as RocketIcon } from "assets/icons/rocket-black.svg";
import { BodySmall } from "components/library/typography";
import { SetupGuide, SetupGuideStep } from "components/SetupGuide";
import { ReactComponent as CreateCareersPageIllustration } from "components/SetupGuide/illustrations/create-careers-page.svg";
import { ReactComponent as InviteIntervewPanelIllustration } from "components/SetupGuide/illustrations/invite-interview-panel.svg";
import { ReactComponent as PostJobBoardsIllustration } from "components/SetupGuide/illustrations/post-job-boards.svg";
import { CTAButton, GrayBody } from "components/SetupGuide/SetupGuideCTA";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useListEditCareersPageJobsQuery } from "services/doverapi/endpoints/careersPageJob";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useAllHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

/* -----------------------------------------------------------------------------
 * SetupGuide
 * -------------------------------------------------------------------------- */

export const JobSetupGuide = ({ jobId }: { jobId: string }): React.ReactElement => {
  const { data: client } = useGetUsersClientQuery();

  // make requests for setup steps
  const { data: job, isFetching: isFetchingJob } = useGetJobQuery(jobId);
  const { stages, isFetching: isFetchingHps } = useAllHps(jobId);
  const {
    currentData: { results: publishableJobs } = {},
    isFetching: isFetchingCareersPagesJobs,
  } = useListEditCareersPageJobsQuery(client?.id ? { clientId: client?.id, limit: 200 } : skipToken);

  // const [hasBookedConsultation, setHasBookedConsultation] = useLocalStorage(`hasBookedConsultation${jobId}`, false);
  const [dismissed, setDismissed] = useLocalStorage(`setupGuideDismissed${jobId}`, false);
  const [hidden, setHidden] = useLocalStorage(`setupGuideHidden${jobId}`, false);

  const steps: Array<SetupGuideStep> = [
    {
      name: "Add job to your careers page",
      content: <GrayBody>Publish your job to your careers page to start getting applicants</GrayBody>,
      graphic: <CreateCareersPageIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.editCareersPage(true)}>Add to careers page</CTAButton>,
      isComplete: !!publishableJobs?.find(j => j.id === jobId && !j.isSample && j.isPublished),
    },
    {
      name: "Post to job boards",
      content: <GrayBody>Promote your job to start getting more applicants</GrayBody>,
      graphic: <PostJobBoardsIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.job.jobBoards(jobId)}>Add to job boards</CTAButton>,
      isComplete: !!job?.jobBoardsSetupComplete,
      // isComplete: true,
    },
    {
      name: "Invite your interview panel",
      content: <GrayBody>Add your team to your interview plan to organize your process</GrayBody>,
      graphic: <InviteIntervewPanelIllustration width="100%" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.job.interviewPlan(jobId)}>Invite team</CTAButton>,
      isComplete:
        !job?.isSample &&
        // all interview stages should have at least one interviewer
        !!stages
          ?.filter(s => s.multipartInterviewStage)
          .every(s => s.multipartInterviewStage?.substages?.filter(s => s.possibleInterviewers.length > 0)),
    },
    // {
    //   name: "Scale your efforts",
    //   isComplete: hasBookedConsultation,
    //   content: (
    //     <GrayBody>
    //       <b>Dover Recruiting Partner</b>
    //       <br />
    //       Dover drives your hiring process and helps you find and close top talent.
    //     </GrayBody>
    //   ),
    //   graphic: (
    //     <Stack paddingLeft={"8px"} paddingRight={"32px"} justifyContent={"center"}>
    //       <ReactPlayer
    //         controls
    //         url="https://cdn.dover.io/dover/meet_the_team.mp4"
    //         width="100%"
    //         height="auto"
    //         onPlay={(): void => setHasBookedConsultation(true)}
    //       />
    //     </Stack>
    //   ),
    //   ctaButton: (
    //     <CTAButton
    //       onPress={(): void => {
    //         setHasBookedConsultation(true);
    //         window.open(calendlyUrl, "_blank", "noopener noreferrer");
    //       }}
    //     >
    //       Get started for free
    //     </CTAButton>
    //   ),
    // },
  ];

  const isLoading = isFetchingHps || isFetchingJob || isFetchingCareersPagesJobs;

  const allComplete = steps.every(s => s.isComplete);

  useEffect(() => {
    if (allComplete) {
      setHidden(true);
    }
  }, [allComplete, setHidden]);

  if (isLoading) {
    return <Skeleton width="100%" height="240px" />;
  }

  const dismissGuide = (): void => {
    setDismissed(true);
  };

  if (dismissed) {
    return <></>;
  }

  return (
    <Stack spacing={1}>
      <SetupGuide
        steps={steps}
        onDismiss={dismissGuide}
        loading={isLoading}
        heading={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Stack direction="row" alignItems={"center"} spacing={0.5}>
              <RocketIcon color="black" />
              <BodySmall weight="600">Setup checklist</BodySmall>
            </Stack>
            {/* {!allComplete && <Chip variant="Informational" label="Incomplete" />} */}
          </Stack>
        }
        showHideButton
        isHiddenInitially={hidden}
        onToggleHide={(): void => setHidden(!hidden)}
      />
    </Stack>
  );
};
