import { DoverUser, ListCampaignEmailSenderOptionEnum, SlimEmailSenderAlias } from "services/openapi";

export function getEmailAliasName(
  userDefinedSenderUser: DoverUser | undefined,
  emailSenderOption: ListCampaignEmailSenderOptionEnum | undefined,
  emailAlias: SlimEmailSenderAlias
): string {
  // In the case the email alias is a virtual recruiter, then we display the virtual recruiter's name
  if (emailSenderOption === ListCampaignEmailSenderOptionEnum.VirtualRecruiter) {
    return emailAlias.fullName ?? "Virtual Recruiter";
  }
  // Otherwise, we display the email alias' user's full name
  return userDefinedSenderUser?.fullName ?? "N/A";
}

export function getEmailAliasEmail(
  userDefinedSenderUser: DoverUser | undefined,
  emailSenderOption: ListCampaignEmailSenderOptionEnum | undefined,
  emailAlias: SlimEmailSenderAlias
): string {
  // In the case the email alias is a virtual recruiter, then we display the virtual recruiter's email
  if (emailSenderOption === ListCampaignEmailSenderOptionEnum.VirtualRecruiter) {
    return emailAlias.email ?? "N/A";
  }
  // Otherwise, we display the email alias' user's email
  return userDefinedSenderUser?.email ?? "N/A";
}
