import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useQueryParam, BooleanParam, withDefault } from "use-query-params";

import { CandidateNote } from "components/CandidateHeader/CandidateNote";
import { NameAndSocialLinks } from "components/CandidateHeader/NameAndSocialLinks";
import { getPrimaryCandidateBioEmail } from "components/dover/CustomizeNextSteps/helpers";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { selectCurrentCandidateId } from "domains/candidate/selectors";
import { useFeatureFlag, FeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetCampaignQuery } from "services/doverapi/endpoints/campaign/endpoints";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useGetCandidateV2Query } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import {
  useGetCampaignMessageRequestQuery,
  useUpdateCampaignMessageRequestMutation,
} from "services/doverapi/endpoints/emailSending";
import { useGetManagedOutboundState } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { useUpsertJobFeatureSettingMutation } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { JobFeatureStateEnum, SlimNextCampaignMessageRequestStateEnum } from "services/openapi";
import { backgrounds } from "styles/theme";
import { isPreResponseStage } from "utils/isStage";
import { CandidateDetailEmailEditor } from "views/CandidateDetail/components/CandidateDetailEmailEditor";
import { CandidateDetailOutboxNextActionBanner } from "views/CandidateDetail/components/CandidateDetailOutreachBanner";
import { CandidateMergeBanner } from "views/CandidateDetail/components/CandidateMergeBanner";
import { EventFeed } from "views/CandidateDetail/components/EventFeed";
import StartNewEmailThreadButton from "views/CandidateDetail/components/new-email-thread/StartNewEmailThread";
import { NextActionCard } from "views/CandidateDetail/components/next-action/NextActionCard";
import { OUTREACH_EMAIL_EDITOR_OPEN_PARAM } from "views/CandidateDetail/constants";
import { getFormattedSendAt } from "views/CandidateDetail/utils";
import { PipelineExpandOption } from "views/candidates/types";

export const CandidateActivity = ({
  shouldHideCandidateDetails,
  isHiredOrOffer,
}: {
  shouldHideCandidateDetails: boolean;
  isHiredOrOffer: boolean;
}): ReactElement => {
  const isPandoraPhase3Enabled = useFeatureFlag(FeatureFlag.PandoraPhase3);

  const jobId = useJobIdFromUrl();

  const useContactName = useFeatureFlag(FeatureFlag.PandoraNameReads);

  const [emailEditorOpen, setEmailEditorOpen] = useQueryParam(
    OUTREACH_EMAIL_EDITOR_OPEN_PARAM,
    withDefault(BooleanParam, false)
  );

  const [emailBody, setEmailBody] = useState<string>("");
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [sendAt, setSendAt] = useState<Date>(new Date());

  const candidateId = useSelector(selectCurrentCandidateId);
  const { data: candidateBio, isLoading: loadingCandidateBio } = useGetCandidateBioQuery(candidateId);
  const { data: pipelineCandidate, isLoading: loadingCandidate } = useGetCandidateV2Query(
    candidateId
      ? {
          id: candidateId,
          expand: PipelineExpandOption.CampaignMessageRequest,
        }
      : skipToken
  );
  const [, { isLoading: jobFeatureSettingIsUpdating }] = useUpsertJobFeatureSettingMutation();

  const { data: campaignMessageRequest, isFetching: loadingCampaignMessageRequest } = useGetCampaignMessageRequestQuery(
    pipelineCandidate?.slimNextCampaignMessageRequest?.campaignMessageRequestId && jobId
      ? { cmrId: pipelineCandidate?.slimNextCampaignMessageRequest?.campaignMessageRequestId, jobId: jobId }
      : skipToken
  );
  const { data: campaign } = useGetCampaignQuery(
    campaignMessageRequest?.campaignId ? campaignMessageRequest.campaignId : skipToken
  );

  const [
    updateCampaignMessageRequestMutation,
    { isLoading: updatingCampaignMessageRequest },
  ] = useUpdateCampaignMessageRequestMutation();

  const loading =
    loadingCandidateBio ||
    loadingCandidate ||
    loadingCampaignMessageRequest ||
    updatingCampaignMessageRequest ||
    jobFeatureSettingIsUpdating;

  const managedOutboundState = useGetManagedOutboundState({ jobId });

  const handleSaveToQueue = async (): Promise<void> => {
    if (pipelineCandidate?.slimNextCampaignMessageRequest?.campaignMessageRequestId) {
      await updateCampaignMessageRequestMutation({
        campaignMessageRequestId: pipelineCandidate?.slimNextCampaignMessageRequest?.campaignMessageRequestId,
        data: {
          subjectOverride: emailSubject || campaignMessageRequest?.subject,
          bodyOverride: emailBody,
          emailSendRequestSendAt: sendAt,
        },
      }).unwrap();

      setEmailEditorOpen(false);
    }
  };

  const candidateEmail = candidateBio?.contactInfo ? getPrimaryCandidateBioEmail(candidateBio.contactInfo) : "";
  const slimNextCampaignMessageRequest = pipelineCandidate?.slimNextCampaignMessageRequest;
  const candidateHasntReplied =
    !pipelineCandidate?.candidatePipelineStage || isPreResponseStage(pipelineCandidate.candidatePipelineStage);

  const showOutboxNextActionCard =
    slimNextCampaignMessageRequest &&
    candidateHasntReplied &&
    slimNextCampaignMessageRequest.state !== SlimNextCampaignMessageRequestStateEnum.Failed &&
    (managedOutboundState === JobFeatureStateEnum.Enabled || campaign?.allowFollowUpsPostDeactivation);

  if (loading) {
    return <DoverLoadingSpinner />;
  }

  return (
    <CandidateActivityContainer>
      <CustomModal
        title="Edit Outreach"
        maxWidth="md"
        open={emailEditorOpen}
        onClose={(): void => setEmailEditorOpen(false)}
      >
        <CandidateDetailEmailEditor
          candidateId={candidateBio?.id}
          candidateEmail={candidateEmail ?? ""}
          candidateName={(useContactName ? candidateBio?.contact?.fullName : candidateBio?.fullName) ?? ""}
          campaignMessageRequest={campaignMessageRequest}
          selectedSendAt={sendAt}
          setBody={setEmailBody}
          setSubject={setEmailSubject}
          setSelectedSendAt={setSendAt}
          setEmailEditorOpen={setEmailEditorOpen}
          handleSaveToQueue={handleSaveToQueue}
        />
      </CustomModal>
      <Box marginBottom={showOutboxNextActionCard ? "12px" : undefined}>
        <NameAndSocialLinks candidateBio={candidateBio!} />
      </Box>

      <Stack spacing={1}>
        {isPandoraPhase3Enabled && (
          <CandidateMergeBanner contact={candidateBio?.contact} candidateId={candidateBio?.id} />
        )}
        {showOutboxNextActionCard && (
          <CandidateDetailOutboxNextActionBanner
            emailSendAt={getFormattedSendAt(campaignMessageRequest?.emailSendRequest?.sendAt?.toString() ?? "")}
            emailOutreachStep={pipelineCandidate?.slimNextCampaignMessageRequest?.emailOutreachStep ?? ""}
            candidate={pipelineCandidate}
          />
        )}
        {/* Can't show the Outbox NAC along with the regular NAC */}
        {!showOutboxNextActionCard && candidateBio && <NextActionCard candidateBio={candidateBio} />}
      </Stack>
      {!shouldHideCandidateDetails && (
        <Box display="flex" mt="16px" width="100%" alignItems="flex-start">
          <Box width="100%">
            <CandidateNote />
          </Box>
          {candidateBio && (
            <Box ml="8px">
              <StartNewEmailThreadButton candidateBio={candidateBio} />
            </Box>
          )}
        </Box>
      )}
      <EventFeed
        pipelineStage={candidateBio?.candidatePipelineStage}
        shouldHideCandidateDetails={shouldHideCandidateDetails}
        isHiredOrOffer={isHiredOrOffer}
      />
    </CandidateActivityContainer>
  );
};

const CandidateActivityContainer = styled.div`
  align-items: stretch;
  background-color: ${backgrounds.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 24px 40px;

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f3f3f3;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c3c3c3;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #949494;
  }
`;
