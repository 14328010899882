import { Stack } from "@mui/material";
import React from "react";

import { doesStageRequireInterviewer } from "components/dover/top-level-modal-manager/hooks/useValidate";
import { ButtonVariant } from "components/library/Button";
import { BodyExtraSmall, BodySmall, Overline, Subtitle2 } from "components/library/typography";
import { NextInterviewer } from "components/NextInterviewer/NextInterviewer";
import { useInvalidInterviewerTooltipText } from "components/NextInterviewer/ValidateNextInterviewer";
import { OneClickActionButtons } from "components/OneClickActionButtons";
import { useDisableCandidateActionsTooltipText } from "hooks/useDisableCandidateActionsTooltipText";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import { useGetJobSlackUrl } from "services/doverapi/endpoints/job/hooks";
import { NextActionSchedulingStateEnum } from "services/openapi";
import { ConciergeProfile } from "views/CandidateDetail/components/next-action/ConciergeProfile";
import { IllHandleButton } from "views/CandidateDetail/components/next-action/IllHandleButton";
import { InterviewStagesMenuButton } from "views/CandidateDetail/components/next-action/InterviewStagesMenuButton";
import { MarkAsWithdrawnButton } from "views/CandidateDetail/components/next-action/MarkAsWithdrawnButton";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { RejectWithoutEmailButton } from "views/CandidateDetail/components/next-action/RejectWithoutEmailButton";
import { ConciergeRescheduleButton } from "views/CandidateDetail/components/next-action/states/ConciergeScheduled";
import { SubmitFeedbackButton } from "views/CandidateDetail/components/next-action/states/SubmitFeedbackButton";
import { colorsMap, NextActionStateComponentProps } from "views/CandidateDetail/components/next-action/utils/constants";

export function ConciergeCallCompleted({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  const conciergeInfo = useConciergeInfo();
  const useContactName = useFeatureFlag(FeatureFlag.PandoraNameReads);
  const nextStage = candidateBio?.nextAction?.nextHiringPipelineStage;
  const jobId = candidateBio.job;
  const isInitialReview = candidateBio?.nextAction?.schedulingState === NextActionSchedulingStateEnum.InitialReview;
  const previousJobTitle = isInitialReview ? candidateBio?.oldJobTitle : undefined;

  const candidateName = useContactName ? candidateBio?.contact.fullName : candidateBio?.fullName;

  const moveJobText = `${candidateName} was originally contacted for ${previousJobTitle} but expressed interest in this role.`;

  const stageRequiresInterviewer = doesStageRequireInterviewer({
    schedulingOwnership: candidateBio.schedulingOwnership,
    desiredHiringPipelineStage: nextStage,
    isTakeHome: candidateBio.nextAction?.nextInterview?.isTakeHome ?? false,
  });

  const invalidCandidateActionPermsTooltip = useDisableCandidateActionsTooltipText(jobId);
  const invalidCandidateActionPerms = !!invalidCandidateActionPermsTooltip;

  const invalidInterviewerTooltip = useInvalidInterviewerTooltipText({
    candidateId: candidateBio.id,
  });

  const approveDisabledTooltip = invalidCandidateActionPermsTooltip ?? invalidInterviewerTooltip;

  const slackUrl = useGetJobSlackUrl(jobId);
  const description = (
    <Stack spacing={2}>
      <ConciergeProfile slackUrl={slackUrl} />
      <Subtitle2>Would you like {conciergeInfo?.firstName} to schedule this candidate?</Subtitle2>
      {previousJobTitle && <BodyExtraSmall>{moveJobText}</BodyExtraSmall>}
      <Stack display="flex" direction="row" spacing={2}>
        <Stack>
          <Overline>Next Interview</Overline>
          <BodySmall>{candidateBio.nextAction?.nextHiringPipelineStage?.name}</BodySmall>
        </Stack>
        {stageRequiresInterviewer && (
          <NextInterviewer candidateId={candidateBio.id} desiredHiringPipelineStage={nextStage} />
        )}
      </Stack>
      <Stack direction="row" spacing={1} height="45px">
        <OneClickActionButtons
          bio={candidateBio}
          disableApprove={invalidCandidateActionPerms}
          disabledApproveTooltip={approveDisabledTooltip}
          disableReject={invalidCandidateActionPerms}
          disableRejectTooltip={invalidCandidateActionPermsTooltip}
        />
        <SubmitFeedbackButton candidateBio={candidateBio} />
        <InterviewStagesMenuButton
          options={[
            <IllHandleButton bio={candidateBio} variant={ButtonVariant.Ghost} fullWidth justifyText="flex-start" />,
            <ConciergeRescheduleButton
              candidateBio={candidateBio}
              variant={ButtonVariant.Ghost}
              fullWidth
              justifyText="flex-start"
            />,
            <MarkAsWithdrawnButton bio={candidateBio} />,
            <RejectWithoutEmailButton bio={candidateBio} />,
          ]}
          candidateBio={candidateBio}
        />
      </Stack>
    </Stack>
  );

  return <NextActionCardSkeleton title={"Awaiting Decision"} colors={colorsMap.yellow} description={description} />;
}
