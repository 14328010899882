import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as GreenCheckSVG } from "assets/icons/check-green-filled.svg";
import { Centered } from "components/Centered";
import { SALES_CALENDLY } from "components/constants";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonRadio } from "components/library/ButtonRadio";
import { Card } from "components/library/Card";
import { Subtitle1 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { CalendlyEmbed } from "components/onboarding/CalendlyEmbed";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { useAuth0 } from "services/react-auth0-spa";
import { colors, screenSizesNumbers } from "styles/theme";

interface CreateJobSuccessScreenProps {
  variant?: "page" | "drawer";
  jobId?: string;
  closeDrawer?: () => void;
}

const SuccessScreenContent = ({ jobId }: CreateJobSuccessScreenProps): React.ReactElement => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { data: userInfo } = useGetAuthedUserInfoQuery();

  const { data: job } = useGetJobQuery(jobId!);

  const [hideSkipButton, setHideSkipButton] = React.useState(false);

  return (
    <Stack spacing={2} width={"100%"} height="100%" padding={"16px 16px 0px 16px"}>
      <Stack zIndex={2} spacing={2} sx={{ backgroundColor: "white" }}>
        <ButtonRadio
          onClick={(): void => {}}
          active={true}
          label={
            <Stack direction="row" spacing={1} alignItems={"center"} justifyContent={"center"}>
              <GreenCheckSVG width="16px" />
              <Subtitle1 color={colors.primary.base} centered>
                {job?.title} job created!
              </Subtitle1>
            </Stack>
          }
        />
        <Subtitle1>Dover Recruiting Partner</Subtitle1>
      </Stack>

      <Stack spacing={1} height={hideSkipButton ? "110%" : "100%"} zIndex={1}>
        <CalendlyEmbed
          calendlyUrl={SALES_CALENDLY}
          name={userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : user.name}
          email={user.email}
          utmCampaign="dover_recruiting_partner"
          utmMedium="app"
          utmSource="create_job"
          onEventScheduled={(): void => {
            navigate({ pathname: APP_ROUTE_PATHS.job.jobPosting(jobId!) });
          }}
          // these fixed dimensions are a bit weird, but because the embed is an iframe
          // it is hard to style, these are optimized for the most common screen sizes
          styles={{
            height: hideSkipButton ? "200%" : "100%",
            maxHeight: "680px",
            width: "100%",
            marginTop: "-60px",
          }}
          dateAndTimeSelectedMaxHeight={"100vh"}
          onDateAndTimeSelected={(): void => setHideSkipButton(true)}
        />
        {!hideSkipButton && (
          <Button
            removePadding
            variant={ButtonVariant.Ghost}
            onClick={(): void => navigate({ pathname: APP_ROUTE_PATHS.job.jobPosting(jobId!) })}
          >
            Skip
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const CreateJobSuccessScreen = ({
  variant = "page",
  jobId,
  closeDrawer,
}: CreateJobSuccessScreenProps): ReactElement => {
  const { data: job } = useGetJobQuery(jobId ? jobId : skipToken);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tablet));

  if (!job) {
    return <DoverLoadingSpinner />;
  }

  if (variant === "drawer") {
    return <SuccessScreenContent jobId={jobId} closeDrawer={closeDrawer} variant={variant} />;
  }

  return (
    <Centered>
      <Card
        style={{
          width: "100%",
          maxWidth: "580px",
          minWidth: smallScreen ? "100vw" : "580px",
          position: "relative",
          paddingTop: "160px",
          overflow: "hidden",
        }}
      >
        <SuccessScreenContent jobId={jobId} closeDrawer={closeDrawer} variant={variant} />
      </Card>
    </Centered>
  );
};

export default CreateJobSuccessScreen;
