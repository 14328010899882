import { Button } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import React, { ReactElement, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { ReactComponent as SparkleSVG } from "assets/icons/ai-sparkles-solid.svg";
import { InfoTip } from "components/InfoTip";
import { Card } from "components/library/Card";
import EditorWithMenu from "components/library/TipTap/EditorWithMenu";
import { Subtitle1 } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { getHtmlFromUnknownContent } from "utils/draftJS";
import { generateJDModalAtom } from "views/job/JobSetup/steps/JobPosting/components/GenerateJD";
import {
  DescriptionFormSchema,
  EMPTY_JD,
  JOB_POSTING_SCROLL_MARGIN_TOP,
} from "views/job/JobSetup/steps/JobPosting/JobPosting";

/* -----------------------------------------------------------------------------
 * JobDescriptionSection
 * -------------------------------------------------------------------------- */

interface JobDescriptionSectionProps {
  jobDescriptionRef: React.RefObject<HTMLDivElement>;
  id?: string;
  scrollableContainerId?: string;
}

export const JobDescriptionSection = ({
  jobDescriptionRef,
  id,
  scrollableContainerId,
}: JobDescriptionSectionProps): ReactElement => {
  const { setValue, watch } = useFormContext<DescriptionFormSchema>();
  const jobDescription = watch("jobDescription");
  const initialValue = useRef(jobDescription ? getHtmlFromUnknownContent(jobDescription) : EMPTY_JD);

  const { open } = useModal(generateJDModalAtom);

  const handleChange = (content: string): void => {
    setValue("jobDescription", content);
  };

  return (
    <Card ref={jobDescriptionRef} style={{ scrollMarginTop: JOB_POSTING_SCROLL_MARGIN_TOP }} id={id}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            {(!jobDescription || jobDescription === EMPTY_JD) && <InfoTip text={"Required to post on job boards"} />}
            <Subtitle1>Description</Subtitle1>
          </Stack>
          <Button variant="outlined" onPress={(): void => open({})} icon={{ Icon: SparkleSVG }}>
            Generate with AI
          </Button>
        </Stack>
        <Stack spacing={1}>
          <EditorWithMenu
            initialContent={initialValue.current}
            onContentChange={handleChange}
            placeholder="Enter a job description here..."
            scrollableContainerId={scrollableContainerId}
            enableImages
          />
        </Stack>
      </Stack>
    </Card>
  );
};
