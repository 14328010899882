import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
  // optional params to have metadata for showing links to this page - works for linkedin
  description?: string;
  image?: string;
  jobPostingStructuredData?: string;
}

const PageHelmet = ({ title, description, image, jobPostingStructuredData }: Props): React.ReactElement => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={title} />
      {description && <meta name="description" property="og:description" content={description} />}
      {image && <meta name="image" property="og:image" content={image} />}
      {jobPostingStructuredData && <script type="application/ld+json">{jobPostingStructuredData}</script>}
    </Helmet>
  );
};

export default PageHelmet;
