/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20042
 */
export interface InlineResponse20042 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse20042
     */
    industriesChildrenAndAliases: Array<string>;
}

export function InlineResponse20042FromJSON(json: any): InlineResponse20042 {
    return InlineResponse20042FromJSONTyped(json, false);
}

export function InlineResponse20042FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20042 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'industriesChildrenAndAliases': json['industries_children_and_aliases'],
    };
}

export function InlineResponse20042ToJSON(value?: InlineResponse20042 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'industries_children_and_aliases': value.industriesChildrenAndAliases,
    };
}


