/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobLocation,
    JobLocationFromJSON,
    JobLocationFromJSONTyped,
    JobLocationToJSON,
    YoeMinMaxRange,
    YoeMinMaxRangeFromJSON,
    YoeMinMaxRangeFromJSONTyped,
    YoeMinMaxRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GenerateJobDescriptionRequest
 */
export interface GenerateJobDescriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateJobDescriptionRequest
     */
    jobId: string;
    /**
     * 
     * @type {Array<JobLocation>}
     * @memberof GenerateJobDescriptionRequest
     */
    locations: Array<JobLocation>;
    /**
     * 
     * @type {YoeMinMaxRange}
     * @memberof GenerateJobDescriptionRequest
     */
    yearsOfExperience: YoeMinMaxRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateJobDescriptionRequest
     */
    skills?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateJobDescriptionRequest
     */
    additionalDetails?: string | null;
}

export function GenerateJobDescriptionRequestFromJSON(json: any): GenerateJobDescriptionRequest {
    return GenerateJobDescriptionRequestFromJSONTyped(json, false);
}

export function GenerateJobDescriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateJobDescriptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': json['job_id'],
        'locations': ((json['locations'] as Array<any>).map(JobLocationFromJSON)),
        'yearsOfExperience': YoeMinMaxRangeFromJSON(json['years_of_experience']),
        'skills': !exists(json, 'skills') ? undefined : json['skills'],
        'additionalDetails': !exists(json, 'additional_details') ? undefined : json['additional_details'],
    };
}

export function GenerateJobDescriptionRequestToJSON(value?: GenerateJobDescriptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_id': value.jobId,
        'locations': ((value.locations as Array<any>).map(JobLocationToJSON)),
        'years_of_experience': YoeMinMaxRangeToJSON(value.yearsOfExperience),
        'skills': value.skills,
        'additional_details': value.additionalDetails,
    };
}


