import { Box, Stack } from "@mui/material";
import React from "react";

import { ReactComponent as AINotesButton } from "assets/icons/add-notes-ai-button.svg";
import { ReactComponent as WandIcon } from "assets/icons/green-wand.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Heading } from "components/library/typography";
import { colors } from "styles/theme";

const AINoteFormatterBanner = ({
  onClick,
  existingRoughNotesAvailable,
}: {
  onClick: (e: any) => void;
  existingRoughNotesAvailable: boolean;
}): React.ReactElement => {
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: colors.white,
        borderRadius: "3px",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
        p: "16px",
      }}
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <WandIcon height="20px" width="20px" />
        <Stack spacing={0.5}>
          <Heading>Autofill from rough notes</Heading>
          <Body color={colors.grayscale.gray600}>
            Input your unstructured notes and Dovers AI will format and align them to your individual interview
            questions!
          </Body>
        </Stack>
      </Stack>
      {existingRoughNotesAvailable ? (
        <Button variant={ButtonVariant.Secondary} onClick={onClick}>
          <Body color={colors.grayscale.gray600} style={{ whiteSpace: "nowrap" }}>
            View notes
          </Body>
        </Button>
      ) : (
        <Box onClick={onClick} sx={{ ":hover": { cursor: "pointer" } }}>
          <AINotesButton width="120px" />
        </Box>
      )}
    </Stack>
  );
};

export default AINoteFormatterBanner;
