import { ListItem } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { skipToken } from "@reduxjs/toolkit/query";
import { ValidationErrors } from "final-form";
import { Switches } from "mui-rff";
import React, { useState } from "react";
import { Field, FormSpy, FormSpyRenderProps, withTypes } from "react-final-form";
import styled from "styled-components";

import { AlwaysShowError } from "components/form/Error";
import Modal from "components/Modal";
import CampaignMessageEditor from "components/outreach-configuration/form/CampaignMessageEditorField_deprecated";
import {
  ALLOWED_SENDING_WINDOWS,
  ALLOWED_SENDING_TIMEZONES,
  ALLOWED_CAMPAIGN_STATES,
  ALLOWED_MESSAGE_STATES,
  ALLOWED_SETUP_STATES,
} from "components/outreach-configuration/form/constants";
import { Divider, StyledSelect, StyledTextField, StyledAutocomplete } from "components/outreach-configuration/Styles";
import { XAxisSlider } from "components/Slider";
import { TextWithNewLinesAndLinks } from "components/TextHelpers";
import { useOpenApiClients } from "hooks/openApiClients";
import { useListProUsersByClientIdQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import {
  Campaign,
  CampaignMessage,
  CampaignMessageCampaignMessageStateEnum,
  CampaignCampaignStateEnum,
  CampaignSendingWindowEnum,
  EmailSenderAlias,
  ApiApi,
  UnsubscribeFooter,
  DoverUser,
} from "services/openapi";
import { InternalLink } from "styles/typography";
import { OnboardingStepState } from "types";

const SmallListText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: block;
`;

const SmallListTextBold = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: block;
`;

const { Form: MUIForm } = withTypes<Campaign>();

interface CampaignFormProps {
  initialValues: Campaign;
  unsubscribeFootersLoading: boolean;
  unsubscribeFooters?: UnsubscribeFooter[];
  onSubmit: (e: Campaign) => void;
  sendTestEmails: (campaign: Campaign) => void;
}

const CampaignForm: React.FC<CampaignFormProps> = ({
  initialValues,
  unsubscribeFootersLoading,
  unsubscribeFooters,
  onSubmit,
  sendTestEmails,
}) => {
  const clientId = initialValues.clientId;
  const { proUsers } = useListProUsersByClientIdQuery(clientId ? { clientId } : skipToken, {
    selectFromResult: ({ data }) => {
      const proUsers: DoverUser[] = listAllEntities(data);
      return {
        proUsers,
      };
    },
  });
  const [values, setValues] = useState<Campaign>(initialValues);

  const potentialEmailAliases = initialValues.potentialEmailAliases ?? [];

  const [generatePitchModalState, setGeneratePitchModalState] = useState({
    loading: false,
    show: false,
    errorMessage: "",
    email: "",
  });
  const apiApi = useOpenApiClients()?.apiApi as ApiApi;

  const toggleShowGeneratePitchModal = (): void =>
    setGeneratePitchModalState({ ...generatePitchModalState, loading: false, show: false });
  const generatePitch = async (): Promise<void> => {
    setGeneratePitchModalState({ ...generatePitchModalState, loading: true });

    try {
      const resp = await apiApi.generateEmailOutreach({ campaign: values.id ?? "" });
      setGeneratePitchModalState({
        loading: false,
        show: true,
        errorMessage: "",
        email: resp.email,
      });
    } catch (e) {
      setGeneratePitchModalState({
        loading: false,
        show: true,
        errorMessage: "Failed to generate pitch",
        email: "",
      });
    }
  };

  const getEmailAlias = (aliasId: string): EmailSenderAlias | undefined => {
    return potentialEmailAliases.find((e: EmailSenderAlias): boolean => e.id === aliasId);
  };

  return (
    <>
      <MUIForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validateForm}
        render={({ handleSubmit }): React.ReactNode => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4} justifyContent="center">
              <Grid container justifyContent="center" item xs={12}>
                <Grid item>
                  <h1>{`Campaign Config for ${initialValues.jobName}`}</h1>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="column"
                alignItems="stretch"
                justifyContent="flex-start"
                item
                xs={6}
                lg={3}
              >
                <Grid container justifyContent="center" item>
                  <Grid item>
                    <h3>{`Settings`}</h3>
                  </Grid>
                </Grid>
                <Grid item>
                  <StyledTextField name="name" label="Campaign Name" variant="outlined" required={true} />
                  <Divider />
                </Grid>
                <Grid item>
                  <StyledAutocomplete
                    name="emailAlias"
                    label="Email Sender"
                    options={potentialEmailAliases.map((e: EmailSenderAlias): string | undefined => e.id)}
                    getOptionLabel={(aliasId: any): string => {
                      const alias = getEmailAlias(aliasId);
                      return alias ? `${alias.fullName} (${alias?.email ?? "Not set yet"})` : "";
                    }}
                    renderOption={(props, aliasId: any): React.ReactNode => {
                      const alias = getEmailAlias(aliasId);
                      if (!alias) {
                        return false;
                      }
                      let aliasEmailText = "";
                      // if an alias's email is set, display that
                      if (alias.email) {
                        aliasEmailText = alias.email;
                      }
                      // else check if the suggestedAlias has been set, i.e. we have generated
                      // an alias with a suggested username but it hasn't been set up by the user yet
                      else if (alias.suggestedAlias) {
                        aliasEmailText = `${alias.suggestedAlias} (suggested)`;
                      }
                      // as the last fallback, we can derive the suggestedAlias *if* this is the default alias,
                      // meaning that the suggested username on its ClientEmailSender will be the same
                      // as the suggested username for the alias, as both email fields on a default alias
                      // and its corresponding email sender are equal
                      else if (alias.isDefault && alias.baseEmailSuggestedUsername) {
                        aliasEmailText = `${alias.baseEmailSuggestedUsername} (suggested)`;
                      } else {
                        aliasEmailText = "Not set yet";
                      }

                      const baseEmailText = alias.baseEmail || `${alias.baseEmailSuggestedUsername} (suggested)` || "";
                      return (
                        <ListItem {...props}>
                          <ListItemText
                            primary={`${alias?.fullName || "Default account (probably don't use)"}`}
                            secondary={
                              <>
                                <SmallListText>Email: {aliasEmailText}</SmallListText>
                                <SmallListText>Base email account: {baseEmailText}</SmallListText>
                                <SmallListTextBold>
                                  {/* for clarity, we will denote default aliases as NOT being aliases
                                so that they are understood as referring to the actual email sender */}
                                  {alias.isDefault ? "This is not an alias" : "This is an alias"}
                                </SmallListTextBold>
                              </>
                            }
                          />
                        </ListItem>
                      );
                    }}
                    textFieldProps={{ variant: "outlined" }}
                  />
                  <div style={{ paddingLeft: "9px" }}>
                    <a
                      href="/admin/django-admin/dover_domain_models/emailsenderalias/add/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Add email sender alias
                    </a>
                  </div>
                  <Divider />
                </Grid>
                <Grid item>
                  <StyledSelect name="sendingWindow" label="Sending Window" variant="outlined">
                    {Object.keys(ALLOWED_SENDING_WINDOWS).map(
                      (sw: string): React.ReactElement => (
                        <MenuItem key={sw} value={sw}>
                          {ALLOWED_SENDING_WINDOWS[sw as CampaignSendingWindowEnum]}
                        </MenuItem>
                      )
                    )}
                  </StyledSelect>
                </Grid>
                <Grid item>
                  <StyledSelect name="sendingDefaultTz" label="Sending Timezone" variant="outlined">
                    {ALLOWED_SENDING_TIMEZONES.map(
                      (tz: string): React.ReactElement => (
                        <MenuItem key={tz} value={tz}>
                          {tz}
                        </MenuItem>
                      )
                    )}
                  </StyledSelect>
                  <Divider />
                </Grid>
                <Grid item>
                  <StyledSelect name="campaignState" label="Campaign State" variant="outlined">
                    {Object.keys(ALLOWED_CAMPAIGN_STATES).map(
                      (st: string): React.ReactElement => (
                        <MenuItem key={st} value={st}>
                          {ALLOWED_CAMPAIGN_STATES[st as CampaignCampaignStateEnum]}
                        </MenuItem>
                      )
                    )}
                  </StyledSelect>
                </Grid>
                <Grid item>
                  <StyledSelect name="setupState" label="Campaign Setup State" variant="outlined">
                    {ALLOWED_SETUP_STATES.map(
                      (setupState: OnboardingStepState): React.ReactElement => (
                        <MenuItem key={setupState ?? "PENDING"} value={setupState ?? "PENDING"}>
                          {setupState}
                        </MenuItem>
                      )
                    )}
                  </StyledSelect>
                </Grid>
                <Grid item>
                  <StyledSelect name="userDefinedSenderUser" label="User Defined Sender User" variant="outlined">
                    {(proUsers ?? []).map(
                      (proUser: DoverUser): React.ReactElement => {
                        return (
                          <MenuItem key={proUser.pk} value={proUser.pk}>
                            {proUser.fullName}
                          </MenuItem>
                        );
                      }
                    )}
                  </StyledSelect>
                </Grid>
                <Grid container spacing={2} alignItems="stretch" item style={{ marginTop: "0.5rem" }}>
                  <Grid item>
                    <label>{"Weight: "}</label>
                  </Grid>
                  <Grid item>
                    <Field name="weight" component={XAxisSlider} />
                  </Grid>
                </Grid>
                <Grid item>
                  <Switches
                    label="Tracking"
                    name="trackingTypes"
                    color="secondary"
                    data={[
                      { label: "Opened", value: "OPENED" },
                      { label: "Link Clicked", value: "LINK_CLICKED" },
                    ]}
                  />
                </Grid>
                <Grid item>
                  <Switches
                    label="Enable Unsubscribe Footer"
                    name="enableUnsubscribeFooter"
                    data={[{ label: "Enabled", value: true }]}
                  />
                </Grid>
                <Grid item>
                  <InternalLink
                    to="/admin/django-admin/dover_domain_models/unsubscribefooter/add/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Create a new unsubscribe footer
                  </InternalLink>
                </Grid>
                <Grid container spacing={2} alignItems="stretch" item style={{ marginTop: "0.5rem" }}>
                  <Button
                    style={{ width: "100%" }}
                    size="large"
                    variant="contained"
                    disabled={generatePitchModalState.loading}
                    onClick={(): void => {
                      generatePitch();
                    }}
                  >
                    {generatePitchModalState.loading ? "Generating (10-20s)" : "Generate from pitch"}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                direction="column"
                alignItems="stretch"
                justifyContent="center"
                item
                xs={12}
                lg={7}
              >
                {(initialValues.threadMessages ?? []).map(
                  (message: CampaignMessage, idx: number): React.ReactElement => (
                    <Grid container spacing={2} justifyContent="center" item>
                      <Grid container spacing={2} direction="column" justifyContent="center" item xs={8} lg={8}>
                        <Grid item>
                          <h3 style={{ textAlign: "center" }}>{idx === 0 ? "Initial Message" : `Followup ${idx}`}</h3>
                        </Grid>
                        <Grid item>
                          <AlwaysShowError name={`threadMessages${idx}`} />
                          <Field
                            name={`threadMessages.${idx}.rawEditorState`}
                            component={CampaignMessageEditor}
                            idx={idx}
                            initialBodyHtmlTemplate={message.bodyTemplate}
                            initialSubjectHtmlTemplate={message.subjectTemplate}
                            initialRawEditorState={message.rawEditorState}
                          />
                        </Grid>
                        <Grid item>
                          <StyledAutocomplete
                            label={unsubscribeFootersLoading ? "Loading..." : "Unsubscribe Footer Override"}
                            name={`threadMessages.${idx}.unsubscribeFooterOverride`}
                            disabled={unsubscribeFootersLoading}
                            options={unsubscribeFooters || []}
                            getOptionValue={(uf: any): string => uf.id || ""}
                            getOptionLabel={(uf: any): string => uf.footer}
                            textFieldProps={{ variant: "outlined" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        direction="column"
                        alignItems="stretch"
                        justifyContent="center"
                        item
                        xs={4}
                        lg={3}
                      >
                        <Grid item>
                          <StyledSelect
                            name={`threadMessages.${idx}.campaignMessageState`}
                            label="Message State"
                            variant="outlined"
                          >
                            {Object.keys(ALLOWED_MESSAGE_STATES).map(
                              (st: string): React.ReactElement => (
                                <MenuItem key={st} value={st}>
                                  {ALLOWED_MESSAGE_STATES[st as CampaignMessageCampaignMessageStateEnum]}
                                </MenuItem>
                              )
                            )}
                          </StyledSelect>
                        </Grid>
                        {idx !== 0 && (
                          <Grid container spacing={2} alignItems="stretch" justifyContent="center" item>
                            <Grid item>
                              <label>{"Delay (days): "}</label>
                            </Grid>
                            <Grid item>
                              <Field name={`threadMessages.${idx}.minMessageDelay`} component={XAxisSlider} xmax={10} />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
              <FormSpy subscription={{ dirty: true, values: true }}>
                {(props: FormSpyRenderProps): React.ReactElement => (
                  <Grid container spacing={2} justifyContent="center" item xs={12}>
                    <Grid item>
                      <Button
                        size="large"
                        variant="contained"
                        onClick={(): void => sendTestEmails(values)}
                        disabled={!props.values.emailAlias || !props.values.emailAliasInfo?.email}
                      >
                        Send Test Emails
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={!props.dirty}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </FormSpy>
            </Grid>
            <FormSpy
              subscription={{ values: true }}
              onChange={(state: any): void => {
                setValues(state.values);
              }}
            />
          </form>
        )}
      />

      <Modal
        open={generatePitchModalState.show}
        onClose={toggleShowGeneratePitchModal}
        title="Generated Outreach"
        maxWidth="md"
      >
        <TextWithNewLinesAndLinks text={generatePitchModalState.email ?? generatePitchModalState.errorMessage} />
      </Modal>
    </>
  );
};

function validateForm(values: Campaign): ValidationErrors {
  const errors: any = {};

  if (!values.name) {
    errors.name = "Please set a name";
  }

  if (!values.sendingWindow) {
    errors.sendingWindow = "Please select a sending window";
  }

  if (!values.sendingDefaultTz) {
    errors.sendingDefaultTz = "Please select a default sending timezone";
  }

  if (!values.campaignState) {
    errors.campaignState = "Please select a state";
  }

  if (!values.weight && values.campaignState === "ACTIVE") {
    errors.campaignState = "Campaign cannot be active if weight is zero";
  }

  if (values.campaignState !== "INACTIVE" && values.threadMessages && values.threadMessages[0]) {
    const rawEditorState = values.threadMessages[0].rawEditorState as any;
    if (rawEditorState) {
      const body = rawEditorState.bodyHtml;
      const regex = /\{\{\bFIRST_NAME\b\}\}/i;
      if (!!body && !body.match(regex)) {
        errors.threadMessages0 = "Initial email body must contain '{{FIRST_NAME}}'";
      }
    }
  }

  return errors;
}

export default CampaignForm;
