/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseJobSetupProUser
 */
export interface BaseJobSetupProUser {
    /**
     * 
     * @type {number}
     * @memberof BaseJobSetupProUser
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseJobSetupProUser
     */
    readonly firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseJobSetupProUser
     */
    readonly lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseJobSetupProUser
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseJobSetupProUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BaseJobSetupProUser
     */
    pictureUrl?: string | null;
}

export function BaseJobSetupProUserFromJSON(json: any): BaseJobSetupProUser {
    return BaseJobSetupProUserFromJSONTyped(json, false);
}

export function BaseJobSetupProUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseJobSetupProUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': json['email'],
        'pictureUrl': !exists(json, 'picture_url') ? undefined : json['picture_url'],
    };
}

export function BaseJobSetupProUserToJSON(value?: BaseJobSetupProUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'picture_url': value.pictureUrl,
    };
}


