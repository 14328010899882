import { useEffect } from "react";
import userflow from "userflow.js";

import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { useHasSeenOnboardingFlowQuery } from "services/doverapi/endpoints/user-onboarding-flow/userOnboardingFlow";
import { useAuth0 } from "services/react-auth0-spa";

export function useIdentifyUserInUserFlow(): void {
  userflow.init("ct_z5h2nqygqbfvxne3mnylpx7hk4");

  const { isAuthenticated, user } = useAuth0();
  const doverPlan = useGetDoverPlan();
  const { data: seenUserOnboardingFlow, isLoading: isLoadingUserOnboardingFlow } = useHasSeenOnboardingFlowQuery();

  useEffect(() => {
    // do nothing on local host
    if (process.env.NODE_ENV === "development") {
      return;
    }

    if (isAuthenticated && !!user && !!doverPlan && !isLoadingUserOnboardingFlow) {
      try {
        userflow.identify(user.email, {
          name: user.name,
          email: user.email,
          dover_plan: doverPlan,
          // store whether they have seen the onboarding flow here
          // it is equivalent to whether they have an oboarding flow with OnboardingState is not null
          saw_onboarding_flow: !!seenUserOnboardingFlow && !!seenUserOnboardingFlow.hasSeenOnboardingFlow,
        });
      } catch (error) {
        console.error("Error identifying user in Userflow", error);
      }
    }
    // NOTE: We are disabling the eslint rule to get more fine grained control over when this effects runs
    // This is to stop spamming the userflow client which is rate limited
    // But be careful when changing this effect to make sure the dependency array is still appropriate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doverPlan,
    isAuthenticated,
    isLoadingUserOnboardingFlow,
    seenUserOnboardingFlow?.hasSeenOnboardingFlow,
    user.name,
    user.email,
  ]);
}
