import { Box, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownCaretSVG } from "assets/icons/chevron-right.svg";
import { FilledButton, FilledRedButton, GhostButton } from "components/Button";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import {
  DropdownToggleVariant,
  ReviewActionsDropdown,
} from "components/move-candidate-to-different-job/ReviewActionsDropdown";
import { colors } from "styles/theme";
import { truncate } from "utils/strings";

export enum ComboButtonVariant {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
  Critical = "CRITICAL",
  LightCritical = "LIGHT_CRITICAL",
  Ghost = "GHOST",
}

const DownCaretSVGStyled = styled(DownCaretSVG)`
  transform: rotate(90deg);
`;

const OutlinedGhostButton = styled(GhostButton)`
  &.MuiButton-root {
    &:hover {
      color: inherit;
      background-color: ${colors.grayscale.gray100};
    }
    padding: 0px;
    margin: 0px;
  }
  padding: 0px;
  margin: 0px;
  border: 1px solid ${colors.grayscale.gray200};
`;

const LightCriticalButton = styled(OutlinedGhostButton)`
  &.MuiButton-root {
    &:hover {
      background-color: ${colors.white};
    }
  }
  background-color: ${colors.critical.light};
`;

interface ComboButtonProps {
  label: string;
  disabled?: boolean;
  mainButtonDisabled?: boolean;
  overflowActions: Array<{ icon?: React.ReactElement; label: string; onClick: React.MouseEventHandler }>;
  onClick: React.MouseEventHandler;
  variant?: ComboButtonVariant;
  icon?: React.ReactElement;
  hideLabel?: boolean;
  // we can feed which label is currently selected, so as to highlight which label is selected by boldening it in the dropdown
  selectedLabel?: string;
  dropdownToggleId?: string;
}

const ComboButton = ({
  label,
  disabled,
  mainButtonDisabled,
  overflowActions,
  onClick,
  variant = ComboButtonVariant.Primary,
  icon,
  hideLabel,
  selectedLabel,
  dropdownToggleId,
}: ComboButtonProps): React.ReactElement => {
  const MAX_BUTTON_LABEL_LIMIT = 30;
  const [showTooltip, setShowTooltip] = useState(false);

  let svgColor;
  if (variant === ComboButtonVariant.Ghost) {
    svgColor = colors.grayscale.gray700;
  } else if (variant === ComboButtonVariant.LightCritical) {
    svgColor = colors.critical.base;
  } else if (variant === ComboButtonVariant.Secondary) {
    svgColor = colors.grayscale.gray400;
  } else {
    svgColor = colors.white;
  }

  const toggleContent = <DownCaretSVGStyled className="svg-color" color={svgColor} />;

  const toggleStyle = {
    width: "40px",
    padding: "12px",
    borderRadius: "0 4px 4px 0",
    height: "100%",
  };

  const hasOverflowActions = overflowActions.length > 0;

  const comboButtonComponent = useMemo((): React.ReactElement => {
    if (variant === ComboButtonVariant.Critical) {
      return (
        <FilledRedButton
          padding={hideLabel ? "10px 8px" : "10px 16px"}
          $fontSize="14px"
          style={{
            height: "100%",
            borderRadius: hasOverflowActions ? "4px 0 0 4px" : "4px",
            whiteSpace: "nowrap",
            minWidth: hideLabel ? "48px" : "64px",
          }}
          onClick={onClick}
          disabled={disabled || mainButtonDisabled}
        >
          {!!icon && icon}
          {!hideLabel && truncate(label, MAX_BUTTON_LABEL_LIMIT)}
        </FilledRedButton>
      );
    } else if (variant === ComboButtonVariant.LightCritical) {
      return (
        <LightCriticalButton
          $fontSize="14px"
          style={{
            borderRadius: hasOverflowActions ? "4px 0 0 4px" : "4px",
            width: "100%",
            display: "flex",
          }}
          onClick={onClick}
          disabled={disabled}
        >
          {/* These stacks are used such that the calibration mode in saap looks good (ie in Sourcing/CandidateCard.tsx) */}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-start"
            padding="7px 12px"
            paddingLeft="9px"
            width="100%"
            height="100%"
          >
            {!!icon && icon}
            {!hideLabel && truncate(label, MAX_BUTTON_LABEL_LIMIT)}
          </Stack>
        </LightCriticalButton>
      );
    } else if (variant === ComboButtonVariant.Ghost) {
      return (
        <OutlinedGhostButton
          $fontSize="14px"
          style={{
            borderRadius: hasOverflowActions ? "4px 0 0 4px" : "4px",
            width: "100%",
          }}
          onClick={onClick}
          disabled={disabled}
        >
          {/* These stacks are used such that the calibration mode in saap looks good (ie in Sourcing/CandidateCard.tsx) */}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-start"
            padding="7px 12px"
            paddingLeft="9px"
            width="100%"
          >
            {!!icon && icon}
            {!hideLabel && truncate(label, MAX_BUTTON_LABEL_LIMIT)}
          </Stack>
        </OutlinedGhostButton>
      );
    } else if (variant === ComboButtonVariant.Secondary) {
      return (
        /* TODO: Using our official Button component here, but should update all to use this */
        <Button
          removePadding={hideLabel}
          width={hideLabel ? "40px" : "auto"}
          borderRadius="4px 0 0 4px"
          height="100%"
          variant={ButtonVariant.Secondary}
          onClick={onClick}
          disabled={disabled || mainButtonDisabled}
        >
          {!!icon && icon}
          <BodySmall>{!hideLabel && truncate(label, MAX_BUTTON_LABEL_LIMIT)}</BodySmall>
        </Button>
      );
    }
    return (
      <FilledButton
        padding={hideLabel ? "10px 0px" : "10px 16px"}
        style={{
          height: "100%",
          borderRadius: hasOverflowActions ? "4px 0 0 4px" : "4px",
          whiteSpace: "nowrap",
          minWidth: hideLabel ? "48px" : "64px",
        }}
        $fontSize="14px"
        onClick={onClick}
        disabled={disabled || mainButtonDisabled}
      >
        {!!icon && icon}
        {!hideLabel && truncate(label, MAX_BUTTON_LABEL_LIMIT)}
      </FilledButton>
    );
  }, [disabled, hasOverflowActions, hideLabel, icon, label, mainButtonDisabled, onClick, variant]);

  let toggleVariant: DropdownToggleVariant;

  if (variant === ComboButtonVariant.Ghost) {
    toggleVariant = DropdownToggleVariant.Ghost;
  } else if (variant === ComboButtonVariant.LightCritical) {
    toggleVariant = DropdownToggleVariant.LightCritical;
  } else if (variant === ComboButtonVariant.Critical) {
    toggleVariant = DropdownToggleVariant.Critical;
  } else if (variant === ComboButtonVariant.Secondary) {
    toggleVariant = DropdownToggleVariant.Secondary;
  } else {
    toggleVariant = DropdownToggleVariant.Primary;
  }

  return (
    <Tooltip title={label} variant={TooltipVariant.Dark} placement="top" open={showTooltip}>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
        onMouseEnter={(): void => setShowTooltip(hideLabel || label.length > MAX_BUTTON_LABEL_LIMIT)}
        onMouseLeave={(): void => setShowTooltip(false)}
        style={{ whiteSpace: "nowrap" }}
      >
        {comboButtonComponent}
        <ReviewActionsDropdown
          actions={overflowActions}
          toggleContent={toggleContent}
          toggleStyle={toggleStyle}
          disabled={disabled}
          toggleVariant={toggleVariant}
          selectedLabel={selectedLabel}
          dropdownToggleId={dropdownToggleId}
        />
      </Box>
    </Tooltip>
  );
};

export default ComboButton;
