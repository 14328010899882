import { startCase, toLower } from "lodash";

export const titleCase = (str: string): string => startCase(toLower(str));

export const trimLastNameOfFullName = (fullName: string): string => {
  const parts = fullName.split(" ");
  const lastPart = parts.pop();

  if (parts.length > 0 && lastPart) {
    return `${parts.join(" ")} ${lastPart.slice(0, 1)}.`;
  }

  return fullName;
};

export const getFullNameInitials = (fullName: string): string => {
  const parts = fullName.split(" ").map(part => part.slice(0, 1));
  return parts.join("");
};

export const truncate = (label: string, limit: number, suffix?: string): string => {
  if (label.length > limit) {
    return `${label.substring(0, limit)}...` + `...${suffix ?? ""}`;
  } else {
    return label;
  }
};

export const removeLineBreakingElementsFromHTML = (html: string): string => {
  return html.replace(/<br>|<div\b[^>]*>|<\/div>|<p\b[^>]*>|<\/p>/g, " ");
};

// take a string and escape any special characters within it so that the string can be safely used as a literal string
// i.e. searching against "foo.bar" will match "foo.bar" instead of "foobar"
export const escapeRegExp = (value: string): string => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};
