import CalendarSVG from "assets/icons/calendar.svg";
import CheckSVG from "assets/icons/check.svg";
import ClockSVG from "assets/icons/clock.svg";
import ContactedSVG from "assets/icons/contacted.svg";
import EyeSVG from "assets/icons/eye.svg";
import InboundSVG from "assets/icons/inbound-black.svg";
import InterviewSVG from "assets/icons/interview.svg";
import NewLeadSVG from "assets/icons/new-lead.svg";
import PenSVG from "assets/icons/pen-tool.svg";
import PhoneSVG from "assets/icons/phone.svg";
import TakeHomeSVG from "assets/icons/take-home.svg";
import {
  BaseCandidatePipelineStage,
  ContactedSubstageEnum,
  HiringPipelineStage,
  HiringPipelineStageMilestone,
  HiringPipelineStageType,
  InterviewSubstageEnum,
} from "services/openapi";
import {
  isInterviewStage,
  isContactedStage,
  isRespondedStage,
  isQueuedStage,
  isAppReviewStage,
  isInitialCallStage,
  isOfferStage,
  isTakeHomeStage,
} from "utils/isStage";

export const getIcon = (stage: BaseCandidatePipelineStage): string | undefined => {
  const getInterviewSubstageIcon = (substage: number): string | undefined => {
    switch (substage) {
      case InterviewSubstageEnum.SCHEDULING_1:
      case InterviewSubstageEnum.SCHEDULING_2:
      case InterviewSubstageEnum.SCHEDULING_3:
        return ClockSVG;
      case InterviewSubstageEnum.SCHEDULED:
        return CalendarSVG;
      case InterviewSubstageEnum.COMPLETED:
        return CheckSVG;
    }
    return undefined;
  };

  // pre interview stages first
  if (isQueuedStage(stage)) {
    return NewLeadSVG;
  }
  if (isContactedStage(stage)) {
    return ContactedSVG;
  }
  if (isAppReviewStage(stage)) {
    return InboundSVG;
  }
  if (isRespondedStage(stage)) {
    return EyeSVG;
  }

  // interview stages
  if (isTakeHomeStage(stage)) {
    return TakeHomeSVG;
  }
  if (isInitialCallStage(stage)) {
    const substageIcon = stage.substage && getInterviewSubstageIcon(stage.substage);
    return substageIcon || PhoneSVG;
  }
  if (isInterviewStage(stage)) {
    const substageIcon = stage.substage && getInterviewSubstageIcon(stage.substage);
    return substageIcon || InterviewSVG;
  }

  // post interview stages
  if (isOfferStage(stage)) {
    return PenSVG;
  }
};

export interface StageSelectValue {
  id: string;
  name: string;
  stageType: HiringPipelineStageType;
  milestone: HiringPipelineStageMilestone | null;
  substage: number;
}

/**
 * Render the HiringPipelineStages with the sub-stage appended to it as select options.
 *
 * HiringPipelineStage names are: Slack Review, Initial Call, Second Interview, Take-Home, Onsite, Offer Extended
 *
 * The following have no substages:
 * Stage 400 (Slack Review)
 * Stage 800 (Offer Extended)
 *
 * The following have substages (Scheduling, Scheduled, Completed):
 *
 * Stage 500 (Initial Call)
 * Stage 600 (Second Interview | Take-Home)
 * Stage 650 (Second Interview | Take-Home)
 * Stage 700 (Onsite)
 *
 * https://github.com/doverhq/dover/blob/master/dover-django/dover_domain_models/enums.py#L361-L440
 *
 * @param job
 * @param isAdmin Only show all the stages if the user is an admin
 * @return Option[]
 */
export const getStageOptions = ({
  stages,
  mainStagesOnly,
}: {
  stages: HiringPipelineStage[];
  mainStagesOnly?: boolean;
}): StageSelectValue[] => {
  // hard coded list of stages
  const options: StageSelectValue[] = [];

  stages?.forEach(stage => {
    options.push({
      name: stage.name,
      id: stage.id,
      stageType: stage.stageType,
      milestone: stage.milestone,
      substage: 0, // base
    });
    if (isInterviewStage(stage) && !mainStagesOnly) {
      options.push({
        name: stage.name + " - Scheduling",
        id: stage.id,
        stageType: stage.stageType,
        milestone: stage.milestone,
        substage: InterviewSubstageEnum.SCHEDULING_1,
      });
      options.push({
        name: stage.name + " - Scheduled",
        id: stage.id,
        stageType: stage.stageType,
        milestone: stage.milestone,
        substage: InterviewSubstageEnum.SCHEDULED,
      });
      options.push({
        name: stage.name + " - Completed",
        id: stage.id,
        stageType: stage.stageType,
        milestone: stage.milestone,
        substage: InterviewSubstageEnum.COMPLETED,
      });
    } else if (isContactedStage(stage)) {
      options.push({
        name: stage.name + " - Follow up 1",
        id: stage.id,
        stageType: stage.stageType,
        milestone: stage.milestone,
        substage: ContactedSubstageEnum.OUTREACH_1_FOLLOW_UP_1,
      });
      options.push({
        name: stage.name + " - Follow up 2",
        id: stage.id,
        stageType: stage.stageType,
        milestone: stage.milestone,
        substage: ContactedSubstageEnum.OUTREACH_1_FOLLOW_UP_2,
      });
      // TODO do we want follow up 3
      options.push({
        name: stage.name + " - Follow up 3",
        id: stage.id,
        stageType: stage.stageType,
        milestone: stage.milestone,
        substage: ContactedSubstageEnum.OUTREACH_1_FOLLOW_UP_3,
      });
    }
  });
  return options;
};

/**
 * Util wihch returns the stage option that matches the candidate.
 * Is particularly useful when an exact match doesn't exist (ie candidate has substage 200, but the stage options only go up to 0 or 300)
 *
 * @param stages - the stage options which we are showing in the stage select component (should come from the function above called getStageOptions)
 * @param candidatePipelineStage - the candidate pipeline stage of the candidate in question
 * @returns the stage option that matches the candidate's stage
 */
export const findCandidateStageFromOptions = ({
  stages,
  candidatePipelineStage,
}: {
  stages: StageSelectValue[];
  candidatePipelineStage: BaseCandidatePipelineStage;
}): StageSelectValue | undefined => {
  // First, filter for stages with the same id as the candidate's
  const stageMatches = stages.filter(stageOption => stageOption.id === candidatePipelineStage.id);

  if (!stageMatches.length) {
    return undefined;
  }

  // Sort the matching stages by substage in descending order to prioritize higher substages first
  const sortedMatches = stageMatches.sort((a, b) => b.substage - a.substage);

  // Find the stage whose substage is the same or the closest lower one to the candidate's substage
  const targetStage = sortedMatches.find(stageOption => stageOption.substage <= (candidatePipelineStage.substage || 0));

  return targetStage;
};
