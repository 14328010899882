import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Stack } from "@mui/material";
import React from "react";

import { Tooltip } from "components/library/Tooltip";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";

export const AvailabilityDebugger = ({
  availabilityDebuggerLink,
}: {
  availabilityDebuggerLink: string | undefined;
}): React.ReactElement => {
  if (!availabilityDebuggerLink) {
    return <></>;
  }

  return (
    <Tooltip title="You can see when candidates are able to schedule interviews on your calendar." placement="top">
      <ExternalLink display="inline" href={availabilityDebuggerLink} target="_blank" rel="noopener noreferrer">
        <Stack direction="row" alignItems="center">
          <OpenInNewIcon sx={{ color: colors.informational.base, width: "16px", height: "16px" }} />
          <Spacer width={4} />
          <span>See my availability</span>
        </Stack>
      </ExternalLink>
    </Tooltip>
  );
};
