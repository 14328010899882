import { Box } from "@mui/material";
import React from "react";

import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { ListCampaign } from "services/openapi";
import CampaignCard from "views/job/JobSetup/steps/CampaignVariants/CampaignCard";
import CampaignCardV2 from "views/job/JobSetup/steps/CampaignVariants/CampaignCardV2";

const CampaignCards = ({
  campaigns,
  setSelectedCampaignId,
  selectedCampaignId,
}: {
  campaigns: ListCampaign[] | undefined;
  setSelectedCampaignId: (selectedCampaignId: string) => void;
  selectedCampaignId: string;
}): React.ReactElement => {
  const useSourcingPage = useFeatureFlag(FeatureFlag.SourcingPage);
  return (
    <Box display="flex" maxWidth="800px" sx={{ overflowX: "auto", flexWrap: "nowrap" }}>
      {(campaigns ?? []).map(campaign => {
        const isSelected = campaign.id === selectedCampaignId;
        return useSourcingPage ? (
          <CampaignCardV2 campaign={campaign} />
        ) : (
          <CampaignCard isSelected={isSelected} setSelectedCampaignId={setSelectedCampaignId} campaign={campaign} />
        );
      })}
    </Box>
  );
};

export default CampaignCards;
