import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsIcon from "@mui/icons-material/Settings";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { Box, ListItemButton, ListSubheader, Menu, MenuItem, Stack } from "@mui/material";
import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import styled from "styled-components";

import { BodySmall, Caption, Subtitle2 } from "components/library/typography";
import { Role, useHasRole } from "components/RBAC";
import { useAuth0 } from "services/react-auth0-spa";
import { colors, filters } from "styles/theme";

const StyledMuiListItem = styled(ListItemButton)<{ children: any }>`
  &.active {
    & .MuiListItemText-root {
      color: ${colors.primary.base};
    }
    & .MuiListItemIcon-root {
      color: ${colors.primary.base};
    }
    & .MuiListItemIcon-root > .side-nav-icon > svg {
      filter: ${filters.filterDarkGreen};
    }
    & .MuiListItemIcon-root > .MuiBadge-root {
      color: ${colors.primary.base};
    }
    box-shadow: 5px 0px 0px 0px ${colors.primary.base} inset;
  }

  & .MuiListItemIcon-root {
    color: ${colors.grayscale.gray600};
  }

  &.MuiButtonBase-root {
    display: flex;
    justify-content: center;
    padding: 18px 8px;
  }
`;

const StyledMuiListSubheader = styled(ListSubheader)`
	font-family: "Inter",
	font-size: 12px,
	font-weight: 500,
  line-height: 0;
`;

const MoreMenuSubheader = ({ label }: { label?: React.ReactNode }): React.ReactElement => (
  <StyledMuiListSubheader disableSticky>{label}</StyledMuiListSubheader>
);

const ListItemMenu = ({
  name,
  email,
  Icon,
  children,
}: {
  name?: string;
  email?: string;
  Icon?: any;
  children: any;
}): React.ReactElement => {
  const [menuAnchorEl, setMenuAnchorClose] = React.useState(null);

  const handleMoreMenuItemClick = (event: React.MouseEvent<any>): void => {
    setMenuAnchorClose(event.currentTarget);
  };
  const handleMoreMenuItemClose = (): void => {
    setMenuAnchorClose(null);
  };

  return (
    <>
      <StyledMuiListItem
        onClick={handleMoreMenuItemClick}
        sx={{
          padding: "4px !important",
          borderRadius: "6px",
          ":hover": { backgroundColor: colors.grayscale.gray200 },
        }}
      >
        <Stack direction="row" spacing={1} width="100%" alignItems="center">
          <Box display="flex" alignItems="center" flexShrink={0}>
            {Icon}
          </Box>
          {(name || email) && (
            <Stack width="100%" minWidth={0}>
              {name && <Subtitle2 ellipsis>{name}</Subtitle2>}
              {email && <Caption ellipsis>{email}</Caption>}
            </Stack>
          )}
        </Stack>
      </StyledMuiListItem>

      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(menuAnchorEl)}
        onClose={handleMoreMenuItemClose}
        MenuListProps={{
          onMouseLeave: handleMoreMenuItemClose,
          onClick: handleMoreMenuItemClose,
        }}
        PaperProps={{
          sx: { minWidth: "200px" },
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export const ProfileListItem = (): React.ReactElement => {
  const { user, logout } = useAuth0();
  const isAdmin = useHasRole(Role.ADMIN);
  const isCoordinator = useHasRole(Role.COORDINATOR);

  const logoutWithRedirect = (): void =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <ListItemMenu
      Icon={
        <img src={user.picture} alt="Profile" className="nav-user-profile rounded-circle" style={{ width: "24px" }} />
      }
    >
      <MenuItem {...{ component: RouterNavLink, to: "/settings" }}>
        <SettingsIcon sx={{ width: "25px", marginRight: "0.5em" }} />
        <BodySmall>Settings</BodySmall>
      </MenuItem>
      <MenuItem onClick={logoutWithRedirect}>
        <PowerSettingsNewIcon sx={{ width: "25px", marginRight: "0.5em" }} />
        <BodySmall>Log out</BodySmall>
      </MenuItem>
      {/* ADMIN ONLY */}
      {(isAdmin || isCoordinator || user.impersonated) && <MoreMenuSubheader label="Admin" />}
      {(isAdmin || user.impersonated) && (
        <MenuItem {...{ component: RouterNavLink, to: "/admin/impersonator" }}>
          <SwitchAccountIcon sx={{ width: "25px", marginRight: "0.5em" }} />
          <BodySmall>Impersonator</BodySmall>
        </MenuItem>
      )}
    </ListItemMenu>
  );
};
