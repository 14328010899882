import { Stack, useMediaQuery, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import styled from "styled-components";

import ConciergeUpgradeModal from "components/dover/dover-upgrade-modal/ConciergeUpgradeModal";
import DoverPlanCard from "components/dover/dover-upgrade-modal/DoverPlanCard";
import { UpgradeModalProps } from "components/dover/top-level-modal-manager/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import CustomModal from "components/Modal";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { useRequestPlanUpgradeMutation } from "services/doverapi/endpoints/planManagement";
import { ClientDoverPlanEnum } from "services/openapi";
import { colors } from "styles/theme";

const PricingPageButton = styled.span`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: ${colors.linkLight};
  }
`;

/**
 *
 * @param isOpen If true modal is open
 * @param closeModal Callback to close modal
 * @param plans Which Dover plans to include, includes all by default. First item in array will be the one initially selected
 */
const UpgradeModal = ({
  isOpen,
  closeModal,
  plans = [ClientDoverPlanEnum.Growth, ClientDoverPlanEnum.Concierge],
}: UpgradeModalProps): React.ReactElement => {
  const [selectedDoverPlanType, setSelectedDoverPlanType] = React.useState<ClientDoverPlanEnum>(plans[0]);
  const currentDoverPlan = useGetDoverPlan();

  const [requestPlanUpgrade, { isLoading: isRequestingPlanUpgrade }] = useRequestPlanUpgradeMutation();

  const onCancel = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onTalkToSales = React.useCallback(() => {
    requestPlanUpgrade({ requestedDoverPlan: selectedDoverPlanType }).then(() => {
      closeModal();
    });
  }, [requestPlanUpgrade, selectedDoverPlanType, closeModal]);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(768));

  const onPricingPageClick = React.useCallback(() => {
    window.open(`https://dover.com/pricing`, "_blank", "noreferrer");
  }, []);

  const includeGrowth = plans.includes(ClientDoverPlanEnum.Growth);
  const includeConcierge = plans.includes(ClientDoverPlanEnum.Concierge);

  if (_.isEqual(plans, [ClientDoverPlanEnum.Concierge])) {
    return <ConciergeUpgradeModal isOpen={isOpen} closeModal={closeModal} />;
  }
  return (
    <CustomModal
      open={isOpen}
      onClose={closeModal}
      title={
        <Body weight="600">
          {"Upgrade your plan ("}
          <PricingPageButton onClick={onPricingPageClick}>{"More Information"}</PricingPageButton>
          {")"}
        </Body>
      }
      maxWidth={"md"}
      customDialogStyles={{ zIndex: 9999 }}
      customContentStyles={{ padding: "0px" }}
      dialogActions={
        <Stack width={"100%"} spacing={2} direction="row" justifyContent="flex-end" padding="12px">
          <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
            {"Cancel"}
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            onClick={onTalkToSales}
            disabled={currentDoverPlan === selectedDoverPlanType}
            loading={isRequestingPlanUpgrade}
          >
            {"Talk to sales"}
          </Button>
        </Stack>
      }
      dialogActionsStyles={{ justifyContent: "space-between" }}
    >
      <Stack padding="16px 24px" spacing={2}>
        <Stack
          direction={smallScreen ? "column" : "row"}
          justifyContent="center"
          sx={{ overflowX: "auto", paddingY: "12px" }}
          spacing={2}
        >
          {includeGrowth && (
            <DoverPlanCard
              cardPlanType={ClientDoverPlanEnum.Growth}
              selectedPlanType={selectedDoverPlanType}
              header={"Growth"}
              callToActionText={"Save up to $4k/mo on Sourcing\n"}
              subtitleText={"All of the Base features plus:"}
              additionsText={[
                "Unlimited candidates and hires for up to 5 simultanous jobs with Sourcing Autopilot",
                "Advanced search criteria filters",
                "In-depth reporting incorporating ATS data",
                "Dedicated customer success manager",
              ]}
              setPlanType={setSelectedDoverPlanType}
            />
          )}
          {includeConcierge && (
            <DoverPlanCard
              cardPlanType={ClientDoverPlanEnum.Concierge}
              selectedPlanType={selectedDoverPlanType}
              header={"Concierge"}
              callToActionText={"Get expert help to drive recruiting results"}
              subtitleText={"Everything in Growth, plus:"}
              additionsText={[
                "Recruiting consultants to drive strategy and results",
                "Expert interviewers to quickly discover top candidates",
                "Phone screen to offer scheduling",
              ]}
              setPlanType={setSelectedDoverPlanType}
            />
          )}
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default UpgradeModal;
