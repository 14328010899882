import { zodResolver } from "@hookform/resolvers/zod";
import { Divider, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isEqual } from "lodash";
import React from "react";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { ZodIssue } from "zod";

import { CandidateFeedbackContext, CandidateFeedbackType } from "contexts/feedback-context/CandidateFeedbackContext";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetSearchV3Query, usePartialUpdateSearchV3Mutation } from "services/doverapi/endpoints/search-v3/endpoints";
import { SearchV3CandidateFeedback } from "services/openapi";
import { colors } from "styles/theme";
import { EventSink } from "utils/EventSink";
import AllFilters from "views/sourcing/Search/components/AllFilters";
import SourcingCandidateView from "views/sourcing/Search/components/SourcingCandidateView";
import TopBar from "views/sourcing/Search/components/TopBar";
import { searchV3FormDefaultValues } from "views/sourcing/Search/constants";
import { FormLoadStateContext, FormLoadStateWrapper } from "views/sourcing/Search/context/FilterToggleContext";
import { useSearchId, useSetFormDirty } from "views/sourcing/Search/hooks";
import { searchV3FormSchema, SearchV3FormSchemaType, SourcingContext } from "views/sourcing/Search/types";
import { getSearchV3FromFormState } from "views/sourcing/Search/utils";

interface SearchViewParam {
  eventSink?: EventSink<void>;
  context?: SourcingContext;
  setDisabledNext?: (disabled: boolean) => void;
}

const SearchViewWithContexts = ({ eventSink, context, setDisabledNext }: SearchViewParam): React.ReactElement => {
  // Sets up the initial form state and auto-validates using zod
  const searchV3FormMethods = useForm<SearchV3FormSchemaType>({
    defaultValues: searchV3FormDefaultValues,
    resolver: zodResolver(searchV3FormSchema),
  });

  return (
    <FormProvider {...searchV3FormMethods}>
      <FormLoadStateWrapper>
        <SearchView eventSink={eventSink} context={context} setDisabledNext={setDisabledNext} />
      </FormLoadStateWrapper>
    </FormProvider>
  );
};

const SearchView = ({ eventSink, context, setDisabledNext }: SearchViewParam): React.ReactElement => {
  // Sets up the initial form state and auto-validates using zod
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<SearchV3FormSchemaType>();

  // Keep track of when form values change
  const values = useWatch({ control });

  const customerOnBasePlan = useIsBasePlanCustomer();

  const candidateFeedback = React.useContext(CandidateFeedbackContext);
  const formLoadState = React.useContext(FormLoadStateContext);

  const v3CandidateFeedback = React.useMemo<SearchV3CandidateFeedback[] | undefined>(() => {
    // Data is irrelevant for base plan and we don't wan to accidentally use it
    if (customerOnBasePlan) {
      return undefined;
    }

    if (!candidateFeedback?.candidateFeedback) {
      return undefined;
    }

    return Object.entries(candidateFeedback.candidateFeedback).map(([key, value]) => ({
      candidateId: key,
      approved: value.type === CandidateFeedbackType.Approve,
      rejectionReason: value.rejectionReason,
      linkedinProfileUrl: value.linkedInUrl,
    }));
  }, [candidateFeedback?.candidateFeedback, customerOnBasePlan]);

  const searchId = useSearchId();
  const { data: search, isLoading: isSearchLoading } = useGetSearchV3Query(searchId ? { id: searchId } : skipToken);
  const [partialUpdateSearch] = usePartialUpdateSearchV3Mutation();

  const onClickSaveSearch = React.useCallback(() => {
    // Early return if the form state isn't legit
    // We'll handle errors at the top level of the Sourcing page
    const formParseResult = searchV3FormSchema.safeParse(values);
    if (!formParseResult.success) {
      return;
    }

    // If a search is undefined or loading, we don't wish to proceed
    if (search === undefined || !searchId || isSearchLoading) {
      return;
    }

    // After updates, we'll get a referentially different search object from RTKQ
    // It's important the we check deep equality against the new search so we don't perform an additional unnecessary update
    const newSearch = getSearchV3FromFormState(formParseResult.data, search);
    if (isEqual(search, newSearch)) {
      return;
    }

    partialUpdateSearch({ id: searchId, data: { ...search, ...newSearch, v3CandidateFeedback } });
  }, [values, search, searchId, isSearchLoading, partialUpdateSearch, v3CandidateFeedback]);

  const [formDirty, setFormDirty] = React.useState<boolean>(false);
  useSetFormDirty(formDirty, setFormDirty, !!formLoadState?.loaded);

  // If values change and form data is loaded, then reset the feedback
  React.useEffect(() => {
    // Nothing to do here if customer is on the base plan
    if (customerOnBasePlan) {
      return;
    }

    if (context === SourcingContext.CreateJob && formDirty && values && candidateFeedback?.hasFeedback) {
      candidateFeedback?.resetFeedback();
    }
  }, [context, formDirty, values, candidateFeedback, customerOnBasePlan]);

  React.useEffect(() => {
    eventSink?.unregisterAll();
    eventSink?.register(onClickSaveSearch);
  }, [eventSink, onClickSaveSearch]);

  React.useEffect(() => {
    if (setDisabledNext) {
      if (Object.keys(errors).length === 0) {
        setDisabledNext(false);
      } else {
        setDisabledNext(true);
      }
    }
  }, [setDisabledNext, errors]);

  React.useEffect(() => {
    // If the form parsing fails, we want to track the errors that occur
    const formParseResult = searchV3FormSchema.safeParse(values);
    if (!formParseResult.success) {
      const flattenedErrors = formParseResult.error.flatten((issue: ZodIssue) => ({
        message: issue.message,
        name: issue.path,
      }));

      // Manually set all the errors we find as a workaround to a bug in zod that doesn't auto-refresh the error set
      Object.entries(flattenedErrors.fieldErrors).forEach(([key, value]) => {
        if (value !== undefined && value.length > 0) {
          setError(key as any, { type: "custom", message: value[0].message });
        } else {
          clearErrors(key as any);
        }
      });

      // Since the form was not parsed correctly, stop here
      return;
    }

    // If the form state is legit, clear out any errors that were there before
    clearErrors();
  }, [clearErrors, setError, values]);

  return (
    <Stack width="100%" height="100%">
      <TopBar context={context} />
      <Stack
        direction="row"
        overflow="auto"
        pb={1}
        divider={<Divider orientation="vertical" flexItem sx={{ borderColor: colors.grayscale.gray200 }} />}
      >
        <AllFilters />
        <SourcingCandidateView context={context} />
      </Stack>
    </Stack>
  );
};

export default SearchViewWithContexts;
