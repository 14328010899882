import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";

import { useGetClientOnboardingQuery, useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import {
  useGetOrCreateUserOnboardingFlowMutation,
  useListUserOnboardingFlowsQuery,
} from "services/doverapi/endpoints/user-onboarding-flow/userOnboardingFlow";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { UseMutationResult } from "services/doverapi/types";
import {
  ClientDoverPlanEnum,
  ClientOnboardingAtsTypeEnum,
  GetOrCreateUserOnboardingFlowRequestContentTypeEnum,
  GetOrCreateUserOnboardingFlowRequestFlowNameEnum,
  UserOnboardingFlow,
  UserOnboardingFlowFlowNameEnum,
} from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";

export function useGetClientId(): string | undefined {
  const { data: client } = useGetUsersClientQuery();
  return useMemo(() => {
    return client?.id;
  }, [client?.id]);
}

export function useGetClientName(): string | undefined {
  const { data: client } = useGetUsersClientQuery();
  return useMemo(() => {
    return client?.name;
  }, [client?.name]);
}

export function useGetDoverPlan(): ClientDoverPlanEnum | undefined {
  const { loading, user } = useAuth0();
  const { data: client } = useGetUsersClientQuery(!loading && user ? undefined : skipToken);
  return useMemo(() => {
    return client?.doverPlan;
  }, [client?.doverPlan]);
}

export function useShouldShowRealProfiles(): boolean {
  const doverPlan = useGetDoverPlan();
  // NOTE: The list of plans needs to be kept in sync with the list of plans in
  // the backend
  return useMemo(() => {
    return (
      !!doverPlan &&
      [
        ClientDoverPlanEnum.JobSlots,
        ClientDoverPlanEnum.Credits,
        ClientDoverPlanEnum.Concierge,
        ClientDoverPlanEnum.Growth,
        ClientDoverPlanEnum.PayAsYouGo,
      ].includes(doverPlan)
    );
  }, [doverPlan]);
}

/**
 * Retrieves the UserOnboardingFlow for the current user and client.
 *
 * Replaces the useGetOnboardingFlow hook below, which should be deprecated after the ATS launch.
 *
 * @returns an RTK Mutation UseMutationResult object with data and states for loading, fetching, errors, etc.
 */
export const useGetUserOnboardingFlow = (
  onboardingFlowType: UserOnboardingFlowFlowNameEnum
): UseMutationResult<UserOnboardingFlow> => {
  // For the initial onboarding flows, we will ensure that there only exists
  // one flow type per user. Because of this, we can simply use the flow name
  // to query for the flow or create it if it doesn't exist.
  const { loading, user } = useAuth0();
  const [
    getOrCreateUserOnboardingFlow,
    {
      isLoading: isLoadingGetOrCreate,
      isSuccess: isSuccessGetOrCreate,
      isUninitialized: isUninitializedGetOrCreate,
      isError: isErrorGetOrCreate,
    },
  ] = useGetOrCreateUserOnboardingFlowMutation();

  const {
    data: userOnboardingFlowsState,
    isLoading: isLoadingList,
    isSuccess: isSuccessList,
    isUninitialized: isUninitializedList,
    isError: isErrorList,
    refetch: refetchList,
  } = useListUserOnboardingFlowsQuery(
    !loading && user ? { flowName: onboardingFlowType, proUserUserEmail: user.email } : skipToken
  );

  const isLoading = isLoadingGetOrCreate || isLoadingList;
  const isSuccess = isSuccessGetOrCreate || isSuccessList;
  const isUninitialized = isUninitializedGetOrCreate || isUninitializedList;
  const isError = isErrorGetOrCreate || isErrorList;

  const userOnboardingFlow = useMemo(() => {
    if (isSuccessList && userOnboardingFlowsState) {
      const flows = listAllEntities(userOnboardingFlowsState);
      if (flows.length > 0) {
        return flows[0];
      }
    }
    return undefined;
  }, [isSuccessList, userOnboardingFlowsState]);

  React.useEffect(() => {
    const createFlow = async (): Promise<void> => {
      await getOrCreateUserOnboardingFlow({
        flowName: (onboardingFlowType as unknown) as GetOrCreateUserOnboardingFlowRequestFlowNameEnum,
      });
      refetchList();
    };
    // If no flows exist for the given user and flow type, create one
    if (!loading && user && !userOnboardingFlow && isSuccessList) {
      createFlow();
    }
  }, [
    userOnboardingFlow,
    getOrCreateUserOnboardingFlow,
    loading,
    onboardingFlowType,
    refetchList,
    user,
    isSuccessList,
  ]);

  return { data: userOnboardingFlow, isLoading, isSuccess, isUninitialized, isError };
};

/**
 * @deprecated
 * Retrieves the UserOnboardingFlow for the current user and client, accounting for the client's Dover plan
 *
 * @returns an RTK Mutation UseMutationResult object with data and states for loading, fetching, errors, etc.
 */
export function useGetOnboardingFlow(
  onboardingFlowType?: UserOnboardingFlowFlowNameEnum,
  contentType?: GetOrCreateUserOnboardingFlowRequestContentTypeEnum,
  relatedObjectUuid?: string
): UseMutationResult<UserOnboardingFlow> {
  const { loading, user } = useAuth0();

  const {
    data: userOnboardingFlowsState,
    isLoading: isLoadingList,
    isSuccess: isSuccessList,
    isUninitialized: isUninitializedList,
    isError: isErrorList,
  } = useListUserOnboardingFlowsQuery(
    !loading && user && !!contentType == !!relatedObjectUuid && onboardingFlowType
      ? { flowName: onboardingFlowType, proUserUserEmail: user.email, contentTypeModel: contentType, relatedObjectUuid }
      : skipToken
  );

  const isLoading = isLoadingList;
  const isSuccess = isSuccessList;
  const isUninitialized = isUninitializedList;
  const isError = isErrorList;

  // There will only ever be one userOnboardingFlow in this list, because we are filtering on pro user and flow name.
  const userOnboardingFlow = useMemo(() => {
    if (userOnboardingFlowsState) {
      const flows = listAllEntities(userOnboardingFlowsState);
      if (flows && flows.length === 1) {
        return flows[0];
      }
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOnboardingFlowsState]);

  return { data: userOnboardingFlow, isLoading, isSuccess, isUninitialized, isError };
}

export function useIsBasePlanCustomer(): boolean {
  const doverPlan = useGetDoverPlan();
  return useMemo(() => {
    return (
      !!doverPlan &&
      [
        ClientDoverPlanEnum.Free,
        ClientDoverPlanEnum.PayAsYouGo,
        ClientDoverPlanEnum.FreeAts,
        ClientDoverPlanEnum.FreeExtension,
      ].includes(doverPlan)
    );
  }, [doverPlan]);
}

export function useIsPaygoCustomer(): boolean {
  const doverPlan = useGetDoverPlan();
  return doverPlan === ClientDoverPlanEnum.PayAsYouGo;
}

export function useIsContractPlanCustomer(): boolean {
  const doverPlan = useGetDoverPlan();
  return useMemo(() => {
    return (
      !!doverPlan &&
      [ClientDoverPlanEnum.JobSlots, ClientDoverPlanEnum.Credits, ClientDoverPlanEnum.Concierge].includes(doverPlan)
    );
  }, [doverPlan]);
}

export function useIsGrowthCustomer(): boolean {
  const doverPlan = useGetDoverPlan();
  return useMemo(() => {
    return !!doverPlan && doverPlan === ClientDoverPlanEnum.Growth;
  }, [doverPlan]);
}

export function useIsFreeCustomer(): boolean {
  const doverPlan = useGetDoverPlan();

  return useMemo(() => {
    return !!doverPlan && doverPlan === ClientDoverPlanEnum.Free;
  }, [doverPlan]);
}

export function useIsFreeATSCustomer(): boolean {
  const doverPlan = useGetDoverPlan();
  return useMemo(() => {
    return !!doverPlan && doverPlan === ClientDoverPlanEnum.FreeAts;
  }, [doverPlan]);
}

export function useIsCreditsCustomer(): boolean {
  const doverPlan = useGetDoverPlan();
  return useMemo(() => {
    return !!doverPlan && doverPlan === ClientDoverPlanEnum.Credits;
  }, [doverPlan]);
}

export function useGetCompanyAtsType(): ClientOnboardingAtsTypeEnum | undefined {
  const { data: clientOnboarding } = useGetClientOnboardingQuery();
  return clientOnboarding?.atsType;
}

export function useGetCompanyAtsIsLever(): boolean | undefined {
  const atsType = useGetCompanyAtsType();
  if (!atsType) {
    return undefined;
  }

  return atsType === ClientOnboardingAtsTypeEnum.Lever;
}

export function useClientIsOnFreeAts(): boolean {
  const doverPlan = useGetDoverPlan();
  return useMemo(() => {
    return doverPlan === ClientDoverPlanEnum.FreeAts;
  }, [doverPlan]);
}

export const useGetClientIsTam = (): boolean | null | undefined => {
  const { loading, user } = useAuth0();
  const { data: client } = useGetUsersClientQuery(!loading && user ? undefined : skipToken);

  return client?.isTam;
};

/**
 * If a customer is on a Base plan, Growth, or Concierge, this returns a human readable plan name
 * Otherwise, it returns undefined
 */
export function useGetPlanNameForNonLegacyPlans(): string | undefined {
  const doverPlan = useGetDoverPlan();
  return useMemo(() => {
    if (!doverPlan) {
      return undefined;
    }

    if ([ClientDoverPlanEnum.Free, ClientDoverPlanEnum.PayAsYouGo].includes(doverPlan)) {
      return "Base";
    }

    if (doverPlan === ClientDoverPlanEnum.Growth) {
      return "Growth";
    }

    if (doverPlan === ClientDoverPlanEnum.Concierge) {
      return "Concierge";
    }

    return undefined;
  }, [doverPlan]);
}

interface ConciergeInfo {
  name: string;
  firstName?: string;
  lastName?: string;
  pictureUrl?: string;
}

export function useConciergeInfo(): ConciergeInfo | undefined {
  const { data: client } = useGetUsersClientQuery();

  if (client?.csm) {
    return {
      name: client.csm.name,
      firstName: client.csm.firstName,
      lastName: client.csm.proUser?.lastName,
      pictureUrl: client.csm.proUser?.pictureUrl,
    };
  }

  return undefined;
}
