import { Box, Step, StepButton, Stepper } from "@mui/material";
import React, { useState, FC } from "react";

import CandidateDetailsForm from "components/dover/AddCandidate/CandidateDetailsForm";
import { FooterSpacer } from "components/dover/AddCandidate/Footer";
import CustomModal from "components/Modal";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { colors } from "styles/theme";

/* -----------------------------------------------------------------------------
 * Form Wrapper
 * -------------------------------------------------------------------------- */
const steps = [
  { label: "Candidate details", component: CandidateDetailsForm },
  // NOTE: this is a placeholder component to satisfy the prop requirements
  { label: "Preview and send email", component: CandidateDetailsForm },
];

const StepButtonStyles = {
  ".MuiStepIcon-root": {
    color: colors.white,
    border: `1px solid ${colors.grayscale.gray300}`,
    borderRadius: "50%",
    ".MuiStepIcon-text": {
      fill: colors.grayscale.gray500,
    },
    ".MuiStepLabel-labelContainer": {
      color: colors.grayscale.gray500,
    },
    "&.Mui-active": {
      color: colors.grayscale.gray500,
      border: "none",
      ".MuiStepIcon-text": {
        fill: colors.white,
      },
      ".MuiStepLabel-labelContainer": {
        color: colors.grayscale.gray700,
      },
    },
    "&.Mui-completed": {
      color: colors.primary.base,
      border: "none",
    },
  },
};

const AddCandidateToCampaignFormWrapper: FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<Record<number, boolean>>({});

  const handleComplete = (): void => {
    setCompleted(prevCompleted => ({ ...prevCompleted, [activeStep]: true }));
  };

  const handleNext = (): void => {
    handleComplete();
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleChangeStep = (step: number): void => {
    setActiveStep(step);
  };

  const ActiveStepComponent = steps[activeStep].component;

  return (
    <Box position="relative">
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={step.label} completed={completed[index]}>
            <StepButton color="inherit" onClick={(): void => handleChangeStep(index)} disabled sx={StepButtonStyles}>
              {step.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box
        p={2}
        bgcolor={colors.grayscale.gray100}
        sx={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "16px" }}
      >
        <ActiveStepComponent handleNextStep={handleNext} handlePrevStep={handleBack} />
      </Box>
      <FooterSpacer />
    </Box>
  );
};

/* -----------------------------------------------------------------------------
 * Main Component
 * -------------------------------------------------------------------------- */

export const AddCandidateToCampaignModal: React.FC<GlobalModalProps> = ({ isOpen, close }) => {
  return (
    <>
      <CustomModal title="Add candidate to campaign" open={isOpen} onClose={close} maxWidth="sm">
        <AddCandidateToCampaignFormWrapper />
      </CustomModal>
    </>
  );
};

export const addCandidateToCampaignModalAtom = modalAtom(AddCandidateToCampaignModal);
