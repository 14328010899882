import { Box, Select, SelectChangeEvent, Skeleton, Stack } from "@mui/material";
import { isNil } from "lodash";
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { ReactSVG } from "react-svg";

import { ReactComponent as DownCaretSVG } from "assets/icons/caret-down-black.svg";
import { useGetDisableCandidateStageOrStatusSelect } from "components/dover/CandidateStageSelect/hooks";
import { StyledMenuItem } from "components/dover/CandidateStageSelect/styles";
import {
  getStageOptions,
  getIcon,
  StageSelectValue,
  findCandidateStageFromOptions,
} from "components/dover/CandidateStageSelect/utils";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import { Role, useHasRole } from "components/RBAC";
import { useModal } from "GlobalOverlays/atoms";
import { useCandidateIsOfferOrHired } from "hooks/useCandidateIsOfferOrHired";
import { useGetCandidateBioQuery, useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import { candidateStageStatusConfirmModal } from "views/CandidateDetail/components/StageAndStatusCard/CandidateStageStatusConfirmModal";
import { useRealHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

// I really don't know how to find this type
// Will look harder another time
const DownArrowIcon = (props: any): ReactElement => (
  // We need to override the mui default top property to properly center the icon
  <Box {...props} top="auto !important">
    <DownCaretSVG width="30px" />
  </Box>
);

export const StageSelect = ({ candidateId }: { candidateId: string; disabled?: boolean }): React.ReactElement => {
  const [value, setValue] = useState<StageSelectValue | null | undefined>(undefined);

  const { data: candidateBio, isLoading: isLoadingCandidateBio } = useGetCandidateBioQuery(candidateId);
  const [updateCandidateBio] = useUpdateCandidateBioMutation();

  const candidateIsOfferOrHired = useCandidateIsOfferOrHired(candidateBio);
  const { open } = useModal(candidateStageStatusConfirmModal);

  const isAdmin = useHasRole(Role.ADMIN);

  const { stages, isFetching: isFetchingHPSs } = useRealHps(candidateBio?.job);

  const stageOptions = React.useMemo(() => {
    if (!stages) {
      return [];
    }
    return getStageOptions({ stages, mainStagesOnly: !isAdmin });
  }, [isAdmin, stages]);

  const { disabled, tooltipText } = useGetDisableCandidateStageOrStatusSelect(candidateBio, candidateBio?.job);

  const currPipelineStageName = useMemo(() => {
    return isNil(value) ? "Change stage" : value.name;
  }, [value]);

  useEffect(() => {
    // Initialize value
    const foundStage =
      candidateBio?.candidatePipelineStage?.id &&
      findCandidateStageFromOptions({
        stages: stageOptions,
        candidatePipelineStage: candidateBio?.candidatePipelineStage,
      });

    if (foundStage && !value) {
      setValue(foundStage);
    }
  }, [candidateBio, stageOptions, stages, value]);

  const handleUpdateStage = useCallback(
    (newVal: StageSelectValue): void => {
      const doUpdate = (): void => {
        updateCandidateBio({
          id: candidateId!,
          jobId: candidateBio?.job,
          data: { currentPipelineStage: newVal.id, currentPipelineSubstage: newVal.substage },
          hideToast: false,
        });
        setValue(newVal);
      };

      if (candidateIsOfferOrHired) {
        open({
          confirm: () => {
            doUpdate();
          },
        });
      } else {
        doUpdate();
      }
    },
    [candidateBio, candidateId, candidateIsOfferOrHired, open, updateCandidateBio, setValue]
  );

  if (isLoadingCandidateBio || isFetchingHPSs) {
    return <Skeleton height="40px" width="100px" />;
  }

  if (!candidateBio) {
    return <></>;
  }

  return (
    <Tooltip title={tooltipText}>
      <Box>
        <Select
          sx={{ width: "100%" }}
          size="small"
          disabled={disabled}
          IconComponent={DownArrowIcon}
          value={value}
          onChange={(e: SelectChangeEvent<StageSelectValue | null>): void => {
            if (!e.target.value) {
              return;
            }

            handleUpdateStage(e.target.value as StageSelectValue);
          }}
          displayEmpty
          renderValue={(): React.ReactNode => {
            const icon = value && getIcon(value);
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                {icon && <ReactSVG src={icon} />}
                <BodySmall ellipsis>{currPipelineStageName}</BodySmall>
              </Stack>
            );
          }}
        >
          {stageOptions.map(stageObj => {
            return (
              <StyledMenuItem
                onClick={(): void => {
                  handleUpdateStage(stageObj);
                }}
                className={value && stageObj.id === value.id && stageObj.substage === value.substage ? "active" : ""}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ReactSVG src={getIcon(stageObj) ?? ""} />
                  <BodyExtraSmall>{stageObj.name}</BodyExtraSmall>
                </Stack>
              </StyledMenuItem>
            );
          })}
        </Select>
      </Box>
    </Tooltip>
  );
};
