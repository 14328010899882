/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArchiveReason,
    ArchiveReasonFromJSON,
    ArchiveReasonFromJSONTyped,
    ArchiveReasonToJSON,
    BaseCandidatePipelineStage,
    BaseCandidatePipelineStageFromJSON,
    BaseCandidatePipelineStageFromJSONTyped,
    BaseCandidatePipelineStageToJSON,
    CandidateBioAts,
    CandidateBioAtsFromJSON,
    CandidateBioAtsFromJSONTyped,
    CandidateBioAtsToJSON,
    CandidateBioClientEmailAlias,
    CandidateBioClientEmailAliasFromJSON,
    CandidateBioClientEmailAliasFromJSONTyped,
    CandidateBioClientEmailAliasToJSON,
    CandidateBioCompletedInterview,
    CandidateBioCompletedInterviewFromJSON,
    CandidateBioCompletedInterviewFromJSONTyped,
    CandidateBioCompletedInterviewToJSON,
    CandidateBioContactInfo,
    CandidateBioContactInfoFromJSON,
    CandidateBioContactInfoFromJSONTyped,
    CandidateBioContactInfoToJSON,
    CandidateBioJobStage,
    CandidateBioJobStageFromJSON,
    CandidateBioJobStageFromJSONTyped,
    CandidateBioJobStageToJSON,
    CandidateBioSocialLink,
    CandidateBioSocialLinkFromJSON,
    CandidateBioSocialLinkFromJSONTyped,
    CandidateBioSocialLinkToJSON,
    CandidateBioSource,
    CandidateBioSourceFromJSON,
    CandidateBioSourceFromJSONTyped,
    CandidateBioSourceToJSON,
    Contact,
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
    NextAction,
    NextActionFromJSON,
    NextActionFromJSONTyped,
    NextActionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidateBio
 */
export interface CandidateBio {
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof CandidateBio
     */
    readonly created?: Date;
    /**
     * 
     * @type {CandidateBioAts}
     * @memberof CandidateBio
     */
    ats?: CandidateBioAts;
    /**
     * 
     * @type {Contact}
     * @memberof CandidateBio
     */
    contact: Contact;
    /**
     * 
     * @type {Array<CandidateBioContactInfo>}
     * @memberof CandidateBio
     */
    readonly contactInfo?: Array<CandidateBioContactInfo>;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    readonly currentEmployment?: string;
    /**
     * 
     * @type {BaseCandidatePipelineStage}
     * @memberof CandidateBio
     */
    candidatePipelineStage?: BaseCandidatePipelineStage;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    readonly job?: string;
    /**
     * 
     * @type {Array<CandidateBioJobStage>}
     * @memberof CandidateBio
     */
    readonly jobStages?: Array<CandidateBioJobStage>;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    readonly jobTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof CandidateBio
     */
    readonly jobHiringManagerId?: number;
    /**
     * 
     * @type {number}
     * @memberof CandidateBio
     */
    readonly jobRecruiterId?: number;
    /**
     * 
     * @type {NextAction}
     * @memberof CandidateBio
     */
    nextAction?: NextAction;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    readonly oldJobTitle?: string;
    /**
     * 
     * @type {CandidateBioSource}
     * @memberof CandidateBio
     */
    searchSource?: CandidateBioSource;
    /**
     * 
     * @type {Array<CandidateBioSocialLink>}
     * @memberof CandidateBio
     */
    readonly socialLinks?: Array<CandidateBioSocialLink>;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    status?: CandidateBioStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    readonly slackUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    schedulingOwnership?: CandidateBioSchedulingOwnershipEnum;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    readonly client?: string;
    /**
     * 
     * @type {CandidateBioClientEmailAlias}
     * @memberof CandidateBio
     */
    clientSchedulingEmailAlias?: CandidateBioClientEmailAlias;
    /**
     * 
     * @type {Array<CandidateBioCompletedInterview>}
     * @memberof CandidateBio
     */
    readonly completedInterviews?: Array<CandidateBioCompletedInterview>;
    /**
     * 
     * @type {CandidateBioClientEmailAlias}
     * @memberof CandidateBio
     */
    currentEmailSenderAlias?: CandidateBioClientEmailAlias;
    /**
     * 
     * @type {number}
     * @memberof CandidateBio
     */
    readonly actionVersion?: number;
    /**
     * 
     * @type {ArchiveReason}
     * @memberof CandidateBio
     */
    archiveReason?: ArchiveReason;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    pendingCustomerResponse?: CandidateBioPendingCustomerResponseEnum;
    /**
     * 
     * @type {string}
     * @memberof CandidateBio
     */
    readonly inboundApplicationId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateBio
     */
    readonly isDemoInboundCand?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateBio
     */
    readonly isStarred?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CandidateBioStatusEnum {
    Withdrew = 'WITHDREW',
    Hired = 'HIRED',
    Rejected = 'REJECTED',
    Snoozed = 'SNOOZED',
    Ignored = 'IGNORED'
}/**
* @export
* @enum {string}
*/
export enum CandidateBioSchedulingOwnershipEnum {
    DoverHandlesScheduling = 'DOVER_HANDLES_SCHEDULING',
    CustomerHandlesScheduling = 'CUSTOMER_HANDLES_SCHEDULING'
}/**
* @export
* @enum {string}
*/
export enum CandidateBioPendingCustomerResponseEnum {
    Question = 'QUESTION',
    QuestioningFit = 'QUESTIONING_FIT',
    OtherRole = 'OTHER_ROLE',
    SchedulingHold = 'SCHEDULING_HOLD'
}

export function CandidateBioFromJSON(json: any): CandidateBio {
    return CandidateBioFromJSONTyped(json, false);
}

export function CandidateBioFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateBio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'ats': !exists(json, 'ats') ? undefined : CandidateBioAtsFromJSON(json['ats']),
        'contact': ContactFromJSON(json['contact']),
        'contactInfo': !exists(json, 'contact_info') ? undefined : ((json['contact_info'] as Array<any>).map(CandidateBioContactInfoFromJSON)),
        'currentEmployment': !exists(json, 'current_employment') ? undefined : json['current_employment'],
        'candidatePipelineStage': !exists(json, 'candidate_pipeline_stage') ? undefined : BaseCandidatePipelineStageFromJSON(json['candidate_pipeline_stage']),
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'job': !exists(json, 'job') ? undefined : json['job'],
        'jobStages': !exists(json, 'job_stages') ? undefined : ((json['job_stages'] as Array<any>).map(CandidateBioJobStageFromJSON)),
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'jobHiringManagerId': !exists(json, 'job_hiring_manager_id') ? undefined : json['job_hiring_manager_id'],
        'jobRecruiterId': !exists(json, 'job_recruiter_id') ? undefined : json['job_recruiter_id'],
        'nextAction': !exists(json, 'next_action') ? undefined : NextActionFromJSON(json['next_action']),
        'oldJobTitle': !exists(json, 'old_job_title') ? undefined : json['old_job_title'],
        'searchSource': !exists(json, 'search_source') ? undefined : CandidateBioSourceFromJSON(json['search_source']),
        'socialLinks': !exists(json, 'social_links') ? undefined : ((json['social_links'] as Array<any>).map(CandidateBioSocialLinkFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'slackUrl': !exists(json, 'slack_url') ? undefined : json['slack_url'],
        'schedulingOwnership': !exists(json, 'scheduling_ownership') ? undefined : json['scheduling_ownership'],
        'client': !exists(json, 'client') ? undefined : json['client'],
        'clientSchedulingEmailAlias': !exists(json, 'client_scheduling_email_alias') ? undefined : CandidateBioClientEmailAliasFromJSON(json['client_scheduling_email_alias']),
        'completedInterviews': !exists(json, 'completed_interviews') ? undefined : ((json['completed_interviews'] as Array<any>).map(CandidateBioCompletedInterviewFromJSON)),
        'currentEmailSenderAlias': !exists(json, 'current_email_sender_alias') ? undefined : CandidateBioClientEmailAliasFromJSON(json['current_email_sender_alias']),
        'actionVersion': !exists(json, 'action_version') ? undefined : json['action_version'],
        'archiveReason': !exists(json, 'archive_reason') ? undefined : ArchiveReasonFromJSON(json['archive_reason']),
        'pendingCustomerResponse': !exists(json, 'pending_customer_response') ? undefined : json['pending_customer_response'],
        'inboundApplicationId': !exists(json, 'inbound_application_id') ? undefined : json['inbound_application_id'],
        'isDemoInboundCand': !exists(json, 'is_demo_inbound_cand') ? undefined : json['is_demo_inbound_cand'],
        'isStarred': !exists(json, 'is_starred') ? undefined : json['is_starred'],
    };
}

export function CandidateBioToJSON(value?: CandidateBio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ats': CandidateBioAtsToJSON(value.ats),
        'contact': ContactToJSON(value.contact),
        'candidate_pipeline_stage': BaseCandidatePipelineStageToJSON(value.candidatePipelineStage),
        'next_action': NextActionToJSON(value.nextAction),
        'search_source': CandidateBioSourceToJSON(value.searchSource),
        'status': value.status,
        'scheduling_ownership': value.schedulingOwnership,
        'client_scheduling_email_alias': CandidateBioClientEmailAliasToJSON(value.clientSchedulingEmailAlias),
        'current_email_sender_alias': CandidateBioClientEmailAliasToJSON(value.currentEmailSenderAlias),
        'archive_reason': ArchiveReasonToJSON(value.archiveReason),
        'pending_customer_response': value.pendingCustomerResponse,
    };
}


