import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useState } from "react";
import { useBoolean } from "react-use";

import { feedbackTemplateDrawerConfigAtom } from "components/dover/FeedbackTemplates/atoms";
import CreateFeedbackTemplateForm from "components/dover/FeedbackTemplates/CreateFeedbackTemplate";
import { DrawerHeader } from "components/dover/FeedbackTemplates/DrawerHeaderFooter";
import DuplicateFeedbackTemplateForm from "components/dover/FeedbackTemplates/DuplicateFeedbackTemplate";
import EditFeedbackTemplateForm from "components/dover/FeedbackTemplates/EditFeedbackTemplate";
import { useFeedbackTemplateFormIsDirty, useReset } from "components/dover/FeedbackTemplates/hooks";
import {
  FeedbackTemplateCreateMode,
  FeedbackTemplateDrawerContextType,
  FeedbackTemplateFormProps,
} from "components/dover/FeedbackTemplates/types";
import { ButtonRadio } from "components/library/ButtonRadio";
import Drawer from "components/library/Drawer";
import { UnsavedChangesModal } from "components/UnsavedChangesModal";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";

/* -----------------------------------------------------------------------------
 * Form Wrapper
 * -------------------------------------------------------------------------- */

const FeedbackTemplateForm: React.FC<FeedbackTemplateFormProps> = ({ validateOnClose }) => {
  const [createMode, setCreateMode] = useState<FeedbackTemplateCreateMode>(FeedbackTemplateCreateMode.FromTemplate);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useBoolean(false);

  const isDirty = useFeedbackTemplateFormIsDirty();
  const resetAtomState = useReset();

  const feedbackTemplateDrawerConfig = useAtomValue(feedbackTemplateDrawerConfigAtom);

  if (feedbackTemplateDrawerConfig.context === FeedbackTemplateDrawerContextType.Edit) {
    return <EditFeedbackTemplateForm validateOnClose={validateOnClose} />;
  }

  if (feedbackTemplateDrawerConfig.context === FeedbackTemplateDrawerContextType.Duplicate) {
    return <DuplicateFeedbackTemplateForm validateOnClose={validateOnClose} />;
  }

  const onDiscardUnsavedChanges = (): void => {
    setShowUnsavedChangesModal(false);
    resetAtomState();
    setCreateMode(
      createMode === FeedbackTemplateCreateMode.FromTemplate
        ? FeedbackTemplateCreateMode.FromScratch
        : FeedbackTemplateCreateMode.FromTemplate
    );
  };

  const handleSetCreateMode = (mode: FeedbackTemplateCreateMode): void => {
    if (isDirty) {
      setShowUnsavedChangesModal(true);
      return;
    }
    setCreateMode(mode);
  };

  return (
    <>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onCancel={setShowUnsavedChangesModal}
        onDiscard={onDiscardUnsavedChanges}
      />
      <Stack p={2} spacing={2}>
        <Stack direction="row" spacing={1}>
          <ButtonRadio
            active={createMode === FeedbackTemplateCreateMode.FromTemplate}
            onClick={(): void => handleSetCreateMode(FeedbackTemplateCreateMode.FromTemplate)}
            label="Start from template"
          />
          <ButtonRadio
            active={createMode === FeedbackTemplateCreateMode.FromScratch}
            onClick={(): void => handleSetCreateMode(FeedbackTemplateCreateMode.FromScratch)}
            label="Start from scratch"
          />
        </Stack>
        <CreateFeedbackTemplateForm
          validateOnClose={validateOnClose}
          fromScratch={createMode === FeedbackTemplateCreateMode.FromScratch}
        />
      </Stack>
    </>
  );
};

/* -----------------------------------------------------------------------------
 * Main Component
 * -------------------------------------------------------------------------- */

const FeedbackTemplateDrawer: React.FC<GlobalModalProps> = ({ isOpen, close: onClose }) => {
  const isDirty = useFeedbackTemplateFormIsDirty();
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useBoolean(false);
  const resetAtomState = useReset();

  const onDiscardUnsavedChanges = (): void => {
    setShowUnsavedChangesModal(false);
    resetAtomState();
    onClose();
  };

  // TODO: consider how to make this a reusable atom instead of passing down
  const validateOnClose = (): void => {
    if (isDirty) {
      setShowUnsavedChangesModal(true);
      return;
    }
    onClose();
  };

  return (
    <>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onCancel={setShowUnsavedChangesModal}
        onDiscard={onDiscardUnsavedChanges}
      />
      <Drawer open={isOpen} onClose={validateOnClose} anchor="right" sx={{ zIndex: 1300 }}>
        <DrawerHeader onClose={validateOnClose} />
        <FeedbackTemplateForm validateOnClose={validateOnClose} />
      </Drawer>
    </>
  );
};

export const FeedbackTemplateDrawerAtom = modalAtom(FeedbackTemplateDrawer);
