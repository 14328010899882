import React from "react";

import {
  BulkRejectModalProps,
  CandidateActionModalProps,
  FilePreviewModalProps,
  MoveJobModalProps,
  SharedTopLevelModalProps,
  UpgradeModalProps,
} from "components/dover/top-level-modal-manager/types";

export interface TopLevelModalManagerOptions {
  closeModal: () => void;
  showUpgradeModal: (props: Omit<UpgradeModalProps, keyof SharedTopLevelModalProps>) => void;
  showMoveJobModal: (props: Omit<MoveJobModalProps, keyof SharedTopLevelModalProps>) => void;
  showCandidateActionModal: (props: Omit<CandidateActionModalProps, keyof SharedTopLevelModalProps>) => void;
  showBulkRejectModal: (props: Omit<BulkRejectModalProps, keyof SharedTopLevelModalProps>) => void;
  showFilePreviewModal: (props: Omit<FilePreviewModalProps, keyof SharedTopLevelModalProps>) => void;
}

export const TopLevelModalManagerOptionsContext: React.Context<
  TopLevelModalManagerOptions | undefined
> = React.createContext<TopLevelModalManagerOptions | undefined>(undefined);
