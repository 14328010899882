import { Alert, Stack, StackProps } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

/* -----------------------------------------------------------------------------
 * ScrollLink
 * -------------------------------------------------------------------------- */

interface ScrollLinkProps extends StackProps {
  children: ReactNode;
}

const ScrollLink = ({ children, ...rest }: ScrollLinkProps): ReactElement => {
  return (
    <Stack sx={{ cursor: "pointer", textDecoration: "underline" }} {...rest}>
      {children}
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * RequiredFieldsAlert
 * -------------------------------------------------------------------------- */

interface RequiredFieldsAlertProps {
  hasLocationError: boolean;
  hasJobDescriptionError: boolean;
  locationRef: React.RefObject<HTMLDivElement>;
  jobDescriptionRef: React.RefObject<HTMLDivElement>;
}

export const RequiredFieldsAlert = ({
  hasLocationError,
  hasJobDescriptionError,
  locationRef,
  jobDescriptionRef,
}: RequiredFieldsAlertProps): ReactElement => {
  if (!hasLocationError && !hasJobDescriptionError) {
    return <></>;
  }

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>): (() => void) => (): void => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Alert
      severity="info"
      icon={
        <Stack justifyContent="center">
          <InfoIcon />
        </Stack>
      }
      sx={{ border: `1px solid ${colors.informational.base}` }}
    >
      <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ xs: "flex-start", sm: "center" }}>
        <BodySmall>Required to post on job boards:&nbsp;</BodySmall>
        <Stack direction="row" alignItems="center">
          {hasJobDescriptionError && (
            <ScrollLink onClick={scrollToRef(jobDescriptionRef)}>
              <BodySmall>Job description</BodySmall>
            </ScrollLink>
          )}
          {hasLocationError && hasJobDescriptionError && <BodySmall>,&nbsp;</BodySmall>}
          {hasLocationError && (
            <ScrollLink onClick={scrollToRef(locationRef)}>
              <BodySmall>Location</BodySmall>
            </ScrollLink>
          )}
        </Stack>
      </Stack>
    </Alert>
  );
};
