/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePersonalizedContentResponse
 */
export interface CreatePersonalizedContentResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalizedContentResponse
     */
    bodyHtml: string | null;
}

export function CreatePersonalizedContentResponseFromJSON(json: any): CreatePersonalizedContentResponse {
    return CreatePersonalizedContentResponseFromJSONTyped(json, false);
}

export function CreatePersonalizedContentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePersonalizedContentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bodyHtml': json['body_html'],
    };
}

export function CreatePersonalizedContentResponseToJSON(value?: CreatePersonalizedContentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body_html': value.bodyHtml,
    };
}


