import { Role, useHasRole } from "components/RBAC";
import { useFeatureFlag, FeatureFlag } from "hooks/useFeatureFlag";
import { jobNavOptionsWithSourcingPage, jobNavOptions, SOURCING_AUTOPILOT_NAV_OPTION } from "views/job/constants";
import { JobNavOption } from "views/job/types";

export const useJobNavOptions = (): JobNavOption[] => {
  const useNewJobNavOptions = useFeatureFlag(FeatureFlag.SourcingPage);
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);
  const isAdmin = useHasRole(Role.ADMIN);

  const showSourcingTab = useLegacySourcingAutopilot || isAdmin;

  let navOptions = [...(useNewJobNavOptions ? jobNavOptionsWithSourcingPage : jobNavOptions)];
  if (!showSourcingTab) {
    navOptions = navOptions.filter((option: JobNavOption) => option !== SOURCING_AUTOPILOT_NAV_OPTION);
  }

  return navOptions;
};
