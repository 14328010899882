import { SaapReviewApplication, TalentNetwork } from "services/openapi";

/**
 * Find the index of an application in a list of applications
 * Returns undefined if the application is not found
 *
 * @param applications List of saap review applications
 * @param applicationId Inbound application id you are trying to find
 */
export const findApplicationIndex = (
  applications: SaapReviewApplication[] | TalentNetwork[],
  applicationId: string
): number | undefined => {
  const idx = applications.findIndex(application => application.inboundAppId === applicationId);
  return idx === -1 ? undefined : idx;
};
