import { Box, Stack } from "@mui/material";
import React from "react";

import { AccountSettingsSectionType } from "App/routing/types";
import GreenCheckSVG from "assets/icons/check-green-filled.svg";
import { ClientPickerWarningBanner } from "components/dover/ClientPickerWarningBanner";
import { GmailAuthContent } from "components/dover/GmailAuth";
import { useGetGmailAuthStatus, useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import { LoadingRelative } from "components/HotLoading";
import { Card } from "components/library/Card";
import { BodySmall } from "components/library/typography";
import { appConfig } from "config/appConfig";
import { colors } from "styles/theme";
import { StyledSVG } from "views/CompanySetup/components/GmailAuth/styles";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { accountSettingsConfiguration } from "views/CompanySetup/constants";

const GmailAuth = (): React.ReactElement => {
  const { gmailAuthStatus, isGmailAuthLoading } = useGetGmailAuthStatus({});
  const { userAuthedSuccessfully } = useUserAuthedSuccessfully();

  if (isGmailAuthLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "440px" }}>
        <LoadingRelative />
      </Box>
    );
  }
  return (
    <SectionWrapper config={accountSettingsConfiguration[AccountSettingsSectionType.EMAIL_CONNECTION]}>
      <Stack spacing={1}>
        <ClientPickerWarningBanner
          warningText={`The client picker is currently set to a different client. You can still auth your own email, but not for anyone else.`}
        />
        <Card>
          {userAuthedSuccessfully && gmailAuthStatus?.email ? (
            <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
              <Stack direction="row" spacing={0.5} alignItems={"center"}>
                <StyledSVG src={GreenCheckSVG} />
                <BodySmall color={colors.success.base}>Connected</BodySmall>{" "}
                <BodySmall>{gmailAuthStatus?.email}</BodySmall>
              </Stack>
              <BodySmall>
                <a href={`${appConfig.appUrl}/utils/auth`} target="_blank" rel="noopener noreferrer">
                  Reauth
                </a>
              </BodySmall>
            </Stack>
          ) : (
            <GmailAuthContent />
          )}
        </Card>
      </Stack>
    </SectionWrapper>
  );
};

export default GmailAuth;
