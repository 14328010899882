import { Box } from "@mui/material";
import { CompositeDecorator, EditorState } from "draft-js";
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import PaperPencilIcon from "assets/icons/paper-pencil.svg";
import { ButtonVariant } from "components/library/Button";
import MentionsEditor from "components/library/TipTap/MentionsEditor";
import { EditorType } from "components/library/TipTap/types";
import { Spacer } from "components/Spacer";
import {
  usePartialUpdateCandidateActivityNoteMutation,
  useDeleteCandidateActivityNoteMutation,
} from "services/doverapi/endpoints/candidate";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { CandidateActivityNote } from "services/openapi";
import { getHtmlBody } from "utils/draft-js-helpers";
import { draftJsLinkDecorators, getHtmlFromUnknownContent } from "utils/draftJS";
import { formatEventTimestamp } from "views/CandidateDetail/components/event-feed/Event";
import { NoteActionsMenu } from "views/CandidateDetail/components/event-feed/NoteActionsMenu";
import {
  CancelButton,
  NoteContainer,
  NoteContent,
  NoteIcon,
  NoteTitle,
  RotatedPushPinSVG,
  RotatedPushPinSVGWrapper,
  SaveButton,
  StyledBox,
  TimestampText,
} from "views/CandidateDetail/components/event-feed/styles";
import { ExtendedNote } from "views/CandidateDetail/components/EventFeed";
import { CandidateActivityNoteAuthorRole } from "views/CandidateDetail/types";

interface NoteProps {
  note: ExtendedNote;
}

export const sortNotes = (a: CandidateActivityNote, b: CandidateActivityNote): number => {
  const timestampA = (a.pinnedAt ? a.pinnedAt : a.created) || 0;
  const timestampB = (b.pinnedAt ? b.pinnedAt : b.created) || 0;
  return timestampA >= timestampB ? -1 : 1;
};

const PushPinIcon = (): ReactElement => (
  <>
    <RotatedPushPinSVGWrapper>
      <RotatedPushPinSVG />
    </RotatedPushPinSVGWrapper>
  </>
);

export const Note = ({ note }: NoteProps): ReactElement => {
  const [updateCandidateNote, { isLoading: isUpdatingNote }] = usePartialUpdateCandidateActivityNoteMutation();
  const [deleteCandidateNote] = useDeleteCandidateActivityNoteMutation();
  const { data: user } = useGetAuthedUserInfoQuery();

  const updateNoteRef = useRef<() => void>();
  const [editModeOn, setEditModeOn] = useState(false);
  const [editorState, setEditorState] = useState<EditorState | undefined>();
  const [noteInputState, setNoteInputState] = useState<string>("");
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const editorRef = useRef<any>(null);
  const editorEmpty = noteInputState.length === 0;

  useEffect(() => {
    setIsOverflow(editorRef.current && editorRef.current.scrollHeight > 65);
  }, [editModeOn, editorState, editorRef]);

  useEffect(() => {
    // However our note content was stored, canonicalize it to HTML before then handling draft-js-specific concerns
    const initialEditorState = EditorState.createWithContent(
      getHtmlBody(getHtmlFromUnknownContent(note.content ?? "")),
      new CompositeDecorator(draftJsLinkDecorators)
    );
    setEditorState(initialEditorState);

    setNoteInputState(note.content ?? "");
  }, [note.content]);

  const handleNoteClick = (): void => {
    isOverflow && setEditorExpanded(!editorExpanded);
  };

  const getAuthorName = (): string => {
    if (
      note.authorRole === CandidateActivityNoteAuthorRole.Admin ||
      note.authorRole === CandidateActivityNoteAuthorRole.Coordinator
    ) {
      return "Dover ";
    } else if (note.authorRole === CandidateActivityNoteAuthorRole.Customer && note.authorName) {
      return `${note.authorName} `;
    } else if (note.authorRole === CandidateActivityNoteAuthorRole.CSM_or_CX && note.authorName) {
      return `${note.authorName} (Dover) `;
    }
    return "Unknown ";
  };

  const deleteNote = async (): Promise<void> => {
    if (!note.id) {
      return;
    }
    await deleteCandidateNote({ candidateAcivtiyNoteId: note.id });
    setEditModeOn(false);
  };

  const updateNote = useCallback(() => {
    const updateNoteTipTapAsync = async (): Promise<void> => {
      if (!note.id) {
        return;
      }
      const updatedNote = {
        ...note,
        content: noteInputState,
      };
      await updateCandidateNote({ candidateAcivtiyNoteId: note.id, updatedNote, candidateId: note.candidate });
      setEditModeOn(false);
    };

    updateNoteTipTapAsync();
  }, [note, noteInputState, updateCandidateNote]);
  const setNotePinned = async (pinned: boolean): Promise<void> => {
    if (!note.id) {
      return;
    }
    const updatedNote = {
      ...note,
      pinnedAt: pinned ? new Date() : null,
    };
    await updateCandidateNote({ candidateAcivtiyNoteId: note.id, updatedNote, candidateId: note.candidate });
  };

  useEffect(() => {
    updateNoteRef.current = updateNote;
  }, [updateNote]);

  const onModEnter = useCallback(() => {
    if (updateNoteRef.current) {
      updateNoteRef.current();
    }
  }, []);

  // This seems to work on mac even though the keys are differently named
  // Adding both the windows and mac names seems to make the event fire twice, which is bad
  useHotkeys("ctrl+enter", updateNote, undefined, [updateNote]);

  const editor = useMemo(() => {
    return (
      <Box
        sx={{
          ".ProseMirror, .ProseMirror-focused": {
            backgroundColor: "inherit",
            padding: !editModeOn ? "0px" : "10px 8px",
            border: !editModeOn ? "none" : undefined,
            "&:focus-visible": {
              border: !editModeOn ? "none" : undefined,
            },
          },
        }}
      >
        <MentionsEditor
          initialContent={note.content ?? ""}
          onContentChange={setNoteInputState}
          readOnly={!editModeOn}
          editorType={EditorType.RawEditor}
          onModEnter={onModEnter}
        />
      </Box>
    );
  }, [editModeOn, note.content, onModEnter]);

  return (
    <NoteContainer key={note.id ?? 0} $isOverflow={isOverflow} $isPinned={!!note.pinnedAt} onClick={handleNoteClick}>
      <StyledBox display="flex" alignItems="top">
        {note.pinnedAt ? <PushPinIcon /> : <NoteIcon src={PaperPencilIcon}></NoteIcon>}
        <NoteContent>
          <NoteTitle>
            <div>
              <b>{getAuthorName()}</b>
              posted a note
            </div>
            {note.created && <TimestampText>{formatEventTimestamp(note.created)}</TimestampText>}
          </NoteTitle>
          <Spacer height={16} />
          {editor}
          <NoteActionsMenu
            showEditButton={!editModeOn && user?.id === note.author}
            showDeleteButton={!editModeOn && user?.id === note.author}
            showPinButton={true}
            setEditModeOn={(): void => {
              setEditModeOn(true);
            }}
            deleteNote={deleteNote}
            pinNote={setNotePinned}
            notePinned={!!note.pinnedAt}
          />
          {editModeOn && (
            <>
              <Spacer height={10} />
              <Box display="flex">
                <SaveButton
                  onClick={updateNote}
                  disabled={editorEmpty || isUpdatingNote}
                  variant={ButtonVariant.Primary}
                >
                  Save
                </SaveButton>
                <Spacer width={10} />
                <CancelButton
                  onClick={(): void => {
                    setEditModeOn(false);
                  }}
                  variant={ButtonVariant.Ghost}
                >
                  Cancel
                </CancelButton>
              </Box>
            </>
          )}
        </NoteContent>
      </StyledBox>
    </NoteContainer>
  );
};
