import LoadingOverlay from "react-loading-overlay";
import styled from "styled-components";

import { theme } from "styles/theme";

LoadingOverlay.propTypes = undefined;

export const CustomLoadingOverlay = styled(LoadingOverlay)`
  width: ${(props: any): string => props.width || "100%"};
  min-height: ${(props: any): string => props.minHeight || "50vh"};
  height: ${(props: any): string => props.height || "100%"};

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.fullScreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 1000;

    .custom_overlay {
      background-color: rgba(244, 246, 248, 0.5);
      z-index: 1000;
    }
  }

  .custom_overlay {
    background: none;
    z-index: 1000;
  }

  &.overlay {
    .custom_overlay {
      background-color: rgba(244, 246, 248, 0.5);
    }
  }

  .custom_content {
    text-align: center;
    color: ${theme.colors.black};
  }

  .custom_spinner {
    width: ${(props: any): string => props.spinnerSize || "60px"};
    height: ${(props: any): string => props.spinnerSize || "60px"};
    margin: 0 auto;

    svg {
      filter: ${(props: any): string => props.filter || theme.filters.filterDarkBlue};

      circle {
        stroke-width: 3px;
      }
    }
  }
`;
