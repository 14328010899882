import { Box, Radio, Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { ReactComponent as LockSVG } from "assets/icons/lock.svg";
import { ReactComponent as MailSVG } from "assets/icons/mail.svg";
import { ReactComponent as PencilEditSVG } from "assets/icons/pencil-edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash-gray.svg";
import { useContactInfo } from "components/dover/top-level-modal-manager/hooks/useContactInfo";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { SharedTopLevelModalProps } from "components/dover/top-level-modal-manager/types";
import { InfoTip } from "components/InfoTip";
import { Button, ButtonVariant } from "components/library/Button";
import { Checkbox } from "components/library/Checkbox";
import { TextField } from "components/library/TextField";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import Confirm from "components/Modal/ConfirmModal";
import {
  useAddCandidateEmailMutation,
  useDeleteCandidateEmailMutation,
  useUpdateCandidateEmailMutation,
  useLazyValidateEmailQuery,
} from "services/doverapi/endpoints/candidate/candidate-emails-endpoint";
import { CandidateBioContactInfo } from "services/openapi";
import { colors } from "styles/theme";
import { FakeLink } from "styles/typography";
import { isValidEmail } from "utils/email";
import { showErrorToast, toastOptions } from "utils/showToast";

export interface EditEmailsModalProps extends SharedTopLevelModalProps {
  candidateId: string;
  modalType: CandidateActionModalType;
}

const EditEmailsModal = ({ isOpen, closeModal, candidateId }: EditEmailsModalProps): React.ReactElement => {
  const { data: contactInfo, isFetching: isFetchingCandidateBio } = useContactInfo(candidateId);

  const [initialContact, setInitialContact] = useState<CandidateBioContactInfo | undefined>(undefined);
  const [initialSetAsDefault, setInitialSetAsDefault] = useState<boolean>(true);
  const [emailEditorOpen, setEmailEditorOpen] = useState(false);

  // endpoints
  const [deleteCandidateEmail, { isLoading: isDeleting }] = useDeleteCandidateEmailMutation();
  const [updateCandidateEmail, { isLoading: isUpdating }] = useUpdateCandidateEmailMutation();
  const [, { isLoading: isAdding }] = useAddCandidateEmailMutation();
  const [, { isLoading: isValidating }] = useLazyValidateEmailQuery();

  const openAddOrEditEmailBox = useCallback(
    async (contact?: CandidateBioContactInfo) => {
      setInitialContact(contact);
      setInitialSetAsDefault(contact?.isPrimary ?? true);
      setEmailEditorOpen(true);
    },
    [setEmailEditorOpen, setInitialContact, setInitialSetAsDefault]
  );

  // callbacks
  const updateEmailCallback = useCallback(
    async (emailId: string, email: string, isPrimary: boolean) => {
      if (!emailId) {
        showErrorToast("Failed to update email");
        return;
      }

      try {
        const updatePromise = updateCandidateEmail({ id: emailId, candidateId, email, isPrimary }).unwrap();
        await toast.promise(
          updatePromise,
          {
            pending: "Updating email...",
            success: "Email updated!",
            error: "Error updating email.",
          },
          { ...toastOptions }
        );
        setEmailEditorOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [candidateId, updateCandidateEmail, setEmailEditorOpen]
  );

  const deleteEmailCallback = useCallback(
    async (emailId?: string) => {
      if (!emailId) {
        showErrorToast("Failed to delete email");
        return;
      }

      try {
        const deletePromise = deleteCandidateEmail({ emailId, candidateId }).unwrap();
        await toast.promise(
          deletePromise,
          {
            pending: "Deleting email...",
            success: "Deleted email!",
            error: "Error deleting email.",
          },
          { ...toastOptions }
        );
      } catch (e) {
        console.error(e);
      }
    },
    [candidateId, deleteCandidateEmail]
  );

  return (
    <CustomModal
      open={isOpen}
      loading={isFetchingCandidateBio || isAdding || isUpdating || isDeleting || isValidating}
      onClose={closeModal}
      title={<Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>Contact Info</Body>}
      maxWidth="sm"
      customDialogStyles={{ ...CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES, zIndex: 1500 }}
      customContentStyles={{ ...CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES, backgroundColor: colors.white }} // There is an issue with mui text fields that only allow transparent background, so overridinng the whole modal to be white here
      dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
    >
      <Stack spacing={2} direction="column">
        {contactInfo &&
          contactInfo.map(contact => (
            <Stack spacing={1} direction="column">
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <MailSVG height="20px" />
                <BodySmall>{contact.value}</BodySmall>
                <Box sx={{ marginLeft: "auto !important" }}>
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Stack direction="row" spacing={0.5} alignItems={"center"}>
                      <Radio
                        checked={contact.isPrimary}
                        onClick={(): void => {
                          updateEmailCallback(contact.id!, contact.value, true);
                        }}
                        sx={{ padding: 0 }}
                      />
                      <BodySmall>Default</BodySmall>
                    </Stack>
                    <BodySmall color={colors.grayscale.gray400}>|</BodySmall>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems={"center"}
                      minWidth="40px"
                      sx={{ paddingBottom: contact.isEditable ? "3px" : undefined }}
                    >
                      {contact.isEditable ? (
                        <>
                          <Button
                            variant={ButtonVariant.Ghost}
                            removePadding
                            removeOutline
                            onClick={(): void => {
                              openAddOrEditEmailBox(contact);
                            }}
                          >
                            <PencilEditSVG height="20px" />
                          </Button>
                          <Confirm
                            title={"Delete email?"}
                            submitText={"Delete"}
                            content={
                              <>
                                <BodySmall weight="600" inline>
                                  Email:{" "}
                                </BodySmall>
                                <BodySmall inline>{contact.value}</BodySmall>
                              </>
                            }
                            fullWidth
                            maxWidth="xs"
                            useProcessing
                            submitVariant="critical"
                            callbackOverride={(): void => {
                              deleteEmailCallback(contact.id);
                            }}
                          >
                            {(onShowModal: any): React.ReactNode => (
                              <Button variant={ButtonVariant.Ghost} removePadding removeOutline onClick={onShowModal()}>
                                <TrashIcon height="20px" />
                              </Button>
                            )}
                          </Confirm>
                        </>
                      ) : (
                        <LockSVG height="20px" />
                      )}
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          ))}
        {emailEditorOpen && (
          <AddOrEditEmailBox
            initialContact={initialContact}
            initialSetAsDefault={initialSetAsDefault}
            candidateId={candidateId}
            setEmailEditorOpen={setEmailEditorOpen}
          />
        )}
        {!emailEditorOpen && (
          <FakeLink
            onClick={(): void => {
              openAddOrEditEmailBox();
            }}
          >
            + Add Email
          </FakeLink>
        )}
      </Stack>
    </CustomModal>
  );
};

const AddOrEditEmailBox = ({
  initialContact,
  initialSetAsDefault,
  candidateId,
  setEmailEditorOpen,
}: {
  initialContact?: CandidateBioContactInfo;
  initialSetAsDefault: boolean;
  candidateId: string;
  setEmailEditorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  const [newEmail, setNewEmail] = useState<string | undefined>(initialContact?.value);
  const [isDefault, setIsDefault] = useState(initialSetAsDefault);

  const [validateEmailTrigger] = useLazyValidateEmailQuery();
  const [addCandidateEmail] = useAddCandidateEmailMutation();
  const [updateCandidateEmail] = useUpdateCandidateEmailMutation();

  // Email validation
  const emailErrorMessage = useMemo(() => {
    if (newEmail === undefined) {
      return undefined;
    }

    if (newEmail.length === 0) {
      return "Please enter an email";
    }

    if (!isValidEmail(newEmail)) {
      return "Please enter a valid email";
    }
  }, [newEmail]);

  const validateEmailCallback = useCallback(async () => {
    try {
      const { data } = await validateEmailTrigger({
        candidateId,
        email: newEmail!,
        isPrimary: isDefault,
      });

      if (!data?.success) {
        return data?.message || "Failed to validate email.";
      }

      return "";
    } catch (e) {
      console.error(e);
      return "";
    }
  }, [validateEmailTrigger, candidateId, newEmail, isDefault]);

  const addEmailCallback = useCallback(async () => {
    const validationError = await validateEmailCallback();
    if (validationError) {
      showErrorToast(validationError);
      return;
    }

    try {
      const addEmailPromise = addCandidateEmail({ candidateId, email: newEmail!, isPrimary: isDefault }).unwrap();
      await toast.promise(
        addEmailPromise,
        {
          pending: "Adding email...",
          success: "Email added!",
          error: "Error adding email.",
        },
        { ...toastOptions }
      );
      setEmailEditorOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [validateEmailCallback, candidateId, addCandidateEmail, newEmail, isDefault, setEmailEditorOpen]);

  const updateEmailCallback = useCallback(async () => {
    if (!validateEmailCallback()) {
      return false;
    }

    try {
      const updateEmailPromise = updateCandidateEmail({
        id: initialContact!.id!,
        candidateId,
        email: newEmail!,
        isPrimary: isDefault,
      }).unwrap();
      await toast.promise(
        updateEmailPromise,
        {
          pending: "Updating email...",
          success: "Email updated!",
          error: "Error updating email.",
        },
        { ...toastOptions }
      );
      setEmailEditorOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [
    validateEmailCallback,
    updateCandidateEmail,
    candidateId,
    initialContact,
    newEmail,
    isDefault,
    setEmailEditorOpen,
  ]);

  const saveCallback = initialContact ? updateEmailCallback : addEmailCallback;

  return (
    <Stack spacing={1} direction="column" bgcolor={colors.grayscale.gray100} padding={"10px"}>
      {initialContact ? (
        <BodySmall weight="600"> Update Email Address</BodySmall>
      ) : (
        <BodySmall weight="600">Add New Email Address</BodySmall>
      )}
      <TextField
        text={newEmail || ""}
        onTextUpdated={(updatedText: string): void => {
          setNewEmail(updatedText.toLowerCase());
        }}
        error={emailErrorMessage !== undefined}
        errorMessage={emailErrorMessage}
      />
      <Stack direction="row" spacing={1} alignItems="left">
        <Checkbox
          defaultChecked={isDefault}
          onChange={(_: any): void => {
            setIsDefault(!isDefault);
          }}
        />
        <BodySmall>Set as default</BodySmall>
        <InfoTip text={"If set to default, scheduling and rejection emails will be sent to this email."} />
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Button variant={ButtonVariant.Secondary} onClick={(): void => setEmailEditorOpen(false)}>
          Cancel
        </Button>
        <Button
          variant={ButtonVariant.Primary}
          onClick={saveCallback}
          disabled={newEmail === undefined || !!emailErrorMessage}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default EditEmailsModal;
