/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontHiringStageEmailTemplate
 */
export interface FrontHiringStageEmailTemplate {
    /**
     * 
     * @type {string}
     * @memberof FrontHiringStageEmailTemplate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FrontHiringStageEmailTemplate
     */
    readonly requiredEmailAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontHiringStageEmailTemplate
     */
    readonly requiredInterviewer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontHiringStageEmailTemplate
     */
    ccEmails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontHiringStageEmailTemplate
     */
    bccEmails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FrontHiringStageEmailTemplate
     */
    readonly body?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FrontHiringStageEmailTemplate
     */
    hasSameInterviewerAndEmailSender?: boolean | null;
}

export function FrontHiringStageEmailTemplateFromJSON(json: any): FrontHiringStageEmailTemplate {
    return FrontHiringStageEmailTemplateFromJSONTyped(json, false);
}

export function FrontHiringStageEmailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontHiringStageEmailTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'requiredEmailAlias': !exists(json, 'required_email_alias') ? undefined : json['required_email_alias'],
        'requiredInterviewer': !exists(json, 'required_interviewer') ? undefined : json['required_interviewer'],
        'ccEmails': !exists(json, 'cc_emails') ? undefined : json['cc_emails'],
        'bccEmails': !exists(json, 'bcc_emails') ? undefined : json['bcc_emails'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'hasSameInterviewerAndEmailSender': !exists(json, 'has_same_interviewer_and_email_sender') ? undefined : json['has_same_interviewer_and_email_sender'],
    };
}

export function FrontHiringStageEmailTemplateToJSON(value?: FrontHiringStageEmailTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'cc_emails': value.ccEmails,
        'bcc_emails': value.bccEmails,
        'has_same_interviewer_and_email_sender': value.hasSameInterviewerAndEmailSender,
    };
}


