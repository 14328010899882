import { Icon } from "@doverhq/dover-ui";
import { IconButton, Stack } from "@mui/material";
import React, { ReactElement } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import LockSVG from "assets/icons/lock.svg";
import PencilEditSVG from "assets/icons/pencil-edit.svg";
import {
  candidateBioSocialLinkToDisplayMap,
  candidateBioSocialLinkToDisplayMapV2,
} from "components/CandidateHeader/SocialLinks/constants";
import { AddOrEditLinkItem, AddOrEditLinkItemV2 } from "components/CandidateHeader/SocialLinks/EditSocialLinkForm";
import { ButtonText } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { CandidateBioSocialLink, ContactSocialLink } from "services/openapi";
import { colors } from "styles/theme";

export const SocialLinkListItem = ({
  socialLink,
  editModeOn,
  setEditMode,
  onDelete,
  onSave,
}: {
  socialLink: CandidateBioSocialLink;
  editModeOn: boolean;
  setEditMode: (v: boolean) => void;
  onDelete: () => void;
  onSave: (link: CandidateBioSocialLink) => void;
}): ReactElement => {
  if (editModeOn) {
    return <AddOrEditLinkItem socialLink={socialLink} onSave={onSave} setEditMode={setEditMode} onDelete={onDelete} />;
  }

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
      <Stack flexDirection="row" alignItems="center">
        <Icon
          Icon={candidateBioSocialLinkToDisplayMap[socialLink.linkType].icon}
          color="white"
          size={candidateBioSocialLinkToDisplayMap[socialLink.linkType].width ?? 16}
        />
        <Spacer width="12px" />
        <SocialLinkText>{socialLink.url}</SocialLinkText>
      </Stack>
      <Spacer width="8px" />
      <Stack flexDirection="row" alignItems="center">
        <Spacer width="4px" />
        {socialLink.editable ? (
          <SmallCircleButton
            onClick={(): void => {
              setEditMode(true);
            }}
          >
            <ReactSVG src={PencilEditSVG} />
          </SmallCircleButton>
        ) : (
          <LockIcon src={LockSVG} />
        )}
      </Stack>
    </Stack>
  );
};

export const SocialLinkListItemV2 = ({
  socialLink,
  editModeOn,
  setEditMode,
  onDelete,
  onSave,
}: {
  socialLink: ContactSocialLink;
  editModeOn: boolean;
  setEditMode: (v: boolean) => void;
  onDelete: () => void;
  onSave: (link: ContactSocialLink) => void;
}): ReactElement => {
  if (editModeOn) {
    return (
      <AddOrEditLinkItemV2 socialLink={socialLink} onSave={onSave} setEditMode={setEditMode} onDelete={onDelete} />
    );
  }

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
      <Stack flexDirection="row" alignItems="center">
        <Icon
          Icon={candidateBioSocialLinkToDisplayMapV2[socialLink.linkType].icon}
          color="white"
          size={candidateBioSocialLinkToDisplayMapV2[socialLink.linkType].width ?? 16}
        />
        <Spacer width="12px" />
        <SocialLinkText>{socialLink.url}</SocialLinkText>
      </Stack>
      <Spacer width="8px" />
      <Stack flexDirection="row" alignItems="center">
        <Spacer width="4px" />
        <SmallCircleButton
          onClick={(): void => {
            setEditMode(true);
          }}
        >
          <ReactSVG src={PencilEditSVG} />
        </SmallCircleButton>
      </Stack>
    </Stack>
  );
};

const SmallCircleButton = styled(IconButton)`
  height: 22px;
  width: 22px;
  outline: none !important;
  &:hover {
    background-color: ${colors.grayscale.gray200} !important;
  }
  svg {
    position: relative;
    bottom: 3px;
  }
`;

const SocialLinkText = styled(ButtonText)`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LockIcon = styled(ReactSVG)`
  position: relative;
  right: 3px;
`;
