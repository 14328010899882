import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/MailOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, IconButton, keyframes, Menu, MenuItem, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import clipboardCopy from "clipboard-copy";
import React, { FC, useRef, useState } from "react";
import { useToggle } from "react-use";

import { EditableDisplayField } from "components/EditableDisplayField";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Overline } from "components/library/typography";
import {
  PartialUpdateContactRequest,
  useCreateContactEmailsMutation,
  useCreateContactPhoneNumbersMutation,
  useDeleteContactEmailMutation,
  useDeleteContactPhoneNumberMutation,
  useListContactEmailsQuery,
  useListContactPhoneNumbersQuery,
  usePartialUpdateContactEmailMutation,
  usePartialUpdateContactMutation,
  usePartialUpdateContactPhoneNumberMutation,
} from "services/doverapi/endpoints/contact";
import { ContactEmail, ContactPhoneNumber } from "services/openapi";
import { colors } from "styles/theme";
import { DetailHeader } from "views/CandidateDetail/components/details/styles";

enum ContactType {
  Email = "Email",
  Phone = "Phone",
}

type ContactItem = ContactEmail | ContactPhoneNumber;

// This seems to be the only way to call this function as it expects only 1 argument
// eslint-disable-next-line no-prototype-builtins
const isEmail = (contactItem: ContactItem): contactItem is ContactEmail => contactItem.hasOwnProperty("email");

const pulsingBackground = keyframes`
  0%, 100% {
    background-color: transparent;
  }
  50% {
    background-color: ${colors.grayscale.gray200};
  }
`;

interface ContactInfoProps {
  contactId: string | undefined;
  candidateId: string | undefined;
}

export const ContactInfo: FC<ContactInfoProps> = ({ contactId, candidateId }) => {
  const [, { isLoading: isCreateEmailsLoading }] = useCreateContactEmailsMutation({
    fixedCacheKey: contactId,
  });
  const [, { isLoading: isCreatePhoneNumbersLoading }] = useCreateContactPhoneNumbersMutation({
    fixedCacheKey: contactId,
  });
  const [, { isLoading: isUpdateContactLoading }] = usePartialUpdateContactMutation({
    fixedCacheKey: contactId,
  });
  const [, { isLoading: isUpdateEmailLoading }] = usePartialUpdateContactEmailMutation({
    fixedCacheKey: contactId,
  });
  const [, { isLoading: isUpdatePhoneNumberLoading }] = usePartialUpdateContactPhoneNumberMutation({
    fixedCacheKey: contactId,
  });
  const [, { isLoading: isDeleteEmailLoading }] = useDeleteContactEmailMutation({
    fixedCacheKey: contactId,
  });
  const [, { isLoading: isDeletePhoneNumberLoading }] = useDeleteContactPhoneNumberMutation({
    fixedCacheKey: contactId,
  });

  const anchorEl = useRef<SVGSVGElement>(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [tempContactItem, setTempContactItem] = useState<ContactItem | undefined>();

  const args = contactId ? { contactId } : skipToken;
  const {
    data: contactEmailsResult,
    isLoading: isListEmailsLoading,
    isFetching: isListEmailsFetching,
  } = useListContactEmailsQuery(args);
  const {
    data: contactPhoneNumbersResult,
    isLoading: isListPhoneNumbersLoading,
    isFetching: isListPhoneNumbersFetching,
  } = useListContactPhoneNumbersQuery(args);

  if (isListEmailsLoading || isListPhoneNumbersLoading) {
    return (
      <Stack spacing={0.5}>
        <DetailHeader>CONTACT</DetailHeader>
        <Skeleton height="36px" width="100%" />
        <Skeleton height="36px" width="100%" />
      </Stack>
    );
  }

  // This is true if any of the mutations are in flight or the list routes are fetching
  const loading =
    isListEmailsFetching ||
    isListPhoneNumbersFetching ||
    isCreateEmailsLoading ||
    isCreatePhoneNumbersLoading ||
    isUpdateContactLoading ||
    isUpdateEmailLoading ||
    isUpdatePhoneNumberLoading ||
    isDeleteEmailLoading ||
    isDeletePhoneNumberLoading;
  const disabled = loading;

  const openMenu = (): void => {
    if (!disabled) {
      toggleMenu(true);
    }
  };

  const addContact = (type: ContactType): void => {
    toggleMenu();

    const contactItem = { contactId, isPrimary: false };
    if (type === ContactType.Email) {
      setTempContactItem({ ...contactItem, email: "" });
    } else {
      setTempContactItem({ ...contactItem, phoneNumber: "" });
    }
  };

  const clearTempContactItem = (): void => {
    setTempContactItem(undefined);
  };

  const emails = contactEmailsResult?.results || [];
  const phoneNumbers = contactPhoneNumbersResult?.results || [];

  return (
    <>
      {/*
        The MUI Menu autofocus props require some arcane magic to understand.  I don't know why, but you need specifically disableRestoreFocus and disableEnforceFocues
        to be true for the menu to not steal focus from the TextField input after you click an option. disableAutoFocus is just true to prevent the menu from focusing the first option
        when opened, which felt weird to me.
      */}
      <Menu
        disableAutoFocus
        disableRestoreFocus
        disableEnforceFocus
        anchorEl={anchorEl.current}
        open={isMenuOpen}
        onClose={toggleMenu}
      >
        <Box px={1}>
          <Overline>Add New</Overline>
        </Box>
        <MenuItem onClick={(): void => addContact(ContactType.Email)}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <EmailIcon />
            <BodySmall>Email</BodySmall>
          </Stack>
        </MenuItem>
        <MenuItem onClick={(): void => addContact(ContactType.Phone)}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <PhoneIcon />
            <BodySmall>Phone</BodySmall>
          </Stack>
        </MenuItem>
      </Menu>
      <Box
        p={1}
        borderRadius="6px"
        sx={{
          animation: loading ? `${pulsingBackground} 2s infinite` : undefined,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <DetailHeader>CONTACT</DetailHeader>
          <IconButton disabled={disabled} onClick={openMenu}>
            <AddIcon ref={anchorEl} />
          </IconButton>
        </Stack>
        <Stack>
          {emails.map(e => (
            <ContactItem
              key={e.id}
              contactItem={e}
              disabled={disabled}
              clearTempContacItem={clearTempContactItem}
              candidateId={candidateId}
            />
          ))}
          {phoneNumbers.map(p => (
            <ContactItem
              key={p.id}
              contactItem={p}
              disabled={disabled}
              clearTempContacItem={clearTempContactItem}
              candidateId={candidateId}
            />
          ))}
          {tempContactItem && (
            <ContactItem
              contactItem={tempContactItem}
              disabled={disabled}
              clearTempContacItem={clearTempContactItem}
              candidateId={candidateId}
            />
          )}
        </Stack>
      </Box>
    </>
  );
};

interface ContactItemProps {
  contactItem: ContactItem;
  clearTempContacItem: () => void;
  disabled?: boolean;
  candidateId: string | undefined;
}

const getContactText = (contactItem: ContactItem): string => {
  return isEmail(contactItem) ? contactItem.email : contactItem.phoneNumber;
};

const ContactItem: FC<ContactItemProps> = ({ contactItem, clearTempContacItem, disabled, candidateId }) => {
  const [createEmail] = useCreateContactEmailsMutation({
    fixedCacheKey: contactItem.contactId,
  });
  const [createPhoneNumber] = useCreateContactPhoneNumbersMutation({
    fixedCacheKey: contactItem.contactId,
  });
  const [updateContact] = usePartialUpdateContactMutation({
    fixedCacheKey: contactItem.contactId,
  });
  const [updateEmail] = usePartialUpdateContactEmailMutation({
    fixedCacheKey: contactItem.contactId,
  });
  const [updatePhoneNumber] = usePartialUpdateContactPhoneNumberMutation({
    fixedCacheKey: contactItem.contactId,
  });
  const [deleteEmail] = useDeleteContactEmailMutation({
    fixedCacheKey: contactItem.contactId,
  });
  const [deletePhoneNumber] = useDeleteContactPhoneNumberMutation({
    fixedCacheKey: contactItem.contactId,
  });

  const anchorEl = useRef<SVGSVGElement>(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const [text, setText] = useState(getContactText(contactItem));

  const reset = (): void => {
    setText(getContactText(contactItem));
  };

  const save = (): void => {
    // Reset the text if user tries to save empty field
    if (!text) {
      reset();
      return;
    }

    // Just return if no changes to contact item
    if (text === getContactText(contactItem)) {
      return;
    }

    if (isEmail(contactItem)) {
      if (contactItem.id === undefined) {
        createEmail({ data: { contactId: contactItem.contactId!, emails: [text] }, candidateId: candidateId });
        clearTempContacItem();
      } else {
        updateEmail({ id: contactItem.id, data: { email: text }, candidateId: candidateId });
      }
    } else {
      if (contactItem.id === undefined) {
        createPhoneNumber({ data: { contactId: contactItem.contactId!, phoneNumbers: [text] } });
        clearTempContacItem();
      } else {
        updatePhoneNumber({ id: contactItem.id, data: { phoneNumber: text } });
      }
    }
  };

  const onDelete = (): void => {
    if (!contactItem.id) {
      console.error("ContactItem: Tried to delete a contact item with no id");
      return;
    }

    if (isEmail(contactItem)) {
      deleteEmail({ id: contactItem.id, candidateId: candidateId });
    } else {
      deletePhoneNumber({ id: contactItem.id });
    }

    toggleMenu();
  };

  const setAsPrimary = (): void => {
    if (!contactItem.contactId) {
      console.error("ContactItem: Tried to set as primary on a contact item with no contact id");
      return;
    }

    const args: PartialUpdateContactRequest = { id: contactItem.contactId, candidateId: candidateId, data: {} };

    if (isEmail(contactItem)) {
      args.data.primaryEmailId = contactItem.id;
    } else {
      args.data.primaryPhoneNumberId = contactItem.id;
    }

    updateContact(args);
    toggleMenu();
  };

  const copy = (): void => {
    clipboardCopy(text);
  };

  const Icon = isEmail(contactItem) ? EmailIcon : PhoneIcon;
  const displayText = text + (contactItem.isPrimary ? " (primary)" : "");

  return (
    <>
      <Menu anchorEl={anchorEl.current} open={isMenuOpen} onClose={toggleMenu}>
        <MenuItem onClick={setAsPrimary}>Set as primary</MenuItem>
        <MenuItem onClick={onDelete}>Delete</MenuItem>
      </Menu>
      <Stack
        direction="row"
        spacing={0.5}
        justifyContent="space-between"
        sx={{
          cursor: "pointer",
          "& .display-on-hover": {
            display: isMenuOpen ? "block" : "none",
          },
          "&:hover .display-on-hover": {
            display: "block",
          },
        }}
      >
        <Stack direction="row" spacing={0.5} alignItems="center" width="100%" minWidth={0}>
          <Icon fontSize="small" />
          <EditableDisplayField
            fullWidth
            DisplayComponent={BodySmall}
            text={text}
            displayOnlyText={displayText}
            disabled={disabled}
            onChange={setText}
            onBlur={save}
            onEnter={save}
            onEscape={reset}
            defaultMode={contactItem.id === undefined ? "edit" : "display"}
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Tooltip title="Copy to clipboard">
            {/* By default these have 5px padding and it makes the icon button 1 pixel taller than the stack which causes layout shifts on hover */}
            <IconButton className="display-on-hover" size="small" onClick={copy} sx={{ padding: "4px" }}>
              {/* @ts-ignore IDK why this fontSize throws a type error, it is both documented and works in practice */}
              <ContentCopyIcon fontSize="18px" />
            </IconButton>
          </Tooltip>
          {!contactItem.isPrimary && (
            <IconButton
              className="display-on-hover"
              size="small"
              disabled={disabled}
              onClick={toggleMenu}
              sx={{ padding: "4px" }}
            >
              {/* @ts-ignore IDK why this fontSize throws a type error, it is both documented and works in practice */}
              <MoreHorizIcon ref={anchorEl} fontSize="18px" />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </>
  );
};
