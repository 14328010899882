import { zodResolver } from "@hookform/resolvers/zod";
import { FormControlLabel, RadioGroup, Stack, TextFieldProps, ToggleButton } from "@mui/material";
import React, { ChangeEvent, FC, ReactElement, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import BulkCandidatesUploadForm from "components/dover/AddCandidate/BulkUploadCandidatesForm";
import FooterActions from "components/dover/AddCandidate/Footer";
import {
  singleAddCandidateToCampaignSchema,
  SingleAddCandidateToCampaignSchemaType,
  UploadType,
} from "components/dover/AddCandidate/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { RequiredAsterisk } from "components/RequiredAsterisk";
import { StyledRadio, StyledTextField, StyledToggleButtonGroup } from "components/StyledMuiComponents";
import { useOpenApiClients } from "hooks/openApiClients";
import { useJobId } from "hooks/useJobIdFromUrl";
import {
  CheckCanCreateManualContextForJobRequestCoreSourceIdentifierEnum,
  CheckCanCreateManualContextForJobResponse,
} from "services/openapi";
import { InternalLink } from "styles/typography";

interface ControlledTextFieldProps extends Pick<TextFieldProps, "placeholder" | "error" | "helperText"> {
  name: "firstName" | "lastName" | "email" | "linkedIn";
}

const ControlledTextField = ({ name, placeholder, error, helperText }: ControlledTextFieldProps): ReactElement => {
  const { control } = useFormContext<SingleAddCandidateToCampaignSchemaType>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): ReactElement => (
        <StyledTextField
          placeholder={placeholder}
          {...field}
          size="small"
          fullWidth
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

const SingleCandidateUploadForm: FC<{ handleNextStep: () => void }> = ({ handleNextStep }) => {
  const form = useForm<SingleAddCandidateToCampaignSchemaType>({
    resolver: zodResolver(singleAddCandidateToCampaignSchema),
  });

  const {
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = form;

  const values = getValues();
  const email = values.email;

  const [jobId] = useJobId();
  const apiApi = useOpenApiClients()?.apiApi;

  const [hasCandidateEmail, setHasCandidateEmail] = useState<boolean | null>(email !== undefined ? !!email : null);
  const [
    manualSourcePrecheckResult,
    setManualSourcePrecheckResult,
  ] = useState<CheckCanCreateManualContextForJobResponse | null>(null);

  const toggleHasCandidateEmail = (_event: ChangeEvent<HTMLInputElement>, value: string): void => {
    // Side effect: if the user toggles to "Yes", clear the LinkedIn field because it's no longer needed and
    // if it has any validation errors, it won't show on screen
    if (value === "true") {
      // ts thinks we're trying to assign a string to type "never" but this is valid based on our schema
      // @ts-ignore
      setValue("linkedIn", "");
      clearErrors("linkedIn");
    }
    setHasCandidateEmail(prevValue => !prevValue);
  };

  const handleNext = async (values: SingleAddCandidateToCampaignSchemaType): Promise<void> => {
    if (!jobId) {
      console.error("No job id found to add candidate to campaign");
    }

    // SUGGESTION: refactor this with try/catch and a redux endpoint to access loading state as well
    const canManuallySourceProfile = await apiApi?.canManuallySourceProfile({
      data: {
        email: values.email,
        job: jobId!,
        firstName: values.firstName,
        lastName: values.lastName,
        url: values.linkedIn,
        coreSourceIdentifier: CheckCanCreateManualContextForJobRequestCoreSourceIdentifierEnum.ManualOutbound,
        isOutreach: true,
      },
    });
    if (canManuallySourceProfile) {
      setManualSourcePrecheckResult(canManuallySourceProfile);
      // If the candidate can be manually sourced, move to the next step
      if (canManuallySourceProfile.valid) {
        handleNextStep();
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleNext)}>
        <Stack spacing={2}>
          {/* First name field */}
          <div>
            <Stack direction="row" spacing={0.5}>
              <Subtitle2>First name</Subtitle2>
              <RequiredAsterisk />
            </Stack>
            <ControlledTextField
              name="firstName"
              placeholder="Enter first name"
              error={!!errors.firstName}
              helperText={errors.firstName?.message ?? ""}
            />
          </div>
          {/* Last name field */}
          <div>
            <Stack direction="row" spacing={0.5}>
              <Subtitle2>Last name</Subtitle2>
              <RequiredAsterisk />
            </Stack>
            <ControlledTextField
              name="lastName"
              placeholder="Enter last name"
              error={!!errors.lastName}
              helperText={errors.lastName?.message ?? ""}
            />
          </div>
          {/* Check if has candidate email */}
          <div>
            <Subtitle2>{"Do you have the candidate's email?"}</Subtitle2>
            <RadioGroup row value={hasCandidateEmail} onChange={toggleHasCandidateEmail} name="hasCandidateEmail">
              <FormControlLabel value={true} control={<StyledRadio />} label={<BodySmall>Yes</BodySmall>} />
              <FormControlLabel
                value={false}
                control={<StyledRadio />}
                label={<BodySmall>No (find it for me)</BodySmall>}
              />
            </RadioGroup>
          </div>
          {/* Conditional email field */}
          {hasCandidateEmail && (
            <div>
              <Stack direction="row" spacing={0.5}>
                <Subtitle2>Email</Subtitle2>
                <RequiredAsterisk />
              </Stack>
              <ControlledTextField
                name="email"
                placeholder="Enter email"
                error={!!errors.email}
                helperText={errors.email?.message ?? ""}
              />
            </div>
          )}
          {/* Conditional LinkedIn field */}
          {hasCandidateEmail === false && (
            <div>
              <Stack direction="row" spacing={0.5}>
                <Subtitle2>{"Enter the candidate's LinkedIn"}</Subtitle2>
                <RequiredAsterisk />
              </Stack>
              <ControlledTextField
                name="linkedIn"
                placeholder="Enter URL"
                error={!!errors.linkedIn}
                helperText={errors.linkedIn?.message ?? ""}
              />
            </div>
          )}
          {manualSourcePrecheckResult?.valid === false && (
            <Banner variant={BannerVariant.Critical}>
              {manualSourcePrecheckResult.error ??
                "There was an error adding this candidate. Please try again or reach out to support."}
              {manualSourcePrecheckResult.candidateId && jobId && (
                <InternalLink
                  to={APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, manualSourcePrecheckResult.candidateId)}
                >
                  Existing candidate
                </InternalLink>
              )}
            </Banner>
          )}
          <FooterActions />
        </Stack>
      </form>
    </FormProvider>
  );
};

const CandidateDetailsForm: FC<{ handleNextStep: () => void; handlePrevStep: () => void }> = ({ handleNextStep }) => {
  const [uploadType, setUploadType] = useState<UploadType>("single");

  return (
    <Stack spacing={2}>
      <StyledToggleButtonGroup
        size="small"
        value={uploadType}
        exclusive
        onChange={(_event, newValue): void => setUploadType(newValue)}
        aria-label="Bulk Upload Toggle"
        color="success"
      >
        <ToggleButton value="single" aria-label="single">
          Single candidate
        </ToggleButton>
        <ToggleButton value="bulk" aria-label="bulk">
          Multiple candidates
        </ToggleButton>
      </StyledToggleButtonGroup>

      {uploadType === "bulk" ? (
        <BulkCandidatesUploadForm handleNextStep={handleNextStep} />
      ) : (
        <SingleCandidateUploadForm handleNextStep={handleNextStep} />
      )}
    </Stack>
  );
};

export default CandidateDetailsForm;
