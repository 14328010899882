import React from "react";
import styled from "styled-components";

interface StickyDivProps {
  children: React.ReactNode;
  top: number;
}

const StickyDivStyled = styled.div`
  position: sticky;
  overflow: auto;
  width: inherit;
`;

const StickyDiv: React.FC<StickyDivProps> = ({ children, top }) => {
  return <StickyDivStyled style={{ top: `${top}px` }}>{children}</StickyDivStyled>;
};

export default StickyDiv;
