/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtensionProfileResumeUploadResponse
 */
export interface ExtensionProfileResumeUploadResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ExtensionProfileResumeUploadResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtensionProfileResumeUploadResponse
     */
    readonly error?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionProfileResumeUploadResponse
     */
    candidateLinkedinUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionProfileResumeUploadResponse
     */
    candidateFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionProfileResumeUploadResponse
     */
    candidateCurrentRole?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionProfileResumeUploadResponse
     */
    candidateCurrentCompany?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionProfileResumeUploadResponse
     */
    candidateSchool?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionProfileResumeUploadResponse
     */
    candidateLocation?: string;
}

export function ExtensionProfileResumeUploadResponseFromJSON(json: any): ExtensionProfileResumeUploadResponse {
    return ExtensionProfileResumeUploadResponseFromJSONTyped(json, false);
}

export function ExtensionProfileResumeUploadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionProfileResumeUploadResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'candidateLinkedinUrl': json['candidate_linkedin_url'],
        'candidateFullName': !exists(json, 'candidate_full_name') ? undefined : json['candidate_full_name'],
        'candidateCurrentRole': !exists(json, 'candidate_current_role') ? undefined : json['candidate_current_role'],
        'candidateCurrentCompany': !exists(json, 'candidate_current_company') ? undefined : json['candidate_current_company'],
        'candidateSchool': !exists(json, 'candidate_school') ? undefined : json['candidate_school'],
        'candidateLocation': !exists(json, 'candidate_location') ? undefined : json['candidate_location'],
    };
}

export function ExtensionProfileResumeUploadResponseToJSON(value?: ExtensionProfileResumeUploadResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'candidate_linkedin_url': value.candidateLinkedinUrl,
        'candidate_full_name': value.candidateFullName,
        'candidate_current_role': value.candidateCurrentRole,
        'candidate_current_company': value.candidateCurrentCompany,
        'candidate_school': value.candidateSchool,
        'candidate_location': value.candidateLocation,
    };
}


