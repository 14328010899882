import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  CURRENT_ONBOARDING_STEP,
  FAKE_ID,
  INTERVIEWER_SCHEDULING_INFO,
  JOB_INTERVIEWERS_SCHEDULING_INFO,
  LIST_TAG,
  PRO_USER,
} from "services/doverapi/endpointTagsConstants";
import {
  ApiApiGetGmailAuthValidRequest,
  ApiApiGetUserInfoRequest,
  ApiApiListProUsersByClientIdRequest,
  ApiApiListProUsersRequest,
  ApiApiPartialUpdateInterviewerProUserRequest,
  ApiApiPartialUpdateInterviewerSchedulingInfoRequest,
  ApiApiPartialUpdateProUserRequest,
  DoverUser,
  InterviewerSchedulingInfo,
  ProUser,
} from "services/openapi";
import { GmailAuth } from "services/openapi/models/GmailAuth";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";

interface UpdateProUserArgs extends ApiApiPartialUpdateProUserRequest {
  flowId?: string | null;
}

const proUserAdapter = createEntityAdapter<DoverUser>();

const proUserEndpointsTemp = doverApi.injectEndpoints({
  endpoints: build => ({
    listProUsersForClient: build.query<EntityState<DoverUser>, ApiApiListProUsersRequest | undefined>({
      queryFn: async requestArgs => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.listProUsers({ limit: 1000, ...requestArgs });
          return { data: proUserAdapter.addMany(proUserAdapter.getInitialState(), response.results) };
        } catch (error) {
          const userFacingMessage = "Failed to load. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
      },
      providesTags: result => {
        // is result available?
        return result
          ? // successful query
            [...result.ids.map(id => ({ type: PRO_USER, id } as const)), { type: PRO_USER, id: LIST_TAG }]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: PRO_USER, id: LIST_TAG }];
      },
    }),
    listProUsersByClientId: build.query<EntityState<DoverUser>, ApiApiListProUsersByClientIdRequest>({
      queryFn: async ({ clientId }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.listProUsersByClientId({ clientId, limit: 300 });
          return { data: proUserAdapter.addMany(proUserAdapter.getInitialState(), response.results) };
        } catch (error) {
          const userFacingMessage = "Failed to load users. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
      },
      providesTags: result => {
        // is result available?
        return result
          ? // successful query
            [...result.ids.map(id => ({ type: PRO_USER, id } as const)), { type: PRO_USER, id: LIST_TAG }]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: PRO_USER, id: LIST_TAG }];
      },
    }),
    getProUser: build.query<DoverUser, string>({
      queryFn: async id => {
        const { apiApi: client } = await getOpenApiClients({});

        let response: DoverUser;
        try {
          response = await client.getProUser({ user: id });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage: "Failed to retrieve pro user",
            },
          };
        }
        return { data: response };
      },
      providesTags: result => {
        // is result available?
        return result ? [{ type: PRO_USER, id: result.id }] : [];
      },
    }),
    partialUpdateProUser: build.mutation<DoverUser, UpdateProUserArgs>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        let response: DoverUser;
        try {
          response = await client.partialUpdateProUser(args);
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage: "Failed to update user. Please refresh and try again",
            },
          };
        }
        return { data: response };
      },
      invalidatesTags: (result, error, args) => {
        return [
          // Invalidate fake entity now that it exists
          { type: PRO_USER, id: FAKE_ID } as const,
          // Invalidate our list so that it includes our newly created ProUser
          { type: PRO_USER, id: LIST_TAG } as const,
          ...(args.flowId ? [{ type: CURRENT_ONBOARDING_STEP, id: args.flowId } as const] : []),
          // Nothing should depend on the created pro user yet, but just in case, invalidate based on its ID too
          ...(result?.id ? [{ type: PRO_USER, id: result.id } as const] : []),
        ];
      },
    }),
    getAuthedUserInfo: build.query<DoverUser, ApiApiGetUserInfoRequest | void>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let response: DoverUser;
        try {
          response = await client.getUserInfo(args ? args : {});
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage: "Failed to retrieve user info",
            },
          };
        }
        return { data: response };
      },
      providesTags: result => {
        // is result available?
        return result ? [{ type: PRO_USER, id: result.id }] : [];
      },
    }),
    getUserGmailAuthValid: build.query<GmailAuth, ApiApiGetGmailAuthValidRequest>({
      queryFn: async ({ userId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        let response: GmailAuth;

        try {
          response = await client.getGmailAuthValid({ userId });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: response };
      },
    }),
    getUserInterviewerSchedulingInfo: build.query<InterviewerSchedulingInfo, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});
        let response: InterviewerSchedulingInfo;

        try {
          response = await client.getUserInterviewerSchedulingInfo({});
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: response };
      },
      providesTags: result => {
        return result ? [{ type: INTERVIEWER_SCHEDULING_INFO, id: result.id }] : [];
      },
    }),
    partialUpdateInterviewerSchedulingInfo: build.mutation<
      InterviewerSchedulingInfo,
      ApiApiPartialUpdateInterviewerSchedulingInfoRequest
    >({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        let response: InterviewerSchedulingInfo;

        try {
          response = await client.partialUpdateInterviewerSchedulingInfo(args);
          showSuccessToast("Interview preferences saved!");
        } catch (error) {
          showErrorToast("An error occurred. Please reload the page.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: response };
      },
      invalidatesTags: result => {
        return [
          { type: JOB_INTERVIEWERS_SCHEDULING_INFO },
          ...(result?.id ? [{ type: INTERVIEWER_SCHEDULING_INFO, id: result.id } as const] : []),
        ];
      },
    }),
    getInterviewerProUser: build.query<ProUser, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});
        let response: ProUser;

        try {
          response = await client.getInterviewerProUser({});
        } catch (error) {
          showErrorToast("An error occurred. Please reload the page.");

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: response };
      },
      providesTags: result => {
        return result ? [{ type: PRO_USER, id: result.id }] : [];
      },
    }),
    partialUpdateProUserForInterviewer: build.mutation<ProUser, ApiApiPartialUpdateInterviewerProUserRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        let response: ProUser;

        try {
          response = await client.partialUpdateInterviewerProUser(args);
        } catch (error) {
          showErrorToast("An error occurred. Please reload the page.");

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: response };
      },
      invalidatesTags: result => {
        const createdProUserId = result?.id;

        return [
          // Invalidate fake entity now that it exists
          { type: PRO_USER, id: FAKE_ID } as const,
          // Invalidate our list so that it includes our newly created ProUser
          { type: PRO_USER, id: LIST_TAG } as const,
          // Nothing should depend on the created pro user yet, but just in case, invalidate based on its ID too
          ...(createdProUserId ? [{ type: PRO_USER, id: createdProUserId } as const] : []),
        ];
      },
    }),
  }),
});

const proUserEndpoints = proUserEndpointsTemp.injectEndpoints({
  endpoints: build => ({
    createProUser: build.mutation<DoverUser, DoverUser>({
      queryFn: async userInfo => {
        const { apiApi: client } = await getOpenApiClients({});
        showPendingToast("Adding a new user...");

        let response: DoverUser;
        try {
          response = await client.createProUser({ data: userInfo });
        } catch (error) {
          const userFacingMessage = "Failed to add a new user. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        showSuccessToast("Successfully added a new user!");
        return { data: response };
      },
      // allow optimistic updates when creating a new user
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        const { data: currentProUsers } = proUserEndpointsTemp.endpoints.listProUsersForClient.select({})(getState());

        try {
          const { data } = await queryFulfilled;
          dispatch(
            proUserEndpointsTemp.util.updateQueryData("listProUsersForClient", undefined, draft => {
              Object.assign(draft, proUserAdapter.addOne(currentProUsers ?? proUserAdapter.getInitialState(), data));
            })
          );
          dispatch(doverApi.util.invalidateTags([{ type: PRO_USER, id: LIST_TAG }]));
        } catch {
          // For now, do nothing on failure. Later, we may want to surface some information to the user.
          // However, this is primary handled now through the normal error handling flow in this endpoint.
          return;
        }
      },
      invalidatesTags: createdUser => {
        const createdProUserId = createdUser?.pk;

        return [
          // Invalidate fake entity now that it exists
          { type: PRO_USER, id: FAKE_ID } as const,
          // Invalidate our list so that it includes our newly created ProUser
          { type: PRO_USER, id: LIST_TAG } as const,
          // Nothing should depend on the created pro user yet, but just in case, invalidate based on its ID too
          ...(createdProUserId ? [{ type: PRO_USER, id: createdProUserId } as const] : []),
        ];
      },
    }),
  }),
});

export const {
  useGetAuthedUserInfoQuery,
  useGetProUserQuery,
  useListProUsersForClientQuery,
  useLazyListProUsersForClientQuery,
  useListProUsersByClientIdQuery,
  useCreateProUserMutation,
  usePartialUpdateProUserMutation,
  useGetUserGmailAuthValidQuery,
  useGetUserInterviewerSchedulingInfoQuery,
  usePartialUpdateInterviewerSchedulingInfoMutation,
  useGetInterviewerProUserQuery,
  usePartialUpdateProUserForInterviewerMutation,
  usePrefetch,
} = proUserEndpoints;

export const { selectById: selectProUserById, selectAll: selectProUsers } = proUserAdapter.getSelectors();
