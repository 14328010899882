/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeedbackTemplateAssignedStage,
    FeedbackTemplateAssignedStageFromJSON,
    FeedbackTemplateAssignedStageFromJSONTyped,
    FeedbackTemplateAssignedStageToJSON,
    FeedbackTemplateCreator,
    FeedbackTemplateCreatorFromJSON,
    FeedbackTemplateCreatorFromJSONTyped,
    FeedbackTemplateCreatorToJSON,
} from './';

/**
 * 
 * @export
 * @interface FeedbackTemplate
 */
export interface FeedbackTemplate {
    /**
     * 
     * @type {string}
     * @memberof FeedbackTemplate
     */
    id: string;
    /**
     * 
     * @type {FeedbackTemplateCreator}
     * @memberof FeedbackTemplate
     */
    createdBy: FeedbackTemplateCreator;
    /**
     * 
     * @type {boolean}
     * @memberof FeedbackTemplate
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeedbackTemplate
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FeedbackTemplate
     */
    assignedStageCount: number;
    /**
     * 
     * @type {Array<FeedbackTemplateAssignedStage>}
     * @memberof FeedbackTemplate
     */
    assignedStages: Array<FeedbackTemplateAssignedStage>;
}

export function FeedbackTemplateFromJSON(json: any): FeedbackTemplate {
    return FeedbackTemplateFromJSONTyped(json, false);
}

export function FeedbackTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdBy': FeedbackTemplateCreatorFromJSON(json['created_by']),
        'isDefault': json['is_default'],
        'name': json['name'],
        'assignedStageCount': json['assigned_stage_count'],
        'assignedStages': ((json['assigned_stages'] as Array<any>).map(FeedbackTemplateAssignedStageFromJSON)),
    };
}

export function FeedbackTemplateToJSON(value?: FeedbackTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by': FeedbackTemplateCreatorToJSON(value.createdBy),
        'is_default': value.isDefault,
        'name': value.name,
        'assigned_stage_count': value.assignedStageCount,
        'assigned_stages': ((value.assignedStages as Array<any>).map(FeedbackTemplateAssignedStageToJSON)),
    };
}


