/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { meta } from "@data-driven-forms/common/prop-types-templates";
import parseInternalValue from "@data-driven-forms/common/select/parse-internal-value";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Autocomplete, TextField, CircularProgress, Grid, Skeleton } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router";

import { validationError } from "components/data-driven-forms/fields/helpers";
import { Role, useHasRole } from "components/RBAC";
import { useFeatureFlag, FeatureFlag } from "hooks/useFeatureFlag";
import { useFormatIndividualAIAnswerMutation } from "services/doverapi/endpoints/candidateInterview";
import { useInterviewRubricResponseQuery } from "views/interview/CandidateInterview/hooks";
import { IndividualAnswerFormatterEndAdornment } from "views/interview/common/components/IndividualAnswerFormatButton";
import { OverallRatingSelect, OVERALL_RATING_NAME } from "views/interview/common/components/OverallRatingSelect";
import { H1 } from "views/interview/common/styles";

// NOTE(jhh3): This is a PoC -- this needs heavy clean up

/**
 * Returns label of selected option
 * @param {Object|Array} option currently selected option
 * @param {Array<Object>} options all options avaiable
 * @returns {String}
 */
export const getOptionLabel = (option, options) => {
  if (typeof option === "undefined") {
    return "";
  }

  if (option === "") {
    return "";
  }

  if (Array.isArray(option) && option.length === 0) {
    return "";
  }

  if (typeof option === "object") {
    const item = options.find(({ value }) => value === option?.value ?? option);
    return option.label || item.label;
  }

  const item = options.find(({ value }) => value === option);
  return (item && item.label) || "";
};

/**
 * Function that creates correct DDF select value format
 * @param {Object|Array} option currently selected values
 * @param {Boolean} isMulti multiple select flag
 * @returns {Object|Array<Object>}
 */
const createValue = (option, isMulti) => {
  if (isMulti) {
    return Array.isArray(option) ? option.map(item => (typeof item === "object" ? item : { value: item })) : option;
  }

  return option;
};

const InternalSelect = ({
  value,
  options,
  label,
  helperText,
  validateOnMount,
  meta,
  isSearchable,
  description,
  isMulti,
  placeholder,
  isFetching,
  hideSelectedOptions,
  required,
  onChange,
  onFocus,
  onBlur,
  TextFieldProps: { inputProps: textFieldInputProps },
  isClearable,
  isDisabled,
  maxOptions,
  disableCloseOnSelect,
  ...rest
}) => {
  const invalid = validationError(meta, validateOnMount);
  const [internalSelected, setInternalSelected] = React.useState();
  const [notes, setNotes] = React.useState(value?.notes);
  const internalValue = parseInternalValue(value?.value, isMulti);
  const [state, setState] = React.useState(
    !internalValue && isMulti
      ? []
      : internalValue && !isMulti
      ? { value: internalValue }
      : createValue(internalValue, isMulti)
  );
  const help = invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description;
  // dont want to allow DI's to use the format AI answer functionality yet
  const hasInterviewerRole = useHasRole(Role.INTERVIEWER);
  const useFormatAiAnswer = useFeatureFlag(FeatureFlag.AINotesFormatter) && !hasInterviewerRole;
  const useAINotetaker = useFeatureFlag(FeatureFlag.AINotetaker);

  const { candidateId, interviewRubricResponseId } = useParams();
  const { interviewRubricResponse: candidateInterviewRubricResponse } = useInterviewRubricResponseQuery({
    candidateId,
    interviewRubricResponseId,
  });

  const [
    formatIndividualAIAnswer,
    { isFetching: isAiFetching, isLoading: isAiLoading },
  ] = useFormatIndividualAIAnswerMutation();

  const onSubmit = async ({ formattingPromptType }) => {
    if (!candidateInterviewRubricResponse?.id) {
      return;
    }

    await formatIndividualAIAnswer({
      interviewRubricResponseId: candidateInterviewRubricResponse.id,
      questionLabel: label,
      currentAnswer: value,
      helperText: helperText,
      formattingPromptType: formattingPromptType,
    })
      .unwrap()
      .then(data => {
        setNotes(data.aiAnswer);
      });
  };

  React.useEffect(() => {
    if (notes || state) {
      const selectValue = createValue(state, isMulti);
      if (isMulti) {
        onChange({ value: (state ?? []).filter(v => !!v).map(v => v.value), notes });
      } else {
        onChange({ value: selectValue?.value, notes });
      }
    }
  }, [notes, state]);

  React.useEffect(() => {
    // Handle remounts in form correctly
    if (isMulti && (state || []).length > 0 && !internalSelected) {
      setInternalSelected(state);
    }
  }, [state]);

  return (
    <Grid container spacing={3}>
      {label !== OVERALL_RATING_NAME && (
        <Grid container item sm={12} md={12}>
          <div>
            <H1 style={{ paddingTop: "16px" }}>{label}</H1>
            {help && <small>Help: {help}</small>}
          </div>
        </Grid>
      )}
      {(isAiFetching || isAiLoading) && (
        // Adding a grid here so that the skeleton has the correct paddings/widths that are associated with the grid's in this view
        <Grid container item sm={12} md={12}>
          <Skeleton height="150px" width="100%" />
        </Grid>
      )}
      {!(isAiFetching || isAiLoading) && (
        <Grid container item sm={12} md={12}>
          {label === OVERALL_RATING_NAME ? (
            <OverallRatingSelect notes={notes} setNotes={setNotes} options={options} />
          ) : (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  style={{ marginBottom: "8px" }}
                  filterSelectedOptions={hideSelectedOptions}
                  disabled={isDisabled}
                  disableClearable={isClearable}
                  popupIcon={isFetching ? <CircularProgress size={20} color="inherit" /> : <ArrowDropDownIcon />}
                  disableCloseOnSelect={disableCloseOnSelect}
                  getOptionDisabled={o => {
                    let disable = false;

                    if (isMulti) {
                      const alreadySelected = !!(internalSelected ?? []).find(s => (s.value ?? s) === o.value);
                      disable = alreadySelected;
                      if (maxOptions) {
                        const numSelected = (internalSelected ?? []).length;
                        const maxReached = numSelected === maxOptions;
                        disable = disable || maxReached;
                      }
                    }

                    return disable;
                  }}
                  {...rest}
                  renderInput={params => {
                    return (
                      <TextField
                        fullWidth
                        onFocus={onFocus}
                        onBlur={onBlur}
                        {...params}
                        required={required}
                        error={!!invalid}
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          ...textFieldInputProps,
                          readOnly: !isSearchable,
                          placeholder: (!state || state.length === 0 ? placeholder : undefined) ?? "Choose...",
                        }}
                      />
                    );
                  }}
                  options={options}
                  multiple={isMulti}
                  getOptionLabel={option => getOptionLabel(option, options)}
                  value={typeof state === "undefined" ? null : state}
                  onChange={(_event, option) => {
                    if (isMulti) {
                      setInternalSelected(option);
                    }

                    setState(createValue(option, isMulti));
                  }}
                  loading={isFetching}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  onFocus={onFocus}
                  onBlur={onBlur}
                  error={!!invalid}
                  value={notes}
                  required={true}
                  rows={3}
                  maxRows={6}
                  onChange={e => setNotes(e.target.value)}
                  placeholder="Notes..."
                  multiline
                  variant="outlined"
                  disabled={isDisabled}
                  InputProps={
                    // dont allow individualAnswerFormatter for a question without a label
                    useFormatAiAnswer && !useAINotetaker && label
                      ? {
                          endAdornment: <IndividualAnswerFormatterEndAdornment onSubmit={onSubmit} />,
                        }
                      : undefined
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

InternalSelect.propTypes = {
  meta,
  placeholder: PropTypes.node,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.node.isRequired }))
    .isRequired,
  description: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
  value: PropTypes.any,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  loadingMessage: PropTypes.node,
  isFetching: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  hideSelectedOptions: PropTypes.bool,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  TextFieldProps: PropTypes.object,
  inputProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  maxOptions: PropTypes.number,
  section: PropTypes.any,
};

InternalSelect.defaultProps = {
  placeholder: "Please choose",
  FormFieldGridProps: {},
  TextFieldProps: {},
  inputProps: {},
  loadingText: "Loading...",
};

const Select = props => {
  const {
    input,
    isRequired,
    isDisabled,
    isReadOnly,
    disabled,
    multiple,
    isMulti,
    isClearable,
    disableClearable,
    loadingMessage,
    loadingText,
    disableCloseOnSelect,
    ...rest
  } = useFieldApi(props);
  const multi = multiple || isMulti;
  return (
    <InternalSelect
      {...input}
      isMulti={multi}
      disableCloseOnSelect={disableCloseOnSelect || multi}
      required={isRequired}
      isDisabled={isDisabled || isReadOnly || disabled}
      disableClearable={!isClearable || disableClearable}
      loadingText={loadingMessage || loadingText}
      {...rest}
    />
  );
};

export default Select;
