import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  RequestPlanUpgradeResponse,
  RequestPlanUpgradeRequestRequestedDoverPlanEnum,
  ClientDoverPlanEnum,
} from "services/openapi";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";

const planManagementEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    requestPlanUpgrade: build.mutation<RequestPlanUpgradeResponse, { requestedDoverPlan: ClientDoverPlanEnum }>({
      queryFn: async ({ requestedDoverPlan }) => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          showPendingToast("Requesting plan upgrade...");
          const response = await client.requestPlanUpgrade({
            data: {
              requestedDoverPlan: (requestedDoverPlan as unknown) as RequestPlanUpgradeRequestRequestedDoverPlanEnum,
            },
          });
          showSuccessToast("Successfully requested plan upgrade.");
          return {
            data: response,
          };
        } catch (error) {
          showErrorToast("Failed to request plan upgrade. Please refresh and try again");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const { useRequestPlanUpgradeMutation } = planManagementEndpoints;
