import { Box, Container, Grid, useTheme, useMediaQuery, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";

import { BodySmall } from "components/library/typography";
import { Role, useContainsRole } from "components/RBAC";
import { Spacer } from "components/Spacer";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { IRRCandidateInterviewInfo } from "services/openapi";
import { IRRCandidate as CandidateModel } from "services/openapi/models/IRRCandidate";
import { FakeLink } from "styles/typography";
import {
  AINotetakerDrawerOpenAtom,
  TRANSCRIPT_DRAWER_WIDTH,
  TranscriptDrawer,
} from "views/interview/CandidateInterview/TranscriptDrawer";
import { InterviewContextCustomerFacing } from "views/interview/common/components/InterviewContext";
import { FormBodyWrapper } from "views/interview/common/components/InterviewRubric/styles";
import Section from "views/interview/common/components/InterviewRubricReview/Section";
import {
  InterviewRubricContainer,
  InterviewRubricContainerWrapper,
} from "views/interview/common/components/InterviewRubricReview/styles";
import { Section as SectionModel } from "views/interview/common/types";

interface Props {
  sections: SectionModel[];
  title: string;
  candidate: CandidateModel;
  candidateInterviewInfo: IRRCandidateInterviewInfo;
  submittedByProUserId?: number;
  hasTranscript: boolean;
  interviewRubricResponseId?: string;
}

const InterviewRubricReview = ({
  sections,
  candidate,
  candidateInterviewInfo,
  submittedByProUserId,
  interviewRubricResponseId,
}: Props): React.ReactElement => {
  const { data: userInfo } = useGetAuthedUserInfoQuery();

  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const interviewerName = candidateInterviewInfo.interviewerName;
  const isDoverScheduledEvent = candidateInterviewInfo.isDoverScheduledEvent;

  const isDoverAdminOrInterviewer = useContainsRole([Role.ADMIN, Role.INTERVIEWER]);
  const isSubmitter = userInfo?.id === submittedByProUserId;

  const isPermittedTeamMember =
    useContainsRole([Role.HIRING_MANAGER, Role.HIRING_TEAM, Role.RECRUITER, Role.CLIENT_ADMIN], candidate.job.id) ||
    isSubmitter;

  const canViewNotes = (): boolean => {
    return isDoverAdminOrInterviewer || isPermittedTeamMember;
  };

  return (
    <InterviewRubricContainerWrapper compact={matches}>
      <InterviewRubricContainer compact={matches}>
        <InterviewContextCustomerFacing
          candidateId={candidate?.id}
          candidateInterviewInfo={candidateInterviewInfo}
          job={candidate.job}
          interviewRubricResponseId={interviewRubricResponseId}
        />
        <>
          {canViewNotes() ? (
            <>
              <Grid container style={{ height: "100%" }} spacing={3}>
                <Grid item order={{ xs: 2, sm: 1 }} sm={12} md={12} style={{ overflow: "auto" }}>
                  <FormBodyWrapper>
                    {sections.map(section => (
                      <div key={section.name}>
                        <Section section={section} />
                        <Spacer height="20px" />
                      </div>
                    ))}
                    {isDoverScheduledEvent && (
                      <Box display="flex" justifyContent="center">
                        <BodySmall>This interview was conducted by {interviewerName}.</BodySmall>
                      </Box>
                    )}
                  </FormBodyWrapper>
                </Grid>
              </Grid>
            </>
          ) : (
            <Container maxWidth={"md"}>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Spacer height="72px" />
                <Grid item>
                  <h2>{"Oh no! You don't have access to this page."}</h2>
                </Grid>
                <Grid item>
                  <div>Only the hiring manager and your recruiter on this search have access.</div>
                </Grid>
                <Spacer height="32px" />
                <Grid item>
                  <FakeLink
                    onClick={(): void => {
                      navigate(-1);
                    }}
                  >
                    Go back
                  </FakeLink>
                </Grid>
              </Grid>
            </Container>
          )}
        </>
      </InterviewRubricContainer>
    </InterviewRubricContainerWrapper>
  );
};

const InterviewRubricReviewWrapper = (props: Props): React.ReactElement => {
  const useAINotetaker = useFeatureFlag(FeatureFlag.AINotetaker);
  const isAINotetakerDrawerOpen = useAtomValue(AINotetakerDrawerOpenAtom);

  if (useAINotetaker && props.hasTranscript) {
    return (
      <Stack direction="row">
        <Box mr={isAINotetakerDrawerOpen ? TRANSCRIPT_DRAWER_WIDTH : 0}>
          <InterviewRubricReview {...props} />
        </Box>
        <TranscriptDrawer candidateInterviewInfo={props.candidateInterviewInfo} />
      </Stack>
    );
  }

  return <InterviewRubricReview {...props} />;
};

export default InterviewRubricReviewWrapper;
