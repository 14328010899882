import { Box, Stack } from "@mui/material";
import capitalize from "lodash/capitalize";
import React, { ReactElement, useCallback } from "react";

import { ReactComponent as InboundIcon } from "assets/icons/inbound.svg";
import { ReactComponent as ManualSourceIcon } from "assets/icons/manual_source.svg";
import { ReactComponent as OutboundIcon } from "assets/icons/outbound.svg";
import { ReactComponent as PencilEditSVG } from "assets/icons/pencil-edit.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import { ReactComponent as ReferralsIcon } from "assets/icons/referrals.svg";
import { ReactComponent as SlackIcon } from "assets/icons/slack.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { useDisableCandidateActionsTooltipText } from "hooks/useDisableCandidateActionsTooltipText";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { CandidateBio, CandidateBioContactInfo, CandidateBioContactInfoTypeEnum } from "services/openapi";
import { ContactInfo } from "views/CandidateDetail/components/ContactInfo";
import { Email } from "views/CandidateDetail/components/details/Email";
import {
  ContactInfoWrapper,
  DetailHeader,
  DetailItem,
  DetailLink,
  DetailsContainer,
  DetailSubtitle,
  DetailText,
} from "views/CandidateDetail/components/details/styles";
import { convertPrettyDate, useShowCandidateEmail } from "views/CandidateDetail/utils";

interface Props {
  candidateBio: CandidateBio;
}

export const CandidateDetails = ({ candidateBio }: Props): ReactElement => {
  const isPandoraNameReadsEnabled = useFeatureFlag(FeatureFlag.PandoraNameReads);

  const ats = candidateBio.ats!;
  const candidateId = candidateBio.id;

  const showEmail = useShowCandidateEmail(candidateBio);
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();

  const contactInfo = candidateBio.contactInfo?.filter(
    info => showEmail || info.type !== CandidateBioContactInfoTypeEnum.Email
  );
  const jobId = candidateBio.job;

  const invalidCandidateActionPermsTooltip = useDisableCandidateActionsTooltipText(jobId);
  const invalidCandidateActionPerms = !!invalidCandidateActionPermsTooltip;

  const showEditEmailsModal = useCallback(() => {
    if (!candidateId || invalidCandidateActionPerms) return;

    showCandidateActionModal({ candidateId, modalType: CandidateActionModalType.EditEmails });
  }, [candidateId, invalidCandidateActionPerms, showCandidateActionModal]);

  return (
    <DetailsContainer>
      {isPandoraNameReadsEnabled ? (
        <ContactInfo contactId={candidateBio.contact.id} candidateId={candidateBio.id} />
      ) : (
        <>
          <Spacer height={24} />
          <DetailItem>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <DetailHeader>CONTACT</DetailHeader>
              <Button
                variant={ButtonVariant.Ghost}
                tooltip={invalidCandidateActionPermsTooltip}
                removePadding
                removeOutline
                onClick={showEditEmailsModal}
              >
                <Box
                  sx={{
                    paddingBottom: "5px",
                    height: "27px",
                    cursor: invalidCandidateActionPerms ? "default" : "pointer",
                  }}
                >
                  <PencilEditSVG />
                </Box>
              </Button>
            </Stack>
            <Spacer height={4} />
            {contactInfo && contactInfo.length > 0 ? (
              genContactInfo(contactInfo)
            ) : (
              <BodySmall>No emails added</BodySmall>
            )}
          </DetailItem>
        </>
      )}
      <Spacer height={12} />
      <SlackLink slackUrl={candidateBio.slackUrl} />
      {candidateBio.searchSource && (
        <>
          <DetailItem>
            <DetailHeader>SOURCE</DetailHeader>
            <Spacer height={8} />
            {genSource(candidateBio)}
          </DetailItem>
          <Spacer height={24} />
        </>
      )}
      {ats && (
        <DetailItem>
          <DetailHeader>ATS</DetailHeader>
          <Spacer height={8} />
          <DetailLink href={ats.url} rel="noopener noreferrer" target="_blank">
            {capitalize(ats.type)}
          </DetailLink>
          <DetailText>{ats.stageName && `(${ats.stageName})`}</DetailText>
          <DetailSubtitle>{ats.lastSyncedAt && `Last synced ${convertPrettyDate(ats.lastSyncedAt)}`}</DetailSubtitle>
        </DetailItem>
      )}
    </DetailsContainer>
  );
};

const genContactInfo = (contactInfo: CandidateBioContactInfo[]): ReactElement[] => {
  return contactInfo.map((info, idx) =>
    info.type === CandidateBioContactInfoTypeEnum.Email ? (
      <Email email={info.value} isPrimary={info.isPrimary} key={idx} />
    ) : (
      <ContactInfoWrapper key={idx}>
        <Stack direction="row" spacing={1}>
          <PhoneIcon width="16px" />
          <DetailText>{info.value}</DetailText>
        </Stack>
      </ContactInfoWrapper>
    )
  );
};

const SlackLink = ({ slackUrl }: { slackUrl: string | undefined }): ReactElement => {
  return slackUrl ? (
    <Box mb={3}>
      <ContactInfoWrapper>
        <Stack direction="row" spacing={1}>
          <SlackIcon width="16px" />
          <DetailLink href={slackUrl} rel="noopener noreferrer" target="_blank">
            View in Slack
          </DetailLink>
        </Stack>
      </ContactInfoWrapper>
    </Box>
  ) : (
    <></>
  );
};

const genSource = (candidateBio: CandidateBio): ReactElement | undefined => {
  const source = candidateBio.searchSource!;
  const { displayCategory, displayAction } = source;

  let icon;
  if (displayAction == "Contacted") {
    icon = <OutboundIcon width="16px" />;
  } else if (displayAction == "Refferred") {
    icon = <ReferralsIcon width="16px" />;
  } else if (displayAction == "Applied") {
    icon = <InboundIcon width="16px" />;
  } else if (displayAction.startsWith("Added by")) {
    icon = <ManualSourceIcon width="16px" />;
  } else if (displayAction.startsWith("Inbound")) {
    icon = <InboundIcon width="16px" />;
  } else {
    icon = <OutboundIcon width="16px" />;
  }

  return (
    <Box display="flex" alignItems="baseline" flexDirection="column">
      <ContactInfoWrapper>
        <Stack direction="row" spacing={1} alignItems="baseline">
          {icon}
          <DetailText>{displayCategory}</DetailText>
        </Stack>
      </ContactInfoWrapper>
      <ContactInfoWrapper>
        {source.pipelineStartedDate && (
          <DetailSubtitle>
            <Spacer height={8} />
            {displayAction} {convertPrettyDate(source.pipelineStartedDate)}
          </DetailSubtitle>
        )}
      </ContactInfoWrapper>
    </Box>
  );
};
