import { Button } from "@doverhq/dover-ui";
import { Stack, Box, styled } from "@mui/material";
import { DataGrid, GridCellParams, GridEnrichedColDef, GridRowModel } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import React, { ReactElement, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { ReactComponent as PencilEditIcon } from "assets/icons/pencil-edit.svg";
import DataGridLoadingOverlay from "components/DataGridLoadingOverlay";
import { EmailEditorMode, EmailEditorModeAtom } from "components/dover/EmailTemplates/atoms";
import { Card } from "components/library/Card";
import { EmailEditor } from "components/library/TipTap/EmailEditor";
import { substituteVariables } from "components/library/TipTap/utils";
import Toggle from "components/library/Toggle";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Overline, Subtitle1, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import {
  useListGenericEmailResponseTemplateQuery,
  useListHiringStageEmailTemplatesQuery,
  usePartialUpdateGenericEmailResponseTemplateMutation,
  usePartialUpdateHiringStageEmailTemplateMutation,
} from "services/doverapi/endpoints/emailTemplates";
import { useGetHiringPipelineStageQuery } from "services/doverapi/endpoints/hiringPipelineStage";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import {
  GenericEmailResponseTemplateTemplateTypeEnum,
  HiringPipelineStageMilestone,
  HiringStageEmailTemplateTemplateTypeEnum,
  JobFeatureFeatureNameEnum,
  SubstageType,
} from "services/openapi";
import { colors } from "styles/theme";
import { getPlainTextFromHtml } from "utils/draftJS";
import { EMPTY_TIPTAP_EDITOR } from "utils/tiptap";
import { EmailsAndAutomationSchema } from "views/job/JobSetup/steps/JobPosting/JobPosting";

/* -----------------------------------------------------------------------------
 * constants
 * -------------------------------------------------------------------------- */

const hiringStageEmailTemplateVariables = {
  scheduling_link: "findatime.io/schedule/77f584c2-2d53-4af8-8ce4-443ed601e497",
  first_name: "Alice",
  sender_first_name: "Douglas",
  interviewer_first_name: "John",
  job_title: "HR Generalist",
  old_job_title: "HR Specialist",
  client_name: "Dover",
  interviewer_role_title_with_article: "an Engineering Manager",
  interviewer_role_title_without_article: "Engineering Manager",
  interview_duration: "2 hours and 45 minutes",
  interview_schedule: "\n\t1. Intro call: 45 minutes\n\t2. Pair Programming: 2 hours and 45 minutes\n\n",
  job_description_link: "www.trover.io/careers/my-awesome-job",
};

const confirmationTemplateVariables = {
  first_name: "Alice",
  job_title: "HR Generalist",
  client_name: "Dover",
};

/* -----------------------------------------------------------------------------
 * helpers
 * -------------------------------------------------------------------------- */

const generateColumn = (
  headerName: "Email Type" | "Auto-Send" | "Template",
  isOnFreePlan: boolean
): GridEnrichedColDef => {
  return {
    headerName,
    field: headerName,
    flex: headerName === "Email Type" ? 1 : 0,
    sortable: false,
    renderHeader: (params): ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params): ReactElement => {
      if (headerName === "Email Type") {
        return (
          <Stack sx={{ textWrap: "wrap" }}>
            <Subtitle2>{params.row.label}</Subtitle2>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <BodySmall color={colors.grayscale.gray600}>{params.row.description}</BodySmall>
            </Box>
          </Stack>
        );
      }
      if (headerName === "Auto-Send") {
        return (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Toggle checked={params.row.autoSend} disabled={params.row.toggleDisabled} label="" />
            {params.row.label === "Scheduling" && (
              <Tooltip
                title={
                  isOnFreePlan
                    ? "Auto-send for scheduling is not available on the free plan"
                    : "Auto-send is always on for scheduling emails as a part of your plan"
                }
              >
                <InfoIcon />
              </Tooltip>
            )}
          </Stack>
        );
      }
      if (headerName === "Template") {
        if (params.row.enableTemplate) {
          return (
            <Box sx={{ cursor: "pointer" }}>
              <PencilEditIcon />
            </Box>
          );
        }
      }
      return <></>;
    },
  };
};

const generateRow = (args: Record<string, any>): GridRowModel => {
  return args;
};

/* -----------------------------------------------------------------------------
 * StyledDataGrid
 * -------------------------------------------------------------------------- */

const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-columnsContainer {
    background: ${colors.grayscale.gray100};
  }
`;

/* -----------------------------------------------------------------------------
 * EmailTemplateModal
 * -------------------------------------------------------------------------- */

const EditTemplateModal = ({
  open,
  onClose,
  title,
  onSave,
  template,
  submitDisabled,
  variables,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  onSave: (bodyTemplate: string, subjectTemplate: string) => void;
  template: {
    bodyTemplate: string;
    subjectTemplate: string;
  };
  submitDisabled: boolean;
  variables: Record<string, string>;
}): React.ReactElement => {
  // Global state
  const selectedEmailEditorTab = useAtomValue(EmailEditorModeAtom);

  // Local state
  const [initialBody, setInitialBody] = useState<string | undefined>(undefined);
  const [initialSubject, setInitialSubject] = useState<string | undefined>(undefined);

  const [body, setBody] = useState<string | undefined>(template.bodyTemplate);
  const [subject, setSubject] = useState<string | undefined>(template.subjectTemplate);

  // Initialize editor state
  useEffect(() => {
    if (initialSubject === undefined) {
      setInitialSubject(getPlainTextFromHtml(template.subjectTemplate));
    }
  }, [initialSubject, template.subjectTemplate]);

  useEffect(() => {
    if (initialBody === undefined) {
      setInitialBody(template.bodyTemplate);
    }
  }, [initialBody, template.bodyTemplate]);

  const onSubjectChanged = (newSubject: string): void => {
    setSubject(newSubject);
  };

  const onBodyChanged = (newBody: string): void => {
    setBody(newBody);
  };

  const handleSave = (): void => {
    if (body && subject) {
      onSave(body, getPlainTextFromHtml(subject));
    }
  };

  const submitButtonDisabled = submitDisabled || subject === EMPTY_TIPTAP_EDITOR || body === EMPTY_TIPTAP_EDITOR;

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      maxWidth={"sm"}
      dialogActions={
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button variant="primaryOutlined" onPress={onClose}>
            Cancel
          </Button>
          <Button variant="primaryFilled" onPress={handleSave} isDisabled={submitButtonDisabled}>
            Submit
          </Button>
        </Stack>
      }
    >
      {selectedEmailEditorTab === EmailEditorMode.Preview ? (
        <EmailEditor
          showPreviewTab
          readOnly
          body={substituteVariables(body, variables)}
          subject={substituteVariables(getPlainTextFromHtml(subject ?? ""), variables)}
        />
      ) : (
        <EmailEditor
          showPreviewTab
          body={initialBody ?? ""}
          subject={initialSubject}
          onBodyChanged={onBodyChanged}
          onSubjectChanged={onSubjectChanged}
        />
      )}
    </CustomModal>
  );
};

/* -----------------------------------------------------------------------------
 * EmailsAndAutomationSection
 * -------------------------------------------------------------------------- */

interface EmailsAndAutomationSectionProps {
  id?: string;
}

export const EmailsAndAutomationSection = ({ id }: EmailsAndAutomationSectionProps): ReactElement => {
  const jobId = useJobIdFromUrl();
  const isOnFreePlan = useIsBasePlanCustomer();
  const { watch, setValue } = useFormContext<EmailsAndAutomationSchema>();
  const sendConfirmationEmail = watch("applicationReceived");
  const sendRejectionEmail = watch("rejection");

  const {
    applicationConfirmationTemplate,
    applicationConfirmationTemplatesIsLoading,
  } = useListGenericEmailResponseTemplateQuery(
    {
      jobId: jobId,
      templateType: GenericEmailResponseTemplateTemplateTypeEnum.ApplicationConfirmation,
    },
    {
      selectFromResult: ({ data, isLoading }) => {
        return {
          applicationConfirmationTemplate: data?.[0],
          applicationConfirmationTemplatesIsLoading: isLoading,
        };
      },
    }
  );
  const {
    data: allTemplates,
    isLoading: listHiringStageEmailTemplatesIsLoading,
  } = useListHiringStageEmailTemplatesQuery(jobId ?? skipToken);

  const { data: job } = useGetJobQuery(jobId ? jobId : skipToken);
  const initialCallHpsId = job?.hiringPipelineStages?.find(
    hps => hps.milestone === HiringPipelineStageMilestone.INITIAL_CALL
  )?.id;

  const { data: initialCallHps } = useGetHiringPipelineStageQuery(
    initialCallHpsId && job?.id ? { jobId: job.id, hpsId: initialCallHpsId } : skipToken
  );
  const e2eEnabled = !!useGetIsFeatureEnabled({
    jobId,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });

  const [
    updateGenericEmailResponseTemplate,
    { isLoading: genericEmailResponseTemplateIsUpdating },
  ] = usePartialUpdateGenericEmailResponseTemplateMutation();
  const [
    partialUpdateHiringStageEmailTemplate,
    { isLoading: isUpdatingHiringStageEmail },
  ] = usePartialUpdateHiringStageEmailTemplateMutation();

  const [customizeRejectionEmailModalOpen, setCustomizeRejectionEmailModalOpen] = useState(false);
  const [customizeConfirmationEmailModalOpen, setCustomizeConfirmationEmailModalOpen] = useState(false);
  const [customizeSchedulingEmailModalOpen, setCustomizeScheudlingEmailModalOpen] = useState(false);

  if (!job || !initialCallHps) {
    return <></>;
  }

  const rejectionTemplate = allTemplates?.find(
    template => template.templateType === HiringStageEmailTemplateTemplateTypeEnum.RejectionInbound
  );
  // NOTE: This is a bandaid fix for the fact there are 2 inbound scheduling templates.
  // Free customers typically send from their personal emails. Default to showing the template where interviewer + sender are the same.
  // Paid customers typically send using some Dover managed shell email, so sender and interviewer should typically be different.
  const schedulingTemplate = allTemplates?.find(template => {
    const isMultipart = initialCallHps.contentTypeName === SubstageType.MULTIPART_INTERVIEW_STAGE;
    const isTakeHome = !!initialCallHps.multipartInterviewStage?.substages?.find(s => s.isTakeHome);
    if (isMultipart) {
      return template.templateType === HiringStageEmailTemplateTemplateTypeEnum.SchedulingInboundMultipart;
    }

    if (isTakeHome) {
      return template.templateType === HiringStageEmailTemplateTemplateTypeEnum.SchedulingInboundTakehome;
    }

    return (
      template.templateType === HiringStageEmailTemplateTemplateTypeEnum.SchedulingInbound &&
      template.hasSameInterviewerAndEmailSender === !e2eEnabled
    );
  });

  const handleUpdateSendRejectionEmail = (checked: boolean): void => {
    if (!jobId) {
      return;
    }
    setValue("rejection", checked);
  };

  const handleUpdateSendConfirmationEmail = (checked: boolean): void => {
    if (!jobId) {
      return;
    }
    setValue("applicationReceived", checked);
  };

  const handleUpdateSendConfirmationEmailTemplate = (bodyTemplate: string, subjectTemplate: string): void => {
    if (!jobId || !applicationConfirmationTemplate?.id) {
      return;
    }
    updateGenericEmailResponseTemplate({
      templateId: applicationConfirmationTemplate.id,
      updatedTemplate: {
        ...applicationConfirmationTemplate,
        body: bodyTemplate,
        subject: subjectTemplate,
      },
      showToasts: true,
    });
    setCustomizeConfirmationEmailModalOpen(false);
  };

  const handleUpdateSendRejectionEmailTemplate = (bodyTemplate: string, subjectTemplate: string): void => {
    if (!jobId || !rejectionTemplate?.id) {
      return;
    }
    partialUpdateHiringStageEmailTemplate({
      hiringStageEmailTemplateId: rejectionTemplate.id,
      updatedHiringStageEmailTemplate: {
        ...rejectionTemplate,
        bodyTemplate,
        subjectTemplate,
      },
      showToasts: true,
    });
    setCustomizeRejectionEmailModalOpen(false);
  };

  const handleUpdateSchedulingEmailTemplate = (bodyTemplate: string, subjectTemplate: string): void => {
    if (!jobId || !schedulingTemplate?.id) {
      return;
    }
    partialUpdateHiringStageEmailTemplate({
      hiringStageEmailTemplateId: schedulingTemplate.id,
      updatedHiringStageEmailTemplate: {
        ...schedulingTemplate,
        bodyTemplate,
        subjectTemplate,
      },
      showToasts: true,
    });
    setCustomizeScheudlingEmailModalOpen(false);
  };

  const handleCellClick = (params: GridCellParams<any, any, any>): void => {
    if (params.field === "Auto-Send") {
      if (params.row.label === "Application Received") {
        setValue("applicationReceived", !sendConfirmationEmail);
        handleUpdateSendConfirmationEmail(!sendConfirmationEmail);
      } else if (params.row.label === "Rejection") {
        setValue("rejection", !sendRejectionEmail);
        handleUpdateSendRejectionEmail(!sendRejectionEmail);
      }
    }
    if (params.field === "Template") {
      if (params.row.label === "Application Received") {
        setCustomizeConfirmationEmailModalOpen(true);
      } else if (params.row.label === "Rejection") {
        setCustomizeRejectionEmailModalOpen(true);
      } else if (params.row.label === "Scheduling") {
        setCustomizeScheudlingEmailModalOpen(true);
      }
    }
  };

  const isLoading = applicationConfirmationTemplatesIsLoading || listHiringStageEmailTemplatesIsLoading;

  const isUpdating = genericEmailResponseTemplateIsUpdating || isUpdatingHiringStageEmail;

  return (
    <>
      <Card id={id}>
        <Stack spacing={2}>
          <Subtitle1>{"Emails & Automation"}</Subtitle1>
          <StyledDataGrid
            autoHeight
            headerHeight={35}
            rowHeight={72}
            rows={
              isLoading
                ? []
                : [
                    generateRow({
                      id: 1,
                      label: "Application Received",
                      description: "Send a confirmation email to a candidate when their application is received",
                      autoSend: sendConfirmationEmail,
                      enableTemplate: !!applicationConfirmationTemplate,
                      toggleDisabled: false,
                    }),
                  ]
            }
            columns={[
              generateColumn("Email Type", isOnFreePlan),
              generateColumn("Auto-Send", isOnFreePlan),
              generateColumn("Template", isOnFreePlan),
            ]}
            hideFooter
            disableColumnFilter
            disableSelectionOnClick
            disableColumnMenu
            loading={isLoading}
            components={{
              LoadingOverlay: DataGridLoadingOverlay,
            }}
            onCellClick={handleCellClick}
          />
        </Stack>
      </Card>
      {rejectionTemplate && (
        <EditTemplateModal
          open={customizeRejectionEmailModalOpen}
          onClose={(): void => setCustomizeRejectionEmailModalOpen(false)}
          onSave={handleUpdateSendRejectionEmailTemplate}
          template={{
            bodyTemplate: rejectionTemplate.bodyTemplate ?? "",
            subjectTemplate: rejectionTemplate.subjectTemplate ?? "",
          }}
          title="Applicant rejection email"
          submitDisabled={isLoading || isUpdating}
          variables={hiringStageEmailTemplateVariables}
        />
      )}
      {applicationConfirmationTemplate && (
        <EditTemplateModal
          open={customizeConfirmationEmailModalOpen}
          onClose={(): void => setCustomizeConfirmationEmailModalOpen(false)}
          onSave={handleUpdateSendConfirmationEmailTemplate}
          title="Applicant confirmation email"
          submitDisabled={isLoading || isUpdating}
          variables={confirmationTemplateVariables}
          template={{
            bodyTemplate: applicationConfirmationTemplate.body ?? "",
            subjectTemplate: applicationConfirmationTemplate.subject ?? "",
          }}
        />
      )}
      {schedulingTemplate && (
        <EditTemplateModal
          open={customizeSchedulingEmailModalOpen}
          onClose={(): void => setCustomizeScheudlingEmailModalOpen(false)}
          onSave={handleUpdateSchedulingEmailTemplate}
          title="Applicant scheduling email"
          submitDisabled={isLoading || isUpdating}
          variables={hiringStageEmailTemplateVariables}
          template={{
            bodyTemplate: schedulingTemplate.bodyTemplate ?? "",
            subjectTemplate: schedulingTemplate.subjectTemplate ?? "",
          }}
        />
      )}
    </>
  );
};
