import { Grid, RadioGroup, FormControlLabel, Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { useField, useForm, useFormState } from "react-final-form";

import { InfoTip } from "components/InfoTip";
import InstructionList from "components/instructions/components/InstructionList";
import { BodySmall, Body } from "components/library/typography";
import { CustomModal } from "components/Modal/index";
import { Spacer } from "components/Spacer";
import { appConfig } from "config/appConfig";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { FakeLink } from "styles/typography";
import {
  MEETING_TYPES_WITH_LOCATION_REQUIRED,
  MEETING_TYPES_OPTIONS,
  DEFAULT_MEETING_TYPE,
  setupZoomMeetingRoomInstructionItems,
} from "views/CompanySetup/components/InterviewPreferences/constants";
import {
  StyledRadio,
  StyledTextField,
  RequiredAsterisk,
} from "views/CompanySetup/components/InterviewPreferences/styles";
import { InterviewPreferencesFormValues } from "views/CompanySetup/components/InterviewPreferences/types";

type Option = { value: string; label: string } | null;

export const MeetingTypePicker = ({
  googleMeetEnabled,
  setIsDirty,
}: {
  googleMeetEnabled: boolean;
  setIsDirty: (value: boolean) => void;
}): React.ReactElement => {
  const form = useForm();
  const { values: formValues } = useFormState<InterviewPreferencesFormValues>();

  const interviewMeetingTypeField = useField<string>("interviewMeetingType");
  const interviewLocationTypeField = useField<string>("interviewLocation");

  const initialOption =
    MEETING_TYPES_OPTIONS.find(option => option?.value === formValues.interviewMeetingType) || DEFAULT_MEETING_TYPE;
  const enableGoogleMeetLink = "https://support.google.com/a/answer/9493952?hl=en";

  const [meetingType, setMeetingType] = useState<Option>(initialOption);
  const [meetingLink, setMeetingLink] = useState(formValues.interviewLocation ?? "");
  const [openInstructionModal, setOpenInstructionModal] = useState(false);

  function getError(): { error?: boolean; helperText?: string } {
    // @ts-ignore
    if (MEETING_TYPES_WITH_LOCATION_REQUIRED.includes(meetingType?.value as string)) {
      if (meetingLink.trim().length === 0) {
        return {
          error: true,
          helperText: "Required",
        };
      } else {
        try {
          const url = new URL(meetingLink);
          if (url.protocol !== "https:") {
            return {
              error: true,
              helperText: "Invalid URL - should begin with https://",
            };
          }
        } catch (e) {
          return {
            error: true,
            helperText: "Invalid URL",
          };
        }
      }
    }

    return {};
  }

  const handleMeetingTypeChange = (newOption?: Option): void => {
    if (!newOption) {
      return;
    }
    setMeetingType(newOption);
    setMeetingLink("");

    const requiresMeetingLink = MEETING_TYPES_WITH_LOCATION_REQUIRED.includes(newOption?.value as "ZOOM" | "WHEREBY");
    if (!requiresMeetingLink) {
      form.batch(() => {
        form.change(interviewMeetingTypeField.input.name, newOption?.value || "");
        form.change(interviewLocationTypeField.input.name, "");
      });
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Body weight="500">
          How will you meet with candidates?
          <RequiredAsterisk />
        </Body>
        <Spacer height="16px" />
      </Grid>

      <Grid container item spacing={2}>
        <Grid item>
          <RadioGroup
            name="interviewMeetingType"
            value={meetingType?.value}
            onChange={(_, newOptionValue: string): void => {
              setIsDirty(true);
              const newOption = MEETING_TYPES_OPTIONS.find(option => option.value === newOptionValue);
              handleMeetingTypeChange(newOption);
            }}
          >
            {MEETING_TYPES_OPTIONS.map(option => {
              if (option.value === "USE_INTERVIEWER_PREFERENCE") {
                return <></>;
              }

              return (
                <>
                  <Stack direction={"row"} alignItems={"center"} sx={{ label: { marginBottom: "0px" } }}>
                    <FormControlLabel
                      value={option.value}
                      control={<StyledRadio color="primary" />}
                      label={option.label}
                      disabled={!googleMeetEnabled && option.value === "GOOGLE_MEET"}
                    />
                    {option.value === "GOOGLE_MEET" && !googleMeetEnabled && (
                      <InfoTip
                        text={
                          <BodySmall color="white">
                            Looks like your account doesn&apos;t have the necessary permissions to access Google Meet.
                            Please follow the instructions
                            <ExternalLink
                              display="inline"
                              href="https://support.google.com/a/answer/9493952?hl=en"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              here{" "}
                            </ExternalLink>
                            or connect with your GSuite administrator to ensure your organization has Google Meet
                            enabled. Once enabled, reauthorize your email{" "}
                            <ExternalLink
                              display="inline"
                              href={`${appConfig.appUrl}/cal/auth`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              here
                            </ExternalLink>
                            .
                          </BodySmall>
                        }
                      />
                    )}
                  </Stack>

                  {// @ts-ignore
                  meetingType?.value === option.value && MEETING_TYPES_WITH_LOCATION_REQUIRED.includes(option.value) && (
                    <Box width="350px">
                      <StyledTextField
                        name="interviewLocation"
                        placeholder="Meeting Link"
                        value={meetingLink}
                        onChange={(e): void => {
                          setIsDirty(true);
                          setMeetingLink(e.target.value.trim());
                        }}
                        onBlur={(e): void => {
                          if (!getError().error) {
                            form.batch(() => {
                              form.change(interviewMeetingTypeField.input.name, meetingType.value);
                              form.change(interviewLocationTypeField.input.name, e.target.value.trim());
                            });
                          }
                        }}
                        fullWidth
                        {...getError()}
                      />
                    </Box>
                  )}
                  {option.value === "ZOOM" && meetingType?.value === "ZOOM" && (
                    <div>
                      <BodySmall inline>
                        {'We recommend you add a passcode and enable the "Waiting Room" feature. '}
                        <FakeLink
                          onClick={(): void => setOpenInstructionModal(true)}
                          display="inline"
                          color={colors.link}
                        >
                          How do I add a personal Zoom room?
                        </FakeLink>
                      </BodySmall>
                      <CustomModal
                        title={"Accessing your personal Zoom room"}
                        open={openInstructionModal}
                        onClose={(): void => setOpenInstructionModal(false)}
                        maxWidth="md"
                      >
                        <>
                          <Body>
                            Every licensed user of Zoom has a personal Zoom room, however, if you want to customize the
                            link you must be on the business or education plan.
                          </Body>
                          <Spacer height="16px" />
                          {setupZoomMeetingRoomInstructionItems && (
                            <InstructionList listItems={setupZoomMeetingRoomInstructionItems} />
                          )}
                        </>
                      </CustomModal>
                    </div>
                  )}
                  {option.value === "GOOGLE_MEET" && meetingType?.value === "GOOGLE_MEET" && (
                    <BodySmall inline style={{ marginBottom: "16px" }}>
                      Dover integrates directly with Google Meet, and calendar events will have a unique meeting link
                      automatically added. Before enabling, please make sure that Google Meet is enabled in your
                      organization.{" "}
                      <ExternalLink
                        target="_blank"
                        rel="noopener noreferrer"
                        color={colors.link}
                        href={enableGoogleMeetLink}
                      >
                        Learn more
                      </ExternalLink>
                    </BodySmall>
                  )}
                </>
              );
            })}
          </RadioGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};
