import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as WandIcon } from "assets/icons/green-wand.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Heading } from "components/library/typography";
import { colors } from "styles/theme";
import { FakeLink } from "styles/typography";

export const AISuggestedQuestionsBanner = ({ onClick }: { onClick: (e: any) => void }): React.ReactElement => {
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: colors.white,
        borderRadius: "3px",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
        p: "16px",
      }}
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <WandIcon height="20px" width="20px" />
        <Stack spacing={0.5}>
          <Heading>Get candidate-specific interview questions</Heading>
          <Body color={colors.grayscale.gray600}>
            {"Get suggestions on questions to ask specific to the candidate's background and experience."}
          </Body>
        </Stack>
      </Stack>
      <Button variant={ButtonVariant.Secondary} onClick={onClick}>
        <Body color={colors.grayscale.gray600} style={{ whiteSpace: "nowrap" }}>
          {"Let's go"}
        </Body>
      </Button>
    </Stack>
  );
};

const AISuggestedQuestionsSlimLink = ({ onClick }: { onClick: (e: any) => void }): React.ReactElement => {
  return (
    <FakeLink onClick={onClick} className="ai-suggested-questions">
      {"Want better questions? Click here to get questions specific to the candidate's background and experience"}
    </FakeLink>
  );
};

export default AISuggestedQuestionsSlimLink;
