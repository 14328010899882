/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useEffect, useMemo, useState } from "react";

import { EmailEditorMode, EmailEditorModeAtom } from "components/dover/EmailTemplates/atoms";
import { Button, ButtonVariant } from "components/library/Button";
import { EmailEditor } from "components/library/TipTap/EmailEditor";
import { BasicEmailOption, EmailOwner } from "components/library/TipTap/types";
import { substituteVariables } from "components/library/TipTap/utils";
import { BodySmall } from "components/library/typography";
import { useCcOptions } from "hooks/useCcOptions";
import { schedulingLinks, variables } from "sections/addcandidate/components/EmailEditor/constants";
import {
  EditorAndVariablesContainer,
  TemplateEditorStack,
  TemplateMessagingContainer,
} from "sections/addcandidate/components/EmailEditor/styles";
import VariableSelector from "sections/addcandidate/components/EmailEditor/VariableSelector";
import { colors } from "styles/theme";
import { getPlainTextFromHtml } from "utils/draftJS";

const validate = (subject: string, body: string): any => {
  const errors: any = {};
  const matches = (subject ? [...Array.from(subject!.matchAll(/{{(.*?)}}/g))] : [])
    .concat(body ? [...Array.from(body!.matchAll(/{{(.*?)}}/g))] : [])
    .map(match => match[1])
    .filter(match => !Object.keys(variables).includes(match));
  if (matches.length > 0) {
    errors.customVariables = matches;
  }
  schedulingLinks.forEach(link => {
    if (subject?.toLowerCase().includes(link) || body?.toLowerCase().includes(link)) {
      errors.schedulingLinks ? errors.schedulingLinks.push(link) : (errors.schedulingLinks = [link]);
    }
  });
  return errors;
};

const Editor = ({
  body,
  subject,
  fromEmail,
  toName,
  ccEmails,
  setBody,
  setSubject,
  setCcEmails,
  setOutreachValid,
}: {
  body?: string | null;
  subject?: string | null;
  fromEmail?: string | null;
  toName?: string | null;
  toEmail?: string | null;
  ccEmails?: string[];
  setBody: (body: string) => void;
  setSubject: (subject: string) => void;
  setCcEmails: (ccEmails: string[]) => void;
  setOutreachValid: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  const [showVariables, setShowVariables] = useState(false);

  // Global state
  const selectedEmailEditorTab = useAtomValue(EmailEditorModeAtom);

  // Local state
  const [initialBody, setInitialBody] = useState<string | undefined>(undefined);
  const [initialSubject, setInitialSubject] = useState<string | undefined>(undefined);

  // Initialize editor state
  useEffect(() => {
    setInitialSubject(getPlainTextFromHtml(subject ?? ""));
  }, [subject]);

  useEffect(() => {
    setInitialBody(body ?? "");
  }, [body]);

  const onSubjectChanged = (newSubject: string): void => {
    setSubject(newSubject);
  };

  const onBodyChanged = (newBody: string): void => {
    setBody(newBody);
  };

  const onCcChanged = (newCc: BasicEmailOption[]): void => {
    setCcEmails(newCc.map(cc => cc.email));
  };

  const errors: any = useMemo(() => {
    const validation = validate(subject ?? "", body ?? "");
    setOutreachValid(Object.keys(validation).length === 0);
    return validation;
  }, [subject, body]);

  const ccOptions = useCcOptions();

  // Just faking parts of the email values here to be similar to what we were displaying before
  // There's probably a more proper way to do this
  const from = {
    id: "",
    email: fromEmail ?? "example@dover.com",
    isAuthed: true,
    owner: EmailOwner.AuthedUser,
  };
  const ccEmailsTipTap = ccEmails?.map(email => {
    return { email, id: "", isAuthed: true, owner: EmailOwner.AuthedUser };
  });

  // Preview Mode
  if (selectedEmailEditorTab === EmailEditorMode.Preview) {
    const substitutionMap = {
      FIRST_NAME: toName ?? "<Example Name>",
      DAY_OF_WEEK: "<e.g. Tuesday>",
      PERSONALIZED_CONTENT: "<A personalized message to the candidate populated at send time.>",
      EMAIL_SENDER_NAME: "<your name>",
    };

    const substitutedBody = substituteVariables(body, substitutionMap);

    const substitutedSubject = substituteVariables(getPlainTextFromHtml(subject ?? ""), substitutionMap);

    return <EmailEditor showPreviewTab readOnly body={substitutedBody} subject={substitutedSubject} />;
  }

  return (
    <Stack spacing={1}>
      {errors.customVariables && (
        <BodySmall color={colors.critical.base}>
          We do not support custom variables. Please select from the variables on the right.
        </BodySmall>
      )}
      {errors.schedulingLinks && (
        <BodySmall color={colors.critical.base}>
          We&apos;re unable to support scheduling links in outreach messages as it disrupts the automated flow we have
          in place. Please remove the scheduling link.
        </BodySmall>
      )}
      <EditorAndVariablesContainer direction="column" spacing={1}>
        <TemplateEditorStack spacing={2}>
          <EmailEditor
            showPreviewTab
            from={from}
            cc={ccEmailsTipTap}
            ccOptions={ccOptions}
            body={initialBody ?? ""}
            subject={initialSubject}
            onBodyChanged={onBodyChanged}
            onSubjectChanged={onSubjectChanged}
            onCcChanged={onCcChanged}
          />
        </TemplateEditorStack>

        <Stack direction="column" spacing={1}>
          <Button variant={ButtonVariant.Ghost} onClick={(): void => setShowVariables(!showVariables)}>
            {showVariables ? "Hide" : "Show"} Variables Help
          </Button>
          <Collapse in={showVariables}>
            <TemplateMessagingContainer spacing={1} sx={{ width: "100%" }}>
              <VariableSelector variables={Object.keys(variables)} />
            </TemplateMessagingContainer>
          </Collapse>
        </Stack>
      </EditorAndVariablesContainer>
    </Stack>
  );
};

export default Editor;
