import { atom } from "jotai";

import { ClientEmailTemplate, HiringStageEmailTemplate } from "services/openapi";

// types
export enum EmailEditorMode {
  Edit = "edit",
  Preview = "preview",
}

export enum EmailTemplateDrawerAction {
  Create = "create",
  Update = "update",
  Delete = "delete",
}

export interface EmailTemplateDrawerConfig {
  onCreateCallback?: (template: ClientEmailTemplate) => void;
  template?: HiringStageEmailTemplate | ClientEmailTemplate;
  action?: EmailTemplateDrawerAction;
}

// toggles
export const ToggleDeleteTemplateConfirmationModalAtom = atom<boolean>(false);
export const ToggleUnsavedChangesModalAtom = atom<boolean>(false);

// drawer state
export const EmailEditorModeAtom = atom<EmailEditorMode>(EmailEditorMode.Edit);
export const EmailTemplateDrawerConfigAtom = atom<EmailTemplateDrawerConfig>({
  onCreateCallback: undefined,
  template: undefined,
  action: undefined,
});
