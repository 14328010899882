/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20044
 */
export interface InlineResponse20044 {
    /**
     * 
     * @type {Array<object>}
     * @memberof InlineResponse20044
     */
    relatedKeywords: Array<object>;
}

export function InlineResponse20044FromJSON(json: any): InlineResponse20044 {
    return InlineResponse20044FromJSONTyped(json, false);
}

export function InlineResponse20044FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20044 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedKeywords': json['relatedKeywords'],
    };
}

export function InlineResponse20044ToJSON(value?: InlineResponse20044 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedKeywords': value.relatedKeywords,
    };
}


