import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useMemo, useState } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import TrashIconSVG from "assets/icons/trash.svg";
import { candidateBioSocialLinkToDisplayMapV2 } from "components/CandidateHeader/SocialLinks/constants";
import { formatURL, isValidURL } from "components/CandidateHeader/SocialLinks/helpers";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonText } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { useCandidateId } from "hooks/useCandidateId";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import {
  CandidateBioSocialLink,
  CandidateBioSocialLinkLinkTypeEnum,
  ContactSocialLink,
  SocialLinkType,
} from "services/openapi";
import { colors } from "styles/theme";

export const AddOrEditLinkItem = ({
  setEditMode,
  onSave,
  onDelete,
  socialLink,
}: {
  setEditMode: (v: boolean) => void;
  onSave: (link: CandidateBioSocialLink) => void;
  onDelete?: () => void;
  socialLink?: CandidateBioSocialLink;
}): ReactElement => {
  const candidateId = useCandidateId();
  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const [linkType, setLinkType] = useState<CandidateBioSocialLinkLinkTypeEnum | null>(socialLink?.linkType ?? null);
  const [linkUrl, setLinkUrl] = useState(socialLink?.url ?? "");
  // Filter out the link types that are already in use
  const filteredLinkTypes = useMemo(() => {
    return Object.values(CandidateBioSocialLinkLinkTypeEnum).filter(type => {
      return socialLink?.linkType === type || !candidateBio?.socialLinks?.find(link => link.linkType === type);
    });
  }, [candidateBio?.socialLinks, socialLink]);

  const urlInputHasError = !linkUrl.length || !isValidURL(linkUrl);
  const disableSave = urlInputHasError || !linkType;

  return (
    <>
      <EditLinkContainer spacing={2}>
        <Box>
          <ButtonText>URL Type</ButtonText>
          <Autocomplete
            value={linkType}
            onChange={(event: any, newOption: any): void => {
              setLinkType(newOption);
            }}
            renderInput={(params: any): React.ReactElement => (
              <StyledTextField {...params} fullWidth label=" " InputLabelProps={{ shrink: false }} />
            )}
            options={filteredLinkTypes}
            getOptionLabel={(linkType: CandidateBioSocialLinkLinkTypeEnum): string => {
              return candidateBioSocialLinkToDisplayMapV2[linkType].name;
            }}
            blurOnSelect
          />
        </Box>
        <Box>
          <ButtonText>Profile link URL</ButtonText>
          <StyledTextField
            value={linkUrl}
            onChange={(e): void => {
              setLinkUrl(e.target.value);
            }}
            fullWidth
            variant="outlined"
            placeholder="Enter a URL"
            label=" "
            InputLabelProps={{ shrink: false }}
            error={urlInputHasError}
          />
        </Box>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box>
            {onDelete && (
              <DeleteButton variant={ButtonVariant.Secondary} onClick={onDelete}>
                <ReactSVG src={TrashIconSVG} />
              </DeleteButton>
            )}
          </Box>
          <Stack flexDirection="row" justifyContent="flex-end">
            <Button
              variant={ButtonVariant.Secondary}
              onClick={(): void => {
                setEditMode(false);
              }}
            >
              Cancel
            </Button>
            <Spacer width="8px" />
            <Button
              variant={ButtonVariant.Primary}
              onClick={(): void => {
                if (linkType) {
                  onSave({
                    ...socialLink,
                    linkType,
                    url: formatURL(linkUrl),
                    editable: socialLink ? socialLink.editable : true,
                  });
                }
              }}
              disabled={disableSave}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </EditLinkContainer>
    </>
  );
};

export const AddOrEditLinkItemV2 = ({
  setEditMode,
  onSave,
  onDelete,
  socialLink,
}: {
  setEditMode: (v: boolean) => void;
  onSave: (link: ContactSocialLink) => void;
  onDelete?: () => void;
  socialLink?: ContactSocialLink;
}): ReactElement => {
  const candidateId = useCandidateId();
  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const [linkType, setLinkType] = useState<SocialLinkType | null>(socialLink?.linkType ?? null);
  const [linkUrl, setLinkUrl] = useState(socialLink?.url ?? "");

  // Filter out the link types that are already in use
  const filteredLinkTypes: Array<Exclude<SocialLinkType, SocialLinkType.NONE>> = useMemo(() => {
    return Object.values(SocialLinkType)
      .filter(type => type !== SocialLinkType.NONE)
      .filter(type => {
        return (
          socialLink?.linkType === type || !candidateBio?.contact.socialLinks?.find(link => link.linkType === type)
        );
      }) as Array<Exclude<SocialLinkType, SocialLinkType.NONE>>;
  }, [candidateBio?.contact.socialLinks, socialLink]);

  const urlInputHasError = !linkUrl.length || !isValidURL(linkUrl);
  const disableSave = urlInputHasError || !linkType;

  return (
    <>
      <EditLinkContainer spacing={2}>
        <Box>
          <ButtonText>URL Type</ButtonText>
          <Autocomplete
            value={linkType}
            onChange={(event, newOption): void => {
              setLinkType(newOption);
            }}
            renderInput={(params: any): React.ReactElement => (
              <StyledTextField {...params} fullWidth label=" " InputLabelProps={{ shrink: false }} />
            )}
            options={filteredLinkTypes}
            getOptionLabel={(linkType: SocialLinkType): string => {
              return candidateBioSocialLinkToDisplayMapV2[linkType].name;
            }}
            blurOnSelect
          />
        </Box>
        <Box>
          <ButtonText>Profile link URL</ButtonText>
          <StyledTextField
            value={linkUrl}
            onChange={(e): void => {
              setLinkUrl(e.target.value);
            }}
            fullWidth
            variant="outlined"
            placeholder="Enter a URL"
            label=" "
            InputLabelProps={{ shrink: false }}
            error={urlInputHasError}
          />
        </Box>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box>
            {onDelete && (
              <DeleteButton variant={ButtonVariant.Secondary} onClick={onDelete}>
                <ReactSVG src={TrashIconSVG} />
              </DeleteButton>
            )}
          </Box>
          <Stack flexDirection="row" justifyContent="flex-end">
            <Button
              variant={ButtonVariant.Secondary}
              onClick={(): void => {
                setEditMode(false);
              }}
            >
              Cancel
            </Button>
            <Spacer width="8px" />
            <Button
              variant={ButtonVariant.Primary}
              onClick={(): void => {
                console.log("linkType", linkType, linkUrl);
                if (linkUrl && linkType) {
                  onSave({
                    linkType,
                    url: formatURL(linkUrl),
                  });
                }
              }}
              disabled={disableSave}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </EditLinkContainer>
    </>
  );
};

const DeleteButton = styled(Button)`
  padding: 6px 12px;
`;

const EditLinkContainer = styled(Stack)`
  background-color: ${colors.grayscale.gray100};
  border-radius: 4px;
  padding: 8px; 16px;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    padding: 8px;
  }
  .MuiInputBase-root {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    background-color: ${colors.white};
  }
`;
