import { DOVER_LINKEDIN_COMPANY_ID, LINKEDIN_URL_PREFIX } from "components/dover/constants";
export function getLinkedInIdFromUrl(url: string): string {
  // If we have the wrong format, then this shouldn't work
  if (!url.includes(LINKEDIN_URL_PREFIX)) {
    return "";
  }

  // If we have the right format, then parse out the company linkedIn ID
  let id = url.replace(LINKEDIN_URL_PREFIX, "");
  if (id.charAt(id.length - 1) === "/") {
    id = id.slice(0, -1);
  }

  // Don't allow users to pass in Dover LinkedIn Company ID
  if (id === DOVER_LINKEDIN_COMPANY_ID) {
    return "";
  }

  return id;
}

export function getLinkedInUrlFromId(id: string | undefined | null): string {
  if (!id) {
    return "";
  }
  return LINKEDIN_URL_PREFIX + id + "/";
}
