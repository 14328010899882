import { Box } from "@mui/material";
import styled, { keyframes } from "styled-components";

export const AIResponseBox = styled(Box)`
  font-family: Inter;
  font-size: 16px;
  font-style: italic;
  width: 200px;
  max-width: 100%;
  display: flex;
`;

const waveAnimation = keyframes`
0% {
  transform: rotate(0deg);
}
10% {
  transform: rotate(12deg);
}
20% {
  transform: rotate(-8deg);
}
30% {
  transform: rotate(12deg);
}
40% {
  transform: rotate(-8deg);
}
60% {
  transform: rotate(0deg);
}
`;

export const AIResponseButton = styled.button`
  width: auto;
  height: 46px;
  padding: 8px 16px;
  background-color: white;
  font-family: Inter;
  font-weight: 500;
  color: black;
  position: relative;
  background-clip: padding-box;
  border: 1.5px solid transparent;
  border-radius: 24px;

  &:focus {
    outline: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1.5px;
    border-radius: inherit;
    background: linear-gradient(to left, #61f4de, #6e78ff);
  }

  &:hover {
    color: white;
    background: linear-gradient(to left, #61f4de, #6e78ff);

    svg {
      animation: ${waveAnimation};
      animation-duration: 2s;
      animation-iteration-count: 1;

      path {
        fill: white;
      }
    }
  }
`;
