import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { PersonaCoin } from "components/PersonaCoin";
import { useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import { CandidateBio, UpdateCandidateBioBodySchedulingOwnershipEnum } from "services/openapi";
import { toastOptions } from "utils/showToast";

export function AskConciergeButton({ bio, disabled }: { bio?: CandidateBio; disabled?: boolean }): React.ReactElement {
  const [updateBio] = useUpdateCandidateBioMutation();
  const conciergeInfo = useConciergeInfo();

  const candidateId = bio?.id;
  const jobId = bio?.job;

  const clickAskConcierge = useCallback(async () => {
    if (!candidateId || !jobId) {
      console.warn("No candidateId or jobId when trying to update scheduling ownership");
      return;
    }

    const args = {
      id: candidateId,
      jobId,
      hideToast: true,
      data: {
        schedulingOwnership: UpdateCandidateBioBodySchedulingOwnershipEnum.DoverHandlesScheduling,
      },
    };

    const submitDecisionPromise = updateBio(args).unwrap();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          pending: "Updating scheduling owndership...",
          success: "Updated!",
          error: "Error updating",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }, [candidateId, jobId, updateBio]);

  return (
    <Button
      removePadding
      height="40px"
      variant={ButtonVariant.Secondary}
      onClick={clickAskConcierge}
      disabled={disabled || !candidateId || !jobId}
    >
      <Stack direction="row" spacing={1} alignItems="center" px="16px">
        <PersonaCoin pictureUrl={conciergeInfo?.pictureUrl} />
        <BodySmall>Ask {conciergeInfo?.firstName} to Schedule</BodySmall>
      </Stack>
    </Button>
  );
}
