import { Box, Stack } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import CustomModal from "components/Modal";

export function UnsavedChangesModal({
  open,
  onCancel,
  onSave,
  onDiscard,
  disableSave = false,
  showSave = true,
  customDialogStyles,
}: {
  open: boolean;
  onCancel: () => void;
  onSave?: () => void;
  onDiscard: () => void;
  disableSave?: boolean;
  showSave?: boolean;
  customDialogStyles?: React.CSSProperties;
}): React.ReactElement {
  return (
    <CustomModal
      open={open}
      title="Discard changes?"
      onClose={onCancel}
      maxWidth="sm"
      customContentStyles={{ borderTop: "none" }}
      customDialogStyles={customDialogStyles}
      dialogActions={
        <Stack direction="row" spacing={2}>
          <Button onClick={onCancel} variant={ButtonVariant.Secondary}>
            Continue editing
          </Button>
          <Stack direction="row" spacing={1}>
            <Button onClick={onDiscard} variant={ButtonVariant.Critical}>
              Discard
            </Button>
            {showSave && onSave && (
              <Button onClick={onSave} variant={ButtonVariant.Primary} disabled={disableSave}>
                Save
              </Button>
            )}
          </Stack>
        </Stack>
      }
    >
      <Box pb={2}>
        <Body>There are unsaved changes.</Body>
        <Body>Are you sure you want to discard them?</Body>
      </Box>
    </CustomModal>
  );
}
