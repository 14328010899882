/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailSendRequest,
    EmailSendRequestFromJSON,
    EmailSendRequestFromJSONTyped,
    EmailSendRequestToJSON,
    FrontCalendarEvent,
    FrontCalendarEventFromJSON,
    FrontCalendarEventFromJSONTyped,
    FrontCalendarEventToJSON,
    FrontContact,
    FrontContactFromJSON,
    FrontContactFromJSONTyped,
    FrontContactToJSON,
    FrontHiringStageEmailTemplate,
    FrontHiringStageEmailTemplateFromJSON,
    FrontHiringStageEmailTemplateFromJSONTyped,
    FrontHiringStageEmailTemplateToJSON,
    FrontJob,
    FrontJobFromJSON,
    FrontJobFromJSONTyped,
    FrontJobToJSON,
    FrontPerson,
    FrontPersonFromJSON,
    FrontPersonFromJSONTyped,
    FrontPersonToJSON,
    HiringPipelineStageMilestone,
    HiringPipelineStageMilestoneFromJSON,
    HiringPipelineStageMilestoneFromJSONTyped,
    HiringPipelineStageMilestoneToJSON,
    HiringPipelineStageType,
    HiringPipelineStageTypeFromJSON,
    HiringPipelineStageTypeFromJSONTyped,
    HiringPipelineStageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface FrontCandidate
 */
export interface FrontCandidate {
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly location?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly timezone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    stageName?: string;
    /**
     * 
     * @type {HiringPipelineStageType}
     * @memberof FrontCandidate
     */
    stageType?: HiringPipelineStageType;
    /**
     * 
     * @type {HiringPipelineStageMilestone}
     * @memberof FrontCandidate
     */
    stageMilestone?: HiringPipelineStageMilestone | null;
    /**
     * 
     * @type {number}
     * @memberof FrontCandidate
     */
    substage?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly substageDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    status?: FrontCandidateStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly preferredPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    airtableManualReplyType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    airtableSentToClient?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    airtableSendToClient?: boolean | null;
    /**
     * 
     * @type {FrontPerson}
     * @memberof FrontCandidate
     */
    person: FrontPerson;
    /**
     * 
     * @type {FrontContact}
     * @memberof FrontCandidate
     */
    contact: FrontContact;
    /**
     * 
     * @type {FrontJob}
     * @memberof FrontCandidate
     */
    job: FrontJob;
    /**
     * 
     * @type {Array<FrontCalendarEvent>}
     * @memberof FrontCandidate
     */
    calendarEvents: Array<FrontCalendarEvent>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontCandidate
     */
    readonly clientCalendars?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontCandidate
     */
    readonly allEmails?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    readonly wasRejected?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontCandidate
     */
    readonly clientDomains?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly shouldSendEmailRejection?: string;
    /**
     * 
     * @type {EmailSendRequest}
     * @memberof FrontCandidate
     */
    queuedAutomatedEmailResponse?: EmailSendRequest | null;
    /**
     * 
     * @type {Date}
     * @memberof FrontCandidate
     */
    readonly latestEmailEventTs?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    readonly manuallySourced?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly sourcingContextDirection?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    readonly isReferral?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly interviewerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly interviewerPronouns?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly interviewerEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    readonly interviewerNeedsSetup?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly interviewerMeetingType?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly doverlySchedulingLink?: string;
    /**
     * 
     * @type {Array<FrontHiringStageEmailTemplate>}
     * @memberof FrontCandidate
     */
    readonly doverlySchedulingEmails?: Array<FrontHiringStageEmailTemplate>;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly doverlyLinkIsValid?: FrontCandidateDoverlyLinkIsValidEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    readonly usingDoverInterviewer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    readonly hasSameInterviewerAndEmailSender?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly emailSenderAccountStatus?: FrontCandidateEmailSenderAccountStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly movedJobFrom?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    readonly movedToNonDoverJob?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    readonly isEmailSenderDown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly multipartInterviewStageId?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    pendingCustomerResponse?: FrontCandidatePendingCustomerResponseEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FrontCandidate
     */
    readonly useEndToEndScheduling?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FrontCandidate
     */
    readonly schedulingOwnership?: FrontCandidateSchedulingOwnershipEnum;
}

/**
* @export
* @enum {string}
*/
export enum FrontCandidateStatusEnum {
    Withdrew = 'WITHDREW',
    Hired = 'HIRED',
    Rejected = 'REJECTED',
    Snoozed = 'SNOOZED',
    Ignored = 'IGNORED'
}/**
* @export
* @enum {string}
*/
export enum FrontCandidateDoverlyLinkIsValidEnum {
    NoInterviewFound = 'No interview found',
    NonNylasCheckCalLink = '❗Non-nylas -- check cal link',
    NonDoverSchedulerCheckCalLink = '❗Non Dover Scheduler -- check cal link',
    LinkIsValidButPleaseOpenToVerifyAvailability = '❌ Link is valid, but please open to verify availability',
    LinkIsValidButTheresNoAvailability = '❌ Link is valid, but there\'s no availability',
    LinkIsValidAndThereIsAvailability = '✅ Link is valid and there is availability',
    CouldNotCheckLinkValidityPleaseOpenTheLinkAndCheckManually = '❌ Could not check link validity. Please open the link and check manually'
}/**
* @export
* @enum {string}
*/
export enum FrontCandidateEmailSenderAccountStatusEnum {
    Active = 'ACTIVE',
    InactiveArchiveAllEmails = 'INACTIVE_ARCHIVE_ALL_EMAILS'
}/**
* @export
* @enum {string}
*/
export enum FrontCandidatePendingCustomerResponseEnum {
    Question = 'QUESTION',
    QuestioningFit = 'QUESTIONING_FIT',
    OtherRole = 'OTHER_ROLE',
    SchedulingHold = 'SCHEDULING_HOLD'
}/**
* @export
* @enum {string}
*/
export enum FrontCandidateSchedulingOwnershipEnum {
    DoverHandlesScheduling = 'DOVER_HANDLES_SCHEDULING',
    CustomerHandlesScheduling = 'CUSTOMER_HANDLES_SCHEDULING'
}

export function FrontCandidateFromJSON(json: any): FrontCandidate {
    return FrontCandidateFromJSONTyped(json, false);
}

export function FrontCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'stageName': !exists(json, 'stage_name') ? undefined : json['stage_name'],
        'stageType': !exists(json, 'stage_type') ? undefined : HiringPipelineStageTypeFromJSON(json['stage_type']),
        'stageMilestone': !exists(json, 'stage_milestone') ? undefined : HiringPipelineStageMilestoneFromJSON(json['stage_milestone']),
        'substage': !exists(json, 'substage') ? undefined : json['substage'],
        'substageDisplay': !exists(json, 'substage_display') ? undefined : json['substage_display'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'preferredPhoneNumber': !exists(json, 'preferred_phone_number') ? undefined : json['preferred_phone_number'],
        'airtableManualReplyType': !exists(json, 'airtable_manual_reply_type') ? undefined : json['airtable_manual_reply_type'],
        'airtableSentToClient': !exists(json, 'airtable_sent_to_client') ? undefined : json['airtable_sent_to_client'],
        'airtableSendToClient': !exists(json, 'airtable_send_to_client') ? undefined : json['airtable_send_to_client'],
        'person': FrontPersonFromJSON(json['person']),
        'contact': FrontContactFromJSON(json['contact']),
        'job': FrontJobFromJSON(json['job']),
        'calendarEvents': ((json['calendar_events'] as Array<any>).map(FrontCalendarEventFromJSON)),
        'clientCalendars': !exists(json, 'client_calendars') ? undefined : json['client_calendars'],
        'allEmails': !exists(json, 'all_emails') ? undefined : json['all_emails'],
        'wasRejected': !exists(json, 'was_rejected') ? undefined : json['was_rejected'],
        'clientDomains': !exists(json, 'client_domains') ? undefined : json['client_domains'],
        'shouldSendEmailRejection': !exists(json, 'should_send_email_rejection') ? undefined : json['should_send_email_rejection'],
        'queuedAutomatedEmailResponse': !exists(json, 'queued_automated_email_response') ? undefined : EmailSendRequestFromJSON(json['queued_automated_email_response']),
        'latestEmailEventTs': !exists(json, 'latest_email_event_ts') ? undefined : (new Date(json['latest_email_event_ts'])),
        'manuallySourced': !exists(json, 'manually_sourced') ? undefined : json['manually_sourced'],
        'sourcingContextDirection': !exists(json, 'sourcing_context_direction') ? undefined : json['sourcing_context_direction'],
        'isReferral': !exists(json, 'is_referral') ? undefined : json['is_referral'],
        'interviewerFirstName': !exists(json, 'interviewer_first_name') ? undefined : json['interviewer_first_name'],
        'interviewerPronouns': !exists(json, 'interviewer_pronouns') ? undefined : json['interviewer_pronouns'],
        'interviewerEmail': !exists(json, 'interviewer_email') ? undefined : json['interviewer_email'],
        'interviewerNeedsSetup': !exists(json, 'interviewer_needs_setup') ? undefined : json['interviewer_needs_setup'],
        'interviewerMeetingType': !exists(json, 'interviewer_meeting_type') ? undefined : json['interviewer_meeting_type'],
        'doverlySchedulingLink': !exists(json, 'doverly_scheduling_link') ? undefined : json['doverly_scheduling_link'],
        'doverlySchedulingEmails': !exists(json, 'doverly_scheduling_emails') ? undefined : ((json['doverly_scheduling_emails'] as Array<any>).map(FrontHiringStageEmailTemplateFromJSON)),
        'doverlyLinkIsValid': !exists(json, 'doverly_link_is_valid') ? undefined : json['doverly_link_is_valid'],
        'usingDoverInterviewer': !exists(json, 'using_dover_interviewer') ? undefined : json['using_dover_interviewer'],
        'hasSameInterviewerAndEmailSender': !exists(json, 'has_same_interviewer_and_email_sender') ? undefined : json['has_same_interviewer_and_email_sender'],
        'emailSenderAccountStatus': !exists(json, 'email_sender_account_status') ? undefined : json['email_sender_account_status'],
        'movedJobFrom': !exists(json, 'moved_job_from') ? undefined : json['moved_job_from'],
        'movedToNonDoverJob': !exists(json, 'moved_to_non_dover_job') ? undefined : json['moved_to_non_dover_job'],
        'isEmailSenderDown': !exists(json, 'is_email_sender_down') ? undefined : json['is_email_sender_down'],
        'multipartInterviewStageId': !exists(json, 'multipart_interview_stage_id') ? undefined : json['multipart_interview_stage_id'],
        'pendingCustomerResponse': !exists(json, 'pending_customer_response') ? undefined : json['pending_customer_response'],
        'useEndToEndScheduling': !exists(json, 'use_end_to_end_scheduling') ? undefined : json['use_end_to_end_scheduling'],
        'schedulingOwnership': !exists(json, 'scheduling_ownership') ? undefined : json['scheduling_ownership'],
    };
}

export function FrontCandidateToJSON(value?: FrontCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stage_name': value.stageName,
        'stage_type': HiringPipelineStageTypeToJSON(value.stageType),
        'stage_milestone': HiringPipelineStageMilestoneToJSON(value.stageMilestone),
        'substage': value.substage,
        'status': value.status,
        'airtable_manual_reply_type': value.airtableManualReplyType,
        'airtable_sent_to_client': value.airtableSentToClient,
        'airtable_send_to_client': value.airtableSendToClient,
        'person': FrontPersonToJSON(value.person),
        'contact': FrontContactToJSON(value.contact),
        'job': FrontJobToJSON(value.job),
        'calendar_events': ((value.calendarEvents as Array<any>).map(FrontCalendarEventToJSON)),
        'queued_automated_email_response': EmailSendRequestToJSON(value.queuedAutomatedEmailResponse),
        'pending_customer_response': value.pendingCustomerResponse,
    };
}


