import { Radio, TextField, ToggleButtonGroup } from "@mui/material";
import Color from "color";
import styled from "styled-components";

import { colors, backgrounds } from "styles/theme";

export const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButton-root {
    text-transform: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    background-color: ${colors.grayscale.gray100};

    &.Mui-selected {
      color: ${colors.primary.base};
      background-color: ${colors.white};
  }

  &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-left: 1px solid ${colors.grayscale.gray200};
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    border: none;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }
`;
