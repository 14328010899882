import { useDroppable } from "@dnd-kit/core";
import { Stack } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import { Overline } from "components/library/typography";
import { AddCandidateButton } from "sections/addcandidate";
import { HiringPipelineStage } from "services/openapi";
import { colors } from "styles/theme";
import { isAppReviewStage } from "utils/isStage";
import Page from "views/candidates/CandidateTable/board/components/Page";
import { BOARD_LIST_CANDIDATES_LIMIT } from "views/candidates/CandidateTable/board/hooks";
import { OpenAppReviewButton } from "views/candidates/CandidateTable/components/OpenAppReviewButton";

interface ColumnProps {
  stage: HiringPipelineStage;
  count: number; // The total number of candidates in this column
}

const Column: FC<ColumnProps> = ({ stage, count }) => {
  const numPages = Math.ceil(count / BOARD_LIST_CANDIDATES_LIMIT); // Total number of pages we could potentially render
  const lastIndex = numPages - 1; // The index of the last page
  const lastPageCount = count % BOARD_LIST_CANDIDATES_LIMIT; // How many items the last page will have

  const { isOver, setNodeRef } = useDroppable({ id: stage.id });

  const stageIsAppReview = isAppReviewStage(stage); // Calculate this here because it will be the same for the whole column

  const pageContent = (index: number): ReactNode => (
    <Stack spacing={1} mb={1}>
      <Page
        key={index}
        stageId={stage.id}
        page={index}
        count={index === lastIndex ? lastPageCount : BOARD_LIST_CANDIDATES_LIMIT}
        showMatchLabel={stageIsAppReview}
      />
    </Stack>
  );

  return (
    <Stack spacing={0.5} px={1} borderRight={`1px solid ${colors.grayscale.gray200}`}>
      <Stack py={0.5} px={1} spacing={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Overline color={colors.grayscale.gray500}>{genColumnTitle(stage, count)}</Overline>
          <AddCandidateButton removeOutline iconOnly hiringPipelineStageId={stage.id} />
        </Stack>
        {stageIsAppReview && <OpenAppReviewButton fullWidth />}
      </Stack>
      <ColumnWrapper ref={setNodeRef} isOver={isOver}>
        <Virtuoso style={{ height: "100%" }} totalCount={numPages} itemContent={pageContent} overscan={1500} />
      </ColumnWrapper>
    </Stack>
  );
};

const genColumnTitle = (stage: HiringPipelineStage, count: number): string => {
  return `${stage.name} (${count})`;
};

interface ColumnWrapperProps {
  isOver: boolean;
}

const ColumnWrapper = styled.div<ColumnWrapperProps>`
  height: 100%;
  width: 275px;
  padding: 8px;
  border-radius: 6px;
  background-color: ${({ isOver }): string | undefined => (isOver ? colors.grayscale.gray200 : undefined)};
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

export default Column;
