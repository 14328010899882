import { ArrowDropDown } from "@mui/icons-material";
import { Box, Menu, MenuItem, Select, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";

import { TextButton } from "components/Button";
import { timezones } from "views/interview/InterviewScheduler/utils";

const TimePicker = ({
  time,
  handleSetTime,
}: {
  time: string;
  handleSetTime: (time: string) => void;
}): React.ReactElement => {
  const menuItems = [];

  const increment = 15; // in minutes
  const iterations = 96;
  const initialTime = dayjs().startOf("day");

  for (let i = 0; i < iterations; i++) {
    const time = initialTime.add(increment * i, "m");
    menuItems.push({ text: time.format("h:mma"), value: time.format("HH:mm") });
  }

  return (
    <Select
      size="small"
      value={time}
      onChange={(e): void => {
        if (e.target.value) {
          handleSetTime(e.target.value);
        }
      }}
    >
      {menuItems.map(item => (
        <MenuItem value={item.value}>{item.text}</MenuItem>
      ))}
    </Select>
  );
};

export const ScheduledSendButton = ({ setSendAt }: { setSendAt: (sendAt: Date) => void }): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [timezone, setTimezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [date, setDate] = useState<Dayjs>(dayjs().tz(timezone));
  const [time, setTime] = useState<string>("00:15");

  const options = generateScheduledSendOptions();

  const getSendAtMoment = (date: Dayjs, hours: number, minutes: number, timezone: string): Date => {
    const computed = date
      .clone()
      .set("hour", hours)
      .set("minute", minutes)
      .tz(timezone, true);

    // moment format for datetime including timezone
    // console.log("sendAtMoment", computed.format("YYYY-MM-DDTHH:mm:ssZ"));
    return computed.toDate();
  };

  const handleSetDate = (date: Dayjs): void => {
    setDate(date);
    const [hours, minutes] = time.split(":").map(num => parseInt(num));
    setSendAt(getSendAtMoment(date, hours, minutes, timezone));
  };

  const handleSetTime = (timeString: string): void => {
    setTime(timeString);
    const [hours, minutes] = timeString.split(":").map(num => parseInt(num));
    setSendAt(getSendAtMoment(date, hours, minutes, timezone));
  };

  const handleSetTimezone = (timezone: string): void => {
    const [hours, minutes] = time.split(":").map(num => parseInt(num));

    setTimezone(timezone);
    setSendAt(getSendAtMoment(date, hours, minutes, timezone));
  };

  const initialIndex: number = 0;
  const [selectedIndex, setSelectedIndex] = React.useState(initialIndex);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (_: React.MouseEvent<HTMLElement>, index: number): void => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Stack direction={"row"} spacing={1}>
      <TextButton padding="8px" onClick={handleOpenMenu} endIcon={<ArrowDropDown />}>
        {options[selectedIndex].label}
      </TextButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem
            key={option.label}
            selected={index === selectedIndex}
            onClick={(event): void => handleMenuItemClick(event, index)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      {selectedIndex === 1 && (
        <>
          <Box
            sx={{
              width: "130px",
              "& .MuiInputBase-root": { height: "46px" },
              "& .MuiFormLabel-root": { top: "-4px" },
              "& .MuiInputLabel-shrink": { top: "0 !important" },
            }}
          >
            {/* @ts-ignore I don't know why this is having a type issue, but it does work */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                componentsProps={{
                  actionBar: {
                    actions: ["clear", "today"],
                  },
                }}
                inputFormat="MM/DD/YY"
                value={date}
                onChange={(newValue): void => {
                  const midnightWithTz = newValue?.startOf("day").tz(timezone, true);
                  if (midnightWithTz) {
                    handleSetDate(midnightWithTz);
                  }
                }}
                renderInput={(params): React.ReactElement => <TextField {...params} label="Date" />}
                disablePast={true}
              />
            </LocalizationProvider>
          </Box>
          <Stack sx={{ width: "90px", "& .MuiInputBase-root": { height: "46px" } }} direction="row" spacing={1}>
            <TimePicker time={time} handleSetTime={handleSetTime} />
            <Select
              size="small"
              value={timezone}
              onChange={(e): void => {
                handleSetTimezone(e.target.value);
              }}
            >
              {timezones.map(tz => (
                <MenuItem key={tz.value} value={tz.value}>
                  {tz.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </>
      )}
    </Stack>
  );
};

/* Helpers */

export enum ScheduledSendEnum {
  Immediately = "Immediately",
  AtDateAndTime = "At date and time",
}
type Option = { value: string; label: string };

function generateScheduledSendOptions(): Option[] {
  return [
    { value: ScheduledSendEnum.Immediately, label: ScheduledSendEnum.Immediately },
    { value: ScheduledSendEnum.AtDateAndTime, label: ScheduledSendEnum.AtDateAndTime },
  ];
}
