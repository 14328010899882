/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationConfig
 */
export interface NotificationConfig {
    /**
     * 
     * @type {string}
     * @memberof NotificationConfig
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof NotificationConfig
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NotificationConfig
     */
    readonly modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof NotificationConfig
     */
    notifType: NotificationConfigNotifTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationConfig
     */
    channel: NotificationConfigChannelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationConfig
     */
    enabled?: boolean;
    /**
     * 
     * @type {object}
     * @memberof NotificationConfig
     */
    data?: object | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationConfig
     */
    job: string;
}

/**
* @export
* @enum {string}
*/
export enum NotificationConfigNotifTypeEnum {
    CallCompleted = 'CALL_COMPLETED',
    CallScheduled = 'CALL_SCHEDULED',
    CallReminder = 'CALL_REMINDER',
    NewInterested = 'NEW_INTERESTED',
    NewBestMatchApplicant = 'NEW_BEST_MATCH_APPLICANT',
    StuckCandidates = 'STUCK_CANDIDATES',
    WeeklyUpdates = 'WEEKLY_UPDATES',
    DailyApplicantSummary = 'DAILY_APPLICANT_SUMMARY',
    NewUnreviewed = 'NEW_UNREVIEWED',
    UnreviewedReminder = 'UNREVIEWED_REMINDER',
    UnreviewedLeads = 'UNREVIEWED_LEADS',
    OutOfDepthAlert = 'OUT_OF_DEPTH_ALERT',
    InvalidGoogleAuth = 'INVALID_GOOGLE_AUTH',
    UnreviewedOutboxReminder = 'UNREVIEWED_OUTBOX_REMINDER',
    JobUnsnoozeReminder = 'JOB_UNSNOOZE_REMINDER',
    AiNotetakerTranscriptReady = 'AI_NOTETAKER_TRANSCRIPT_READY',
    ApplicationConfirmation = 'APPLICATION_CONFIRMATION',
    NewUserInvitation = 'NEW_USER_INVITATION'
}/**
* @export
* @enum {string}
*/
export enum NotificationConfigChannelEnum {
    Email = 'EMAIL',
    Slack = 'SLACK'
}

export function NotificationConfigFromJSON(json: any): NotificationConfig {
    return NotificationConfigFromJSONTyped(json, false);
}

export function NotificationConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'notifType': json['notif_type'],
        'channel': json['channel'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'job': json['job'],
    };
}

export function NotificationConfigToJSON(value?: NotificationConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notif_type': value.notifType,
        'channel': value.channel,
        'enabled': value.enabled,
        'data': value.data,
        'job': value.job,
    };
}


