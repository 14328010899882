import { Icon } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import React from "react";
import { useQueryParam, withDefault } from "use-query-params";

import { ReactComponent as ExternalLinkSVG } from "assets/icons/external-link.svg";
import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import {
  CompanySlugAtom,
  GenerateCareersPageErroredAtom,
  SkipImportCareersPageAtom,
} from "components/dover/EditCareersPage/atoms";
import ImportCareersPage from "components/dover/EditCareersPage/ImportCareersPage";
import { BodySmall, Heading } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import {
  useGetPublishedJobCountQuery,
  useListEditCareersPageJobsQuery,
} from "services/doverapi/endpoints/careersPageJob";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId, useGetClientName } from "services/doverapi/endpoints/client/hooks";
import { colors } from "styles/theme";
import { ExternalLink, InternalLink } from "styles/typography";
import { EditCareersPageFormWrapper } from "views/EditCareersPage/components/EditCareersPageFormWrapper";
import { PublishJobsTable } from "views/EditCareersPage/components/PublishJobsTable";
import { EditCareersPageTabBar } from "views/EditCareersPage/components/TabBar";
import { TalentNetworkBanner } from "views/EditCareersPage/components/TalentNetworkBanner";
import { CAREERS_PAGE_TAB_PARAM, PUBLISH_JOBS_PAGE_LIMIT } from "views/EditCareersPage/constants";
import { EditCareersPageTabEnum, tabParamType } from "views/EditCareersPage/types";

/***** Sub-components *****/

const EditCareersPageHeader = ({
  clientId,
  clientSlug,
}: {
  clientId?: string;
  clientSlug?: string;
}): React.ReactElement => {
  const useSlugPoweredCareersPage = useFeatureFlag(FeatureFlag.SlugPoweredCareersPageUrls);
  const jobsPageUrl = useSlugPoweredCareersPage ? `/jobs/${clientSlug}` : `/dover/careers/${clientId}`;
  return (
    <Stack direction="row" justifyContent="space-between" flexGrow={1}>
      <Heading>Careers Page</Heading>
      <Stack direction="row" spacing={2}>
        <ExternalLink
          href={"https://help.dover.com/en/articles/6946047-careers-page-quick-setup#h_4d8c470ce6"}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <HelpIcon color={colors.link} className="svg-color" />
            <BodySmall color={colors.link}>Add careers page to my website</BodySmall>
          </Stack>
        </ExternalLink>
        {/* TODO: "dover" should not be hardcoded in this link -- should be client name */}
        <InternalLink to={`${jobsPageUrl}`} target="_blank" rel="noopener noreferrer">
          <Stack direction="row" spacing={0.7} alignItems="center">
            <Icon Icon={ExternalLinkSVG} color="transparent" />
            <BodySmall color={colors.grayscale.gray500}>Preview</BodySmall>
          </Stack>
        </InternalLink>
      </Stack>
    </Stack>
  );
};

const CustomizeTabContent = (): React.ReactElement => {
  const { currentData: clientOnboarding, isFetching: clientOnboardingDataIsLoading } = useGetClientOnboardingQuery();

  const skipImportCareersPage = useAtomValue(SkipImportCareersPageAtom);
  const isErrorGeneratingCareersPage = useAtomValue(GenerateCareersPageErroredAtom);

  if (clientOnboardingDataIsLoading) {
    return <DoverLoadingSpinner />;
  }

  if (!clientOnboarding) {
    return <></>;
  }

  // Show the edit form if there is already a careers page or if there was an error generating the careers page
  const showEditCareersPageForm =
    !!clientOnboarding?.careersPageInfo || skipImportCareersPage || isErrorGeneratingCareersPage;

  return showEditCareersPageForm ? (
    <EditCareersPageFormWrapper clientOnboarding={clientOnboarding} isLoading={clientOnboardingDataIsLoading} />
  ) : (
    <ImportCareersPage inOnboardingFlow={false} />
  );
};

/***** Main Component *****/

export const EditCareersPage = (): React.ReactElement => {
  const clientId = useGetClientId();
  const clientSlug = useAtomValue(CompanySlugAtom);
  const clientName = useGetClientName();

  // Tab bar selector
  const [tab, setTab] = useQueryParam(
    CAREERS_PAGE_TAB_PARAM,
    withDefault(tabParamType, EditCareersPageTabEnum.Customize)
  );

  // Publish Jobs queries & values
  const [tablePage, setTablePage] = React.useState(0);
  const { currentData: { publishedJobsCount } = {} } = useGetPublishedJobCountQuery({});
  const {
    currentData: { results: publishableJobs, count: totalJobCount } = {},
    isLoading: isLoadingJobs,
    isFetching: isFetchingJobs,
  } = useListEditCareersPageJobsQuery(clientId ? { clientId, offset: tablePage * PUBLISH_JOBS_PAGE_LIMIT } : skipToken);

  const { currentData: clientOnboarding } = useGetClientOnboardingQuery();
  if (!clientOnboarding) {
    return <></>;
  }

  return (
    <Stack
      direction="column"
      p={4}
      width="100%"
      alignItems="center"
      position="relative"
      overflow={tab === EditCareersPageTabEnum.Customize ? "hidden" : undefined}
    >
      <Box maxWidth={{ sm: "710px", xl: "900px" }} width="100%">
        {(clientSlug || clientId) && <EditCareersPageHeader clientId={clientId} clientSlug={clientSlug} />}
        <EditCareersPageTabBar numJobs={publishedJobsCount} selectedTab={tab} setSelectedTab={setTab} />{" "}
        <Box mt={1}>
          {tab === EditCareersPageTabEnum.Customize && <CustomizeTabContent />}
          {tab === EditCareersPageTabEnum.PublishJobs && (
            <PublishJobsTable
              jobs={publishableJobs}
              jobCount={totalJobCount ?? 0}
              jobsLoading={isLoadingJobs || isFetchingJobs}
              clientId={clientId}
              clientName={clientName}
              page={tablePage}
              handlePageChange={setTablePage}
            />
          )}
          {tab === EditCareersPageTabEnum.TalentNetwork && <TalentNetworkBanner />}
        </Box>
      </Box>
    </Stack>
  );
};
