import { Box, Stack, Grid, Hidden, Skeleton } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "react-use";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { navigateToLink } from "App/routing/utils";
import { ReactComponent as PaperIcon } from "assets/icons/call-notes.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil-edit.svg";
import { NameAndSocialLinks } from "components/CandidateHeader/NameAndSocialLinks";
import FeedbackTemplateSelectModal from "components/dover/FeedbackTemplates/FeedbackTemplateSelectModal";
import { Body, BodySmall } from "components/library/typography";
import { PronounceName } from "components/PronounceName";
import { Role, useContainsRole } from "components/RBAC";
import { Spacer } from "components/Spacer";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useUpdateInterviewRubricResponseMutation } from "services/doverapi/endpoints/candidateInterview";
import { useGetFeedbackTemplateQuery } from "services/doverapi/endpoints/feedbackTemplates";
import {
  CandidateBioSocialLinkLinkTypeEnum,
  JIRTJob,
  JobCompensation,
  JobLocation,
  NextStepsInfo,
  IRRCandidateInterviewInfo,
} from "services/openapi";
import { IRRCandidate as CandidateModel } from "services/openapi/models/IRRCandidate";
import { JIRTJobFAQ as JobFAQModel } from "services/openapi/models/JIRTJobFAQ";
import { colors } from "styles/theme";
import { FakeLink } from "styles/typography";
import { displayJobCompensation } from "utils/displayJobCompensation";
import { displayJobLocations } from "utils/displayJobLocations";
import { SocialLinks } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/SocialLinks";
import { interviewRubricModeAtom } from "views/interview/CandidateInterview/atoms";
import { useInterviewRubricResponseQuery } from "views/interview/CandidateInterview/hooks";
import { InterviewRubricMode } from "views/interview/CandidateInterview/types";
import { H0, H1 } from "views/interview/common/styles";

const FeedbackTemplateInfo = ({
  jobId,
  interviewStageFeedbackTemplateId,
  interviewRubricResponseId,
}: {
  jobId?: string;
  interviewStageFeedbackTemplateId?: string;
  interviewRubricResponseId?: string;
}): React.ReactElement => {
  const [openFeedbackTemplateModal, setOpenFeedbackTemplateModal] = useBoolean(false);

  const interviewRubricModeAtomValue = useAtomValue(interviewRubricModeAtom);
  const isEditable = interviewRubricModeAtomValue === InterviewRubricMode.Interview;

  // Fetch the current IRR
  const { interviewRubricResponse, isLoadingInterviewRubricResponse } = useInterviewRubricResponseQuery({
    interviewRubricResponseId,
  });

  // Retrieve the selected feedback template set on the IRR
  const { data: selectedFeedbackTemplate, isLoading: isLoadingSelectedFeedbackTemplate } = useGetFeedbackTemplateQuery(
    interviewRubricResponse?.feedbackTemplate ?? skipToken
  );

  // To update the selected feedback template on the IRR
  const [updateInterviewRubricResponse] = useUpdateInterviewRubricResponseMutation();

  const handleChangeFeedbackTemplate = (feedbackTemplateId?: string): void => {
    if (!interviewRubricResponseId || !feedbackTemplateId || !interviewRubricResponse) {
      return;
    }
    updateInterviewRubricResponse({
      // TODO: refactor so rubricResponses is not required
      rubricResponses: interviewRubricResponse.rubricResponses,
      interviewRubricResponseId: interviewRubricResponseId,
      feedbackTemplate: feedbackTemplateId,
    });
  };

  const isLoading = isLoadingSelectedFeedbackTemplate || isLoadingInterviewRubricResponse;

  return (
    <>
      <FeedbackTemplateSelectModal
        isOpen={openFeedbackTemplateModal}
        close={(): void => setOpenFeedbackTemplateModal(false)}
        feedbackTemplateId={interviewRubricResponse?.feedbackTemplate ?? undefined}
        setFeedbackTemplateId={handleChangeFeedbackTemplate}
        jobId={jobId}
        interviewStageFeedbackTemplateId={interviewStageFeedbackTemplateId}
      />
      <Stack direction="row" spacing={0.5} alignItems="center">
        <PaperIcon height="14px" />
        <BodySmall>Feedback form: </BodySmall>
        {isLoading ? (
          <Skeleton width={100} height={24} />
        ) : (
          <Stack direction="row" spacing={1} alignItems="center">
            <BodySmall>{selectedFeedbackTemplate?.name ?? "None selected"}</BodySmall>
            {isEditable && (
              <Box onClick={(): void => setOpenFeedbackTemplateModal(true)} sx={{ cursor: "pointer" }}>
                <PencilIcon />
              </Box>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

interface InfoItemProps {
  label: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ label, children }) => (
  <>
    <H1>{label}</H1>
    <Body>{children}</Body>
    <Spacer height="16px" />
  </>
);

interface OptionalInfoItemProps {
  label: string;
  value?: string | null;
  defaultValue?: string;
}

const OptionalInfoItem = ({ label, value, defaultValue }: OptionalInfoItemProps): React.ReactElement | null => {
  if (!value && !defaultValue) {
    return null;
  }

  return <InfoItem label={label}>{value || defaultValue}</InfoItem>;
};

interface InfoHeaderProps {
  label: string;
}

const InfoHeader = ({ label }: InfoHeaderProps): React.ReactElement => (
  <>
    <H0>{label}</H0>
    <Spacer height="24px" />
  </>
);

interface InterviewContextCustomerFacingProps {
  candidateId?: string;
  candidateInterviewInfo?: IRRCandidateInterviewInfo;
  job?: JIRTJob;
  interviewRubricResponseId?: string;
}
export const InterviewContextCustomerFacing = ({
  candidateId,
  candidateInterviewInfo,
  job,
  interviewRubricResponseId,
}: InterviewContextCustomerFacingProps): React.ReactElement => {
  const jobId = job?.id;
  const jobTitle = job?.title;

  // need candidateBio for the NameAndSocialLinks
  const { data: candidateBio, isLoading: isLoadingCandidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const navigate = useNavigate();

  if (!candidateBio || isLoadingCandidateBio || !candidateInterviewInfo) {
    return <Skeleton height="200px" width="100%" />;
  }

  const dateAndInterviewerName = [];
  if (candidateInterviewInfo.startDate) {
    dateAndInterviewerName.push(candidateInterviewInfo.startDate);
  }
  if (candidateInterviewInfo.interviewerName) {
    dateAndInterviewerName.push(candidateInterviewInfo.interviewerName);
  }

  return (
    <Stack spacing={1} padding="16px 0px">
      <Stack direction="row" width="100%" justifyContent="space-between">
        <NameAndSocialLinks candidateBio={candidateBio} />
        <Box maxHeight="30px">
          <FakeLink
            onClick={(event: React.MouseEvent): void =>
              navigateToLink(event, navigate, APP_ROUTE_PATHS.candidates.candidateDetail(candidateBio.id!))
            }
          >
            View full profile
          </FakeLink>
        </Box>
      </Stack>
      <Stack
        sx={{ backgroundColor: colors.grayscale.gray100 }}
        style={{ border: `1px solid ${colors.grayscale.gray200}`, borderRadius: "3px" }}
        padding="15px"
      >
        <Body weight="600">
          {jobTitle ? `${jobTitle} • ${candidateInterviewInfo.stageName}` : candidateInterviewInfo.stageName}
        </Body>
        {!!dateAndInterviewerName.length && (
          <BodySmall color={colors.grayscale.gray500}>{dateAndInterviewerName.join(" • ")}</BodySmall>
        )}
        <FeedbackTemplateInfo
          jobId={jobId}
          interviewStageFeedbackTemplateId={candidateInterviewInfo.defaultFeedbackTemplate ?? undefined}
          interviewRubricResponseId={interviewRubricResponseId}
        />
      </Stack>
    </Stack>
  );
};

interface CandidateContextProps {
  candidate: CandidateModel;
}

const CandidateContext = ({ candidate }: CandidateContextProps): React.ReactElement => {
  const useContactName = useFeatureFlag(FeatureFlag.PandoraNameReads);
  const candidateName = useContactName ? candidate?.contact?.fullName : candidate?.person?.fullName;
  return (
    <>
      <InfoHeader label="Candidate" />
      <InfoItem label="Name">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <a href={`/candidates/${candidate?.id}`} target="_blank" rel="noopener noreferrer">
            {candidateName}
          </a>
          <PronounceName name={candidateName} />
        </Stack>
      </InfoItem>
      <InfoItem label="Profiles">
        {candidate?.person?.linkedinUrl && (
          <SocialLinks
            socialLinks={[
              {
                linkType: CandidateBioSocialLinkLinkTypeEnum.Linkedin,
                url: candidate.person.linkedinUrl,
              },
            ]}
          />
        )}
      </InfoItem>
      {!!candidate.preferredPhoneNumber && <InfoItem label="Phone Number">{candidate.preferredPhoneNumber}</InfoItem>}
    </>
  );
};

interface NextStepsContextProps {
  interviewerName: string;
  interviewerTitle: string;
  interviewerPronouns: string | undefined;
  nextStageLabel: string;
}

const NextStepsContext = ({
  interviewerName,
  interviewerTitle,
  interviewerPronouns,
  nextStageLabel,
}: NextStepsContextProps): React.ReactElement => {
  let interviewerStr = interviewerName;
  if (interviewerTitle) {
    interviewerStr += ` (${interviewerTitle})`;
  }

  return (
    <>
      <InfoHeader label="Next Step" />
      <InfoItem label={"Interview type"}>{nextStageLabel}</InfoItem>
      <InfoItem label={"Interviewer"}>{interviewerStr}</InfoItem>
      <InfoItem label={"Interviewer pronouns"}>{interviewerPronouns ? interviewerPronouns : "Not filled out"}</InfoItem>
    </>
  );
};

interface JobInfoContextProps {
  job: JIRTJob;
  jobLocations?: JobLocation[];
  jobCompensation?: JobCompensation;
  visaSupport?: boolean;
}

interface JobFAQs {
  [key: string]: JobFAQModel;
}

const JobInfoContext = ({
  job,
  jobLocations,
  jobCompensation,
  visaSupport,
}: JobInfoContextProps): React.ReactElement => {
  const faqs = useMemo<JobFAQs>(() => {
    const faqs = job?.faqs;
    if (faqs) {
      return faqs.reduce((x: JobFAQs, faq: JobFAQModel): JobFAQs => {
        if (faq) {
          x[faq.question] = faq;
        }
        return x;
      }, {});
    }
    return {};
  }, [job]);

  const isAdminOrInterviewer = useContainsRole([Role.ADMIN, Role.INTERVIEWER]);

  // this tells the DIs whether they can disclose comp to candidates or not
  const openToSharingComp = jobCompensation?.openToSharingComp;
  const openToSharingCompText = openToSharingComp ? "Yes" : "No";

  const compText = displayJobCompensation(jobCompensation);

  // Get Locations Info
  const locationDisplay = displayJobLocations(jobLocations);
  const locationText = locationDisplay;

  // Get visa info
  const visaSupportText = visaSupport ? "Yes" : "No";

  return (
    <>
      <InfoHeader label="Job Info" />
      <InfoItem label="Company">{job?.client.name}</InfoItem>
      {openToSharingComp && isAdminOrInterviewer && (
        <OptionalInfoItem label="Compensation" value={compText} defaultValue="not specified" />
      )}
      <OptionalInfoItem label="Can Share Compensation" value={openToSharingCompText} defaultValue="No" />
      <InfoItem label="Job">
        {faqs["jobDescription"]?.answer ? (
          <a href={faqs["jobDescription"].answer} target="_blank" rel="noopener noreferrer">
            {job?.title}
          </a>
        ) : (
          <>{job?.title}</>
        )}
      </InfoItem>
      <OptionalInfoItem label="Hiring Manager" value={job?.hiringManager?.name} />
      <Hidden smDown>
        {/* TODO: pitch */}
        <OptionalInfoItem label="Location" value={locationText} defaultValue="Remote" />
        <OptionalInfoItem label="Relocation" value={faqs["relocation"]?.answer} />
        <OptionalInfoItem label="Management or Growth Opportunities" value={faqs["leadershipAvailable"]?.answer} />
        <OptionalInfoItem label="H1-B Transfer" value={visaSupportText} />
      </Hidden>
    </>
  );
};

interface InterviewContextProps {
  candidate?: CandidateModel;
  includeCandidateContext: boolean;
  job?: JIRTJob;
  jobLocations?: JobLocation[];
  jobCompensation?: JobCompensation;
  visaSupport?: boolean;
  includeJobInfoContext: boolean;
  nextStepsInfo?: NextStepsInfo;
  includeNextStepsInfoContext: boolean;
}

export default ({
  candidate,
  includeCandidateContext,
  job,
  jobLocations,
  jobCompensation,
  visaSupport,
  includeJobInfoContext,
  nextStepsInfo,
  includeNextStepsInfoContext,
}: InterviewContextProps): React.ReactElement => {
  return (
    <Grid container style={{ width: "100%" }} spacing={0}>
      {!!candidate && includeCandidateContext && (
        <Grid item xs={6} md={12}>
          <CandidateContext candidate={candidate} />
          <Hidden smDown>
            <Spacer height="32px" />
          </Hidden>
        </Grid>
      )}
      {!!nextStepsInfo && nextStepsInfo.interviewerName && nextStepsInfo.nextStageLabel && includeNextStepsInfoContext && (
        <Grid item xs={6} md={12}>
          <NextStepsContext
            interviewerName={nextStepsInfo.interviewerName}
            interviewerTitle={nextStepsInfo.interviewerTitle!}
            nextStageLabel={nextStepsInfo.nextStageLabel}
            interviewerPronouns={nextStepsInfo.interviewerPronouns}
          />
          <Hidden smDown>
            <Spacer height="32px" />
          </Hidden>
        </Grid>
      )}
      {!!job && includeJobInfoContext && (
        <Grid item xs={6} md={12}>
          <JobInfoContext
            job={job}
            jobLocations={jobLocations}
            jobCompensation={jobCompensation}
            visaSupport={visaSupport}
          />
        </Grid>
      )}
    </Grid>
  );
};
