import { Stack } from "@mui/material";
import React from "react";

import { BodySmall } from "components/library/typography";
import { useFeatureFlag, FeatureFlag } from "hooks/useFeatureFlag";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import { ConciergeProfile } from "views/CandidateDetail/components/next-action/ConciergeProfile";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { SubmitFeedbackButton } from "views/CandidateDetail/components/next-action/states/SubmitFeedbackButton";
import { colorsMap, NextActionStateComponentProps } from "views/CandidateDetail/components/next-action/utils/constants";

export function ConciergeCompletedLastStage({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  const conciergeInfo = useConciergeInfo();
  const useContactName = useFeatureFlag(FeatureFlag.PandoraNameReads);
  const fullName = useContactName ? candidateBio?.contact.fullName : candidateBio?.fullName;
  const name = fullName ?? "Candidate";

  const description = (
    <Stack spacing={2}>
      <ConciergeProfile slackUrl={candidateBio.slackUrl} />
      <BodySmall>
        {`${name} has just completed their final interview for the position. Please let ${conciergeInfo?.firstName} know how you would
        like to proceed.`}
      </BodySmall>
      <SubmitFeedbackButton candidateBio={candidateBio} />
    </Stack>
  );

  return <NextActionCardSkeleton title={"Awaiting Decision"} colors={colorsMap.yellow} description={description} />;
}
