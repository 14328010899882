import { Button } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import React, { ReactElement, useState } from "react";

import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { BodySmall } from "components/library/typography";
import { appConfig } from "config/appConfig";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useSubmitJobCandidateSourceFormMutation } from "services/doverapi/endpoints/job-source-settings/endpoints";
import { EnrichedCandidateSourceDetails, SourceStatus } from "services/doverapi/endpoints/job-source-settings/types";
import {
  JobCandidateSourceSettingDesiredStateEnum,
  JobCandidateSourceSettingExternalJobOriginEnum,
  JobCandidateSourceSettingStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast, showSuccessToast } from "utils/showToast";
import { ListItem } from "views/job/JobBoards/components/ConfigureSource/styles";

export const SelfPostingActionButtons = ({
  selectedEnrichedSource,
  closeModalHandler,
}: {
  selectedEnrichedSource: EnrichedCandidateSourceDetails;
  closeModalHandler: () => void;
}): React.ReactElement => {
  const [jobId] = useJobId();

  const { jobCandidateSourceSettings: sourceSettings } = selectedEnrichedSource;
  const isActive = sourceSettings?.state === JobCandidateSourceSettingStateEnum.Active;
  const isSettingUp = selectedEnrichedSource.status === SourceStatus.SettingUp;

  const [submitJobCandidateSourceForm, { isLoading }] = useSubmitJobCandidateSourceFormMutation();

  const updateOrCreateJobCandidateSource = async ({
    desiredState,
    updateState,
  }: {
    desiredState: JobCandidateSourceSettingDesiredStateEnum;
    updateState: boolean;
  }): Promise<void> => {
    if (!jobId) {
      return;
    }

    try {
      await submitJobCandidateSourceForm({
        desiredState: desiredState,
        candidateSourceId: selectedEnrichedSource?.candidateSource.id!,
        jobId: jobId,
        forceSetState: updateState,
        externalJobOrigin: JobCandidateSourceSettingExternalJobOriginEnum.Client,
      }).unwrap();
    } catch (error) {
      console.error(error);
      showErrorToast("Failed to save changes");

      return;
    }

    const successText =
      desiredState === JobCandidateSourceSettingDesiredStateEnum.Active
        ? "Successfully added source"
        : "Successfully deactivated source";

    showSuccessToast(successText);
    closeModalHandler();

    return;
  };

  return (
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      {/* If source is Active or being Set Up, show button to mark as Inactive */}
      {(isActive || isSettingUp) && (
        <Button
          variant="criticalOutlined"
          onPress={(): void => {
            updateOrCreateJobCandidateSource({
              desiredState: JobCandidateSourceSettingDesiredStateEnum.Inactive,
              updateState: true,
            });
          }}
          isDisabled={isLoading}
        >
          Mark as inactive
        </Button>
      )}
      {/* If source is Inactive show button to mark as Setting Up */}
      {!isSettingUp && !isActive && (
        <Button
          variant="outlined"
          onPress={(): void => {
            updateOrCreateJobCandidateSource({
              desiredState: JobCandidateSourceSettingDesiredStateEnum.Active,
              updateState: false,
            });
          }}
        >
          {"I'll do this later"}
        </Button>
      )}
      {/* If source is inactive or being set up, show button to mark as Active */}
      {(!isActive || isSettingUp) && (
        <Button
          variant="primaryFilled"
          onPress={(): void => {
            updateOrCreateJobCandidateSource({
              desiredState: isActive
                ? JobCandidateSourceSettingDesiredStateEnum.Inactive
                : JobCandidateSourceSettingDesiredStateEnum.Active,
              updateState: true,
            });
          }}
          isDisabled={isLoading}
        >
          {"I posted the job"}
        </Button>
      )}
    </Stack>
  );
};

const SelfToPostModalContent = ({
  selectedEnrichedSource,
  closeModalHandler,
}: {
  selectedEnrichedSource: EnrichedCandidateSourceDetails;
  closeModalHandler: () => void;
}): ReactElement => {
  const { data: client } = useGetUsersClientQuery();

  const [jobId] = useJobId();

  const [clipboardText, setClipboardText] = useState<string | undefined>(undefined);
  const copyToClipboard = (textToCopy: string): void => {
    navigator.clipboard.writeText(textToCopy);
    setClipboardText(textToCopy);
  };

  const inboundURL = `${appConfig.appUrl}/apply/${client?.name}/${jobId}?rs=${selectedEnrichedSource?.candidateSource.encodedSlug}`;
  const sourceDetailsURL = selectedEnrichedSource?.candidateSource.sourceDetailsUrl;

  return (
    <>
      <Stack spacing={1} paddingTop={1}>
        <ListItem>
          <BodySmall weight="600">
            Go to your posting on&nbsp;
            <a href={sourceDetailsURL} target="_blank" rel="noopener noreferrer">
              {selectedEnrichedSource?.candidateSource.label}
            </a>
          </BodySmall>
        </ListItem>
        <ListItem style={{ marginLeft: "40px", marginTop: "16px" }}>
          <BodySmall weight="600">Add the following link to your posting</BodySmall>
        </ListItem>
        <Stack spacing={1} paddingLeft={5} direction="row">
          <Box
            padding={1}
            sx={{
              backgroundColor: colors.grayscale.gray025,
              border: `1px solid ${colors.grayscale.gray200}`,
              borderRadius: "2px",
            }}
          >
            <BodySmall>{inboundURL ?? ""}</BodySmall>
          </Box>
          <Button
            variant="outlined"
            onPress={(): void => {
              copyToClipboard(inboundURL ?? "");
            }}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <CopyIcon />
              <BodySmall color={colors.grayscale.gray600}>
                {inboundURL && clipboardText === inboundURL ? "Copied!" : "Copy"}
              </BodySmall>
            </Stack>
          </Button>
        </Stack>

        <Box paddingLeft={5}>
          <BodySmall>Dover will track the applicant source through the link for accurate reporting data.</BodySmall>
        </Box>
        <SelfPostingActionButtons
          selectedEnrichedSource={selectedEnrichedSource}
          closeModalHandler={closeModalHandler}
        />
      </Stack>
    </>
  );
};

export default SelfToPostModalContent;
