import { Button } from "@doverhq/dover-ui";
import { ReactComponent as BookmarkFilledIcon } from "@doverhq/dover-ui/icons/bookmark-filled.svg";
import { ReactComponent as BookmarkIcon } from "@doverhq/dover-ui/icons/bookmark.svg";
import React, { FC } from "react";

import { useUpdateApplicationBookmarkMutation } from "services/doverapi/endpoints/inboundApplication";
import { SaapReviewApplication } from "services/openapi";
import { useListAppsOptimisticUpdateArgsAtom } from "views/candidates/ApplicationReview/atoms/listApplicationArgs";

interface BookmarkButtonProps {
  application?: SaapReviewApplication;
  compact?: boolean; // The one click buttons are more compact than the next action card buttons
}

export const BookmarkButton: FC<BookmarkButtonProps> = ({ application, compact }) => {
  const [updateInboundApplication] = useUpdateApplicationBookmarkMutation();

  const listAppsOptimisticUpdateArgs = useListAppsOptimisticUpdateArgsAtom();

  const isBookmarked = application?.bookmarked ?? false;

  const click = (): void => {
    if (!application) return; // Shouldn't be possible because button is disabled

    const args = {
      args: {
        id: application.inboundAppId,
        bookmarked: !isBookmarked,
      },
      ...listAppsOptimisticUpdateArgs,
    };

    updateInboundApplication(args);
  };

  return (
    <Button
      variant="outlined"
      icon={{ Icon: isBookmarked ? BookmarkFilledIcon : BookmarkIcon, size: 24, color: "linkLight", side: "left" }}
      isDisabled={!application}
      onPress={click}
      p={compact ? 1 : undefined}
    >
      {isBookmarked ? null : "Bookmark"}
    </Button>
  );
};
