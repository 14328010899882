import {
  CampaignMessageCampaignMessageStateEnum,
  CampaignCampaignStateEnum,
  CampaignSendingWindowEnum,
} from "services/openapi";
import { OnboardingStepState } from "types";

export const ALLOWED_SENDING_WINDOWS: Record<CampaignSendingWindowEnum, string> = {
  DEFAULT_BUSINESS_HOURS: "Default Business Hours",
  DEFAULT_BUSINESS_HOURS_PLUS_WEEKENDS: "Default Business Hours Plus Weekends",
  ALWAYS_WEEKEND_HOURS: "12PM - 8PM every day",
};
export const ALLOWED_SENDING_TIMEZONES: string[] = ["America/Los_Angeles", "America/New_York"];
export const ALLOWED_CAMPAIGN_STATES: Record<CampaignCampaignStateEnum, string> = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  DELETED: "Deleted",
};
export const ALLOWED_SETUP_STATES: OnboardingStepState[] = [
  "PENDING",
  "STARTED_PENDING_CLIENT_ACTION",
  "STARTED_PENDING_DOVER_ACTION",
  "COMPLETE",
];
export const ALLOWED_MESSAGE_STATES: Record<CampaignMessageCampaignMessageStateEnum, string> = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const variables = {
  FIRST_NAME: {
    value: "Alice",
    description: "The first name of the recipient",
  },
  PERSONALIZED_CONTENT: {
    value:
      "How are you doing at Google? Congratulations on your promotions there! I saw that you've been there for over two years now, so I wanted to reach out.",
    description:
      "Dover will personalize each email for you based on the person's experience. Personalized content increases the chance that the candidate will respond.",
  },
  EMAIL_SENDER_NAME: {
    value: "Sidd",
    description: "The first name of the email sender",
  },
};

export const DEFAULT_CAMPAIGN_MESSAGE_DELAY = 172800;
export const CAMPAIGN_MESSAGE_DELAY_OPTIONS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
export const SECONDS_IN_DAY = 86400;
