import { Stack } from "@mui/material";
import React from "react";

import { Subtitle1, BodySmall } from "components/library/typography";

const NoResultsAi = (): React.ReactElement => {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%" spacing={2} px={1}>
      <Stack alignItems="center">
        <Subtitle1>No matches found.</Subtitle1>
        <BodySmall centered>Try asking the AI something else.</BodySmall>
      </Stack>
    </Stack>
  );
};

export default NoResultsAi;
