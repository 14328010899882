import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as HelpIconSVG } from "assets/icons/help-question.svg";
import { Chip } from "components/library/Chip";
import { Tooltip } from "components/library/Tooltip";
import { EnrichedCandidateSourceDetails, SourceStatus } from "services/doverapi/endpoints/job-source-settings/types";
import { CandidateSourceFeaturesEnum } from "services/openapi";
import { ExternalLink } from "styles/typography";
import { isOneClickIntegration } from "views/job/JobBoards/components/ConfigureSource/helpers";

export const StatusCell: React.FC<{
  enrichedSourceDetails: EnrichedCandidateSourceDetails;
}> = ({ enrichedSourceDetails }) => {
  const candidateSource = enrichedSourceDetails.candidateSource;
  const status = enrichedSourceDetails.status;
  const hasBeenPreviouslyAdded = !!enrichedSourceDetails.jobCandidateSourceSettings;

  // If the source has not been previously added and is a featured source, show the featured type badge (i.e. the Recommended section)
  const isFeaturedType = candidateSource.features?.some(feature =>
    [CandidateSourceFeaturesEnum.DoverIntegration, CandidateSourceFeaturesEnum.RecommendedByDover].includes(feature)
  );

  if (isFeaturedType && !hasBeenPreviouslyAdded) {
    if (candidateSource.features?.includes(CandidateSourceFeaturesEnum.DoverIntegration)) {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Chip variant="Informational" label="ONE-CLICK POST" />
          {/* variant={candidateSource.internalName === "LINKEDIN" ? "Primary" : "Informational"}
            label={candidateSource.internalName === "LINKEDIN" ? "SPECIAL PROMOTION" : "ONE-CLICK POST"} */}
        </Stack>
      );
    } else return <></>;
  }

  // Else if the source has not been previously added (i.e. the majority of the Discover more list), don't show a badge
  if (!hasBeenPreviouslyAdded) {
    return <></>;
  }

  // Else if the source has been previously added, show the status badge
  switch (status) {
    case SourceStatus.Active:
      // If Active source is LinkedIn, show explainer tooltip beside the badge
      if (isOneClickIntegration(candidateSource)) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip variant="Primary" label="ACTIVE" />
            <Stack justifyContent="center">
              <Tooltip
                title={
                  <>
                    Your posting may take 24-48 hours to be live. It will be posted on your company page on the job
                    board site. More info&nbsp;
                    <ExternalLink
                      $variant="secondary"
                      href="https://help.dover.com/en/articles/6238844-linkedin-job-postings"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </ExternalLink>
                  </>
                }
              >
                <HelpIconSVG />
              </Tooltip>
            </Stack>
          </Stack>
        );
      }
      return <Chip variant="Primary" label="ACTIVE" />;
    case SourceStatus.SettingUp:
      return <Chip variant="Warning" label="SETTING UP" />;
    case SourceStatus.Deactivating:
      return <Chip variant="Gray" label="DEACTIVATING" />;
    case SourceStatus.Inactive:
      return <Chip variant="Gray" label="INACTIVE" />;
    default:
      return <></>;
  }
};
