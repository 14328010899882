import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { compact } from "lodash";
import React, { useEffect } from "react";
import { Field, useField, useForm } from "react-final-form";
import { useParams } from "react-router-dom";

import { ValidateInterviewers } from "components/dover/CustomizeNextSteps/components/ValidateSelectedInterviewer";
import {
  JobFeatureSettingToggleHandler,
  JobFeatureSettingToggleHandlerContext,
} from "components/dover/feature-toggle-handler/types";
import { JobFeatureGatedBanner } from "components/dover/JobFeatureGating/JobFeatureGatedBanner";
import { AddInterviewersToHiringTeam } from "components/SetupHiringPipeline/components/Drawer/fields/EditAddInterviewersToHiringTeam";
import { useGetFeatureShouldBeEnabled } from "services/doverapi/cross-endpoint-hooks/useGetFeatureShouldBeEnabled";
import { useDoverManagedSchedulingConfig } from "services/doverapi/cross-endpoint-hooks/useGetSchedulingConfig";
import {
  useGetJobFeaturesQuery,
  useUpsertJobFeatureSettingMutation,
} from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import {
  CandidateBioSchedulingOwnershipEnum,
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { isStage } from "utils/isStage";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";
import { DoverInterviewerField } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/DoverInterviewerField";
import { InterviewersField } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/InterviewersField";
import {
  InterviewerContainer,
  RequiredAsterisk,
  StageDrawerFormLabel,
} from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/styles";

export const EditStageInterviewers = ({
  fieldPrefix,
  stageData,
  required,
  isTakeHome,
}: {
  fieldPrefix: string;
  stageData: StageDataWrapper;
  required: boolean;
  isTakeHome: boolean;
}): React.ReactElement => {
  const [interviewersInvalid, setInterviewersInvalid] = React.useState(false);
  const { jobId } = useParams<{ jobId: string }>();
  const [, { isLoading: jobFeatureSettingIsUpdating }] = useUpsertJobFeatureSettingMutation();

  const { isInitialCall, isMultipart } = isStage(stageData.stage);

  const { jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: ({ data, isFetching }) => {
      return {
        jobFeatures: getCurrentJobFeatureStates(data?.features || []),
        isFetchingJobFeatures: isFetching,
      };
    },
  });

  const form = useForm();
  const hasDoverManagedScheduling = useDoverManagedSchedulingConfig(jobId);
  const useDoverInterviewer = stageData?.useDoverInterviewer;
  const interviewerIds: string[] = compact(useField(fieldPrefix + ".interviewers").input.value);

  const doverInterviewerEnabled: boolean | undefined =
    jobFeatures && jobFeatures[JobFeatureFeatureNameEnum.DoverInterviewer];

  useEffect(() => {
    if (doverInterviewerEnabled !== undefined && isInitialCall) {
      form.change(`${fieldPrefix}.useDoverInterviewer`, doverInterviewerEnabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldPrefix, doverInterviewerEnabled, jobFeatures]);

  const jobFeatureToggleHandler = React.useContext<JobFeatureSettingToggleHandler | undefined>(
    JobFeatureSettingToggleHandlerContext
  );

  const onSubmit = async (newValue: boolean): Promise<Object | undefined> => {
    if (jobFeatureSettingIsUpdating || !jobId) {
      return undefined;
    }

    jobFeatureToggleHandler?.onFeatureToggled({
      featureName: UpsertJobFeatureSettingFeatureNameEnum.DoverInterviewer,
      desiredFeatureState: newValue
        ? UpsertJobFeatureSettingStateEnum.Enabled
        : UpsertJobFeatureSettingStateEnum.Disabled,
    });
  };

  const featureShouldBeEnabled = useGetFeatureShouldBeEnabled(JobFeatureFeatureNameEnum.DoverInterviewer, jobId);

  const showSetupDIIncompleteBanner =
    jobId && isInitialCall && stageData.useDoverInterviewer && !featureShouldBeEnabled;

  if (isTakeHome) {
    return <></>;
  }

  if (isMultipart) {
    return <></>;
  }

  return (
    <InterviewerContainer $isInline={isTakeHome}>
      <StageDrawerFormLabel bold>
        Default Interviewer(s) {required && <RequiredAsterisk>*</RequiredAsterisk>}
      </StageDrawerFormLabel>
      <Field
        name={fieldPrefix + ".interviewers"}
        validate={(value): boolean => {
          return required && !value.length;
        }}
      >
        {(): React.ReactElement => {
          if (useDoverInterviewer && isInitialCall) {
            return <DoverInterviewerField name={fieldPrefix + ".useDoverInterviewer"} onSubmit={onSubmit} />;
          }
          return <InterviewersField name={fieldPrefix + ".interviewers"} small initialInterviewers={interviewerIds} />;
        }}
      </Field>
      <AddInterviewersToHiringTeam interviewerIds={interviewerIds} />
      <Box mt={1}>
        <ValidateInterviewers
          selectedInterviewerIds={interviewerIds}
          setSelectedInterviewersValid={setInterviewersInvalid}
          selectedInterviewersValid={interviewersInvalid}
          scheduler={
            hasDoverManagedScheduling
              ? CandidateBioSchedulingOwnershipEnum.DoverHandlesScheduling
              : CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling
          }
        />
      </Box>
      {showSetupDIIncompleteBanner && jobId && (
        <Box paddingTop="16px">
          <JobFeatureGatedBanner jobId={jobId} jobFeatureName={JobFeatureFeatureNameEnum.DoverInterviewer} />
        </Box>
      )}
    </InterviewerContainer>
  );
};
