import { Stack, Tooltip } from "@mui/material";
import clipboardCopy from "clipboard-copy";
import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { ContactInfoWrapper } from "views/CandidateDetail/components/details/styles";

interface Props {
  email: string;
  isPrimary?: boolean;
}
export const Email = ({ email, isPrimary }: Props): React.ReactElement => {
  const [clicked, setClicked] = useState(false);
  return (
    <Tooltip
      title={clicked ? "Copied!" : "Copy to clipboard"}
      placement="top"
      onOpen={(): void => setClicked(false)}
      onClick={(): void => {
        clipboardCopy(email);
        setClicked(true);
      }}
    >
      <EmailWrapper>
        <ContactInfoWrapper>
          <Stack direction="row" spacing={1} mb={0.5}>
            <MailIcon width="16px" />
            <BodySmall color={colors.grayscale.gray700}>
              {email} {isPrimary ? "(default)" : ""}
            </BodySmall>
          </Stack>
        </ContactInfoWrapper>
      </EmailWrapper>
    </Tooltip>
  );
};

const EmailWrapper = styled.div`
  border-radius: 4px;
  &:hover {
    background-color: ${colors.grayscale.gray300};
    cursor: pointer;
  }
`;
