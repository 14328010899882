import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useCallback, useState } from "react";
import { Form } from "react-final-form";
import { useParams } from "react-router-dom";

import { ReactComponent as NewTabIconSVG } from "assets/icons/external-link.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { ButtonRadio } from "components/library/ButtonRadio";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall, Heading } from "components/library/typography";
import CustomModal from "components/Modal";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import { useSubmitJobCandidateSourceFormMutation } from "services/doverapi/endpoints/job-source-settings/endpoints";
import { JobSourceFormValues } from "services/doverapi/endpoints/job-source-settings/types";
import {
  CandidateSource,
  CandidateSourceFeaturesEnum,
  JobCandidateSourceSettingDesiredStateEnum,
  JobCandidateSourceSettingExternalJobOriginEnum,
  JobCandidateSourceSettingStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { showErrorToast, showSuccessToast } from "utils/showToast";
import {
  getSourceIsReadyForUse,
  isOneClickIntegration,
  setValue,
  showSubscriptionDetailsForActiveSource,
} from "views/job/JobBoards/components/ConfigureSource/helpers";
import { getInitialValues, validateFormValues } from "views/job/JobBoards/components/ConfigureSource/helpers";
import SelfToPostModalContent, {
  SelfPostingActionButtons,
} from "views/job/JobBoards/components/ConfigureSource/SelfToPostModalContent";
import SubscriptionDetails from "views/job/JobBoards/components/ConfigureSource/SubscriptionDetails";
import {
  AddEditModalProps,
  SubmitWithDesiredStateProps,
  JobSourceFormErrors,
} from "views/job/JobBoards/components/ConfigureSource/types";
import { JobSourceDetails } from "views/job/JobBoards/components/JobSourceDetails";
import { YC_WAAS_ID } from "views/job/JobBoards/constants";

/***** Sub-Components *****/

const ModalTitle = ({ source }: { source: CandidateSource }): ReactElement => {
  if (source.sourceDetailsUrl) {
    return (
      <ExternalLink href={source.sourceDetailsUrl} target="__blank" rel="noopener noreferrer">
        <Stack direction="row" spacing={1} alignItems="center">
          <Heading>{source.label}</Heading>
          <NewTabIconSVG className="svg-fill" color={colors.grayscale.gray700} />
        </Stack>
      </ExternalLink>
    );
  }
  return <Heading>{source.label}</Heading>;
};

/***** Main Component *****/

export const ConfigureSourceModal = ({
  isModalOpen,
  toggleModalOff,
  selectedEnrichedSource,
}: AddEditModalProps): ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();

  const { data: job } = useGetJobSetupQuery(jobId ?? skipToken);
  const calendlyUrl = useGetCalendlyUrl("app", "job_boards", "dover_to_post_cta");
  const isSampleJob = job?.isSample;

  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>(undefined);
  const [hasExistingJobPosting, setHasExistingJobPosting] = useState<boolean>();
  const [doverToPost, setDoverToPost] = useState<boolean>();

  const isBasePlanCustomer = useIsBasePlanCustomer();

  const closeModalHandler = React.useCallback((): void => {
    // Reset state
    setHasExistingJobPosting(undefined);
    setDoverToPost(undefined);
    setPaymentErrorMessage(undefined);
    toggleModalOff();
  }, [toggleModalOff]);

  const [submitJobCandidateSourceForm] = useSubmitJobCandidateSourceFormMutation();

  const onSubmit = useCallback(
    async (values: JobSourceFormValues): Promise<Object | undefined> => {
      if (!jobId) {
        return;
      }
      const sourceIsActive =
        selectedEnrichedSource?.jobCandidateSourceSettings?.state === JobCandidateSourceSettingStateEnum.Active;

      const newExternalJobOrigin = doverToPost
        ? JobCandidateSourceSettingExternalJobOriginEnum.Dover
        : JobCandidateSourceSettingExternalJobOriginEnum.Client;

      try {
        await submitJobCandidateSourceForm({
          ...values,
          candidateSourceId: selectedEnrichedSource?.candidateSource.id!,
          externalJobOrigin: sourceIsActive ? undefined : newExternalJobOrigin,
          jobId: jobId,
        }).unwrap();
      } catch (error) {
        console.error(error);
        if (error.status === 402) {
          const errorResponse = await error.json();
          setPaymentErrorMessage(errorResponse.message);
        }
        showErrorToast("Failed to save changes");

        return { submissionError: "Failed to submit form values" };
      }

      const successText =
        values.desiredState === JobCandidateSourceSettingDesiredStateEnum.Active
          ? "Successfully added source"
          : "Successfully deactivated source";
      showSuccessToast(successText);
      closeModalHandler();

      return undefined;
    },
    [
      jobId,
      selectedEnrichedSource?.candidateSource.id,
      selectedEnrichedSource?.jobCandidateSourceSettings?.state,
      closeModalHandler,
      submitJobCandidateSourceForm,
      doverToPost,
    ]
  );

  if (!selectedEnrichedSource) {
    return <></>;
  }

  return (
    <CustomModal
      open={isModalOpen}
      onClose={(): void => {
        closeModalHandler();
      }}
      title={<ModalTitle source={selectedEnrichedSource.candidateSource} />}
      maxWidth={"sm"}
    >
      {/* TODO: convert to React Hook Form */}
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialValues(selectedEnrichedSource)}
        validate={(values: JobSourceFormValues): JobSourceFormErrors =>
          validateFormValues({
            values,
            source: selectedEnrichedSource.candidateSource,
          })
        }
        mutators={{ setValue }}
      >
        {({
          form: {
            submit,
            mutators: { setValue },
          },
          submitting,
          submitFailed,
          dirtyFields,
          valid,
          values,
        }): React.ReactNode => {
          const { candidateSource: source, jobCandidateSourceSettings: sourceSettings } = selectedEnrichedSource;

          const selectedPricingTier = source.pricingTiers?.find(pricingTier => pricingTier.id === values.pricingTierId);

          // the form is considered pristine if the non deactivation reason fields have not been modified
          const dirtyFieldKeys = Object.keys(dirtyFields);
          const optionalFields = ["deactivationReason", "deactivationReasonNotes"];
          const isFormPristine: boolean =
            dirtyFieldKeys.length == 0 || !dirtyFieldKeys.some(key => !optionalFields.includes(key));

          const readyForUse = getSourceIsReadyForUse(source, selectedPricingTier?.price);

          const isEditable = !!source.externallyEditable;
          // YC Waas is special in that it's "always active" so we want to instead hard code it to not being active
          // and have a hacky UI where it gives you instructions to make sure it's enabled if you want it as such
          const isActive =
            sourceSettings?.desiredState === JobCandidateSourceSettingDesiredStateEnum.Active &&
            source.id !== YC_WAAS_ID;
          const supportsAdditionalInfo = !!source.features?.includes(
            CandidateSourceFeaturesEnum.SupportsAdditionalInfo
          );

          const sourceIsOneClickIntegration = isOneClickIntegration(source);

          const shouldShowSubscriptionDetailsForActiveSource = showSubscriptionDetailsForActiveSource({
            enrichedCandidateSourceDetails: selectedEnrichedSource,
            isFreeCustomer: isBasePlanCustomer,
          });

          const disableDoverToPostOption = isBasePlanCustomer && !sourceIsOneClickIntegration;

          const setDesiredStateAndSubmit = ({ desiredState, forceSetState }: SubmitWithDesiredStateProps): void => {
            setValue("desiredState", desiredState);
            setValue("forceSetState", forceSetState);
            submit();
          };

          return (
            <Stack spacing={2}>
              <JobSourceDetails
                enrichedSourceDetails={selectedEnrichedSource}
                showExternalJobOptions={isEditable && readyForUse}
                showAdditionalInfoField={supportsAdditionalInfo}
                sourceSettings={sourceSettings}
              />
              {isSampleJob && <BodySmall weight="500">Posting to job boards is disabled for sample jobs.</BodySmall>}
              {/* If this is YC Waas just hide these options as we just want to instruct people on who to set it up but not give them any options */}
              {!isSampleJob && !isActive && source.id !== YC_WAAS_ID && (
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <BodySmall weight="500">{`Do you already have a job posting on ${selectedEnrichedSource
                      .candidateSource.label ?? "this job board"}?`}</BodySmall>
                    <Stack direction="row" spacing={1}>
                      <Box width="fit-content">
                        <ButtonRadio
                          active={hasExistingJobPosting === false}
                          onClick={(): void => setHasExistingJobPosting(false)}
                          label="No"
                        />
                      </Box>
                      <Box width="fit-content">
                        <ButtonRadio
                          active={!!hasExistingJobPosting}
                          onClick={(): void => setHasExistingJobPosting(true)}
                          label="Yes"
                        />
                      </Box>
                    </Stack>
                  </Stack>
                  {hasExistingJobPosting === false && (
                    <>
                      <Stack spacing={1}>
                        <BodySmall weight="500">
                          {sourceIsOneClickIntegration
                            ? "How will you set up your posting?"
                            : "Who will set up your job posting?"}
                        </BodySmall>
                        <Stack direction="row" spacing={1}>
                          <Tooltip
                            title={
                              disableDoverToPostOption ? (
                                <BodyExtraSmall color={colors.white}>
                                  Want Dover to post on any job board for you?{" "}
                                  <ExternalLink $variant="secondary" href={calendlyUrl} target="_blank">
                                    Book a call
                                  </ExternalLink>
                                </BodyExtraSmall>
                              ) : (
                                ""
                              )
                            }
                          >
                            <Box width="fit-content">
                              <ButtonRadio
                                active={!!doverToPost}
                                onClick={(): void => setDoverToPost(true)}
                                label={sourceIsOneClickIntegration ? "Dover one-click integration" : "Dover"}
                                disabled={disableDoverToPostOption}
                              />
                            </Box>
                          </Tooltip>
                          <Box width="fit-content">
                            <ButtonRadio
                              active={doverToPost === false || disableDoverToPostOption}
                              onClick={(): void => setDoverToPost(false)}
                              label={sourceIsOneClickIntegration ? "I will do it manually" : "Me"}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                      {doverToPost && (
                        <SubscriptionDetails
                          selectedEnrichedSource={selectedEnrichedSource}
                          setDesiredStateAndSubmit={setDesiredStateAndSubmit}
                          submitting={submitting}
                          submitFailed={submitFailed}
                          valid={valid}
                          pristine={isFormPristine}
                          paymentErrorMessage={paymentErrorMessage}
                          selectedPricingTier={selectedPricingTier}
                        />
                      )}
                    </>
                  )}
                  {(hasExistingJobPosting ||
                    doverToPost === false ||
                    (hasExistingJobPosting === false && disableDoverToPostOption)) && (
                    <SelfToPostModalContent
                      selectedEnrichedSource={selectedEnrichedSource}
                      closeModalHandler={closeModalHandler}
                    />
                  )}
                </Stack>
              )}
              {isActive && (
                <>
                  {shouldShowSubscriptionDetailsForActiveSource ? (
                    <SubscriptionDetails
                      selectedEnrichedSource={selectedEnrichedSource}
                      setDesiredStateAndSubmit={setDesiredStateAndSubmit}
                      submitting={submitting}
                      submitFailed={submitFailed}
                      valid={valid}
                      pristine={isFormPristine}
                      paymentErrorMessage={paymentErrorMessage}
                      selectedPricingTier={selectedPricingTier}
                    />
                  ) : (
                    <SelfPostingActionButtons
                      selectedEnrichedSource={selectedEnrichedSource}
                      closeModalHandler={closeModalHandler}
                    />
                  )}
                </>
              )}
            </Stack>
          );
        }}
      </Form>
    </CustomModal>
  );
};
