import { Box, Container, Grid, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col } from "reactstrap";
import { useQueryParam, withDefault } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import HelpSVG from "assets/icons/help-question.svg";
import { FilterDivider, FilterTitle } from "components/filters/styles";
import Loading from "components/HotLoading";
import PageHelmet from "components/PageHelmet";
import { Spacer } from "components/Spacer";
import { selectFromListJobsQueryResult, useListJobsQuery } from "services/doverapi/endpoints/job";
import { DashboardJob } from "services/openapi";
import { colors } from "styles/theme";
import { B2_doNotUse, ExternalLink } from "styles/typography";
import { ActiveCandidates } from "views/Reporting/components/ActiveCandidates";
import {
  ChannelsDropdownSelector,
  OnChannelDropdownChangeOptions,
} from "views/Reporting/components/ChannelsDropdownSelector";
import { ChannelTiles } from "views/Reporting/components/ChannelTiles";
import { DateDropdownSelector } from "views/Reporting/components/DateDropdownSelector";
import { DemographicDropdownSelector } from "views/Reporting/components/DemographicDropdownSelector";
import { InterviewStatsTable } from "views/Reporting/components/InterviewStatsTable";
import { KickoffDate } from "views/Reporting/components/KickoffDate";
import {
  ALL_CHANNELS_OPTION,
  DateOption,
  dateRangeOptionQueryParamName,
  DateRangeOptionQueryParamType,
  demographicQueryParamName,
  DemographicQueryParamType,
} from "views/Reporting/constants";
import { StyledRow, StyledSVG, Title } from "views/Reporting/styles";
import { CandidateDemographic, DateRange, SourceOption } from "views/Reporting/types";
import { getFilterDates } from "views/Reporting/utils";

const JobReport = (): React.ReactElement => {
  const navigate = useNavigate();
  const { jobId } = useParams<{ jobId?: string }>();

  const [job, setJob] = useState<DashboardJob | null>(null);

  const [dateRangeOption, setDateRangeOption] = useQueryParam(
    dateRangeOptionQueryParamName,
    withDefault(DateRangeOptionQueryParamType, DateOption.AllTime)
  );
  const [sourceOption, setSourceOption] = useState<SourceOption>(ALL_CHANNELS_OPTION);
  const [demographic, setDemographic] = useQueryParam(
    demographicQueryParamName,
    withDefault(DemographicQueryParamType, CandidateDemographic.All)
  );

  const { activeJobs, allJobs, isLoading: jobsLoading } = useListJobsQuery(
    {},
    {
      selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults),
    }
  );

  const DateFilterTooltipText: string = "The date a candidate was sent outreach or applied to the job.";

  useEffect(() => {
    if (activeJobs && activeJobs.length) {
      setJob(activeJobs[0]);
    }
  }, [activeJobs]);

  useEffect(() => {
    if (!allJobs) {
      return;
    }

    // if no job id in params, default to first job in list
    if (!jobId) {
      navigate(APP_ROUTE_PATHS.reports.reporting(allJobs[0]?.id));
    }
    setJob(allJobs.find(j => j.id === jobId) || null);
  }, [jobId, allJobs, navigate]);

  const selectedDateRange: DateRange = useMemo(() => {
    return getFilterDates(dateRangeOption);
  }, [dateRangeOption]);

  const handleSourceOptionChange = React.useCallback(
    ({ sourceOption, didChannelChange, shouldPerformSideEffects }: OnChannelDropdownChangeOptions): void => {
      setSourceOption(sourceOption);

      // Reset channel-dependent filters if the channel has changed.
      if (didChannelChange && shouldPerformSideEffects) {
        setDemographic(CandidateDemographic.All);
      }
    },
    [setDemographic]
  );

  if (jobsLoading || !job || !job.id) {
    return <Loading />;
  }

  const sourceOptionValue = sourceOption?.value;
  const shouldShowChannelTiles = !jobsLoading && job.id;

  return (
    <>
      <PageHelmet title={job.title ? `${job.title} - Report` : "Report"} />

      <Container sx={{ paddingBottom: "32px", display: "flex" }} maxWidth={"xl"}>
        <StyledRow>
          {job && (
            <Stack direction="row" spacing={3} marginTop="32px" flexGrow={1}>
              <Stack minWidth="280px" maxWidth="280px" padding="0px 25px">
                <FilterTitle>Filters</FilterTitle>
                <Spacer height={24} />
                <FilterDivider />
                <Spacer height={24} />
                <Box display="flex">
                  <B2_doNotUse bold $color={colors.grayscale.gray500}>
                    Date
                  </B2_doNotUse>
                  <Tooltip title={DateFilterTooltipText} placement="top">
                    <div>
                      <StyledSVG src={HelpSVG} style={{ marginLeft: "4px" }} />
                    </div>
                  </Tooltip>
                </Box>
                <Spacer height={16} />
                <DateDropdownSelector
                  value={dateRangeOption}
                  onChange={(value: DateOption): void => setDateRangeOption(value)}
                />
                <Spacer height={24} />
                <FilterDivider />
                <Spacer height={24} />
                <Box display="flex">
                  <B2_doNotUse bold $color={colors.grayscale.gray500}>
                    Channels
                  </B2_doNotUse>
                </Box>
                <Spacer height={16} />
                <ChannelsDropdownSelector jobId={job.id} onChange={handleSourceOptionChange} />
                <Spacer height={24} />
                <FilterDivider />
                <Spacer height={24} />
                <Box display="flex">
                  <B2_doNotUse bold $color={colors.grayscale.gray500}>
                    Demographics
                  </B2_doNotUse>
                </Box>
                <Spacer height={16} />
                <DemographicDropdownSelector
                  value={demographic}
                  onChange={(demographic: CandidateDemographic): void => {
                    setDemographic(demographic);
                  }}
                  selectedSource={sourceOption}
                />
              </Stack>
              <Box flexGrow={1}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                  <Grid item xs={12}>
                    <Box color={colors.grayscale.gray600} display="flex" justifyContent="space-between">
                      <Box display="flex" width="100%">
                        <ActiveCandidates jobId={job.id} />
                        <Spacer width={16} />
                        <KickoffDate jobId={job.id} />
                        <Box display="flex" marginLeft="auto" alignItems="center">
                          <StyledSVG src={HelpSVG} $width="18px" $color={colors.informational.base} />
                          <Spacer width={4} />
                          <ExternalLink
                            href="https://help.dover.com/en/articles/6308801-reports-page-faqs"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <B2_doNotUse $color={colors.informational.base}>Learn More</B2_doNotUse>
                          </ExternalLink>
                        </Box>
                      </Box>
                    </Box>

                    <Spacer height={16} />
                  </Grid>
                </Grid>
                <br />
                {shouldShowChannelTiles && (
                  <ChannelTiles
                    jobId={job.id}
                    dateRange={selectedDateRange}
                    selectedSource={sourceOptionValue}
                    selectedDemographic={demographic}
                  />
                )}
                <InterviewStatsTable
                  job={job}
                  dateRange={selectedDateRange}
                  selectedSource={sourceOptionValue}
                  selectedDemographic={demographic}
                />
              </Box>
            </Stack>
          )}
          {!job && !jobsLoading && (
            <Col lg={12}>
              <Title>Please select a job</Title>
            </Col>
          )}
        </StyledRow>
      </Container>
    </>
  );
};

export default JobReport;
