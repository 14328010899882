import GradingRounded from "@mui/icons-material/GradingRounded";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import styled from "styled-components";
import { useQueryParam, withDefault } from "use-query-params";

import { SocialLink } from "components/dover/SocialLink";
import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { useOpenApiClients } from "hooks/openApiClients";
import BaseStepControls from "sections/addcandidate/components/BaseStepControls";
import {
  OutreachForm,
  BulkOutreachForm,
} from "sections/addcandidate/components/steps/CandidateInfoStep/components/CandidateInfoForm";
import { outreachLabels } from "sections/addcandidate/data/flowConfig";
import { OUTREACH_TYPE_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { StepConfig } from "sections/addcandidate/data/stepConfig";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import {
  AddCandidateStep,
  CandidateInfoStepState,
  FlowTypes,
  OutreachType,
  OutreachTypeParam,
  OutreachTypes,
  StepTypes,
} from "sections/addcandidate/types";
import {
  BulkCandidateInfoSchema,
  CandidateInfoSchema,
  InterviewPipelineCandidateInfoSchema,
} from "sections/addcandidate/types/candidateInfo";
import {
  CandidateBioSocialLinkLinkTypeEnum,
  CheckCanCreateManualContextForJobRequestCoreSourceIdentifierEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";

const OutreachTypeSelector = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const [outreachType, setOutreachTypeParam] = useQueryParam(
    OUTREACH_TYPE_QUERY_PARAM,
    withDefault(OutreachTypeParam, OutreachTypes.SINGLE)
  );

  const handleChangeOutreachType = (_event: React.MouseEvent<HTMLElement>, newValue: OutreachType): void => {
    setOutreachTypeParam(newValue);
  };

  //-----------------------------------------------------------------------------
  // Render

  return (
    <Stack sx={{ mb: 2, mt: 2 }} spacing={2}>
      <Subtitle2>Add a single candidate or multiple?</Subtitle2>
      <ToggleButtonGroup
        color="primary"
        value={outreachType}
        exclusive
        onChange={handleChangeOutreachType}
        aria-label="Platform"
      >
        {Object.values(OutreachTypes).map(ot => (
          <ToggleButton key={ot} value={ot} sx={{ textTransform: "none" }} size="small">
            <BodySmall>{outreachLabels[ot]}</BodySmall>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};

const StepContent = (): React.ReactElement => {
  const apiApi = useOpenApiClients()?.apiApi;

  //-----------------------------------------------------------------------------
  // State
  const formRef = React.useRef<HTMLFormElement>(null);

  const {
    flowState: {
      [StepTypes.JOB_SELECT]: { jobId },
      [StepTypes.CANDIDATE_INFO]: state,
    },
    flowType,
    setStepState,
    handleNextStep,
  } = useAddCandidateContext();

  const [outreachType] = useQueryParam(OUTREACH_TYPE_QUERY_PARAM, withDefault(OutreachTypeParam, OutreachTypes.SINGLE));

  const triggerSubmit = (): void => {
    if (typeof formRef.current?.requestSubmit === "function") {
      formRef.current?.requestSubmit();
    } else {
      const submitEvent = new Event("submit", { cancelable: true, bubbles: true });
      formRef.current?.dispatchEvent(submitEvent);
    }
  };

  const onSubmit = async (values: CandidateInfoStepState): Promise<void> => {
    // TODO: async validation that creating the candidate will succeed would be
    // A+
    if (outreachType === OutreachTypes.SINGLE && jobId) {
      const canManuallySourceProfile = await apiApi?.canManuallySourceProfile({
        data: {
          // @ts-ignore
          email: values.email,
          job: jobId!,
          // @ts-ignore
          firstName: values.firstName,
          // @ts-ignore
          lastName: values.lastName,
          // @ts-ignore
          url: values.linkedinUrl,
          coreSourceIdentifier: CheckCanCreateManualContextForJobRequestCoreSourceIdentifierEnum.ManualOutbound,
          isOutreach: flowType === "initial-outreach",
        },
      });
      if (canManuallySourceProfile?.valid === false) {
        setStepState(StepTypes.CANDIDATE_INFO, {
          ...state,
          manualSourcingPreCheck: {
            isValid: false,
            existingCandidate: canManuallySourceProfile.candidateId ?? undefined,
            errorMessage: canManuallySourceProfile.error,
          },
        });
        return;
      }
    }
    setStepState(StepTypes.CANDIDATE_INFO, values);
    handleNextStep();
  };

  //-----------------------------------------------------------------------------
  // Render

  return (
    <>
      {/* Single vs Bulk only applies to Outreach */}
      {flowType !== FlowTypes.INTERVIEW_PIPELINE && <OutreachTypeSelector />}

      <Stack sx={{ mb: 2, mt: 2 }}>
        {outreachType !== OutreachTypes.BULK ? (
          <OutreachForm
            jobId={jobId ?? ""}
            onSubmit={onSubmit}
            ref={formRef}
            initialValues={state as CandidateInfoSchema}
            flowType={flowType}
          />
        ) : (
          <BulkOutreachForm
            jobId={jobId ?? ""}
            onSubmit={onSubmit}
            ref={formRef}
            initialValues={state as BulkCandidateInfoSchema}
          />
        )}
        {!state?.manualSourcingPreCheck?.isValid && !!state?.manualSourcingPreCheck?.errorMessage && (
          <Banner variant={BannerVariant.Warning}>
            {state.manualSourcingPreCheck.existingCandidate ? (
              <BodySmall>
                This candidate already exists, you can find them{" "}
                <InternalLink
                  to={`/candidates/${state.manualSourcingPreCheck.existingCandidate}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </InternalLink>
              </BodySmall>
            ) : (
              <BodySmall>{state.manualSourcingPreCheck.errorMessage}</BodySmall>
            )}
          </Banner>
        )}
      </Stack>

      <BaseStepControls overrideHandleNext={triggerSubmit} />
    </>
  );
};

const StyledSocialLink = styled.div`
  a > div {
    display: flex;
  }
`;

const PostCompletionContent = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    flowState: { [StepTypes.CANDIDATE_INFO]: state },
  } = useAddCandidateContext();

  //-----------------------------------------------------------------------------
  // Render

  //  Gangsta type assertion
  if ((state as BulkCandidateInfoSchema).candidatesCsv !== undefined) {
    const { candidatesCsv, manualSourcingItems } = state as BulkCandidateInfoSchema;
    return (
      <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
        <BodySmall>{manualSourcingItems?.length} candidates provided</BodySmall>
        {candidatesCsv && (
          <Tooltip title={`Resume: ${candidatesCsv.name}`}>
            <GradingRounded sx={{ color: colors.primary.base, width: 20 }} />
          </Tooltip>
        )}
      </Stack>
    );
  }

  const gangstaState = state as CandidateInfoSchema | InterviewPipelineCandidateInfoSchema;

  return (
    <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
      <BodySmall>
        {gangstaState.firstName} {gangstaState.lastName} {gangstaState.email && `(${gangstaState.email})`}
      </BodySmall>
      {gangstaState.resume && (
        <Tooltip title={`Resume: ${gangstaState.resume.name}`}>
          <GradingRounded sx={{ color: colors.primary.base, width: 20 }} />
        </Tooltip>
      )}
      {gangstaState.linkedinUrl && (
        <StyledSocialLink>
          <SocialLink linkType={CandidateBioSocialLinkLinkTypeEnum.Linkedin} url={gangstaState.linkedinUrl} />
        </StyledSocialLink>
      )}
    </Stack>
  );
};

const step: AddCandidateStep = {
  ...StepConfig[StepTypes.CANDIDATE_INFO],
  StepContent: <StepContent />,
  PostCompletionContent: <PostCompletionContent />,
};

export default step;
