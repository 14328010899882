import { useMemo } from "react";

import { ListCampaign } from "services/openapi";
import {
  useIsEligibleForCampaignOutreachGeneration,
  usePitchStepIsIrrelevantOrCompleted,
} from "views/job/JobSetup/hooks";

export function useShouldAttemptCampaignOutreachGeneration(
  jobId: string | undefined,
  dismissedJobPitchIncompleteModal: boolean,
  allowIncompleteJobPitch: boolean = false
): boolean {
  const everythingSetupAndEligible = useIsEligibleForCampaignOutreachGeneration(jobId, allowIncompleteJobPitch);
  const pitchStepIsIrrelevantOrComplete = usePitchStepIsIrrelevantOrCompleted(jobId);

  const isEligibleForCampaignOutreachGenerationButIncompleteJobPitch = useIsEligibleForCampaignOutreachGenerationButIncompleteJobPitch(
    jobId
  );
  return useMemo<boolean>(() => {
    if (everythingSetupAndEligible && (allowIncompleteJobPitch || pitchStepIsIrrelevantOrComplete)) {
      return true;
    }
    return isEligibleForCampaignOutreachGenerationButIncompleteJobPitch && dismissedJobPitchIncompleteModal;
  }, [
    everythingSetupAndEligible,
    pitchStepIsIrrelevantOrComplete,
    isEligibleForCampaignOutreachGenerationButIncompleteJobPitch,
    dismissedJobPitchIncompleteModal,
    allowIncompleteJobPitch,
  ]);
}

function useIsEligibleForCampaignOutreachGenerationButIncompleteJobPitch(jobId?: string): boolean {
  const pitchStepIsIrrelevantOrComplete = usePitchStepIsIrrelevantOrCompleted(jobId);
  const wouldOtherwiseAttemptCampaignOutreachGeneration = useIsEligibleForCampaignOutreachGeneration(jobId, true);

  return useMemo<boolean>(() => {
    return wouldOtherwiseAttemptCampaignOutreachGeneration && !pitchStepIsIrrelevantOrComplete;
  }, [wouldOtherwiseAttemptCampaignOutreachGeneration, pitchStepIsIrrelevantOrComplete]);
}

function useHasNotAutogeneratedCampaignContentBefore(campaigns: ListCampaign[] | undefined): boolean {
  return useMemo<boolean>(() => {
    return (
      !!campaigns &&
      campaigns.length === 1 &&
      // This checks that the only campaign has no campaign message content generated yet.
      (campaigns[0].threadMessages ?? []).every(message => !message.bodyTemplate)
    );
  }, [campaigns]);
}

export function useShouldShowIncompleteJobPitchModal(
  jobId: string | undefined,
  dismissedJobPitchIncompleteModal: boolean,
  campaigns: ListCampaign[] | undefined,
  isGeneratingCampaignContent: boolean
): boolean {
  const isEligibleForCampaignOutreachGenerationButIncompleteJobPitch = useIsEligibleForCampaignOutreachGenerationButIncompleteJobPitch(
    jobId
  );
  const hasNotAutogeneratedCampaignContentBefore = useHasNotAutogeneratedCampaignContentBefore(campaigns);
  return useMemo<boolean>(() => {
    if (dismissedJobPitchIncompleteModal || isGeneratingCampaignContent) {
      return false;
    }
    return isEligibleForCampaignOutreachGenerationButIncompleteJobPitch && hasNotAutogeneratedCampaignContentBefore;
  }, [
    isEligibleForCampaignOutreachGenerationButIncompleteJobPitch,
    dismissedJobPitchIncompleteModal,
    hasNotAutogeneratedCampaignContentBefore,
    isGeneratingCampaignContent,
  ]);
}
