import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Popover } from "@mui/material";
import { Box, Stack, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAtomValue } from "jotai";
import moment from "moment";
import React, { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { CandidateActionButtons } from "components/CandidateActionButtons/CandidateActionButtons";
import UserRatingModal from "components/dover/UserRatingModal";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Caption, Subtitle2 } from "components/library/typography";
import Confirm from "components/Modal/ConfirmModal";
import { Spacer } from "components/Spacer";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import {
  useSubmitInterviewRubricResponseMutation,
  useSubmitNoShowRescheduleCandidateMutation,
  useUpdateInterviewRubricResponseMutation,
  useWithdrawCandidateMutation,
} from "services/doverapi/endpoints/candidateInterview";
import { useListUserRatingsByEntityQuery } from "services/doverapi/endpoints/userRating";
import {
  InterviewRubricResponse,
  IRRCandidateInterviewInfo,
  UserRatingContentTypeEnum,
  UserRatingRatingTypeEnum,
} from "services/openapi";
import { IRRCandidate as CandidateModel } from "services/openapi/models/IRRCandidate";
import { IRRInterview as InterviewModel } from "services/openapi/models/IRRInterview";
import { colors } from "styles/theme";
import { colorsMap } from "views/CandidateDetail/components/next-action/utils/constants";
import { interviewRubricModeAtom } from "views/interview/CandidateInterview/atoms";
import {
  useAuthedUserHasInterviewerRole,
  useInterviewRubricResponseQuery,
} from "views/interview/CandidateInterview/hooks";
import {
  AINotetakerDrawerOpenAtom,
  TRANSCRIPT_DRAWER_WIDTH,
} from "views/interview/CandidateInterview/TranscriptDrawer";
import { InterviewRubricMode } from "views/interview/CandidateInterview/types";

interface NoShowRescheduleButtonProps {
  numTimesRescheduled?: number;
  shouldDisableActionButtons?: boolean;
  candidate?: CandidateModel;
}

const NoShowRescheduleButton = ({
  numTimesRescheduled,
  shouldDisableActionButtons,
  candidate,
}: NoShowRescheduleButtonProps): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<any>();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [submitNoShowRescheduleCandidate] = useSubmitNoShowRescheduleCandidateMutation();

  const hasAlreadyRescheduled = numTimesRescheduled && numTimesRescheduled >= 1;
  const disabled = Boolean(shouldDisableActionButtons || submitted);

  const button = (
    <Button
      variant={ButtonVariant.Secondary}
      disabled={disabled}
      onClick={async (e: any): Promise<void> => {
        e.preventDefault();
        if (!candidate?.id) {
          return;
        }
        setAnchorEl(e.currentTarget);
      }}
    >
      {submitted ? "Sending email" : "No show reschedule"}
    </Button>
  );

  const popoverContent = hasAlreadyRescheduled ? (
    <Box padding={3}>
      <p>
        This candidate has already been a no-show once. Please use the <strong>Withdraw candidate as no-show</strong>{" "}
        button to withdraw them from the process.
      </p>
    </Box>
  ) : (
    <Box padding={3}>
      <p>Dover will email this candidate to reschedule them.</p>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant={ButtonVariant.Primary}
          onClick={async (e: any): Promise<void> => {
            e.preventDefault();
            if (!candidate?.id) {
              return;
            }
            setAnchorEl(null);
            setSubmitted(true);
            submitNoShowRescheduleCandidate({ candidateId: candidate.id });
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      {button}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(): void => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {popoverContent}
      </Popover>
    </>
  );
};

interface BtnWithConfirmationProp {
  btnLabel: string;
  title: string;
  message: string;
  action: () => void;
  shouldDisableActionButtons?: boolean;
}

const ButtonWithConfirmation = ({
  btnLabel,
  title,
  message,
  action,
  shouldDisableActionButtons,
}: BtnWithConfirmationProp): React.ReactElement => (
  <Confirm
    title={title}
    submitText="Confirm"
    submitVariant="critical"
    content={
      <Box width={520} marginLeft="auto" marginRight="auto">
        <Body>{message}</Body>
      </Box>
    }
  >
    {(confirm: any): React.ReactNode => (
      <Button variant={ButtonVariant.Secondary} disabled={shouldDisableActionButtons} onClick={confirm(action)}>
        {btnLabel}
      </Button>
    )}
  </Confirm>
);

interface ContainerStyleProps {
  center?: boolean;
  compact?: boolean;
}

const BaseStickyControlsContainer = styled.div<ContainerStyleProps>`
  min-height: 66px;
  width: 100%;
  display: flex;
  justify-content: ${(props: ContainerStyleProps): any => `${props.center ? "center" : "space-between"};`};
  background-color: white;
  align-items: center;
  padding: ${(props: ContainerStyleProps): any => `${props.compact ? "8px 16px" : "8px 64px"};`};
  z-index: 999; // 1 less than nav dropdown
  background-color: #fafafa;
`;

const StickyControlsFooterContainer = styled(BaseStickyControlsContainer)`
  left: 0;
  bottom: 0;
  position: fixed;
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.08);
  min-height: 77px;
  z-index: 999;
`;

const B1Approved = styled(Body)`
  color: ${colors.primary.base};
`;

const B1Rejected = styled(Body)`
  color: ${colors.critical.base};
`;

interface FinalDecisionTextProps {
  candidate?: CandidateModel;
}

const SCHEDULED_DATE_FORMAT = "hh:mm a [on] MMMM Do";

const FullFinalDecisionText = ({ candidate }: FinalDecisionTextProps): React.ReactElement => {
  const approved = candidate?.feedback?.advanceToNextRound;
  const rejected = !!candidate?.status;

  const decidedOn = moment(candidate?.feedback?.modified).local();

  // explicitly render the text if we know the approve/reject state
  // there is some issue with FirstRoundInterviewFeedback being updated and can't track it down
  if (approved) {
    return (
      <B1Approved>
        <CheckRoundedIcon /> {"Advanced"} on {decidedOn.format("l")}
      </B1Approved>
    );
  }

  if (rejected) {
    return (
      <B1Rejected>
        <ClearRoundedIcon /> {"Rejected"} on {decidedOn.format("l")}
      </B1Rejected>
    );
  }

  // otherwise if we don't know for sure, just don't show anything
  return <></>;
};

interface ReviewProps {
  setEditing?: () => void;
  candidate?: CandidateModel;
  interviewRubricResponse?: InterviewRubricResponse;
}

const ReviewControls = ({ setEditing, candidate, interviewRubricResponse: irr }: ReviewProps): React.ReactElement => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [showRatingModal, setShowRatingModal] = useState(false);
  const { data: candidateBio, isLoading: isCandidateBioLoading } = useGetCandidateBioQuery(candidate!.id!);
  const hasDoverInterviewer = !!irr?.interview?.interviewer?.isDoverInterviewer;

  const { data: userRatings, isLoading: userRatingsLoading } = useListUserRatingsByEntityQuery({
    entityType: UserRatingContentTypeEnum.Interviewrubricresponse,
    entityUuid: irr?.id || "",
  });

  if (isCandidateBioLoading) {
    return <></>;
  }

  if (!hasDoverInterviewer) {
    return (
      <StickyControlsFooterContainer center>
        <Stack marginLeft="200px" justifyContent="flex-start" direction="row" spacing={1} width="100%">
          <Button
            variant={ButtonVariant.Secondary}
            onClick={(e): void => {
              // prevent form submit
              e.preventDefault();
              setEditing?.();
            }}
          >
            Edit Feedback
          </Button>
        </Stack>
      </StickyControlsFooterContainer>
    );
  }

  if (
    candidate?.feedback?.advanceToNextRound ||
    // this view is only relevant if the interviews match. If they don't match, we know for sure the candidate has been updated stages
    irr?.candidateInterviewInfo?.interviewId !== candidateBio?.nextAction?.currentInterview?.id
  ) {
    if (userRatingsLoading) {
      return <></>;
    }

    const shouldShowRatingButton =
      hasDoverInterviewer &&
      !(
        userRatings &&
        userRatings.some(userRating => userRating.ratingType === UserRatingRatingTypeEnum.DoverInterviewerFeedback)
      );

    return (
      <>
        <UserRatingModal
          question={"How useful were the interview notes provided by your Dover Interviewer?"}
          ratingOptions={[
            { label: "Very useful", value: 100 },
            { label: "Somewhat useful", value: 60 },
            { label: "Not useful", value: 30 },
          ]}
          showModal={showRatingModal && irr !== undefined}
          onCancelCallback={(): void => setShowRatingModal(false)}
          ratingType={UserRatingRatingTypeEnum.DoverInterviewerFeedback}
          contentType={UserRatingContentTypeEnum.Interviewrubricresponse}
          contentId={irr?.id || ""}
          onSubmitCallback={(): void => setShowRatingModal(false)}
        />
        <StickyControlsFooterContainer center>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FullFinalDecisionText candidate={candidate} />
            {shouldShowRatingButton && (
              <>
                <Spacer width="24px" />
                <Subtitle2>How were your interview notes?</Subtitle2>
                <Spacer width="8px" />
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={(e: any): void => {
                    e.preventDefault();
                    setShowRatingModal(true);
                  }}
                >
                  Leave feedback
                </Button>
              </>
            )}
          </Box>
        </StickyControlsFooterContainer>
      </>
    );
  }

  const colors = colorsMap.white;

  return (
    <StickyControlsFooterContainer
      center
      compact={matches}
      style={{
        backgroundColor: colors.backgroundColor,
      }}
    >
      {// If Candidate has already reached final state. Status is not NONE (active)
      candidate && !!candidate.status ? (
        <Caption
          weight={"700"}
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "uppercase",
          }}
        >
          Candidate {candidate.status}
        </Caption>
      ) : (
        <>
          {candidateBio && (
            <CandidateActionButtons
              candidateBio={candidateBio}
              showValidationTooltip
              interviewRubricResponseId={irr?.id}
              horizontalLayout
              preventDefaultEvent
            />
          )}
        </>
      )}
    </StickyControlsFooterContainer>
  );
};

interface InterviewProps {
  isDoverInterview: boolean;
  lastSavedAt?: string;
  isSaving?: boolean;
  inProgress?: boolean;
  interviewInfo?: InterviewModel;
  candidate?: CandidateModel;
  numTimesRescheduled?: number;
  isPostInitialSubmit?: boolean;
  isPostDecision?: boolean;
  isUserHm?: boolean;
}

const InterviewControls = ({
  isDoverInterview,
  lastSavedAt,
  isSaving,
  inProgress,
  interviewInfo,
  candidate,
  numTimesRescheduled,
  isPostInitialSubmit,
  isPostDecision,
  isUserHm,
}: InterviewProps): React.ReactElement => {
  const useContactName = useFeatureFlag(FeatureFlag.PandoraNameReads);
  const isAINotetakerDrawerOpen = useAtomValue(AINotetakerDrawerOpenAtom);
  const isUserDoverInterviewer = useAuthedUserHasInterviewerRole();
  const [withdrawCandidate] = useWithdrawCandidateMutation();

  const [, { isLoading: isUpdatingRubric }] = useUpdateInterviewRubricResponseMutation({
    fixedCacheKey: "updating-irr",
  });
  const [, { isLoading: isSubmittingRubric }] = useSubmitInterviewRubricResponseMutation({
    fixedCacheKey: "submitting-irr",
  });

  // only disable for non-DI users and after initial call decision is submitted for dover interview case
  const shouldDisableActionButtons =
    isUpdatingRubric ||
    isSubmittingRubric ||
    (isDoverInterview && !isUserDoverInterviewer) ||
    (isDoverInterview && isPostDecision);

  const submitResponsesButtonText = isPostInitialSubmit ? "Update responses" : "Submit responses";

  const candidateName = useContactName ? candidate?.contact?.fullName : candidate?.person?.fullName;

  const ActionButtons = React.useMemo(() => {
    const submitButton = (
      <Button
        // @ts-ignore
        type="submit"
        variant={ButtonVariant.Primary}
        disabled={shouldDisableActionButtons}
      >
        {submitResponsesButtonText}
      </Button>
    );

    // non DI's/customers should just see the submit button
    if (!isDoverInterview) {
      return submitButton;
    }

    return (
      <Stack direction="row" spacing={1}>
        <NoShowRescheduleButton
          numTimesRescheduled={numTimesRescheduled}
          shouldDisableActionButtons={shouldDisableActionButtons}
          candidate={candidate}
        />
        <ButtonWithConfirmation
          btnLabel="Withdraw candidate as no-show"
          title="Please confirm no-show withdrawal"
          message={`${candidateName}(${candidate?.job?.title}) should be withdrawn due to multiple no-shows.`}
          shouldDisableActionButtons={shouldDisableActionButtons}
          action={async (): Promise<void> => {
            if (!candidate?.id) {
              return;
            }
            withdrawCandidate({ candidateId: candidate.id, isNoShow: true });
          }}
        />
        <ButtonWithConfirmation
          btnLabel="Withdraw candidate"
          title="Please confirm withdrawal"
          message={`${candidateName}(${candidate?.job?.title}) indicated that they wanted to withdraw from the process.`}
          shouldDisableActionButtons={shouldDisableActionButtons}
          action={async (): Promise<void> => {
            if (!candidate?.id) {
              return;
            }
            withdrawCandidate({ candidateId: candidate.id });
          }}
        />
        {submitButton}
      </Stack>
    );
  }, [
    candidate,
    candidateName,
    isDoverInterview,
    numTimesRescheduled,
    shouldDisableActionButtons,
    submitResponsesButtonText,
    withdrawCandidate,
  ]);

  return (
    <StickyControlsFooterContainer>
      <div>
        {!inProgress && (
          <Link to="/admin/interviews" style={{ textDecoration: "none", color: "#000000" }}>
            <ArrowBackRoundedIcon /> Interviews
          </Link>
        )}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {isUserHm && isDoverInterview ? (
          <>
            {interviewInfo?.start && interviewInfo?.interviewer?.name ? (
              <Body>
                Interview scheduled for{" "}
                {moment(interviewInfo.start)
                  .local()
                  .format(SCHEDULED_DATE_FORMAT)}{" "}
                with {interviewInfo?.interviewer?.name}
              </Body>
            ) : (
              <Body>Interview has not completed yet</Body>
            )}
          </>
        ) : (
          <>
            {inProgress ? (
              // Give it some margin so it doesn't intersect with intercom button
              <Stack
                direction="row"
                marginRight={isAINotetakerDrawerOpen ? TRANSCRIPT_DRAWER_WIDTH : "45px"}
                alignItems="center"
                spacing={2}
              >
                {lastSavedAt && !isSaving && (
                  <Body color={colors.grayscale.gray600}>
                    Saved{" "}
                    <Moment interval={1000} fromNow>
                      {lastSavedAt}
                    </Moment>
                  </Body>
                )}
                {isSaving && <Body color={colors.grayscale.gray600}>Saving...</Body>}
                {ActionButtons}
              </Stack>
            ) : (
              <>
                <DoneRoundedIcon /> Submitted
              </>
            )}
          </>
        )}
      </div>
    </StickyControlsFooterContainer>
  );
};

interface Props {
  lastSavedAt?: string;
  isSaving?: boolean;
  inProgress?: boolean;
  setEditing?: () => void;
  interviewInfo?: InterviewModel;
  candidateInterviewInfo: IRRCandidateInterviewInfo;
  interviewRubricResponseId: string | undefined;
  candidate?: CandidateModel;
  numTimesRescheduled?: number;
}

export default ({
  lastSavedAt,
  isSaving,
  inProgress,
  setEditing,
  interviewInfo,
  candidateInterviewInfo,
  interviewRubricResponseId,
  candidate,
  numTimesRescheduled,
}: Props): React.ReactElement => {
  const interviewRubricModeAtomValue = useAtomValue(interviewRubricModeAtom);

  const {
    interviewRubricResponse,
    isLoadingInterviewRubricResponse,
    isPostDecision,
    isPostInitialSubmit,
    isUserHm,
  } = useInterviewRubricResponseQuery({ candidateId: candidate?.id, interviewRubricResponseId });

  const inEditMode = interviewRubricModeAtomValue === InterviewRubricMode.Interview;

  const inReadOnlyMode = interviewRubricModeAtomValue === InterviewRubricMode.Review;

  if (isLoadingInterviewRubricResponse) {
    return <></>;
  }

  // Review mode: show decision buttons; assume this won't be used if decision already rendered.
  if (inReadOnlyMode) {
    return (
      <ReviewControls setEditing={setEditing} candidate={candidate} interviewRubricResponse={interviewRubricResponse} />
    );
  }

  // Interview mode: show submit button or submitted message
  if (inEditMode) {
    return (
      <InterviewControls
        candidate={candidate}
        isSaving={isSaving}
        lastSavedAt={lastSavedAt}
        inProgress={inProgress}
        interviewInfo={interviewInfo}
        isDoverInterview={candidateInterviewInfo.useDoverInterviewer}
        numTimesRescheduled={numTimesRescheduled}
        isPostInitialSubmit={isPostInitialSubmit}
        isPostDecision={isPostDecision}
        isUserHm={isUserHm}
      />
    );
  }

  // this won't be reachable after feedback_templates FF is GA because interviewRubricMode is never undefined
  return <></>;
};
