import { Button } from "@doverhq/dover-ui";
import { ReactComponent as ArrowRight } from "@doverhq/dover-ui/icons/arrow-right.svg";
import { ReactComponent as XIcon } from "@doverhq/dover-ui/icons/x.svg";
import { Box, Stack } from "@mui/material";
import isEqual from "date-fns/isEqual";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as AIPoweredChip } from "assets/icons/ai-powered-chip.svg";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { useGetClientIsTam } from "services/doverapi/endpoints/client/hooks";
import { SearchV3 } from "services/openapi";
import { colors } from "styles/theme";

interface SetupFlowEntryCardProps {
  jobId: string;
  search: SearchV3;
}

export const SetupFlowEntryCard = ({ jobId, search }: SetupFlowEntryCardProps): ReactElement => {
  const navigate = useNavigate();
  const [isDismissed, setIsDismissed] = useLocalStorage(`isSetupCardDismissed-${jobId}`, false);
  const isModified = !isEqual(new Date(search.created ?? "0"), new Date(search.modified ?? "0"));

  const onDismiss = (): void => {
    setIsDismissed(true);
  };

  const onSetup = (): void => {
    navigate(APP_ROUTE_PATHS.onboarding.applicantSorting(jobId));
  };

  const clientIsExplicitNonTam = useGetClientIsTam() === false;

  if (isModified || isDismissed || clientIsExplicitNonTam) return <></>;

  return (
    <Box p={1} flex="1 1 auto">
      <Stack
        flex="0 1 auto"
        border={1}
        borderRadius={1.5}
        p={1.5}
        pt={0}
        sx={{
          background: `linear-gradient(90deg, rgba(235, 255, 250, 0.60) 0%, ${colors.white} 100.96%)`,
          borderColor: colors.brand,
        }}
        spacing={2}
      >
        <Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Subtitle2 style={{ marginRight: "4px" }}>Applicant sorting</Subtitle2>
              <AIPoweredChip />
            </Stack>
            <Button variant="ghost" icon={{ Icon: XIcon }} onPress={onDismiss} />
          </Stack>
          <BodySmall>Add your criteria to auto-sort your applications.</BodySmall>
        </Stack>
        <Button
          variant="primaryFilled"
          icon={{ Icon: ArrowRight, side: "right" }}
          style={{ width: "100px", height: "40px" }}
          onPress={onSetup}
        >
          Set up
        </Button>
      </Stack>
    </Box>
  );
};
