import { useAtomValue } from "jotai";

import { isChatOpenAtom } from "views/candidates/ApplicationReview/atoms/chat";
import { selectedApplicantListTabAtom } from "views/candidates/ApplicationReview/atoms/selectedApplicantListTab";
import { useApplications } from "views/candidates/ApplicationReview/hooks/useApplications";

/**
 * Bakes in the selectedTab check to return the count for the selected tab.
 * Useful for AI Chat as well , when theres not really the concept of a selected tab.
 */
export const useSelectedTabCount = (): number | undefined => {
  const selectedTab = useAtomValue(selectedApplicantListTabAtom);

  const { unreviewedCount, bookmarkedCount, matchingCount } = useApplications();

  const isChatOpen = useAtomValue(isChatOpenAtom);

  if (isChatOpen) {
    return unreviewedCount;
  }

  switch (selectedTab) {
    case "Unreviewed":
      return unreviewedCount;
    case "Matching":
      return matchingCount;
    case "Bookmarked":
      return bookmarkedCount;
    default:
      return undefined;
  }
};
