import "commandbar-launcher/assets/index.css";
import Launcher from "commandbar-launcher";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import DoverCommandBar from "App/components/Nav/CommandBar";
import { INVITE_TEAM_QUERY_PARAM } from "App/components/Nav/constants";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CREATE_JOB_DRAWER_OPEN_PARAM } from "components/dover/CreateJob/constants";
import { useModal } from "GlobalOverlays/atoms";
import { useOpenApiClients } from "hooks/openApiClients";
import { ADD_CANDIDATE_DRAWER_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { addCandidateV2DrawerAtom } from "sections/addCandidateV2";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useAuth0 } from "services/react-auth0-spa";

// Use commandbar-launcher to render the search bar
// Setup commandbar on mount
export const SearchBarV3 = (): React.ReactElement => {
  const navigate = useNavigate();
  const apiClient = useOpenApiClients()?.apiApi;
  const { data: client } = useGetUsersClientQuery();
  const isBasePlanCustomer = useIsBasePlanCustomer();
  const { open } = useModal(addCandidateV2DrawerAtom);

  const [, setCreateJobDrawerOpen] = useQueryParam(CREATE_JOB_DRAWER_OPEN_PARAM, BooleanParam);
  const [, setIsOpenAddCandidateDrawerParam] = useQueryParam(ADD_CANDIDATE_DRAWER_QUERY_PARAM, BooleanParam);
  const [, setInviteNewUsersModalOpen] = useQueryParam(INVITE_TEAM_QUERY_PARAM, BooleanParam);
  const { user } = useAuth0();

  useEffect(() => {
    if (apiClient && client) {
      DoverCommandBar.getInstance(
        navigate,
        apiClient,
        user.sub,
        (): void => setCreateJobDrawerOpen(true),
        (): void => open({}),
        (): void => setInviteNewUsersModalOpen(true),
        (): void => {
          if (isBasePlanCustomer) {
            navigate(APP_ROUTE_PATHS.embeddedRecruiter());
          } else {
            window.open(`https://dovertalent.slack.com/archives/${client?.adminChannelId}`, "_blank");
          }
        }
      ).register();
    }
  }, [
    apiClient,
    navigate,
    setCreateJobDrawerOpen,
    setIsOpenAddCandidateDrawerParam,
    setInviteNewUsersModalOpen,
    client,
    isBasePlanCustomer,
    user,
    open,
  ]);

  // @ts-ignore
  return <Launcher text=" " />;
};

export default (): React.ReactElement => {
  return <SearchBarV3 />;
};
