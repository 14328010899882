import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { baseColors } from "styles/theme";

interface StyledBannerProps {
  styledColor: string;
  filled?: boolean;
  alignItems?: "center" | "flex-start" | "flex-end" | "baseline";
  padding?: string;
}

export const StyledBanner = styled.div<StyledBannerProps>`
  display: flex;
  align-items: ${(props): string => props.alignItems || "center"};
  padding: ${(props): string => (props.padding ? props.padding : "16px")};
  background-color: ${(props): string => (props.filled ? props.styledColor : baseColors.white)};
  border: ${(props): string => (props.filled ? "none" : `1px solid ${props.styledColor}`)};
  border-radius: 4px;
`;

interface StyledReactSVGProps {
  alignItems?: "center" | "flex-start" | "flex-end" | "baseline";
  svgBottom?: string;
  svgColor?: string;
}

export const StyledReactSVG = styled(ReactSVG)<StyledReactSVGProps>`
  display: ${({ alignItems }): string => (alignItems === "center" ? "flex" : "block")};
  alignitems: center;
  margin: 4px;
  svg {
    height: 16px;
    width: 16px;
    position: ${({ svgBottom }): string => (svgBottom ? "relative" : "static")};
    bottom: ${({ svgBottom }): string => svgBottom ?? "0px"};
  }
`;
