import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  CAMPAIGN,
  CAMPAIGN_MESSAGE_REQUEST,
  CANDIDATE_SOURCE,
  CANDIDATE_BIO,
  CHANNEL_PERSONA_STATS_BENCHMARK,
  CLIENT_ONBOARDING,
  CLIENT_ROLES,
  JOB,
  JOB_CANDIDATE_SOURCE_SETTING,
  JOB_DESCRIPTION,
  JOB_FULFILLMENT,
  JOB_PITCH,
  JOB_SETUP,
  NOTIFICATION_CONFIG,
  PRO_USER,
  SEARCH_CRITERIA,
  USER_CREDENTIAL,
  USER_UPLOADED_FILE,
  CANDIDATE_EMAIL_EVENT,
  CANDIDATE_INTERVIEW_EVENT,
  CANDIDATE_JOB_APPLICATION_EVENT,
  CANDIDATE_STAGE_CHANGE_EVENT,
  CANDIDATE_MOVED_JOB_EVENT,
  CANDIDATE_NOTE,
  HIRING_STAGE_EMAIL_TEMPLATE,
  PIPELINE_CANDIDATE,
  MANUAL_SOURCING_CONTEXT,
  JOB_FEATURE_SETTINGS,
  JOB_ATS_STAGES,
  GREENHOUSE_DATA,
  LEVER_DATA,
  JOB_SETUP_STEP,
  INTERVIEWER_SCHEDULING_INFO,
  JOB_INTERVIEWERS_SCHEDULING_INFO,
  PROFILE_ATTRIBUTE,
  USER_RATING,
  USER_RATINGS_BY_ENTITY,
  HIRING_PIPELINE_STAGE,
  USER_ONBOARDING_FLOW,
  CANDIDATES_NEXT_ACTIONS,
  INTERVIEWER,
  APPLICATION_REVIEW_CANDIDATE_DETAILS,
  PIPELINE_CANDIDATE_COUNTS,
  CLIENT,
  SEARCH_V3,
  EMAIL_SENDER_ALIAS,
  HIRING_PLAN_JOB_POSITION,
  CANDIDATE_INTERVIEW_RUBRIC_RESPONSE,
  LIST_FEEDBACK_FORMS_FOR_INTERVIEW,
  SCHOOL_LIST,
  COMPANY_LIST,
  JOB_ATS_SOURCE_SETTINGS,
  JOB_ATS_SOURCES,
  LEVER_AUTH_INFO,
  DOVER_INTERVIEWER_CANDIDATES,
  GMAIL_AUTH_CHECK,
  CANDIDATE_INTERVIEW,
  ASHBY_DATA,
  SEARCH_V3_CHANGE_LOG,
  APPLICATION_QUESTION,
  GENERIC_EMAIL_RESPONSE_TEMPLATE,
  PERSONAS_V2,
  TITLE_PATTERNS,
  PATTERNS_FOR_PERSONA,
  CAREERS_PAGE_JOB,
  APPLICATION_PORTAL_JOB,
  CANDIDATE_FILE_LIST,
  SAAP_REVIEW_SCORED_APPLICATION_LIST,
  CURRENT_ONBOARDING_STEP,
  EDIT_CAREERS_PAGE_JOB,
  JOB_BOARDS,
  CANDIDATE_INTERVIEW_TRANSCRIPT,
  CAREERS_PAGE_JOB_COUNT,
  CLIENT_EMAIL_TEMPLATE,
  FEEDBACK_TEMPLATE,
  STARRED_CANDIDATE_LIST,
  GET_EMAIL_TEMPLATE_V2,
  LIST_TALENT_NETWORK,
  LIST_CONTACT_EMAILS,
  LIST_CONTACT_PHONE_NUMBERS,
  RECRUITING_PARTNER,
  FIND_DUPE_CONTACT,
  FIND_RELATED_CANDIDATES,
} from "services/doverapi/endpointTagsConstants";
import { DoverApiError } from "services/doverapi/types";

// This defines an empty api service that endpoints get injected into individually
const untaggedDoverApi = createApi({
  reducerPath: "doverApi",
  baseQuery: fakeBaseQuery<DoverApiError>(),
  // Don't add your endpoints here. Instead, make use of doverApi.injectEndpoints
  endpoints: () => ({}),
});

export const doverApi = untaggedDoverApi.enhanceEndpoints({
  addTagTypes: [
    CAMPAIGN,
    CAMPAIGN_MESSAGE_REQUEST,
    CANDIDATE_SOURCE,
    CHANNEL_PERSONA_STATS_BENCHMARK,
    JOB,
    JOB_CANDIDATE_SOURCE_SETTING,
    GET_EMAIL_TEMPLATE_V2,
    JOB_BOARDS,
    JOB_PITCH,
    JOB_ATS_STAGES,
    NOTIFICATION_CONFIG,
    USER_CREDENTIAL,
    PRO_USER,
    JOB_DESCRIPTION,
    JOB_SETUP,
    JOB_INTERVIEWERS_SCHEDULING_INFO,
    APPLICATION_PORTAL_JOB,
    INTERVIEWER_SCHEDULING_INFO,
    DOVER_INTERVIEWER_CANDIDATES,
    CLIENT,
    CLIENT_ONBOARDING,
    CLIENT_ROLES,
    USER_UPLOADED_FILE,
    SEARCH_CRITERIA,
    JOB_FULFILLMENT,
    CANDIDATE_BIO,
    CANDIDATE_EMAIL_EVENT,
    CANDIDATE_INTERVIEW_EVENT,
    CANDIDATE_JOB_APPLICATION_EVENT,
    CANDIDATE_STAGE_CHANGE_EVENT,
    CANDIDATE_MOVED_JOB_EVENT,
    CANDIDATE_NOTE,
    APPLICATION_QUESTION,
    HIRING_STAGE_EMAIL_TEMPLATE,
    GENERIC_EMAIL_RESPONSE_TEMPLATE,
    PIPELINE_CANDIDATE,
    PIPELINE_CANDIDATE_COUNTS,
    MANUAL_SOURCING_CONTEXT,
    JOB_FEATURE_SETTINGS,
    GREENHOUSE_DATA,
    ASHBY_DATA,
    USER_ONBOARDING_FLOW,
    LEVER_DATA,
    LEVER_AUTH_INFO,
    JOB_SETUP_STEP,
    PROFILE_ATTRIBUTE,
    USER_RATING,
    USER_RATINGS_BY_ENTITY,
    RECRUITING_PARTNER,
    HIRING_PIPELINE_STAGE,
    PIPELINE_CANDIDATE_COUNTS,
    CANDIDATES_NEXT_ACTIONS,
    INTERVIEWER,
    APPLICATION_REVIEW_CANDIDATE_DETAILS,
    STARRED_CANDIDATE_LIST,
    SAAP_REVIEW_SCORED_APPLICATION_LIST,
    SEARCH_V3,
    EMAIL_SENDER_ALIAS,
    HIRING_PLAN_JOB_POSITION,
    CANDIDATE_INTERVIEW_RUBRIC_RESPONSE,
    LIST_FEEDBACK_FORMS_FOR_INTERVIEW,
    SCHOOL_LIST,
    COMPANY_LIST,
    JOB_ATS_SOURCE_SETTINGS,
    JOB_ATS_SOURCES,
    GMAIL_AUTH_CHECK,
    CANDIDATE_INTERVIEW,
    PERSONAS_V2,
    TITLE_PATTERNS,
    PATTERNS_FOR_PERSONA,
    SEARCH_V3_CHANGE_LOG,
    CAREERS_PAGE_JOB,
    CANDIDATE_FILE_LIST,
    CURRENT_ONBOARDING_STEP,
    USER_ONBOARDING_FLOW,
    EDIT_CAREERS_PAGE_JOB,
    CAREERS_PAGE_JOB_COUNT,
    CANDIDATE_INTERVIEW_TRANSCRIPT,
    CLIENT_EMAIL_TEMPLATE,
    FEEDBACK_TEMPLATE,
    LIST_TALENT_NETWORK,
    LIST_CONTACT_EMAILS,
    LIST_CONTACT_PHONE_NUMBERS,
    FIND_DUPE_CONTACT,
    FIND_RELATED_CANDIDATES,
  ],
});
