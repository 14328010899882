import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as Logos } from "assets/images/onboarding-logos.svg";
import Quote from "assets/images/onboarding-quote.png";
import { PageTitle } from "components/library/typography";

const OnboardingGraphic = (): React.ReactElement => {
  return (
    <Stack spacing={2} alignItems="center">
      <PageTitle weight="400">Dover has helped hiring teams at:</PageTitle>
      <Logos />
      <img src={Quote} style={{ width: "100%", maxWidth: "445px" }} />
    </Stack>
  );
};

export default OnboardingGraphic;
