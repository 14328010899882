import { DndContext, DragEndEvent, DragOverlay, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { Progress } from "@doverhq/dover-ui";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as RedXIcon } from "assets/icons/red-x.svg";
import { ReactComponent as CircleX } from "assets/icons/x-red-circle.svg";
import { SocialLink } from "components/dover/SocialLink";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall, Overline } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { StarCandidateButton } from "components/StarCandidateButton";
import { useCandidateCountMap, useCandidateCountMapArgs } from "hooks/useCandidateCountMap";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { AddCandidateButton } from "sections/addcandidate";
import { useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import {
  ApiApiListPipelineCandidatesRequest,
  CandidateBioSocialLinkLinkTypeEnum,
  HiringPipelineStage,
  PipelineCandidate,
  PipelineCandidateSchedulingOwnershipEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { isAppReviewStage } from "utils/isStage";
import { getLinkedinUrl } from "utils/linkedin";
import ApplicantsColumn from "views/candidates/CandidateTable/board/ApplicantsColumn";
import { getCandidateFromMapAtom } from "views/candidates/CandidateTable/board/atoms";
import { CandidateCard as CandidateCard2 } from "views/candidates/CandidateTable/board/components/Card";
import Column from "views/candidates/CandidateTable/board/components/Column";
import { useCountFilters, useKanbanListArgsGetter } from "views/candidates/CandidateTable/board/hooks";
import { CandidateCard, KanbanWrapper, StageColumn, StageTitle } from "views/candidates/CandidateTable/board/styles";
import { OpenAppReviewButton } from "views/candidates/CandidateTable/components/OpenAppReviewButton";
import { NextUpCell } from "views/candidates/CandidateTable/table/cells/NextUp/NextUpCell";
import {
  useCandidates,
  useGetArgs,
  useListCandidatesWithNextActions,
  useBoardStages,
  useBoardStagesV2,
} from "views/candidates/hooks";
import { CandidateNextActionMap } from "views/candidates/types";

interface StageToCandidatesMap {
  [key: string]: PipelineCandidate[];
}

// Temporary, just to switch with FF
const CandidatesBoardFFWrapper = (): React.ReactElement => {
  const isMergeAppReviewEnabled = useFeatureFlag(FeatureFlag.MergeAppReview);

  return isMergeAppReviewEnabled ? <CandidatesBoardV2 /> : <CandidatesBoardWrapper />;
};

const CandidatesBoardWrapper = (): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  // Get all the stages including substages
  const stages = useBoardStages();
  const stageIds = stages?.map(s => s.id) ?? [];

  // We override the limit because we can't paginate this like a table
  // We override the stage because this hook by default operates off of "current stage"
  // Which makes sense in the candidate table, but not in this board were you see all stages at once
  const limitOverride = 1000;

  const { data, isFetching } = useCandidates({
    limitOverride,
    stageIdsOverride: stageIds,
    provideCandidateBucketLabels: true,
  });

  // These are passed in to rtkq update candidate bio to optimistically update list candidates endpoint
  const listPipelineCandidatesArgs = useGetArgs(limitOverride, stageIds, undefined, true);
  const useSuperApi = true;

  if (isFetching) {
    return <DoverLoadingOverlay active={true} />;
  }

  if (!jobId) {
    return (
      <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
        <RedXIcon />
        <BodySmall>No Job Id Found</BodySmall>
      </Stack>
    );
  }

  return (
    <CandidatesBoard
      jobId={jobId}
      stages={stages ?? []}
      candidates={data?.results ?? ([] as PipelineCandidate[])}
      listPipelineCandidatesArgs={{ args: listPipelineCandidatesArgs, useSuperApi }}
    />
  );
};

const CandidatesBoard = ({
  jobId,
  stages,
  candidates,
  listPipelineCandidatesArgs,
}: {
  jobId: string;
  stages: Array<HiringPipelineStage>;
  candidates: PipelineCandidate[];
  listPipelineCandidatesArgs: { args: ApiApiListPipelineCandidatesRequest; useSuperApi: boolean };
}): React.ReactElement => {
  const [updateCandidateBio] = useUpdateCandidateBioMutation();

  // Sort starred candidates to the top of each column
  const sortedCandidates = [...candidates].sort((a, b) => Number(b.isStarred) - Number(a.isStarred));

  // We show the match label instead of the next action card for candidates in the applied stage
  // and the next action logic is very expensive on the backend, so we remove the applied candidates
  // for this call to skip the unecessary work
  const nacCandidateIds = sortedCandidates
    .filter(c => (c.candidatePipelineStage ? !isAppReviewStage(c.candidatePipelineStage) : false))
    .map(c => c.id!);
  const { data: candidatesWithNextActions } = useListCandidatesWithNextActions(nacCandidateIds);

  // Initial object for the stage to candidate map, just an object with each stage for the key and empty arrays for the values
  const emptyStageToCandidateMap = stages.reduce(
    (acc: StageToCandidatesMap, stage: HiringPipelineStage) => ({ ...acc, [stage.id]: [] }),
    {}
  );

  // Group candidates by stage
  const stageToCandidateMap: Record<string, Array<PipelineCandidate>> = sortedCandidates.reduce(
    (acc: StageToCandidatesMap, candidate: PipelineCandidate) => {
      const stage = candidate.candidatePipelineStage;

      // Make sure stage is defined (0 is valid, so can't check !stage)
      if (stage === undefined) {
        return acc;
      }

      // Add the candidate to its stage
      acc[stage.id]?.push(candidate);

      return acc;
    },
    emptyStageToCandidateMap
  );

  const getColumnTitle = (stage: HiringPipelineStage): string => {
    const displayNumCandidates = stage.id in stageToCandidateMap ? `(${stageToCandidateMap[stage.id].length})` : "";

    return `${stage.name} ${displayNumCandidates}`;
  };

  const handleDragEnd = (result: DropResult): void => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    const destinationStage = destination.droppableId;
    const sourceStage = source.droppableId;

    // Don't allow reording in same column (We don't store this anywhere)
    if (destinationStage === sourceStage) {
      return;
    }

    const stage = stages.find(s => s.id === destinationStage);

    if (!stage) {
      return;
    }

    updateCandidateBio({
      id: draggableId,
      jobId: jobId,
      data: { currentPipelineStage: destinationStage, currentPipelineSubstage: 0 },
      hideToast: true,
      listPipelineCandidatesArgs,
      candidatePipelineStage: {
        id: stage.id,
        name: stage.name,
        stageType: stage.stageType,
        milestone: stage.milestone,
        orderIndex: stage.orderIndex ?? undefined,
        substage: 0,
      },
      // This prevents the candidate list from being invalidated which can cause flashes
      // when the user is making many moves quickly and early api routes are returning and
      // overwriting the optimistic update cache with stale data. Also prevents isFetching from turning true again.
      skipTagInvalidation: true,
    });
  };

  return (
    <KanbanWrapper>
      <ApplicantsColumn />
      <DragDropContext onDragEnd={handleDragEnd}>
        {stages.map(stage => (
          <Droppable key={stage.name} droppableId={stage.id}>
            {(provided): React.ReactElement => (
              <StageColumn ref={provided.innerRef} {...provided.droppableProps} minimized={false}>
                <StageTitle rotate={false}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Overline color={colors.grayscale.gray500}>{getColumnTitle(stage)}</Overline>
                    <AddCandidateButton removeOutline iconOnly hiringPipelineStageId={stage.id} />
                  </Stack>
                  {isAppReviewStage(stage) && <OpenAppReviewButton fullWidth />}
                </StageTitle>
                {stageToCandidateMap[stage.id]?.map((candidate: PipelineCandidate, idx: number) => {
                  const isDragDisabled =
                    candidate.schedulingOwnership === PipelineCandidateSchedulingOwnershipEnum.DoverHandlesScheduling;

                  return (
                    <CandidateBoardCard
                      key={candidate.id}
                      jobId={jobId}
                      candidate={candidate}
                      idx={idx}
                      isDragDisabled={isDragDisabled}
                      candidatesWithNextActions={candidatesWithNextActions}
                      showMatchLabel={isAppReviewStage(stage)}
                    />
                  );
                })}
                {provided.placeholder}
              </StageColumn>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </KanbanWrapper>
  );
};

const CandidateBoardCard = ({
  jobId,
  candidate,
  isDragDisabled,
  idx,
  candidatesWithNextActions,
}: {
  jobId: string;
  candidate: PipelineCandidate;
  isDragDisabled: boolean;
  idx: number;
  candidatesWithNextActions: CandidateNextActionMap | undefined;
  showMatchLabel?: boolean; // noop - just used for type camptibility with v2
}): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const useContactName = useFeatureFlag(FeatureFlag.PandoraNameReads);

  const conciergeInfo = useConciergeInfo();

  const linkedInUrl = getLinkedinUrl(candidate);

  const handleCandidateCardClick = (id: string): void => {
    navigate(APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, id, new URLSearchParams(location.search)));
  };

  const candidateName = useContactName ? candidate.contact.fullName : candidate.person?.fullName;

  return (
    <>
      <Draggable key={candidate.id} draggableId={candidate.id!} index={idx} isDragDisabled={isDragDisabled}>
        {(provided): React.ReactElement => (
          <Tooltip
            title={
              isDragDisabled
                ? `Recruiting Partner enabled: contact  ${conciergeInfo?.firstName ??
                    "your Recruiting Partner"} to move candidate.`
                : ""
            }
          >
            <CandidateCard
              id={candidateName}
              onClick={(): void => handleCandidateCardClick(candidate.id!)}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Stack
                spacing={1}
                // To hide the star button until hover
                sx={{
                  "& .hover-star-candidate": {
                    visibility: "hidden",
                  },
                  "&:hover .hover-star-candidate": {
                    visibility: "visible",
                  },
                }}
              >
                <Stack>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <BodySmall weight="600">{candidateName}</BodySmall>
                      {linkedInUrl && (
                        <div onClick={(e): void => e.stopPropagation()}>
                          <SocialLink
                            linkType={CandidateBioSocialLinkLinkTypeEnum.Linkedin}
                            url={linkedInUrl}
                            style={{ height: "13", width: "13", verticalAlign: "top" }}
                          />
                        </div>
                      )}
                    </Stack>
                    <StarCandidateButton
                      removePadding
                      alwaysShowStarred
                      candidate={{
                        id: candidate.id,
                        jobId: candidate.job,
                        name: candidate.person.fullName,
                        fullName: candidate.contact.fullName,
                        isStarred: candidate.isStarred,
                      }}
                    />
                  </Stack>
                  <BodySmall ellipsis>{candidate.contact.headline}</BodySmall>
                </Stack>
                {candidatesWithNextActions && candidatesWithNextActions[candidate.id!] && (
                  <NextUpCell
                    condensed
                    hideTooltip
                    nextAction={candidatesWithNextActions[candidate.id!]!}
                    candidate={candidate}
                  />
                )}
              </Stack>
            </CandidateCard>
          </Tooltip>
        )}
      </Draggable>
    </>
  );
};

/**
 * The Candidates board is a kanban style board that displays all candidates in a job across all stages.
 * Each stage will be rendered as a column with the candidates in that stage displayed as cards.
 *
 * Each stage is implemented as a Virtualized List with "infinite" scrolling.
 *
 * The virtualization strategy is to use each "Page" as an item in the virtualized list and each <Page /> component will
 * make it's paginated call to rtkq to fetch the candidate data it needs.
 * The natural mechanisms of virtualization will mount and unmount these <Page /> components as the user scrolls and they become
 * visble or invisible.
 *
 * This behaviour of mounting and unmounting is also what drives our infinite scrolling data fetching as well, since
 * each page component has a rtkq hook call that fires off when it is mounted (and only when it is mounted) it will fetch the data
 * it needs and when it is unmounted its cache entry will be flushed after 60 seconds, preventing any memory leaks.
 *
 * Each page component will also handle it's loading state, so you will see incremental results come in and loading skeletons as you scroll.
 * We use overscan in the virtualizer to "prefetch" some of the upcoming pages that aren't quite in view yet.
 */
const CandidatesBoardV2 = (): React.ReactElement => {
  const [updateCandidateBio] = useUpdateCandidateBioMutation();
  const getListArgs = useKanbanListArgsGetter();

  const jobId = useJobIdFromUrl();

  const [activeId, setActiveId] = useState<string | undefined>();

  const getCandidate = useAtomValue(getCandidateFromMapAtom);
  const candidate = getCandidate(activeId);

  // We need to add this activation constraint to our mouse sensor so that the
  // candidate cards on click events can still trigger
  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { delay: 200, tolerance: 100 } });
  const sensors = useSensors(mouseSensor);

  // We will iterate through each stage and make a column for each
  const stages = useBoardStagesV2() ?? [];

  // We need to get all the counts for each stage in advance because
  // the virtualizers need to know the total items they will managing right from the get go
  const countFilters = useCountFilters(stages);
  const { counts, isFetching, isError } = useCandidateCountMap(countFilters);

  // We also need to grab the args for optimistic updates
  const candidateCountsArgs = useCandidateCountMapArgs(countFilters);

  if (isFetching) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  if (!counts || isError) {
    return (
      <Stack alignItems="center" spacing={1}>
        <CircleX height="36px" width="36px" />
        <Body>Error while fetching candidate data.</Body>
      </Stack>
    );
  }

  const handleDragStart = (event: any): void => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    const candidateId = event.active.id as string | undefined;
    const sourceStageId = candidate?.candidatePipelineStage?.id;
    const destinationStageId = event.over?.id as string | undefined;

    setActiveId(undefined);

    if (!sourceStageId || !destinationStageId || !jobId || !candidateId || !candidate) {
      console.error(
        "Missing source, destination, jobId, candidateId, or candidate",
        sourceStageId,
        destinationStageId,
        jobId,
        candidateId,
        candidate
      );
      return;
    }

    // Don't allow reording in same column (We don't store this anywhere)
    if (destinationStageId === sourceStageId) {
      return;
    }

    const destinationStage = stages.find(s => s.id === destinationStageId);

    if (!destinationStage) {
      return;
    }

    const page = 0; // We always just drop to top of list because we sort by last modified
    const prevListArgs = getListArgs({
      stageId: sourceStageId,
      page,
    });
    const nextListArgs = getListArgs({
      stageId: destinationStageId,
      page,
    });

    const candidatePipelineStage = {
      id: destinationStage.id,
      name: destinationStage.name,
      stageType: destinationStage.stageType,
      milestone: destinationStage.milestone,
      orderIndex: destinationStage.orderIndex ?? undefined,
      substage: 0,
    };

    updateCandidateBio({
      id: candidateId,
      jobId: jobId,
      data: { currentPipelineStage: destinationStageId, currentPipelineSubstage: 0 },
      hideToast: true,
      kanbanStageUpdate:
        candidateCountsArgs === skipToken || prevListArgs === skipToken || nextListArgs === skipToken
          ? undefined
          : {
              prevListArgs,
              nextListArgs,
              candidate: {
                ...candidate,
                candidatePipelineStage,
              },
              candidateCountsArgs,
              prevStageId: sourceStageId,
              nextStageId: destinationStageId,
            },
      candidatePipelineStage,
      // This prevents the candidate list from being invalidated which can cause flashes
      // when the user is making many moves quickly and early api routes are returning and
      // overwriting the optimistic update cache with stale data. Also prevents isFetching from turning true again.
      skipTagInvalidation: true,
    });
  };

  return (
    <KanbanWrapper>
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        {stages.map(s => (
          <Column stage={s} count={counts.get(s.id) ?? 0} />
        ))}
        <DragOverlay>{activeId && candidate ? <CandidateCard2 candidate={candidate} /> : null}</DragOverlay>
      </DndContext>
    </KanbanWrapper>
  );
};

export default CandidatesBoardFFWrapper;
