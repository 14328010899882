import { Stack } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import Banner from "components/Banner";
import { JOB_PITCH_DRAWER_QUERY_PARAM } from "components/dover/JobPitch/data/queryParam";
import JobPitchDrawer from "components/dover/JobPitch/JobPitchDrawer";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { BodySmall } from "components/library/typography";

export const JobPitchIncompleteBanner = ({
  setDismissedJobPitchModal,
}: {
  setDismissedJobPitchModal: (dismissedJobPitchModal: boolean) => void;
}): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();

  const [, setIsOpenJobPitchDrawerParam] = useQueryParam(
    JOB_PITCH_DRAWER_QUERY_PARAM,
    withDefault(BooleanParam, false)
  );

  const handleCompleteJobPitch = (): void => {
    setIsOpenJobPitchDrawerParam(true);
  };

  const handleGenerateAnyway = (): void => {
    setDismissedJobPitchModal(true);
  };

  return (
    <>
      <Card>
        <Stack spacing={3}>
          <Banner type="info" alignItems="flex-start">
            <Stack spacing={1}>
              <BodySmall weight="600">Job pitch questions are incomplete.</BodySmall>
              <BodySmall>
                Completing the basic job questions will help Dover auto-generate more detailed outreach messaging.
              </BodySmall>
              <BodySmall>
                You can still move forward and auto-generate your outreach and your customer success manager will assist
                you in adding this information later.
              </BodySmall>
            </Stack>
          </Banner>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {jobId && (
              <Button variant={ButtonVariant.Secondary} onClick={handleCompleteJobPitch}>
                <BodySmall>Complete job pitch</BodySmall>
              </Button>
            )}
            <Button variant={ButtonVariant.Secondary} onClick={handleGenerateAnyway}>
              <BodySmall>Auto-generate outreach anyway</BodySmall>
            </Button>
          </Stack>
        </Stack>
      </Card>
      <JobPitchDrawer />
    </>
  );
};
