import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useListCampaignsQuery, selectFromListCampaignsQueryResult } from "services/doverapi/endpoints/campaign";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { useGetManagedOutboundState } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { useListSearchesV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import {
  ClientDoverPlanEnum,
  JobFeatureStateEnum,
  ListCampaignStateEnum,
  SearchV3SearchTypeEnum,
} from "services/openapi";

/**
 * Custom hook for checking if we should disable autopilot setup for contract customers or not.
 *
 * @param jobId - ID of job we're enabling autopilot for.
 * @returns A callable function that returns whether or not to disable autopilot activation.
 */
const useShouldDisableAutopilotSetup = (jobId: string | undefined): (() => boolean) => {
  // Get dover plan, current job feature state, existing outbound searches and existing outreach campaigns
  const doverPlan = useGetDoverPlan();
  const managedOutboundState = useGetManagedOutboundState({ jobId });
  const { data: outboundSearches, isLoading: isLoadingSearches } = useListSearchesV3Query(
    jobId
      ? {
          job: jobId,
          searchTypeList: SearchV3SearchTypeEnum.Outbound,
        }
      : skipToken
  );

  const { allCampaigns: campaigns, isLoading: isLoadingCampaigns } = useListCampaignsQuery(
    jobId ? { jobId } : skipToken,
    {
      selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
    }
  );

  return (): boolean => {
    // Only require active search & outreach campaigns for contract customers
    if (doverPlan === ClientDoverPlanEnum.Free) {
      return false;
    }
    // If the feature's already on, don't disable
    if (managedOutboundState === JobFeatureStateEnum.Enabled) {
      return false;
    }

    const isLoadingSearchesAndCampaigns = isLoadingSearches || isLoadingCampaigns;
    // Disable activation if either of these are missing
    const activeOutboundSearch = outboundSearches?.find(search => !!search.active);
    const activeOutreachCampaign = campaigns?.find(campaign => campaign.state === ListCampaignStateEnum.Active);
    return !isLoadingSearchesAndCampaigns && (!activeOutboundSearch || !activeOutreachCampaign);
  };
};

export default useShouldDisableAutopilotSetup;
