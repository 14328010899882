import { Stack } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";

import { ReactComponent as EyeSVG } from "assets/icons/eye.svg";
import { ReactComponent as EditPencilSVG } from "assets/icons/pencil-edit.svg";
import { EmailEditorMode, EmailEditorModeAtom } from "components/dover/EmailTemplates/atoms";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import "components/library/TipTap/TipTapEditorStyles.scss";

export const EditOrPreviewTabBar = (): React.ReactElement => {
  const [selectedEmailEditorTab, setSelectedTab] = useAtom(EmailEditorModeAtom);
  const editTabIsSelected = selectedEmailEditorTab === EmailEditorMode.Edit;
  const previewTabIsSelected = selectedEmailEditorTab === EmailEditorMode.Preview;

  return (
    <Stack
      direction="row"
      spacing={3}
      paddingTop={1}
      border={`1px solid ${colors.grayscale.gray200}`}
      borderBottom={"0px"}
      justifyContent="space-around"
    >
      <Stack
        direction="row"
        maxWidth="100%"
        spacing={1}
        onClick={(): void => setSelectedTab(EmailEditorMode.Edit)}
        color={editTabIsSelected ? colors.black : colors.grayscale.gray400}
        borderBottom={editTabIsSelected ? `2px solid ${colors.primary.base}` : "none"}
        px={2}
        paddingBottom={1}
        justifyContent="center"
        alignItems="center"
        width="50%"
        sx={{ cursor: "pointer" }}
      >
        <>
          <EditPencilSVG />
          <BodySmall
            color={editTabIsSelected ? colors.black : colors.grayscale.gray500}
            weight={editTabIsSelected ? "600" : undefined}
          >
            Edit
          </BodySmall>
        </>
      </Stack>
      <Stack
        direction="row"
        maxWidth="100%"
        spacing={1}
        onClick={(): void => setSelectedTab(EmailEditorMode.Preview)}
        color={previewTabIsSelected ? colors.black : colors.grayscale.gray400}
        borderBottom={previewTabIsSelected ? `2px solid ${colors.primary.base}` : "none"}
        px={2}
        paddingBottom={1}
        justifyContent="center"
        alignItems="center"
        width="50%"
        sx={{ cursor: "pointer" }}
      >
        <>
          <EyeSVG />
          <BodySmall
            color={previewTabIsSelected ? colors.black : colors.grayscale.gray500}
            weight={previewTabIsSelected ? "600" : undefined}
          >
            Preview
          </BodySmall>
        </>
      </Stack>
    </Stack>
  );
};
