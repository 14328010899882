import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Banner from "components/Banner";
import CompanyPitchQuestionFields from "components/dover/CompanySetupPitch/QuestionFields";
import { companyPitchFormSchema, CompanyPitchFormSchema } from "components/dover/CompanySetupPitch/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { BodySmall, ButtonText, Subtitle2, TitleLarge } from "components/library/typography";
import CustomModal from "components/Modal";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useUpdateCompanyBioMutation } from "services/doverapi/endpoints/company-setup/endpoints";
import { useGetCompanyPitchQuestionsQuery } from "services/doverapi/endpoints/company-setup/endpoints";
import { colors } from "styles/theme";

export const CompanyPitchIncompleteBanner = (): React.ReactElement => {
  const [openModal, setOpenModal] = useState(false);

  const clientId = useGetClientId();
  const { data: companyPitchQuestions } = useGetCompanyPitchQuestionsQuery({});
  const [updateCompanyBioMutation, { isLoading: updating }] = useUpdateCompanyBioMutation();

  const showModal = React.useCallback(() => {
    setOpenModal(true);
  }, []);

  const hideModal = React.useCallback(() => {
    setOpenModal(false);
  }, []);

  const companyPitchFormMethods = useForm<CompanyPitchFormSchema>({ resolver: zodResolver(companyPitchFormSchema) });

  const { handleSubmit: handleCompanyPitchSubmit } = companyPitchFormMethods;

  const onCompanyPitchSubmit = React.useCallback(
    async (data: CompanyPitchFormSchema) => {
      const trySubmitCompanyPitch = async (): Promise<void> => {
        if (companyPitchQuestions === undefined) {
          return;
        }

        await updateCompanyBioMutation({
          answers: {
            ...(companyPitchQuestions.answers as any),
            companyDescription: data.companyDescription,
          },
          clientId,
        }).unwrap();
      };
      trySubmitCompanyPitch();
    },
    [clientId, companyPitchQuestions, updateCompanyBioMutation]
  );

  return (
    <>
      <Card>
        <Stack spacing={3}>
          <Banner type="info" alignItems="flex-start">
            <Stack spacing={1}>
              <Subtitle2>Company pitch questions are incomplete.</Subtitle2>
              <BodySmall>
                Completing the basic company questions will help Dover auto-generate more detailed outreach messaging.
              </BodySmall>
            </Stack>
          </Banner>
          <Stack direction="row" justifyContent="flex-end">
            <Button variant={ButtonVariant.Secondary} onClick={showModal}>
              <BodySmall>Complete company pitch</BodySmall>
            </Button>
          </Stack>
        </Stack>
      </Card>
      <CustomModal
        open={openModal}
        maxWidth={"md"}
        onClose={hideModal}
        title={
          <Stack direction="row" justifyContent="space-between">
            <TitleLarge>{"Company Pitch"}</TitleLarge>
          </Stack>
        }
      >
        <CompanyPitchQuestionFields companyPitchFormMethods={companyPitchFormMethods} />
        <Stack direction="row" justifyContent="flex-end" paddingTop="12px">
          <Button
            variant={ButtonVariant.Primary}
            onClick={handleCompanyPitchSubmit(onCompanyPitchSubmit)}
            loading={updating}
          >
            <ButtonText centered color={colors.white}>
              Submit
            </ButtonText>
          </Button>
        </Stack>
      </CustomModal>
    </>
  );
};
