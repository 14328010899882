import { Stack } from "@mui/material";
import { TextField } from "mui-rff";
import React from "react";
import { Field } from "react-final-form";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

import { BillingSessionPathEnum } from "App/routing/types";
import { CopyToClipboard } from "components/CopyToClipboard";
import { Chip } from "components/library/Chip";
import { Body, BodySmall } from "components/library/typography";
import { EnrichedCandidateSourceDetails } from "services/doverapi/endpoints/job-source-settings/types";
import { CompleteCheckoutRequestBillingSessionTypeEnum, JobCandidateSourceSetting } from "services/openapi";
import { colors } from "styles/theme";
import { isOneClickIntegration } from "views/job/JobBoards/components/ConfigureSource/helpers";

interface JobSourceDetailsProps {
  enrichedSourceDetails: EnrichedCandidateSourceDetails;
  showExternalJobOptions: boolean;
  showAdditionalInfoField: boolean;
  sourceSettings?: JobCandidateSourceSetting;
}

export const BILLING_SESSION_PATH_TO_SESSION_TYPE_MAP = {
  [BillingSessionPathEnum.JobBoards]: CompleteCheckoutRequestBillingSessionTypeEnum.JobBoards,
  [BillingSessionPathEnum.DefaultPaymentMethod]: CompleteCheckoutRequestBillingSessionTypeEnum.DefaultPaymentMethod,
};

export const JobSourceDetails = ({
  enrichedSourceDetails,
  sourceSettings,
  showExternalJobOptions,
  showAdditionalInfoField,
}: JobSourceDetailsProps): React.ReactElement => {
  const source = enrichedSourceDetails.candidateSource;
  const sourceIsOneClickIntegration = isOneClickIntegration(source);

  return (
    <Stack spacing={2}>
      {sourceIsOneClickIntegration && <Chip variant="Informational" label="ONE-CLICK POST" />}
      <SourceInformation enrichedSourceDetails={enrichedSourceDetails} />
      {showExternalJobOptions && (
        <Stack>
          <div>
            <Error name={"desiredState"} />
          </div>
          {sourceSettings?.externalJobIdentifier && (
            // Once Dover has set an external job identifier for an external job that Dover
            // has created, we want to show that identifier to the user as a disabled text field.
            <Stack direction="row">
              <TextField
                label={source.externalJobIdentifierLabel}
                name="externalJobIdentifier"
                disabled={true}
                variant="standard"
              />
              <CopyToClipboard
                content={(): string => sourceSettings.externalJobIdentifier ?? ""}
                label={""}
                style={{ marginTop: "10px", marginLeft: "-10px" }}
              />
            </Stack>
          )}
          {showAdditionalInfoField && source.additionalInfoFieldLabel && (
            <Stack spacing={1}>
              <BodySmall color={colors.grayscale.gray500}>{source.additionalInfoFieldLabel}</BodySmall>
              <TextField name="additionalInfo" required={true} multiline rows={3} variant="outlined" />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const SourceInformation: React.FC<{
  enrichedSourceDetails: EnrichedCandidateSourceDetails;
}> = ({ enrichedSourceDetails }) => {
  const source = enrichedSourceDetails.candidateSource;

  const description: React.ReactNode = source.description ? (
    <SourceDescription color={colors.grayscale.gray600}>{ReactHtmlParser(source.description)}</SourceDescription>
  ) : null;

  if (!description) {
    return null;
  }

  return <Stack spacing={2}>{description}</Stack>;
};

const Error: React.FC<{ name: string }> = ({ name }) => (
  <Field
    name={name}
    subscribe={{ error: true }}
    render={({ meta: { error } }): React.ReactElement => <StyledErrorSpan>{error}</StyledErrorSpan>}
  />
);

const SourceDescription = styled(Body)`
  white-space: pre-line;

  a {
    color: ${colors.link};
  }
`;

const StyledErrorSpan = styled.span`
  && {
    color: ${(props): string => props.theme.colors.critical.dark};
  }
`;
