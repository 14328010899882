export const maxFileSizeUpload = 5 * 1024 * 1024; // 5 MB
export const CAMPAIGN_MESSAGE_LIMIT = 5;
export const INVALID_PERMS_TOOLTIP_TEXT = "This action is restricted. Please contact a Dover admin to grant access.";

export const SALES_CALENDLY = "https://calendly.com/dover-team/dover-intro-2";
export const PRODUCT_CALENDLY = "https://calendly.com/dover-team/dover-onboarding";
export const GENERIC_CALENDLY = "https://dover.com/schedule-call";

export const DOVER_OVERVIEW_CALENDLY_URL =
  "https://www.calendly.com/dover-sales/20min?utm_medium=app&utm_source=premium_feature&utm_campaign=splash_card";
