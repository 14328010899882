export const UPGRADE_PLAN_QUERY_PARAM = "upgradePlan";
export const UPGRADE_SOURCE_QUERY_PARAM = "upgradeSource";
export const SOURCING_AUTOPILOT_MODAL_QUERY_PARAM = "sourcingAutopilot";
export const DOVER_INTERVIEWER_MODAL_QUERY_PARAM = "doverInterviewerModal";

export enum UpgradeSourceEnum {
  Signup = "SIGNUP",
  TrialOverEmail = "TRIAL_OVER_EMAIL",
  AdditionalCandidatesEmail = "ADDITIONAL_CANDIDATES_EMAIL",
  FreeTrialBanner = "FREE_TRIAL_BANNER",
  AdditionalCandidatesBanner = "ADDITIONAL_CANDIDATES_BANNER",
  OutboxBanner = "OUTBOX_BANNER",
}
