import { Box, Pagination } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import React, { FC } from "react";

import { Caption } from "components/library/typography";
import { isChatOpenAtom } from "views/candidates/ApplicationReview/atoms/chat";
import { LIST_AI_CHAT_API_LIMIT } from "views/candidates/ApplicationReview/atoms/listApplicationArgs";
import { multiSelectEnabledAtom } from "views/candidates/ApplicationReview/atoms/multiSelect";
import { DEFAULT_NUM_CANDS_PER_PAGE, pageAtom } from "views/candidates/ApplicationReview/atoms/pagination";
import { MultiSelectActionBar } from "views/candidates/ApplicationReview/components/ApplicantListPanel/MultiSelectActionBar";

interface ApplicantListFooterProps {
  jobId?: string;
  numApplicants?: number;
  isFetchingApplications?: boolean;
}

export const ApplicantListFooter: FC<ApplicantListFooterProps> = ({ jobId, numApplicants, isFetchingApplications }) => {
  const multiSelectEnabled = useAtomValue(multiSelectEnabledAtom);
  const [page, setPage] = useAtom(pageAtom);
  const isChatOpen = useAtomValue(isChatOpenAtom);

  if (numApplicants === undefined) {
    return null;
  }

  const numberOfPagesToShow = !numApplicants ? 1 : Math.ceil(numApplicants / DEFAULT_NUM_CANDS_PER_PAGE);
  const showPagination = numberOfPagesToShow > 1;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  const numShowing = numApplicants > LIST_AI_CHAT_API_LIMIT ? LIST_AI_CHAT_API_LIMIT : numApplicants;

  return (
    <>
      <Box width="100%" display="flex" justifyContent="center" paddingY="5px">
        {isChatOpen ? (
          <Caption>Showing the best {numShowing} matches</Caption>
        ) : (
          showPagination && (
            <Pagination
              count={numberOfPagesToShow}
              page={page}
              onChange={handlePageChange}
              size="small"
              disabled={!!isFetchingApplications}
            />
          )
        )}
      </Box>
      {multiSelectEnabled && <MultiSelectActionBar jobId={jobId} />}
    </>
  );
};
