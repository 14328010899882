import { z } from "zod";

export const interviewPipelineCandidateInfoZodSchema = z
  .object({
    firstName: z.string().min(1, { message: "Required" }),
    lastName: z.string().min(1, { message: "Required" }),
    email: z
      .string()
      .email({ message: "Invalid email" })
      .optional()
      .or(z.literal(""))
      .nullable(),
    linkedinUrl: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/.+/, {
        message: "Invalid LinkedIn URL",
      })
      .max(200, { message: "LinkedIn URL cannot be more than 200 characters long" })
      .optional()
      .or(z.literal("")),
    resume: z.nullable(z.instanceof(File)).optional(),
    source: z.string().min(1, { message: "Required" }),
    agencyName: z
      .string()
      .optional()
      .nullable(),
    agencyEmail: z
      .string()
      .optional()
      .nullable(),
  })
  // Require name and email for agency sources.
  // Require name for referrals.
  .refine(data => !(["AGENCY"].includes(data.source) && !data.agencyEmail), {
    message: "Required",
    path: ["agencyEmail"],
  })
  .refine(data => !(["AGENCY", "CANDIDATE_REFERRED"].includes(data.source) && !data.agencyName), {
    message: "Required",
    path: ["agencyName"],
  });

export type InterviewPipelineCandidateInfoSchema = z.infer<typeof interviewPipelineCandidateInfoZodSchema>;
