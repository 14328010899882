import { Button } from "@doverhq/dover-ui";
import { MenuItem, Stack } from "@mui/material";
import { Select } from "mui-rff";
import React, { ReactElement } from "react";
import ReactHtmlParser from "react-html-parser";

import { BillingSessionPathEnum } from "App/routing/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall, Subtitle2 } from "components/library/typography";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { EnrichedCandidateSourceDetails, SourceStatus } from "services/doverapi/endpoints/job-source-settings/types";
import {
  CandidateSource,
  CandidateSourceFeaturesEnum,
  CandidateSourcePricingTier,
  JobCandidateSourceSettingDesiredStateEnum,
  JobCandidateSourceSettingStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink, InternalLink } from "styles/typography";
import {
  getSourceIsReadyForUse,
  isClientAllowedToPostToSource,
  isFreeIntegration,
  turnToMonthEnd,
} from "views/job/JobBoards/components/ConfigureSource/helpers";
import { StyledDivider, StyledSwitches } from "views/job/JobBoards/components/ConfigureSource/styles";
import { SubmitWithDesiredStateProps } from "views/job/JobBoards/components/ConfigureSource/types";

const WarningBanner = ({ candidateSource }: { candidateSource: CandidateSource }): ReactElement => {
  return (
    <Banner variant={BannerVariant.Critical}>
      <Stack>
        <Body>
          {(candidateSource.readyForUseInfo?.notReadyDetails?.message as string) ||
            `${candidateSource.label} is unavailable for use.`}
        </Body>
        <BodySmall>
          <ExternalLink
            href={candidateSource.readyForUseInfo?.notReadyDetails?.actionUrl as string}
            target="_blank"
            rel="noopener noreferrer"
          >
            {candidateSource.readyForUseInfo?.notReadyDetails?.actionLabel}
          </ExternalLink>
        </BodySmall>
      </Stack>
    </Banner>
  );
};

const PricingDetails = ({
  selectedEnrichedSource,
  paymentErrorMessage,
  selectedPricingTier,
}: {
  selectedEnrichedSource: EnrichedCandidateSourceDetails;
  paymentErrorMessage: string | undefined;
  selectedPricingTier?: CandidateSourcePricingTier;
}): ReactElement => {
  const { candidateSource } = selectedEnrichedSource;

  const readOnly =
    selectedEnrichedSource.jobCandidateSourceSettings?.desiredState ==
      JobCandidateSourceSettingDesiredStateEnum.Active ||
    selectedEnrichedSource.jobCandidateSourceSettings?.state == JobCandidateSourceSettingStateEnum.Active ||
    candidateSource.pricingTiers?.length == 1;
  return (
    <>
      {candidateSource.features && candidateSource.features.includes(CandidateSourceFeaturesEnum.BudgetedBilling) && (
        <Stack spacing={1}>
          {paymentErrorMessage && (
            <Banner variant={BannerVariant.Critical}>
              {paymentErrorMessage}{" "}
              <InternalLink
                to={`/billing/session/begin/${BillingSessionPathEnum.JobBoards}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Update your payment method here.
              </InternalLink>
            </Banner>
          )}
          <Subtitle2>Subscription Amount</Subtitle2>
          <Select
            name="pricingTierId"
            formControlProps={{ margin: "none" }}
            variant="outlined"
            color="secondary"
            disabled={readOnly}
          >
            {(candidateSource.pricingTiers ?? []).map(pricingTier => {
              return (
                <MenuItem
                  key={pricingTier.id}
                  sx={{ "&.MuiButtonBase-root": { display: "flex", justifyContent: "start", padding: "8px" } }}
                  value={pricingTier.id}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <div>{pricingTier.label}</div>
                    {pricingTier.description && <BodySmall>{ReactHtmlParser(pricingTier.description)}</BodySmall>}
                  </Stack>
                </MenuItem>
              );
            })}
          </Select>
          {selectedEnrichedSource.jobCandidateSourceSettings?.desiredState ==
            JobCandidateSourceSettingDesiredStateEnum.Active && (
            <BodySmall>
              If you&apos;d like to upgrade or downgrade this subscription, please contact{" "}
              <a href="mailto:support@dover.com">support@dover.com</a>.
            </BodySmall>
          )}
          {selectedPricingTier?.price !== undefined && selectedPricingTier?.price > 0 && (
            <StyledSwitches
              name="shouldAutoRenew"
              data={{
                label: "Renew Automatically Every Month",
                value: "shouldAutoRenew",
              }}
            />
          )}
        </Stack>
      )}
    </>
  );
};

export const ActionButtons = ({
  selectedEnrichedSource,
  setDesiredStateAndSubmit,
  disableSaveAction,
  disableActivateAction,
  isFreeIntegration,
  selectedPricingTier,
  tooltipMessage,
}: {
  selectedEnrichedSource: EnrichedCandidateSourceDetails;
  setDesiredStateAndSubmit: ({ desiredState, forceSetState }: SubmitWithDesiredStateProps) => void;
  disableSaveAction: boolean;
  disableActivateAction: boolean;
  isFreeIntegration: boolean;
  selectedPricingTier?: CandidateSourcePricingTier;
  tooltipMessage?: string | ReactElement;
}): React.ReactElement => {
  const { candidateSource, jobCandidateSourceSettings: sourceSettings } = selectedEnrichedSource;
  const isActive = sourceSettings?.state === JobCandidateSourceSettingStateEnum.Active;
  const isSettingUp = selectedEnrichedSource.status === SourceStatus.SettingUp;

  const sourceSupportsBudgetedBilling = candidateSource.features?.includes(CandidateSourceFeaturesEnum.BudgetedBilling);

  const sourceSupportsInboundApps = candidateSource.features?.includes(
    CandidateSourceFeaturesEnum.InboundApplicationsSupported
  );

  if (isActive || isSettingUp) {
    return (
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="criticalFilled"
          onPress={(): void => {
            setDesiredStateAndSubmit({
              desiredState: JobCandidateSourceSettingDesiredStateEnum.Inactive,
              forceSetState: isFreeIntegration,
            });
          }}
        >
          {sourceSupportsInboundApps ? "Deactivate job posting" : "Deactivate"}
        </Button>
        {sourceSupportsBudgetedBilling && (
          <Button
            variant="primaryFilled"
            onPress={(): void => {
              setDesiredStateAndSubmit({
                desiredState: JobCandidateSourceSettingDesiredStateEnum.Active,
                forceSetState: isFreeIntegration,
              });
            }}
            isDisabled={disableSaveAction}
          >
            Save
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <Stack alignItems="flex-end">
      <Tooltip title={tooltipMessage}>
        <span>
          <Button
            variant="primaryFilled"
            onPress={(): void => {
              setDesiredStateAndSubmit({
                desiredState: JobCandidateSourceSettingDesiredStateEnum.Active,
                forceSetState: isFreeIntegration,
              });
            }}
            isDisabled={disableActivateAction}
          >
            {isFreeIntegration
              ? "Activate Subscription"
              : sourceSupportsBudgetedBilling && selectedPricingTier?.price !== 0
              ? "Purchase Subscription"
              : "Add Source"}
          </Button>
        </span>
      </Tooltip>
    </Stack>
  );
};

const SubscriptionDetails = ({
  selectedEnrichedSource,
  setDesiredStateAndSubmit,
  submitting,
  submitFailed,
  valid,
  pristine,
  paymentErrorMessage,
  selectedPricingTier,
}: {
  selectedEnrichedSource: EnrichedCandidateSourceDetails;
  setDesiredStateAndSubmit: ({ desiredState, forceSetState }: SubmitWithDesiredStateProps) => void;
  submitting: boolean;
  submitFailed: boolean;
  valid: boolean;
  pristine: boolean;
  paymentErrorMessage: string | undefined;
  selectedPricingTier?: CandidateSourcePricingTier;
}): ReactElement => {
  const { candidateSource, jobCandidateSourceSettings } = selectedEnrichedSource;

  const showInboundJobBoardDetails = selectedEnrichedSource.candidateSource.features?.includes(
    CandidateSourceFeaturesEnum.InboundApplicationsSupported
  );
  const { data: client } = useGetUsersClientQuery();
  const isActive =
    selectedEnrichedSource.jobCandidateSourceSettings?.desiredState ===
    JobCandidateSourceSettingDesiredStateEnum.Active;
  const clientAllowedToPostToSource = isClientAllowedToPostToSource(candidateSource, client);
  const sourceIsFreeIntegration = isFreeIntegration(candidateSource, selectedPricingTier?.price);
  const sourceIsReadyForUse = getSourceIsReadyForUse(candidateSource, selectedPricingTier?.price);

  const shouldShowWarningMessage = !isActive && !sourceIsReadyForUse;

  const sourceSupportsBudgetedBilling = candidateSource.features?.includes(CandidateSourceFeaturesEnum.BudgetedBilling);

  const currentPeriodEndText = turnToMonthEnd(jobCandidateSourceSettings);
  const currentPeriodEndDay = jobCandidateSourceSettings?.currentPeriodEnd?.getDate();
  const showCostDisclaimer =
    !candidateSource.pricingTiers?.some(pt => pt.price && pt.price > 0) && sourceSupportsBudgetedBilling && !isActive;

  const disableSaveSource = submitting || pristine || !valid;
  const disableActivateSource =
    submitting || (!valid && !submitFailed) || !sourceIsReadyForUse || !clientAllowedToPostToSource;

  return (
    <Stack spacing={2}>
      {shouldShowWarningMessage && <WarningBanner candidateSource={candidateSource} />}
      {showInboundJobBoardDetails && (
        <PricingDetails
          selectedEnrichedSource={selectedEnrichedSource}
          paymentErrorMessage={paymentErrorMessage}
          selectedPricingTier={selectedPricingTier}
        />
      )}

      {showCostDisclaimer && (
        <>
          <StyledDivider />
          <BodySmall color={colors.grayscale.gray500}>
            Upon subscription purchase you will be charged the subscription amount selected above. If auto-renewal is
            enabled, you will additionally be charged every 30 days until the job board subscription is deactivated or
            auto-renewal is turned off.
          </BodySmall>
        </>
      )}
      {isActive && sourceSupportsBudgetedBilling && (
        <BodySmall color={colors.grayscale.gray500}>
          {currentPeriodEndText && currentPeriodEndDay ? (
            <>
              Your current subscription runs until {currentPeriodEndText} {currentPeriodEndDay}
            </>
          ) : (
            <>Your job posting is active</>
          )}
          . Deactivating your subscription will not result in any refund for the remaining subscription period. If you
          wish to deactivate the post at the end of your current subscription period, turn off auto-renewal above.
        </BodySmall>
      )}
      <ActionButtons
        selectedEnrichedSource={selectedEnrichedSource}
        setDesiredStateAndSubmit={setDesiredStateAndSubmit}
        disableActivateAction={disableActivateSource}
        disableSaveAction={disableSaveSource}
        isFreeIntegration={sourceIsFreeIntegration}
        selectedPricingTier={selectedPricingTier}
        tooltipMessage={
          !clientAllowedToPostToSource && client?.isAgency ? (
            <>
              {`Dover one-click integration for this job board is not available for staffing or talent agencies.
              Please select "I will do it manually" above in order to post to LinkedIn. Learn more `}
              <a href="https://help.dover.com/en/articles/6238844-linkedin-job-postings">here</a>.
            </>
          ) : (
            undefined
          )
        }
      />
    </Stack>
  );
};

export default SubscriptionDetails;
