import { Button } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { FC } from "react";

import { ReactComponent as PencilIcon } from "assets/icons/pencil-edit.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Tooltip } from "components/library/Tooltip";
import { Caption } from "components/library/typography";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { CandidateBio, CandidateBioContactInfoTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { useShowCandidateEmail } from "views/CandidateDetail/utils";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";

interface ContactInfoProps {
  candidateBio: CandidateBio;
}

export const ContactInfo: FC<ContactInfoProps> = ({ candidateBio }) => {
  const isPandoraNameReadsEnabled = useFeatureFlag(FeatureFlag.PandoraNameReads);

  const dtnMode = useAtomValue(isDtnMode);

  const { showCandidateActionModal } = useTopLevelModalManagerOptions();
  const showEmail = useShowCandidateEmail(candidateBio);

  const candidateId = candidateBio.id;

  // Filter out the emails if we don't want to show them
  const contactInfo = candidateBio.contactInfo?.filter(
    info => showEmail || info.type !== CandidateBioContactInfoTypeEnum.Email
  );

  // Grab the primary email if we are including emails, otherwise just get the first contact info
  const displayContactInfo = showEmail
    ? contactInfo?.find(info => info.isPrimary && info.type === CandidateBioContactInfoTypeEnum.Email)?.value
    : contactInfo?.[0]?.value;

  if (!displayContactInfo || !candidateId) {
    return <></>;
  }

  const clickEdit = (): void => {
    showCandidateActionModal({ candidateId, modalType: CandidateActionModalType.EditEmails });
  };

  // Calculate how many more contact infos there are
  const numOverflowContactInfo = contactInfo?.length ? contactInfo.length - 1 : 0;
  const overflowNumDisplay = numOverflowContactInfo ? `+${numOverflowContactInfo}` : "";
  const overflowDisplay = contactInfo && numOverflowContactInfo > 0 ? contactInfo.map(ci => ci.value).join(", ") : "";

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {!dtnMode && !isPandoraNameReadsEnabled && (
        <Button variant="ghost" p={1} icon={{ Icon: PencilIcon }} onPress={clickEdit} />
      )}
      <Tooltip title={overflowDisplay} placement="bottom">
        <Box sx={{ whiteSpace: "nowrap" }}>
          <Caption color={colors.grayscale.gray500}>{`${displayContactInfo} ${overflowNumDisplay}`}</Caption>
        </Box>
      </Tooltip>
    </Stack>
  );
};
