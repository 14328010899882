import { Progress } from "@doverhq/dover-ui";
import { Box, Container, Paper, Stack } from "@mui/material";
import React, { ReactElement } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useLocation, useNavigate } from "react-router-dom";
import { BooleanParam, createEnumParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import CreateJobForm from "components/dover/CreateJob";
import { CREATE_JOB_DRAWER_OPEN_PARAM, CREATE_JOB_CONTEXT_PARAM } from "components/dover/CreateJob/constants";
import Drawer from "components/library/Drawer";
import DoverLoadingOverlay from "components/loading-overlay";
import PageHelmet from "components/PageHelmet";
import { Spacer } from "components/Spacer";
import StepFlow from "components/StepFlow/StepFlow";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useCreateJobMutation, useListJobPersonasQuery } from "services/doverapi/endpoints/create-job/endpoints";
import { FormData } from "services/doverapi/endpoints/create-job/types";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { CreateNewJobResponse } from "services/openapi";
import { CreateNewJobRequestCreateJobContextEnum } from "services/openapi/models/CreateNewJobRequest";
import { colors } from "styles/theme";
import FailureMessage from "views/create-job/CreateJob/components/FailureMessage";
import CreateJobSuccessScreen from "views/create-job/shared-steps/SuccessScreen";

/* -----------------------------------------------------------------------------
 * CreateJobWrapper
 * -------------------------------------------------------------------------- */

interface JobWrapperProps {
  goNext: () => void;
}

const CreateJobWrapper = ({ goNext }: JobWrapperProps): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const enumParam = createEnumParam(Object.values(CreateNewJobRequestCreateJobContextEnum));
  const [context] = useQueryParam(CREATE_JOB_CONTEXT_PARAM, enumParam);
  const [createJob, { isLoading: creating }] = useCreateJobMutation();
  const [, setJobIdParam] = useQueryParam("jobId", StringParam);
  const { data: client } = useGetUsersClientQuery();

  const onSubmit = React.useCallback(
    async (values: FormData): Promise<void> => {
      const tryCreateJob = async (): Promise<CreateNewJobResponse> => {
        const response = await createJob({
          values,
          jobPositionId: location?.state?.jobPositionId,
          createJobContext: context ? context : undefined,
        }).unwrap();

        if (response.success && response.jobId) {
          setJobIdParam(response.jobId);
        }

        return response;
      };

      const resp = await tryCreateJob();
      if (resp.success && resp.jobId) {
        if (values.recruiterId || client?.usesEmbeddedRecruiter) {
          navigate(APP_ROUTE_PATHS.job.jobPosting(resp.jobId));
        } else {
          goNext();
        }
      }
    },
    [context, createJob, location?.state?.jobPositionId, setJobIdParam, goNext, navigate, client?.usesEmbeddedRecruiter]
  );
  return (
    <DoverLoadingOverlay active={creating}>
      <Stack justifyContent="center" direction="row" p={2}>
        <Box flexGrow={1}>
          <CreateJobForm
            showHiringManagerAndRecruiterFields={true}
            creating={creating}
            initialTitle={location?.state?.jobTitle}
            onSubmit={async (values: FormData): Promise<void> => {
              await onSubmit(values);
            }}
          />
        </Box>
      </Stack>
    </DoverLoadingOverlay>
  );
};

/* -----------------------------------------------------------------------------
 * CreateJobSuccessScreenWrapper
 * -------------------------------------------------------------------------- */

interface CreateJobSuccessScreenWrapperProps
  extends JobWrapperProps,
    Pick<CreateJobFlowProps, "variant" | "setCreateJobDrawerOpen"> {}

const CreateJobSuccessScreenWrapper = ({
  variant,
  setCreateJobDrawerOpen,
  goNext,
}: CreateJobSuccessScreenWrapperProps): ReactElement => {
  const [jobIdFromParam] = useQueryParam("jobId", StringParam);

  return (
    <CreateJobSuccessScreen
      variant={variant}
      jobId={jobIdFromParam ?? undefined}
      closeDrawer={(): void => {
        setCreateJobDrawerOpen(false);
        goNext();
      }}
    />
  );
};

const createJobSuccessScreenWrapper = (props: Omit<CreateJobSuccessScreenWrapperProps, "goNext">) => {
  return ({ goNext }: JobWrapperProps): ReactElement => {
    return <CreateJobSuccessScreenWrapper goNext={goNext} {...props} />;
  };
};

/* -----------------------------------------------------------------------------
 * CreateJobFlow
 * -------------------------------------------------------------------------- */

interface CreateJobFlowProps extends CreateJobProps {
  setCreateJobDrawerOpen: (open?: boolean | null) => void;
}

const CreateJobFlow = React.memo(
  ({ variant, setCreateJobDrawerOpen }: CreateJobFlowProps): ReactElement => {
    return (
      <StepFlow
        hideProgressBar
        title="Add a job"
        steps={[
          {
            component: CreateJobWrapper,
            progressLabel: "Basics",
            stepRoute: "create-job",
          },
          {
            component: createJobSuccessScreenWrapper({ variant, setCreateJobDrawerOpen }),
            stepRoute: "success",
          },
        ]}
        onClose={(): void => {
          setCreateJobDrawerOpen(false);
        }}
        bgColor={colors.white}
      />
    );
  }
);

/* -----------------------------------------------------------------------------
 * CreateJob
 * -------------------------------------------------------------------------- */

interface CreateJobProps {
  variant?: "page" | "drawer";
}

const CreateJob = ({ variant = "page" }: CreateJobProps): React.ReactElement => {
  const [createJobDrawerOpen, setCreateJobDrawerOpen] = useQueryParam(
    CREATE_JOB_DRAWER_OPEN_PARAM,
    withDefault(BooleanParam, false)
  );
  const location = useLocation();
  const enumParam = createEnumParam(Object.values(CreateNewJobRequestCreateJobContextEnum));
  const navigate = useNavigate();

  const [context] = useQueryParam(CREATE_JOB_CONTEXT_PARAM, enumParam);
  const { jobsLoading } = useGetJobsDerivedData({});
  const [createJob, { isLoading: creating, data: createJobResponse }] = useCreateJobMutation();

  const { isLoading: arePersonasLoading } = useListJobPersonasQuery();
  const isLoading = arePersonasLoading || jobsLoading;

  const onSubmit = React.useCallback(
    (values: FormData) => {
      const tryCreateJob = async (): Promise<void> => {
        const response = await createJob({
          values,
          jobPositionId: location?.state?.jobPositionId,
          createJobContext: context ? context : undefined,
        }).unwrap();

        if (variant === "page" && response.success && response.jobId) {
          navigate(APP_ROUTE_PATHS.job.overview(response.jobId), { replace: true });
        }
      };

      tryCreateJob();
    },
    [context, createJob, location?.state?.jobPositionId, navigate, variant]
  );

  if (variant === "drawer") {
    return (
      <Drawer
        open={createJobDrawerOpen}
        onClose={(): void => {
          setCreateJobDrawerOpen(false);
        }}
        anchor="right"
      >
        {isLoading ? (
          <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
            <Progress size="large" />
          </Box>
        ) : (
          <CreateJobFlow setCreateJobDrawerOpen={setCreateJobDrawerOpen} variant={variant} />
        )}
      </Drawer>
    );
  }

  if (isLoading) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  return (
    <>
      <PageHelmet title="Create New Job" />
      <Spacer height="24px" />
      <Container>
        <LoadingOverlay active={creating} spinner>
          <Paper style={{ padding: "32px" }}>
            {createJobResponse?.failureReason ? (
              <FailureMessage failureMessage={createJobResponse.failureReason} />
            ) : (
              <CreateJobForm
                showHiringManagerAndRecruiterFields={true}
                creating={creating}
                initialTitle={location?.state?.jobTitle}
                onSubmit={onSubmit}
              />
            )}
          </Paper>
        </LoadingOverlay>
      </Container>
    </>
  );
};

export default CreateJob;
