/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationQuestionApplicationReviewInboundApplication,
    ApplicationQuestionApplicationReviewInboundApplicationFromJSON,
    ApplicationQuestionApplicationReviewInboundApplicationFromJSONTyped,
    ApplicationQuestionApplicationReviewInboundApplicationToJSON,
    PassingAndFailingFilters,
    PassingAndFailingFiltersFromJSON,
    PassingAndFailingFiltersFromJSONTyped,
    PassingAndFailingFiltersToJSON,
} from './';

/**
 * 
 * @export
 * @interface SaapReviewApplication
 */
export interface SaapReviewApplication {
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    latestTitleAtLatestCompany?: string;
    /**
     * 
     * @type {number}
     * @memberof SaapReviewApplication
     */
    totalYoe?: number;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    resumePath?: string;
    /**
     * 
     * @type {Date}
     * @memberof SaapReviewApplication
     */
    appliedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    inboundAppId: string;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    candidateId?: string;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    candidateBucketLabel?: SaapReviewApplicationCandidateBucketLabelEnum;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    candidateLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    linkedinUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    cleanFirstName?: string;
    /**
     * 
     * @type {Array<ApplicationQuestionApplicationReviewInboundApplication>}
     * @memberof SaapReviewApplication
     */
    applicationQuestions?: Array<ApplicationQuestionApplicationReviewInboundApplication>;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    additionalInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    inboundAppState?: string;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    candidateSourceLabel?: string;
    /**
     * 
     * @type {number}
     * @memberof SaapReviewApplication
     */
    candidateActionVersion?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SaapReviewApplication
     */
    isDemoCandidate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    schedulingOwnership?: SaapReviewApplicationSchedulingOwnershipEnum;
    /**
     * 
     * @type {PassingAndFailingFilters}
     * @memberof SaapReviewApplication
     */
    explanation?: PassingAndFailingFilters;
    /**
     * 
     * @type {boolean}
     * @memberof SaapReviewApplication
     */
    bookmarked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaapReviewApplication
     */
    email: string;
}

/**
* @export
* @enum {string}
*/
export enum SaapReviewApplicationCandidateBucketLabelEnum {
    BestMatch = 'BEST_MATCH',
    CloseMatch = 'CLOSE_MATCH',
    Mismatch = 'MISMATCH',
    Scoring = 'SCORING',
    EnrichingProfile = 'ENRICHING_PROFILE',
    NoInfo = 'NO_INFO'
}/**
* @export
* @enum {string}
*/
export enum SaapReviewApplicationSchedulingOwnershipEnum {
    DoverHandlesScheduling = 'DOVER_HANDLES_SCHEDULING',
    CustomerHandlesScheduling = 'CUSTOMER_HANDLES_SCHEDULING'
}

export function SaapReviewApplicationFromJSON(json: any): SaapReviewApplication {
    return SaapReviewApplicationFromJSONTyped(json, false);
}

export function SaapReviewApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaapReviewApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'latestTitleAtLatestCompany': !exists(json, 'latest_title_at_latest_company') ? undefined : json['latest_title_at_latest_company'],
        'totalYoe': !exists(json, 'total_yoe') ? undefined : json['total_yoe'],
        'resumePath': !exists(json, 'resume_path') ? undefined : json['resume_path'],
        'appliedAt': !exists(json, 'applied_at') ? undefined : (new Date(json['applied_at'])),
        'inboundAppId': json['inbound_app_id'],
        'candidateId': !exists(json, 'candidate_id') ? undefined : json['candidate_id'],
        'personId': json['person_id'],
        'candidateBucketLabel': !exists(json, 'candidate_bucket_label') ? undefined : json['candidate_bucket_label'],
        'candidateLocation': !exists(json, 'candidate_location') ? undefined : json['candidate_location'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'cleanFirstName': !exists(json, 'clean_first_name') ? undefined : json['clean_first_name'],
        'applicationQuestions': !exists(json, 'application_questions') ? undefined : ((json['application_questions'] as Array<any>).map(ApplicationQuestionApplicationReviewInboundApplicationFromJSON)),
        'additionalInfo': !exists(json, 'additional_info') ? undefined : json['additional_info'],
        'inboundAppState': !exists(json, 'inbound_app_state') ? undefined : json['inbound_app_state'],
        'candidateSourceLabel': !exists(json, 'candidate_source_label') ? undefined : json['candidate_source_label'],
        'candidateActionVersion': !exists(json, 'candidate_action_version') ? undefined : json['candidate_action_version'],
        'isDemoCandidate': !exists(json, 'is_demo_candidate') ? undefined : json['is_demo_candidate'],
        'schedulingOwnership': !exists(json, 'scheduling_ownership') ? undefined : json['scheduling_ownership'],
        'explanation': !exists(json, 'explanation') ? undefined : PassingAndFailingFiltersFromJSON(json['explanation']),
        'bookmarked': !exists(json, 'bookmarked') ? undefined : json['bookmarked'],
        'email': json['email'],
    };
}

export function SaapReviewApplicationToJSON(value?: SaapReviewApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'full_name': value.fullName,
        'latest_title_at_latest_company': value.latestTitleAtLatestCompany,
        'total_yoe': value.totalYoe,
        'resume_path': value.resumePath,
        'applied_at': value.appliedAt === undefined ? undefined : (value.appliedAt.toISOString()),
        'inbound_app_id': value.inboundAppId,
        'candidate_id': value.candidateId,
        'person_id': value.personId,
        'candidate_bucket_label': value.candidateBucketLabel,
        'candidate_location': value.candidateLocation,
        'linkedin_url': value.linkedinUrl,
        'clean_first_name': value.cleanFirstName,
        'application_questions': value.applicationQuestions === undefined ? undefined : ((value.applicationQuestions as Array<any>).map(ApplicationQuestionApplicationReviewInboundApplicationToJSON)),
        'additional_info': value.additionalInfo,
        'inbound_app_state': value.inboundAppState,
        'candidate_source_label': value.candidateSourceLabel,
        'candidate_action_version': value.candidateActionVersion,
        'is_demo_candidate': value.isDemoCandidate,
        'scheduling_ownership': value.schedulingOwnership,
        'explanation': PassingAndFailingFiltersToJSON(value.explanation),
        'bookmarked': value.bookmarked,
        'email': value.email,
    };
}


