import { Skeleton, Stack } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { range } from "lodash";
import React from "react";

import { Card } from "components/library/Card";
import { DashboardJob } from "services/openapi";
import { JobCard } from "views/Homepage/components/JobsList/JobCard";

const NUM_JOBS_PER_PAGE = 5;
interface JobsListProps {
  jobs: DashboardJob[];
  isLoading: boolean;
  numSkeletonRows?: number;
}

export const JobsList = React.memo(
  ({ jobs, isLoading, numSkeletonRows = 2 }: JobsListProps): React.ReactElement => {
    const numJobPages = Math.ceil(jobs.length / NUM_JOBS_PER_PAGE);
    const [page, setPage] = React.useState(1);

    const handlePageChange = React.useCallback((event: React.ChangeEvent<unknown>, value: number): void => {
      setPage(value);
    }, []);

    const jobsToDisplay = React.useMemo(() => {
      if (isLoading || jobs.length === 0) {
        return undefined;
      }

      // get the jobs for the current page
      const jobsForPage = jobs.slice((page - 1) * NUM_JOBS_PER_PAGE, page * NUM_JOBS_PER_PAGE);
      return (
        <>
          {jobsForPage.map(job => (
            <JobCard key={job.id!} job={job} />
          ))}
          {numJobPages > 1 && <Pagination count={numJobPages} page={page} onChange={handlePageChange} />}
        </>
      );
    }, [handlePageChange, isLoading, jobs, numJobPages, page]);

    return (
      <Stack spacing={1}>
        {jobsToDisplay ?? <></>}
        {!jobsToDisplay &&
          isLoading &&
          range(numSkeletonRows).map((_, idx) => (
            <Stack key={idx} direction="row" width="100%" spacing={2} alignItems="center">
              <Card fullWidth>
                <Skeleton height="30px" width="80%" />
                <Skeleton height="30px" width="60%" />
              </Card>
            </Stack>
          ))}
      </Stack>
    );
  }
);
