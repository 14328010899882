import { JobCompensation } from "services/openapi";

export const displayJobCompensation = (jobCompensation?: JobCompensation): string => {
  if (!jobCompensation || !jobCompensation.lowerBound || !jobCompensation.upperBound) return "Not specified";

  const currencySymbol = currencyCodeToSybmol(jobCompensation.currencyCode);

  return `${currencySymbol}${jobCompensation.lowerBound.toLocaleString()} - ${currencySymbol}${jobCompensation.upperBound.toLocaleString()}`;
};

export const currencyCodeToSybmol = (currencyCode: string): string => {
  switch (currencyCode) {
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "CAD":
      return "C$";
    case "INR":
      return "₹";
    case "CNY":
      return "¥";
    case "USD":
    default:
      return "$";
  }
};
