import { Stack } from "@mui/material";
import React from "react";

import { BodySmall } from "components/library/typography";
import { useFeatureFlag, FeatureFlag } from "hooks/useFeatureFlag";
import { CandidateBioSourceDirectionEnum, NextActionSchedulingStateEnum } from "services/openapi";
import { isSinglepartStage } from "utils/isStage";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { InterviewScheduledButtons } from "views/CandidateDetail/components/next-action/states/InterviewScheduled";
import { SubmitFeedbackButton } from "views/CandidateDetail/components/next-action/states/SubmitFeedbackButton";
import { NextActionStateComponentProps } from "views/CandidateDetail/components/next-action/utils/constants";
import { getNextActionTitle, getNextActionColors } from "views/CandidateDetail/components/next-action/utils/helpers";
import { convertPrettyDate } from "views/CandidateDetail/utils";

export function ATSInitialReviewOrCallCompleted({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  const isInitialReview = candidateBio.nextAction?.schedulingState === NextActionSchedulingStateEnum.InitialReview;
  const useContactName = useFeatureFlag(FeatureFlag.PandoraNameReads);

  const description = React.useMemo(() => {
    const candidateName = useContactName ? candidateBio.contact.fullName : candidateBio.fullName;

    if (isInitialReview) {
      const pipelineStartDate = candidateBio.searchSource?.pipelineStartedDate;
      if (!pipelineStartDate) {
        return <BodySmall>Please schedule or reject {candidateName}.</BodySmall>;
      }

      const initialReviewDate = convertPrettyDate(candidateBio.searchSource?.pipelineStartedDate!);
      switch (candidateBio.searchSource?.direction) {
        case CandidateBioSourceDirectionEnum.Inbound:
          return (
            <BodySmall>
              {candidateName} applied via {candidateBio.searchSource.label} on {initialReviewDate}.
            </BodySmall>
          );
        case CandidateBioSourceDirectionEnum.Outbound:
          if (candidateBio.searchSource?.internalName === "MANUAL") {
            return (
              <BodySmall>
                {candidateBio.searchSource?.displayAction} to Dover on {initialReviewDate}.
              </BodySmall>
            );
          }
          return (
            <BodySmall>
              {candidateName} responded interested{initialReviewDate === "today" ? "" : " on"} {initialReviewDate}.
            </BodySmall>
          );
      }
    }

    // not initial review, call actually completed

    const interview = candidateBio.nextAction?.currentInterview;
    const stageName = interview?.stageName;
    const interviewDate = interview?.endDate;

    // single part interviews
    // render card with stage, interviewer and date to best of our ability
    if (candidateBio.candidatePipelineStage && isSinglepartStage(candidateBio.candidatePipelineStage)) {
      const interviewer = candidateBio.nextAction?.currentInterview?.interviewer;

      if (!interviewDate) {
        return <BodySmall>{stageName} completed.</BodySmall>;
      }

      if (interviewer && interviewer?.firstName) {
        const interviewerName = `${interviewer?.firstName} ${interviewer?.lastName}`;
        return (
          <BodySmall>
            {stageName} completed with {interviewerName} on {convertPrettyDate(interviewDate)}.
          </BodySmall>
        );
      }
    }

    // fallthrough - catch multiparts OR single part where we don't have interviewer/date information
    if (!interviewDate) {
      return <BodySmall>{stageName} completed.</BodySmall>;
    }

    return (
      <BodySmall>
        {stageName} completed on {convertPrettyDate(interviewDate)}.
      </BodySmall>
    );
  }, [
    candidateBio.candidatePipelineStage,
    candidateBio.contact.fullName,
    candidateBio.fullName,
    candidateBio.nextAction?.currentInterview,
    candidateBio.searchSource?.direction,
    candidateBio.searchSource?.displayAction,
    candidateBio.searchSource?.internalName,
    candidateBio.searchSource?.label,
    candidateBio.searchSource?.pipelineStartedDate,
    isInitialReview,
    useContactName,
  ]);

  const DescriptionEl = React.useMemo(() => {
    return (
      <Stack spacing={1}>
        {description}
        {!isInitialReview && <SubmitFeedbackButton candidateBio={candidateBio} />}
      </Stack>
    );
  }, [description, candidateBio, isInitialReview]);

  return (
    <NextActionCardSkeleton
      title={getNextActionTitle(candidateBio?.nextAction)}
      colors={getNextActionColors(candidateBio?.nextAction)}
      description={DescriptionEl}
      buttons={<InterviewScheduledButtons candidateBio={candidateBio} />}
    />
  );
}
