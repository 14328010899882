import { z } from "zod";

const campaignEditorsSchema = z.array(
  z.object({
    rawEditorState: z.unknown(),
    id: z.string(),
    enabled: z.boolean(),
    minMessageDelay: z.number(),
  })
);

export const campaignsFormSchema = z.object({
  campaignEditors: campaignEditorsSchema,
  isDirty: z.boolean(),
});

export type CampaignEditorsType = z.infer<typeof campaignEditorsSchema>;
export type CampaignsFormSchemaType = z.infer<typeof campaignsFormSchema>;
