import { Progress } from "@doverhq/dover-ui";
import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import { CareersPageJob } from "services/openapi";
import { screenSizes } from "styles/theme";
import { B1_doNotUse } from "styles/typography";
import { JobCard } from "views/inboundExternal/CareersPage/JobCard";
import { HeaderTitle } from "views/inboundExternal/styles";

const JobListStyle = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 789px;
  min-width: 344px;
  height: 100%;

  @media (max-width: ${screenSizes.tablet}) {
    min-width: auto;
    max-width: 100%;
  }
`;

export const JobList = ({
  jobs,
  clientName,
  isJobsLoading,
  isEmbed,
}: {
  jobs: CareersPageJob[];
  clientName: string | undefined;
  isJobsLoading: boolean;
  isEmbed?: boolean;
}): React.ReactElement => {
  return (
    <JobListStyle>
      {isJobsLoading ? (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
          <Progress size="large" />
        </Box>
      ) : jobs && jobs.length > 0 && clientName ? (
        <>
          {!isEmbed && (
            <>
              <HeaderTitle size="sm">Open Positions</HeaderTitle>
              <Spacer height={16} />
            </>
          )}
          {clientName &&
            jobs.map((job, i) => {
              return <JobCard key={i} job={job} clientName={clientName} isEmbed={isEmbed} />;
            })}
        </>
      ) : (
        <B1_doNotUse style={{ textAlign: "center" }}>No positions available at this time</B1_doNotUse>
      )}
    </JobListStyle>
  );
};
