export const variables = {
  SCHEDULING_LINK: {
    value: "findatime.io/schedule/77f584c2-2d53-4af8-8ce4-443ed601e497",
    description: "The link sent to a candidate to schedule an interview.",
  },
  FIRST_NAME: {
    value: "Alice",
    description: "The candidate's first name.",
  },
  SENDER_FIRST_NAME: {
    value: "Douglas",
    description: "The email sender's first name.",
  },
  INTERVIEWER_FIRST_NAME: {
    value: "John",
    description: "The next interviewer's first name.",
  },
  JOB_TITLE: {
    value: "HR Generalist",
    description: "The job req title.",
  },
  OLD_JOB_TITLE: {
    value: "HR Specialist",
    description: "The previously considered job req title.",
  },
  CLIENT_NAME: {
    value: "Dover",
    description: "The name of the company",
  },
  INTERVIEWER_ROLE_TITLE_WITH_ARTICLE: {
    value: "an Engineering Manager",
    description: "The role of the interviewer with article prepended",
  },
  INTERVIEWER_ROLE_TITLE_WITHOUT_ARTICLE: {
    value: "Engineering Manager",
    description: "The role of the interviewer",
  },
  INTERVIEW_DURATION: {
    value: "2 hours and 45 minutes",
    description: "How long the entire interview will be (multipart interviews only)",
  },
  INTERVIEW_SCHEDULE: {
    value: "\n\t1. Intro call: 45 minutes\n\t2. Pair Programming: 2 hours and 45 minutes\n\n",
    description: "A schedule of events making up this interview (multipart interviews only)",
  },
  JOB_DESCRIPTION_LINK: {
    value: "www.trover.io/careers/my-awesome-job",
    description: "A url pointing to the job description, hosted either by Dover or the customer.",
  },
};

export const standardSelectableVariables = [
  "scheduling_link",
  "first_name",
  "sender_first_name",
  "interviewer_first_name",
  "interviewer_role_title_with_article",
  "job_title",
];

export const onsiteAndFinalSelectableVariables = ["first_name", "sender_first_name", "client_name"];

export const SLACK_SUPPORT_URL = "https://dovertalent.slack.com/archives/";
