import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isAfter } from "date-fns";
import { useMemo } from "react";

import { useGetPermissions } from "components/RBAC";
import {
  selectJobById,
  useGetJobSetupQuery,
  useGetJobSetupStepsQuery,
  useListJobsQuery,
} from "services/doverapi/endpoints/job/endpoints";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { DashboardJob, JobSetupStepStateEnum, JobSetupStepStepNameEnum } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { isValidDate } from "utils/dates";

interface JobsDerivedData {
  allJobs: DashboardJob[] | undefined;
  activeJobs: DashboardJob[] | undefined;
  inactiveJobs: DashboardJob[] | undefined;
  authedUsersJobs: DashboardJob[] | undefined;
  notAuthedUsersJobs: DashboardJob[] | undefined;
  authedUsersActiveJobs: DashboardJob[] | undefined;
  authedUsersInactiveJobs: DashboardJob[] | undefined;
  notAuthedUsersActiveJobs: DashboardJob[] | undefined;
  notAuthedUsersInactiveJobs: DashboardJob[] | undefined;
  jobsLoading: boolean;
  uninitialized: boolean;
}

export const useGetJobIsSnoozed = (jobId: string): boolean => {
  const { data: jobEntities } = useListJobsQuery({});
  const selectedJob = jobEntities ? selectJobById(jobEntities, jobId) : undefined;

  return useMemo(() => {
    const snoozedUntil = selectedJob?.snoozedUntil;
    return !!snoozedUntil && isValidDate(snoozedUntil) && isAfter(snoozedUntil, new Date());
  }, [selectedJob?.snoozedUntil]);
};

interface UseGetJobsDerivedDataArgs {
  sortFunc?: (jobs: DashboardJob[]) => DashboardJob[];
}

export const useGetJobsDerivedData = ({ sortFunc }: UseGetJobsDerivedDataArgs): JobsDerivedData => {
  const { data: jobs, isLoading: jobsLoading, isUninitialized } = useListJobsQuery({});
  const { permissionsData } = useGetPermissions();

  const jobIdsUserIsRelatedTo = Object.keys(permissionsData?.jobRoles ?? {});

  const {
    user: { email: authedUserEmail },
  } = useAuth0();

  const allJobs = useMemo(() => {
    if (jobsLoading || !jobs) {
      return undefined;
    }

    let allJobsTemp = listAllEntities(jobs);
    if (sortFunc) {
      allJobsTemp = sortFunc(allJobsTemp);
    }

    return allJobsTemp;
  }, [jobs, jobsLoading, sortFunc]);

  const activeJobs = useMemo(() => {
    if (!allJobs) {
      return undefined;
    }

    return allJobs?.filter(job => job.active);
  }, [allJobs]);

  const inactiveJobs = useMemo(() => {
    if (!allJobs) {
      return undefined;
    }

    return allJobs?.filter(job => !job.active);
  }, [allJobs]);

  const authedUsersJobs = useMemo(() => {
    if (!allJobs) {
      return undefined;
    }

    // We want to indicate that this value is loaded, it's just empty
    if (!authedUserEmail) {
      return [];
    }

    return allJobs.filter(
      job =>
        job.hiringManager?.email === authedUserEmail ||
        job.recruiter?.email === authedUserEmail ||
        jobIdsUserIsRelatedTo.includes(job.id!)
    );
  }, [allJobs, authedUserEmail, jobIdsUserIsRelatedTo]);

  const authedUsersActiveJobs = useMemo(() => {
    if (!authedUsersJobs) {
      return undefined;
    }

    return authedUsersJobs.filter(job => job.active);
  }, [authedUsersJobs]);

  const authedUsersInactiveJobs = useMemo(() => {
    if (!authedUsersJobs) {
      return undefined;
    }

    return authedUsersJobs.filter(job => !job.active);
  }, [authedUsersJobs]);

  const notAuthedUsersJobs = useMemo(() => {
    if (!allJobs) {
      return undefined;
    }

    const authedUsersJobsIds = (authedUsersJobs || []).map(job => job.id);

    return allJobs.filter(job => !authedUsersJobsIds.includes(job.id!));
  }, [allJobs, authedUsersJobs]);

  const notAuthedUsersActiveJobs = useMemo(() => {
    if (!notAuthedUsersJobs) {
      return undefined;
    }

    return notAuthedUsersJobs.filter(job => job.active);
  }, [notAuthedUsersJobs]);

  const notAuthedUsersInactiveJobs = useMemo(() => {
    if (!notAuthedUsersJobs) {
      return undefined;
    }

    return notAuthedUsersJobs.filter(job => !job.active);
  }, [notAuthedUsersJobs]);

  return useMemo(() => {
    return {
      // Transformations
      allJobs,
      activeJobs,
      inactiveJobs,
      authedUsersJobs,
      notAuthedUsersJobs,
      authedUsersActiveJobs,
      authedUsersInactiveJobs,
      notAuthedUsersActiveJobs,
      notAuthedUsersInactiveJobs,
      jobsLoading,
      uninitialized: isUninitialized,
    };
  }, [
    allJobs,
    activeJobs,
    inactiveJobs,
    authedUsersJobs,
    notAuthedUsersJobs,
    authedUsersActiveJobs,
    authedUsersInactiveJobs,
    notAuthedUsersActiveJobs,
    notAuthedUsersInactiveJobs,
    jobsLoading,
    isUninitialized,
  ]);
};

export const useGetJobSlackUrl = (jobId?: string | null): string | undefined => {
  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);
  return useMemo(() => {
    return jobSetup?.slackChannelId ? `https://dovertalent.slack.com/archives/${jobSetup?.slackChannelId}` : undefined;
  }, [jobSetup]);
};

export const useIsJobSetupStepComplete = (stepName: JobSetupStepStepNameEnum, jobId?: string): boolean | undefined => {
  const { data: jobSetupSteps } = useGetJobSetupStepsQuery(jobId ?? skipToken);

  return useMemo(() => {
    const completedStates = [JobSetupStepStateEnum.StartedPendingDoverAction, JobSetupStepStateEnum.Complete];
    const step = jobSetupSteps?.setupSteps.find(step => step.stepName === stepName);
    return step ? completedStates.includes(step.state) : undefined;
  }, [jobSetupSteps?.setupSteps, stepName]);
};
