import { Box, Divider, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { differenceInDays } from "date-fns";
import _ from "lodash";
import React, { useMemo } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { AccountSettingsSectionType } from "App/routing/types";
import { ReactComponent as WarningIcon } from "assets/icons/alert.svg";
import AlertIcon from "assets/icons/alert.svg";
import HelpIcon from "assets/icons/help-question.svg";
import MailOpenIcon from "assets/icons/mail-open.svg";
import ThumbsUpIcon from "assets/icons/thumbs-up.svg";
import { addCandidateToCampaignModalAtom } from "components/dover/AddCandidate/AddCandidateToCampaignModal";
import { useGetGmailAuthStatus } from "components/dover/GmailAuth/hooks";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall, Subtitle1 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useModal } from "GlobalOverlays/atoms";
import { useGetCampaignStatsQuery } from "services/doverapi/endpoints/campaign";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import {
  CampaignStats,
  ListCampaign,
  ListCampaignStateEnum,
  SlimEmailSenderAliasGmailCredentialAuthStateEnum,
} from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { getEmailAliasEmail, getEmailAliasName } from "utils/getEmailAliasName";
import { StatusCell } from "views/job/JobSetup/steps/CampaignVariants/StatusCell";
import { CampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/types";
import { convertFloatToPercentWithOneDecimal } from "views/job/JobSetup/steps/CampaignVariants/utils";

const CampaignCardContainer = styled(Stack)`
  height: auto;
  width: 186px;
  border-radius: 10px;
  border: 2px solid ${colors.grayscale.gray200};
  margin-right: 12px;
  background-color: ${backgrounds.white};
  cursor: pointer;

  // Fancy shmancy selector to disable the hover effect when the child button is hovered
  &:hover:not(:has(button:hover)) {
    background: ${colors.grayscale.gray100};
  }
`;

const StyledReactSVG = styled(ReactSVG)`
  display: inline;
  padding-right: 8px;
  svg {
    margin-bottom: 4px;
    height: 16px;
    width: 16px;
  }
`;

const StyledThumbsUpSVG = styled(StyledReactSVG)`
  svg {
    path {
      stroke: black;
    }
  }
`;

const StyledSVG = styled(StyledReactSVG)`
  padding-right: 0px;
  padding-left: 4px;
  svg {
    path {
      color: ${({ color }: { color: string }): string => color};
    }
  }
`;

const AuthGmailTooltip = ({ campaign }: { campaign: ListCampaign }): React.ReactElement => {
  const { data: userDefinedSenderUser } = useGetProUserQuery((campaign.userDefinedSenderUser as unknown) as string);

  const { gmailAuthStatus } = useGetGmailAuthStatus({
    userId: campaign.userDefinedSenderUser ? campaign.userDefinedSenderUser.toString() : undefined,
  });

  const showEmailUnathedWarning =
    gmailAuthStatus?.valid === false ||
    (campaign.emailAlias &&
      ![
        SlimEmailSenderAliasGmailCredentialAuthStateEnum.Valid,
        SlimEmailSenderAliasGmailCredentialAuthStateEnum.IsShellAccount,
      ].includes(campaign.emailAlias.gmailCredentialAuthState!));

  if (!showEmailUnathedWarning) {
    return <></>;
  }

  const name = getEmailAliasName(userDefinedSenderUser, campaign.emailSenderOption, campaign.emailAlias);
  const email = getEmailAliasEmail(userDefinedSenderUser, campaign.emailSenderOption, campaign.emailAlias);

  return (
    <Tooltip
      title={
        <>
          {`To send emails from ${email} please have ${name} `}
          <InternalLink
            $variant="secondary"
            to={APP_ROUTE_PATHS.accountSetup(AccountSettingsSectionType.EMAIL_CONNECTION)}
          >{` connect their Gmail account`}</InternalLink>
        </>
      }
    >
      <Box mr={0.5}>
        <WarningIcon />
      </Box>
    </Tooltip>
  );
};

const CampaignAgeWarning = ({
  earliestFirstContactedOn,
}: {
  earliestFirstContactedOn: Date | null | undefined;
}): React.ReactElement => {
  if (!earliestFirstContactedOn) {
    return <></>;
  }

  const daysActive = differenceInDays(new Date(), earliestFirstContactedOn);

  let message: string;
  if (daysActive <= 14) {
    message = "This email campaign has not been running for long so data may be skewed.";
  } else if (daysActive > 90) {
    message =
      "This email campaign has been running for over 3 months, so comparing to a new campaign is not a true A/B test.";
  } else {
    return <></>;
  }

  return (
    <Tooltip title={message} placement="top">
      <div>
        <StyledSVG src={AlertIcon} color={colors.warning.base} />
      </div>
    </Tooltip>
  );
};

const EmptyStatValue = (): React.ReactElement => (
  <span>
    --
    <StyledSVG src={HelpIcon} color={colors.grayscale.gray400} />
  </span>
);

const CampaignStat = ({
  icon,
  label,
  statName,
  isPercentage,
  campaignStats,
}: {
  icon?: React.ReactNode;
  label: string;
  statName: "interestRate" | "openRate" | "numContacted";
  isPercentage: boolean;
  campaignStats: CampaignStats;
}): React.ReactElement => {
  const stat = campaignStats[statName];

  const statValue = useMemo(
    (): React.ReactElement =>
      _.isNil(stat) ? (
        <Tooltip
          title="No candidates have been contacted through this campaign yet. Check back in a few days!"
          placement="top"
        >
          <div>
            <EmptyStatValue />
          </div>
        </Tooltip>
      ) : (
        <>{isPercentage ? convertFloatToPercentWithOneDecimal(stat) : stat}</>
      ),
    [isPercentage, stat]
  );

  return (
    <Box display="flex" justifyContent="space-between">
      <BodySmall>
        {icon && icon}
        {label}:
      </BodySmall>
      <BodySmall>{statValue}</BodySmall>
    </Box>
  );
};

const CampaignCard = ({ campaign }: { campaign: ListCampaign }): React.ReactElement => {
  const { open: openAddCandidateToCampaignModal } = useModal(addCandidateToCampaignModalAtom);

  const { data: userDefinedSenderUser, isFetching: isFetchingProUser } = useGetProUserQuery(
    (campaign.userDefinedSenderUser as unknown) as string
  );
  const { data: campaignStats, isFetching: isFetchingCampaignStats } = useGetCampaignStatsQuery(
    campaign?.id ? { campaignId: campaign.id! } : skipToken
  );

  const campaignStatus: CampaignStatus =
    campaign.state === ListCampaignStateEnum.Active ? CampaignStatus.Active : CampaignStatus.Inactive;

  const disableSourceCandidateButton = campaign.state === ListCampaignStateEnum.Inactive;

  return (
    <CampaignCardContainer
      spacing={1}
      p={1}
      onClick={
        // TODO: opens drawer to view/edit campaign
        (): void => {}
      }
    >
      <Stack direction="row" spacing={1}>
        <StatusCell status={campaignStatus} stats={campaignStats} />
        <span style={{ marginTop: "2px" }}>
          <CampaignAgeWarning earliestFirstContactedOn={campaignStats?.earliestFirstContactedOn} />
        </span>
      </Stack>

      <Subtitle1>{campaign.name}</Subtitle1>

      <BodyExtraSmall color={colors.grayscale.gray600} weight="500">
        {isFetchingProUser ? (
          <Skeleton width="70%" />
        ) : (
          <Stack direction="row" alignItems="center">
            <AuthGmailTooltip campaign={campaign} />
            <BodySmall>
              {getEmailAliasEmail(userDefinedSenderUser, campaign.emailSenderOption, campaign.emailAlias)}
            </BodySmall>
          </Stack>
        )}
      </BodyExtraSmall>
      {isFetchingCampaignStats || !campaignStats ? (
        <Box height="50px">
          <DoverLoadingSpinner spinnerSize={"40px"} minHeight={"50px"} />
        </Box>
      ) : (
        <Stack spacing={1}>
          <CampaignStat
            label="Total contacted"
            statName="numContacted"
            isPercentage={false}
            campaignStats={campaignStats}
          />
          <CampaignStat
            label="Open"
            statName="openRate"
            icon={<StyledReactSVG src={MailOpenIcon} />}
            isPercentage={true}
            campaignStats={campaignStats}
          />
          <CampaignStat
            label="Interested"
            statName="interestRate"
            icon={<StyledThumbsUpSVG src={ThumbsUpIcon} />}
            isPercentage={true}
            campaignStats={campaignStats}
          />
        </Stack>
      )}
      {/* Apply negative margin to Divider to expand over container padding */}
      <Divider sx={{ marginLeft: "-8px !important", marginRight: "-8px !important" }} />
      <Button
        width="100%"
        variant={ButtonVariant.Secondary}
        onClick={(): void => {
          // TODO: pass through campaign id here once modal accepts it
          openAddCandidateToCampaignModal({});
        }}
        disabled={disableSourceCandidateButton}
        tooltip={disableSourceCandidateButton ? "Campaign must be active to source candidates" : ""}
      >
        Source candidate
      </Button>
    </CampaignCardContainer>
  );
};

export default CampaignCard;
