import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import _ from "lodash";
import React, { ReactElement, useCallback } from "react";
import styled from "styled-components";

import { ReactComponent as DownCaretSVG } from "assets/icons/caret-down-black.svg";
import { useGetDisableCandidateStageOrStatusSelect } from "components/dover/CandidateStageSelect/hooks";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useCandidateIsOfferOrHired } from "hooks/useCandidateIsOfferOrHired";
import { useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import { ArchiveReasonTypeEnum, CandidateBio, UpdateCandidateBioBodyStatusEnum } from "services/openapi";
import { colors } from "styles/theme";
import { candidateStageStatusConfirmModal } from "views/CandidateDetail/components/StageAndStatusCard/CandidateStageStatusConfirmModal";

const greenDotColor = colors.success.base;
const yellowDotColor = colors.warning.base;
const redDotColor = colors.critical.base;

type Statusi = { [key in UpdateCandidateBioBodyStatusEnum]: { label: string; color: string } };

const statusi: Statusi = {
  [UpdateCandidateBioBodyStatusEnum.Active]: { label: "Active", color: greenDotColor },
  [UpdateCandidateBioBodyStatusEnum.Hired]: { label: "Hired", color: greenDotColor },
  [UpdateCandidateBioBodyStatusEnum.Ignored]: { label: "Ignored", color: yellowDotColor },
  [UpdateCandidateBioBodyStatusEnum.Rejected]: { label: "Rejected", color: redDotColor },
  [UpdateCandidateBioBodyStatusEnum.Snoozed]: { label: "Snoozed", color: yellowDotColor },
  [UpdateCandidateBioBodyStatusEnum.Withdrew]: { label: "Withdrew", color: redDotColor },
};

// NOTE: We want to disallow setting status to Ignored
type PickableStatusi = Omit<Statusi, UpdateCandidateBioBodyStatusEnum.Ignored>;
const pickableStatusi: PickableStatusi = _.omit(statusi, UpdateCandidateBioBodyStatusEnum.Ignored);

// I really don't know how to find this type
// Will look harder another time
const DownArrowIcon = (props: any): ReactElement => (
  // We need to override the mui default top property to properly center the icon
  <Box {...props} top="auto !important">
    <DownCaretSVG width="30px" />
  </Box>
);

const dotIcon = <span className="dot"></span>;
const hiredIcon = <span className="emoji">🎉</span>;

const getStatusOptionIcon = (option: UpdateCandidateBioBodyStatusEnum): JSX.Element => {
  if (option === UpdateCandidateBioBodyStatusEnum.Hired) {
    return <StyledMenuItemIcon>{hiredIcon}</StyledMenuItemIcon>;
  }
  return <StyledMenuItemIcon $color={statusi[option].color}>{dotIcon}</StyledMenuItemIcon>;
};
export const StatusSelect = ({ candidateBio }: { candidateBio: CandidateBio }): React.ReactElement => {
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();
  const [updateCandidateBio] = useUpdateCandidateBioMutation();

  const candidateIsOfferOrHired = useCandidateIsOfferOrHired(candidateBio);

  const { disabled, tooltipText } = useGetDisableCandidateStageOrStatusSelect(candidateBio, candidateBio.job);
  const { open } = useModal(candidateStageStatusConfirmModal);

  const [status, setStatus] = React.useState<UpdateCandidateBioBodyStatusEnum>(
    (candidateBio.status ?? UpdateCandidateBioBodyStatusEnum.Active) as UpdateCandidateBioBodyStatusEnum
  );

  const onChange = useCallback(
    (e: SelectChangeEvent<UpdateCandidateBioBodyStatusEnum>): void => {
      const newValue = e.target.value as UpdateCandidateBioBodyStatusEnum;
      if (!newValue) return;

      if (candidateIsOfferOrHired) {
        open({
          confirm: () => {
            setStatus(newValue);

            updateCandidateBio({
              id: candidateBio.id!,
              data: { status: newValue },
              hideToast: false,
              jobId: candidateBio?.job,
            });
          },
        });
        return;
      }

      setStatus(newValue);

      // Reject or withdrew should open super component
      // Otherwise just persist change to backend
      if (
        newValue === UpdateCandidateBioBodyStatusEnum.Rejected ||
        newValue === UpdateCandidateBioBodyStatusEnum.Withdrew
      ) {
        const archiveContext =
          newValue === UpdateCandidateBioBodyStatusEnum.Rejected
            ? ArchiveReasonTypeEnum.Rejected
            : ArchiveReasonTypeEnum.Withdrew;

        showCandidateActionModal({
          candidateId: candidateBio.id!,
          modalType: CandidateActionModalType.Reject,
          archiveContext,
        });
      } else {
        updateCandidateBio({
          id: candidateBio.id!,
          data: { status: newValue },
          hideToast: false,
          jobId: candidateBio?.job,
        });
      }
    },
    [candidateBio.id, candidateBio?.job, showCandidateActionModal, updateCandidateBio, candidateIsOfferOrHired, open]
  );

  return (
    <Tooltip title={tooltipText}>
      <Box>
        <Select
          sx={{ width: "100%" }}
          size="small"
          value={status}
          disabled={disabled}
          displayEmpty
          onChange={onChange}
          IconComponent={DownArrowIcon}
          renderValue={(value): React.ReactNode => {
            if (!value) {
              return (
                <BodySmall>
                  {getStatusOptionIcon(UpdateCandidateBioBodyStatusEnum.Active)}
                  {statusi[UpdateCandidateBioBodyStatusEnum.Active].label}
                </BodySmall>
              );
            }

            return (
              <BodySmall>
                {getStatusOptionIcon(value)}
                {statusi[value].label}
              </BodySmall>
            );
          }}
        >
          {Object.entries(pickableStatusi).map(([key, option]) => {
            return (
              <MenuItem key={key} value={key}>
                <BodySmall>
                  {getStatusOptionIcon(key as UpdateCandidateBioBodyStatusEnum)}
                  {option.label}
                </BodySmall>
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </Tooltip>
  );
};

interface StyledMenuItemIconProps {
  $color?: string;
}

const StyledMenuItemIcon = styled.span<StyledMenuItemIconProps>`
  display: inline-block;
  width: 24px;

  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: relative;
    top: -2px;
    background-color: ${(props): string => props.$color ?? "transparent"};
  }
  .emoji {
    position: relative;
    left: -3px;
  }
`;
