/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject24
 */
export interface InlineObject24 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject24
     */
    searchId: string;
}

export function InlineObject24FromJSON(json: any): InlineObject24 {
    return InlineObject24FromJSONTyped(json, false);
}

export function InlineObject24FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject24 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchId': json['search_id'],
    };
}

export function InlineObject24ToJSON(value?: InlineObject24 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search_id': value.searchId,
    };
}


