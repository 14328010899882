import { Chat, Button, Icon } from "@doverhq/dover-ui";
import { Message } from "@doverhq/dover-ui/dist/types/Chat";
import { ReactComponent as ArrowUpLeftIcon } from "@doverhq/dover-ui/icons/arrow-up-left.svg";
import { ReactComponent as HelpIcon } from "@doverhq/dover-ui/icons/help.svg";
import { ReactComponent as RotateCcwIcon } from "@doverhq/dover-ui/icons/rotate-ccw.svg";
import { Box, Stack } from "@mui/material";
import { useSetAtom, useAtomValue } from "jotai";
import { useResetAtom } from "jotai/utils";
import React, { FC, useEffect } from "react";

import { InfoTip } from "components/InfoTip";
import Toggle from "components/library/Toggle";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall } from "components/library/typography";
import {
  addMessageAtom,
  CHAT_USER,
  DOVER_AI_USER,
  isChatControlDisabledAtom,
  messagesAtom,
  setMessagesFromConversationAtom,
  toggleUseSaapParamsAtom,
  undoMessageAtom,
  useSaapParamsAtom,
} from "views/candidates/ApplicationReview/atoms/chat";
import { useApplications } from "views/candidates/ApplicationReview/hooks/useApplications";

export const AiChat: FC = () => {
  const messages = useAtomValue(messagesAtom);
  const addMessage = useSetAtom(addMessageAtom);
  const setMessages = useSetAtom(setMessagesFromConversationAtom);

  const { conversation, isFetching, isError } = useApplications();

  // If there is an error fetching the conversation, add an error message to the chat
  useEffect(() => {
    if (!isError) return;

    addMessage({
      user: DOVER_AI_USER,
      message: "I had an issue with that request. You can try again or use the undo or reset buttons below.",
    });
  }, [isError, addMessage]);

  // When the api returns with a new conversation override the chat state with it
  useEffect(() => {
    if (!conversation) return;

    setMessages(conversation);
  }, [conversation, setMessages]);

  const onSend = (message: Message): void => {
    addMessage(message);
  };

  return (
    <>
      <Chat id="app-review-ai-chat" messages={messages} primaryUser={CHAT_USER} isTyping={isFetching} onSend={onSend} />
      <AiChatToolbar />
    </>
  );
};

const AiChatToolbar: React.FC = () => {
  const isChatControlDisabled = useAtomValue(isChatControlDisabledAtom);
  const undoMessage = useSetAtom(undoMessageAtom);
  const useSaapParams = useAtomValue(useSaapParamsAtom);
  const toggleUseSaapParams = useSetAtom(toggleUseSaapParamsAtom);
  const resetMessages = useResetAtom(messagesAtom);

  return (
    <Stack direction="row" spacing={1} alignItems="center" px={1}>
      <Tooltip title="Undo">
        <div>
          <Button
            variant="ghost"
            p={1}
            icon={{ Icon: ArrowUpLeftIcon }}
            isDisabled={isChatControlDisabled}
            onPress={undoMessage}
          />
        </div>
      </Tooltip>
      <Tooltip title="Reset">
        <div>
          <Button
            variant="ghost"
            p={1}
            icon={{ Icon: RotateCcwIcon }}
            isDisabled={isChatControlDisabled}
            onPress={resetMessages}
          />
        </div>
      </Tooltip>
      <Tooltip
        title={
          <div>
            See more info about how to use this feature{" "}
            <a href="https://help.dover.com/en/articles/8672810-how-to-use-ai-applicant-sorting">here</a>.
          </div>
        }
      >
        <Box px={1} py="12px" display="flex" height="16px">
          <Icon Icon={HelpIcon} size={16} />
        </Box>
      </Tooltip>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Toggle label="Use Search Params?" checked={useSaapParams} onChange={toggleUseSaapParams} />
        <BodyExtraSmall>Include criteria</BodyExtraSmall>
        <InfoTip text="Toggling this on will also apply filters that you have on the Criteria tab, and only show applicants that pass both." />
      </Stack>
    </Stack>
  );
};
