import { Stack } from "@mui/material";
import React from "react";

import { HiringPipelineStage } from "services/openapi";
import { EditStage } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStage";
import { StageName } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/StageParts";

interface InterestedStageRowProps {
  stage: HiringPipelineStage;
}

export const InterestedStageRow: React.FC<InterestedStageRowProps> = ({ stage }) => {
  return (
    <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
      <StageName name={stage.name} />
      <EditStage stage={stage} />
    </Stack>
  );
};
