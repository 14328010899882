import { Divider, FormControlLabel, Radio, Stack, useTheme, useMediaQuery } from "@mui/material";
import Color from "color";
import React from "react";
import styled from "styled-components";

import { BodySmall, Heading, Overline, Subtitle1 } from "components/library/typography";
import StyledBadge from "components/StyledBadge";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { ClientDoverPlanEnum } from "services/openapi";
import { backgrounds, colors, screenSizesNumbers } from "styles/theme";

interface ConfigCardProps {
  selected: boolean;
  width: string;
}

const ConfigCard = styled(Stack)<ConfigCardProps>`
  border: ${({ selected }): string =>
    selected ? `2px solid ${colors.success.base}` : `2px solid ${colors.grayscale.gray300}`};
  border-radius: 4px;
  width: ${({ width }): string => width};
`;

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

interface DoverPlanCardProps {
  cardPlanType: ClientDoverPlanEnum;
  selectedPlanType: ClientDoverPlanEnum;
  header: string;
  callToActionText: string;
  subtitleText: string;
  additionsText: string[];
  setPlanType: (selectedEmailsPerWeekOption: ClientDoverPlanEnum) => void;
}

const DoverPlanCard = ({
  cardPlanType,
  selectedPlanType,
  header,
  callToActionText,
  subtitleText,
  additionsText,
  setPlanType,
}: DoverPlanCardProps): React.ReactElement => {
  const isSelected = cardPlanType === selectedPlanType;
  const currentDoverPlan = useGetDoverPlan();

  const onCardClick = React.useCallback(() => {
    setPlanType(cardPlanType);
  }, [cardPlanType, setPlanType]);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tablet));

  return (
    <ConfigCard
      selected={isSelected}
      onClick={onCardClick}
      divider={<Divider orientation="vertical" flexItem sx={{ borderColor: colors.grayscale.gray300 }} />}
      width={smallScreen ? "100%" : "50%"}
    >
      <Stack direction="row" padding="16px 16px 0px 16px">
        <FormControlLabel
          sx={{ position: "relative", bottom: "8px", marginRight: "0px" }}
          value={cardPlanType}
          control={<StyledRadio checked={isSelected} />}
          label={null}
        />
        <Stack direction="row" spacing={1}>
          <Heading>{header}</Heading>
          {cardPlanType === currentDoverPlan && (
            <StyledBadge
              color={colors.informational.dark}
              backgroundColor={colors.informational.light}
              borderColor={"none"}
              text={<Overline weight="600">CURRENT PLAN</Overline>}
              display={"inline-flex"}
            />
          )}
        </Stack>
      </Stack>
      <Stack padding="16px" spacing={2}>
        <Subtitle1 color={colors.success.base}>{callToActionText}</Subtitle1>
        <Stack spacing={2.5}>
          <BodySmall weight="600" color={colors.grayscale.gray500}>
            {subtitleText}
          </BodySmall>
          <BodySmall>
            <ul style={{ paddingLeft: "20px", listStyleType: "- " }}>
              {additionsText.map(addition => {
                return <li key={addition}>{addition}</li>;
              })}
            </ul>
          </BodySmall>
        </Stack>
      </Stack>
    </ConfigCard>
  );
};

export default DoverPlanCard;
