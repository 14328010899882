import { Skeleton, Stack } from "@mui/material";
import React from "react";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import { colors } from "styles/theme";
import { convertPrettyDate } from "views/CandidateDetail/utils";
import { goalHireDateModalAtom } from "views/job/components/GoalHireDate";

//------------------------------------------------------------------------------
// Component

interface GoalHireDateButtonProps {
  jobId: string;
}

const GoalHireDateButton = ({ jobId }: GoalHireDateButtonProps): React.ReactElement => {
  const { open } = useModal(goalHireDateModalAtom);
  const { data: jobSetup, isFetching: isJobSetupFetching } = useGetJobSetupQuery(jobId);

  if (isJobSetupFetching) {
    return <Skeleton width={"125px"} />;
  }

  const pastDue = jobSetup?.goalHireDate && jobSetup.goalHireDate < new Date();

  return (
    <Stack
      height="fit-content"
      direction="row"
      px={1}
      py={0.5}
      spacing={0.5}
      alignItems="center"
      borderRadius={"6px"}
      border={`1px solid ${colors.grayscale.gray200}`}
      onClick={(e): void => {
        e.preventDefault();
        open({ jobId });
      }}
      sx={{
        cursor: "pointer",
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: colors.grayscale.gray200,
        },
      }}
    >
      <Stack direction="row" alignItems="center">
        {!jobSetup?.goalHireDate && <PlusIcon height="14px" />}
        <BodySmall color={colors.grayscale.gray600} weight="500">
          {jobSetup?.goalHireDate ? "Goal hire date:" : "Goal hire date"}
        </BodySmall>
      </Stack>
      {jobSetup?.goalHireDate && (
        <BodySmall color={pastDue ? colors.warning.dark : colors.grayscale.gray600}>
          {convertPrettyDate(jobSetup.goalHireDate)}
        </BodySmall>
      )}
    </Stack>
  );
};

export default GoalHireDateButton;
