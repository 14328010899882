import { Divider, Stack } from "@mui/material";
import React, { FC } from "react";

import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { CandidateBio } from "services/openapi";
import { ApplicantName } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/ApplicantName";
import { AtsInfo } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/AtsInfo";
import { ContactInfo } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/ContactInfo";
import { SocialLinks } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/SocialLinks";
import { SourceInfo } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/SourceInfo";

interface ApplicantInfoProps {
  candidateBio: CandidateBio;
}

export const ApplicantInfo: FC<ApplicantInfoProps> = ({ candidateBio }) => {
  const useContactName = useFeatureFlag(FeatureFlag.PandoraNameReads);
  const fullName = useContactName ? candidateBio?.contact?.fullName : candidateBio?.fullName;

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" justifyContent="space-between">
      <Stack direction="row" spacing={1}>
        <ApplicantName fullName={fullName} isSample={candidateBio.isDemoInboundCand} />
        {candidateBio.id && <SocialLinks candidateId={candidateBio.id} socialLinks={candidateBio.socialLinks || []} />}
      </Stack>
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        divider={<Divider orientation="vertical" sx={{ height: "10px", mr: "4px" }} />}
      >
        {candidateBio.ats && <AtsInfo ats={candidateBio.ats} />}
        <ContactInfo candidateBio={candidateBio} />
        {candidateBio.searchSource && <SourceInfo source={candidateBio.searchSource} />}
      </Stack>
    </Stack>
  );
};
