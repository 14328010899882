import { Stack } from "@mui/material";
import React, { ReactElement, useState } from "react";
import styled from "styled-components";

import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";
import {
  standardSelectableVariables,
  onsiteAndFinalSelectableVariables,
} from "views/job/JobSetup/components/constants";

export const VariableSelector = ({ isMultipart }: { isMultipart: boolean }): ReactElement => {
  const [clipboardText, setClipboardText] = useState<string | undefined>(undefined);
  const selectableVariables = isMultipart ? onsiteAndFinalSelectableVariables : standardSelectableVariables;

  const copyToClipboard = (textToCopy: string): void => {
    navigator.clipboard.writeText(textToCopy);
    setClipboardText(textToCopy);
  };

  return (
    <VariableSelectContainer spacing={1}>
      <B2_doNotUse bold>Personalize your email</B2_doNotUse>
      <B2_doNotUse>To insert dynamic content, you can use the following:</B2_doNotUse>
      <ul>
        {selectableVariables.map(v => {
          const variableWithTokens = `{{${v}}}`;
          return (
            <Tooltip
              title={clipboardText === variableWithTokens ? "Copied!" : "Click to copy"}
              variant={TooltipVariant.Dark}
              placement="top"
            >
              <li
                onClick={(): void => {
                  copyToClipboard(variableWithTokens);
                }}
              >
                {variableWithTokens}
              </li>
            </Tooltip>
          );
        })}
      </ul>
    </VariableSelectContainer>
  );
};

const VariableSelectContainer = styled(Stack)`
  background-color: ${colors.grayscale.gray100};
  border-radius: 3px;
  padding: 16px;
  width: 100%;

  ul {
    padding-left: 16px;
  }

  li {
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 14px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${colors.grayscale.gray200};
    }
  }
`;
