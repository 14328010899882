import { Button } from "@doverhq/dover-ui";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Stack, Skeleton } from "@mui/material";
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBoolean } from "react-use";
import styled from "styled-components";

import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button as LegacyButton, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { TextField } from "components/library/TextField";
import { Body, BodySmall } from "components/library/typography";
import { useHasRole, Role } from "components/RBAC";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import {
  useGetFormattedInboundSourceExplorerRows,
  useGetSourceDisplaySectionData,
} from "services/doverapi/endpoints/job-source-settings/customHooks";
import {
  EnrichedCandidateSourceDetails,
  FormattedInboundSourceExplorerRow,
} from "services/doverapi/endpoints/job-source-settings/types";
import { colors } from "styles/theme";
import { escapeRegExp } from "utils/strings";
import { SourceCard } from "views/job/JobBoards/components/SourceCard";
import { DISCOVER_MORE_SECTION_ID } from "views/job/JobBoards/constants";
import { COLUMN_NAMES_TO_QUICK_FILTER_BY, DATA_GRID_PAGE_SIZE } from "views/job/JobBoards/constants";
import { CardProps } from "views/job/JobBoards/types";

/***** Custom Grid Components *****/

const CustomRow = (props: any): React.ReactElement => {
  return <SourceCard source={props.row} onClick={(): void => props.onClick(props.row.enrichedSourceDetails)} />;
};

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: (text: string) => void;
  value: string;
}

function NoSearchRows(): React.ReactElement {
  return (
    <Stack
      position="absolute"
      top="50%"
      left="50%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      sx={{ transform: "translate(-50%, -50%)" }}
    >
      <Body weight="600">No matching sources</Body>
      <Body centered>Try searching by a different name</Body>
    </Stack>
  );
}

function QuickSearchToolbar(props: QuickSearchToolbarProps): React.ReactElement {
  return (
    <Box width="100%" my={1}>
      <TextField
        text={props.value}
        onTextUpdated={props.onChange}
        placeholderText="Search for more job boards"
        fullWidth
        startAdornment={<StyledSearchIcon fontSize="small" />}
        endAdornment={
          props.value ? (
            <Button variant="ghost" onPress={props.clearSearch}>
              <StyledClearIcon fontSize="small" />
            </Button>
          ) : (
            <></>
          )
        }
      />
    </Box>
  );
}

/***** Main Component *****/

const DiscoverCard = ({ openModalWithSource }: CardProps): React.ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "job_details", "job_boards_banner");

  const [chatOpen, setChatOpen] = useBoolean(false);
  const toggleChat = (): void => setChatOpen(!chatOpen);

  const jobId = useJobIdFromUrl();
  const isAdmin = useHasRole(Role.ADMIN);

  /* eslint-disable react-hooks/exhaustive-deps */
  const formattedInboundSourceExplorerRows =
    useGetFormattedInboundSourceExplorerRows({
      jobId: jobId,
      includeAdminPreview: isAdmin,
      // Include all sources, even if they've been enabled for the job already
      filterOutPreviouslyAddedInboundSources: false,
      includeYcWaas: true,
    }) ?? [];

  const [rows, setRows] = useState<GridRowModel<FormattedInboundSourceExplorerRow>[]>(
    formattedInboundSourceExplorerRows
  );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setRows(formattedInboundSourceExplorerRows);
  }, [formattedInboundSourceExplorerRows]);

  // If should be open, unhide and go to home. Add onHide event listener.
  // If should be closed, hide.
  React.useEffect(() => {
    const Intercom = (window as any).Intercom;
    if (Intercom) {
      if (!chatOpen) {
        Intercom("hide");
      } else {
        Intercom("update", {
          hide_default_launcher: false,
        });
        Intercom("onHide", () => {
          Intercom("update", {
            hide_default_launcher: true,
          });

          // Adjust state if they hide using the launcher
          if (chatOpen) {
            setChatOpen(false);
          }
        });
        Intercom("showSpace", "home");
      }
    }
  }, [chatOpen]);

  const requestSearch = useCallback(
    (searchValue: string): void => {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = formattedInboundSourceExplorerRows.filter(row => {
        return Object.keys(row).some(field => {
          if (COLUMN_NAMES_TO_QUICK_FILTER_BY.includes(field)) {
            // @ts-ignore
            return searchRegex.test(row[field].toString());
          }
          return false;
        });
      });
      setRows(filteredRows);
    },
    [setSearchText, formattedInboundSourceExplorerRows, setRows]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 350,
        hide: true,
        disableColumnMenu: true,
      },
    ],
    [openModalWithSource, colors]
  );

  const { isFetching } = useGetSourceDisplaySectionData({ jobId, includeAdminPreview: false });

  return (
    <Card id={DISCOVER_MORE_SECTION_ID}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" width="100%">
          <Body weight="600">All job boards</Body>
          <Stack direction="row" spacing={1}>
            <BodySmall color={colors.grayscale.gray600}>{"Don't see a job board?"}</BodySmall>
            <Box onClick={toggleChat} sx={{ textDecoration: "underline", cursor: "pointer" }}>
              <BodySmall color={colors.grayscale.gray600}>Let us know</BodySmall>
            </Box>
          </Stack>
        </Stack>
        <Banner variant={BannerVariant.Info}>
          <i>Not sure where to post your job?</i>{" "}
          <LegacyButton
            removePadding
            variant={ButtonVariant.Link}
            onClick={(): void => {
              window.open(calendlyUrl, "_blank", "noopener noreferrer");
            }}
          >
            Talk to a recruiting expert
          </LegacyButton>{" "}
        </Banner>
        {isFetching ? (
          <>
            <Skeleton width="100%" height="120px" />
          </>
        ) : (
          <SourcesDataGrid
            components={{
              NoRowsOverlay: NoSearchRows,
              NoResultsOverlay: NoSearchRows,
              Toolbar: QuickSearchToolbar,
              Row: CustomRow,
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick={true}
            rowHeight={74}
            autoHeight
            sortingOrder={["desc", "asc", null]}
            hideFooter={rows.length <= DATA_GRID_PAGE_SIZE}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (text: string): void => requestSearch(text),
                clearSearch: (): void => requestSearch(""),
              },
              row: {
                onClick: (sourceDetails: EnrichedCandidateSourceDetails): void => {
                  openModalWithSource(sourceDetails);
                },
              },
            }}
          />
        )}
      </Stack>
    </Card>
  );
};

export default DiscoverCard;

/***** Styled Grid *****/

const SourcesDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root {
    border: none;
    box-shadow: none;
  }

  &.MuiDataGrid-root .MuiDataGrid-footerContainer {
    background-color: ${colors.grayscale.gray100};
    justify-content: center;
    min-height: auto; // remove explicit height on pagination footer
  }

  .MuiTablePagination-toolbar {
    min-height: auto !important; // remove explicit height on pagination footer
  }

  &.MuiDataGrid-root .MuiDataGrid-virtualScroller,
  &.MuiDataGrid-root .MuiDataGrid-cell {
    background-color: white;
  }

  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  }

  &.MuiDataGrid-root .MuiDataGrid-footerContainer {
    // add border around pagination footer
    border: 1px solid ${colors.grayscale.gray200};
    border-top: none; // remove top border to avoid double border with row container
    border-radius: 0px 0px 6px 6px;
  }

  &.MuiDataGrid-root .MuiTablePagination-displayedRows {
    margin-bottom: 0px; // centers pagination range vertically in footer
  }

  .MuiDataGrid-columnsContainer {
    display: none; // hide column headers
  }

  .MuiDataGrid-virtualScroller {
    margin-top: 0 !important; // remove margin corresponding to column headers
    border: 1px solid ${colors.grayscale.gray200};
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    width: 100%; // make rows take up full width
  }
`;

const StyledClearIcon = styled(ClearIcon)`
  fill: ${colors.grayscale.gray400} !important;
  color: ${colors.grayscale.gray400} !important;
`;

const StyledSearchIcon = styled(SearchIcon)`
  fill: ${colors.grayscale.gray400} !important;
  color: ${colors.grayscale.gray400} !important;
  margin-right: 12px;
`;
