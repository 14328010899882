import { Box, Stack } from "@mui/material";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { DOVER_OVERVIEW_CALENDLY_URL } from "components/constants";
import { DoverInterviewerCardPreview } from "components/dover/DoverInterviewerCard";
import { SourcingAutopilotCardPreview } from "components/dover/sourcing/SourcingAutopilotCard";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, PageTitle } from "components/library/typography";
import CustomModal from "components/Modal";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { EmbeddedRecruiterCardPreview } from "views/job/JobSetup/steps/Overview/components/EmbeddedRecruiterCard";

export const ConciergeUpgradeModal = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}): ReactElement => {
  return (
    <>
      <CustomModal
        open={isOpen}
        onClose={closeModal}
        maxWidth="md"
        customContentStyles={{ borderTop: "none" }}
        dialogActions={
          <Box width="100%" display="flex" justifyContent="center" flexDirection="row" paddingBottom="20px">
            <ExternalLink href={DOVER_OVERVIEW_CALENDLY_URL} target="_blank" style={{ textDecoration: "none" }}>
              <Button variant={ButtonVariant.Primary} onClick={closeModal}>
                Book a call
              </Button>
            </ExternalLink>
          </Box>
        }
      >
        <ModalBody spacing={1.5}>
          <Stack paddingBottom="5px">
            <PageTitle>Let Dover take recruiting work off your plate</PageTitle>
            <Body color={colors.grayscale.gray500}>
              {"Dover's solutions can help you source candidates, conduct interviews, schedule candidates and more."}
            </Body>
          </Stack>
          <EmbeddedRecruiterCardPreview />
          <SourcingAutopilotCardPreview />
          <DoverInterviewerCardPreview />
        </ModalBody>
      </CustomModal>
    </>
  );
};

export default ConciergeUpgradeModal;

const ModalBody = styled(Stack)`
  padding: 0px 40px;
  marign: 0px;
`;
