import { Stack, TextField as MuiTextField, InputBaseComponentProps, Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import styled from "styled-components";

import { Body, BodySmall } from "components/library/typography";
import RemoveButton from "components/RemoveButton";
import { colors } from "styles/theme";

interface StyledTextFieldProps {
  $hideBorder?: boolean;
}

const StyledTextField = styled(MuiTextField)<StyledTextFieldProps>`
  &.MuiFormControl-root {
    flex-grow: 1;
  }

  .MuiOutlinedInput-input {
    padding: 0;
  }

  .MuiOutlinedInput-root {
    height: ${(props): string | undefined => (props.multiline ? undefined : "40px")};

    &.MuiInputBase-root {
      background-color: white;
      border: none;
      font-size: 14px;
      font-family: Inter;
      padding-left: 11px;
    }

    fieldset {
      border-color: ${colors.grayscale.gray300};
    }

    &:hover fieldset {
      border-color: ${colors.grayscale.gray400};
    }
  }
  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${colors.grayscale.gray600};
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0;
    border: ${(props): string | undefined => (props.$hideBorder ? "none" : undefined)};
  }

  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }

  .MuiFormHelperText-root {
    margin-left: 0px;
  }

  input {
    font-size: 16px;
  }
`;

interface TextFieldProps {
  title?: string;
  text?: string | null;
  subTitle?: string;
  boldSubtitle?: boolean;
  onTextUpdated?: (text: string) => void;
  placeholderText?: string;
  required?: boolean;
  errorMessage?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onDelete?: () => void;
  onBlur?: () => void;
  enterOnBlur?: boolean;
  numbersOnly?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  inputProps?: InputBaseComponentProps;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  autoFocus?: boolean;
  handleEnter?: (text: string) => void;
  clearOnEnter?: boolean;
  defaultValue?: string;
  fullWidth?: boolean;
  maxWidth?: string | number;
  hideBorder?: boolean;
  onFocus?: () => void;
}

export const TextField = ({
  title,
  subTitle,
  boldSubtitle,
  text,
  onTextUpdated,
  required,
  errorMessage,
  placeholderText,
  startAdornment,
  endAdornment,
  onDelete,
  onBlur,
  enterOnBlur,
  numbersOnly,
  error,
  helperText,
  disabled,
  inputProps,
  multiline,
  minRows,
  maxRows,
  autoFocus,
  handleEnter,
  clearOnEnter,
  defaultValue,
  fullWidth,
  maxWidth,
  hideBorder,
  onFocus,
}: TextFieldProps): React.ReactElement => {
  const [innerText, setInnerText] = React.useState<string>(text ?? "");

  React.useEffect(() => {
    setInnerText(text ?? "");
  }, [text]);

  const handleTextChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setInnerText(event.target.value);
      onTextUpdated?.(event.target.value);
    },
    [onTextUpdated]
  );

  const isError = React.useMemo(() => {
    return error || (required && text === "");
  }, [error, required, text]);

  const bottomText = React.useMemo(() => {
    return isError ? errorMessage : helperText;
  }, [errorMessage, helperText, isError]);

  const onEnter = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        handleEnter?.(innerText);

        if (clearOnEnter) {
          setInnerText("");
        }
      }
    },
    [clearOnEnter, handleEnter, innerText]
  );

  const blurEnter = React.useCallback(() => {
    handleEnter?.(innerText);
  }, [handleEnter, innerText]);

  return (
    <Stack spacing={1} flexGrow={fullWidth ? 1 : undefined} maxWidth={maxWidth}>
      {title && <Body weight="500">{title}</Body>}
      {subTitle && <BodySmall weight={boldSubtitle ? "600" : undefined}>{subTitle}</BodySmall>}
      <Stack direction="row">
        {/* Workaround to toggle between controlled and uncontrolled component */}
        {text !== undefined || !!handleEnter ? (
          <StyledTextField
            defaultValue={defaultValue}
            onChange={handleTextChange}
            onBlur={enterOnBlur ? blurEnter : onBlur}
            value={innerText}
            error={isError}
            onFocus={onFocus}
            helperText={bottomText}
            placeholder={placeholderText}
            onKeyDown={onEnter}
            disabled={disabled}
            multiline={!!multiline}
            minRows={minRows}
            maxRows={maxRows}
            autoFocus={autoFocus}
            type={numbersOnly ? "number" : undefined}
            InputProps={{
              startAdornment: startAdornment ? (
                <InputAdornment position="start">{startAdornment}</InputAdornment>
              ) : (
                <></>
              ),
              endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : <></>,
            }}
            fullWidth={fullWidth}
            inputProps={inputProps}
            $hideBorder={hideBorder}
          />
        ) : (
          <StyledTextField
            defaultValue={defaultValue}
            onBlur={onBlur}
            error={isError}
            helperText={bottomText}
            placeholder={placeholderText}
            disabled={disabled}
            multiline={!!multiline}
            minRows={minRows}
            maxRows={maxRows}
            autoFocus={autoFocus}
            InputProps={{
              startAdornment: startAdornment ? (
                <InputAdornment position="start">{startAdornment}</InputAdornment>
              ) : (
                <></>
              ),
            }}
            inputProps={inputProps}
            fullWidth={fullWidth}
            $hideBorder={hideBorder}
          />
        )}
        {onDelete && (
          <Box sx={bottomText ? { position: "relative", bottom: "0px" } : undefined}>
            <RemoveButton noRightPadding={true} color={colors.critical.hover} onClick={onDelete} />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
