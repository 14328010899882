import React, { useCallback } from "react";

import { TopLevelModalManagerOptionsContext } from "components/dover/top-level-modal-manager/context";
import { FilePreviewModal } from "components/dover/top-level-modal-manager/FilePreviewModal";
import { BulkRejectModal } from "components/dover/top-level-modal-manager/modals/BulkRejectModal";
import { CandidateActionModal } from "components/dover/top-level-modal-manager/modals/candidate-action-modal";
import MoveJobModal from "components/dover/top-level-modal-manager/modals/MoveJobModal";
import UpgradeModal from "components/dover/top-level-modal-manager/modals/UpgradeModal";
import {
  AllowedState,
  BulkRejectModalProps,
  CandidateActionModalProps,
  FilePreviewModalProps,
  MoveJobModalProps,
  SharedTopLevelModalProps,
  UpgradeModalProps,
} from "components/dover/top-level-modal-manager/types";

const TopLevelModalManager: React.FC<{}> = ({ children }) => {
  const [componentAndProps, setComponentAndProps] = React.useState<AllowedState | undefined>();

  const closeModal = useCallback(() => {
    // Ideally, close modal becomes more complex so that we can keep the modal with local state mounted
    // For now, it's fine to reset state on each mount
    setComponentAndProps(undefined);
  }, []);

  const showUpgradeModal = useCallback(
    (props: Omit<UpgradeModalProps, keyof SharedTopLevelModalProps>) => {
      setComponentAndProps({
        Component: UpgradeModal,
        props: {
          isOpen: true,
          closeModal,
          ...props,
        },
      });
    },
    [closeModal]
  );

  const showMoveJobModal = useCallback(
    (props: Omit<MoveJobModalProps, keyof SharedTopLevelModalProps>) => {
      setComponentAndProps({
        Component: MoveJobModal,
        props: {
          isOpen: true,
          closeModal,
          ...props,
        },
      });
    },
    [closeModal]
  );

  const showCandidateActionModal = useCallback(
    (props: Omit<CandidateActionModalProps, keyof SharedTopLevelModalProps>) => {
      setComponentAndProps({
        Component: CandidateActionModal,
        props: {
          isOpen: true,
          closeModal,
          ...props,
        },
      });
    },
    [closeModal]
  );

  const showBulkRejectModal = useCallback(
    (props: Omit<BulkRejectModalProps, keyof SharedTopLevelModalProps>) => {
      setComponentAndProps({
        Component: BulkRejectModal,
        props: {
          isOpen: true,
          closeModal,
          ...props,
        },
      });
    },
    [closeModal]
  );

  const showFilePreviewModal = useCallback(
    (props: Omit<FilePreviewModalProps, keyof SharedTopLevelModalProps>) => {
      setComponentAndProps({
        Component: FilePreviewModal,
        props: {
          isOpen: true,
          closeModal,
          ...props,
        },
      });
    },
    [closeModal]
  );

  return (
    <TopLevelModalManagerOptionsContext.Provider
      value={{
        closeModal,
        showUpgradeModal,
        showMoveJobModal,
        showCandidateActionModal,
        showBulkRejectModal,
        showFilePreviewModal,
      }}
    >
      {children}
      {/** Safe ignore below as we are guaranteed to have the correct props set for the given modal. TypeScript just doesn't know this because of how it type-narrows for union types */}
      {/** @ts-ignore */}
      {componentAndProps && <componentAndProps.Component {...componentAndProps.props} />}
    </TopLevelModalManagerOptionsContext.Provider>
  );
};

export default TopLevelModalManager;
