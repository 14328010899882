/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateSourcePricingTier,
    CandidateSourcePricingTierFromJSON,
    CandidateSourcePricingTierFromJSONTyped,
    CandidateSourcePricingTierToJSON,
    CandidateSourceReadyForUseResults,
    CandidateSourceReadyForUseResultsFromJSON,
    CandidateSourceReadyForUseResultsFromJSONTyped,
    CandidateSourceReadyForUseResultsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidateSource
 */
export interface CandidateSource {
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    readonly internalName?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    readonly direction?: CandidateSourceDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    readonly description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateSource
     */
    readonly features?: Array<CandidateSourceFeaturesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateSource
     */
    readonly externallyEditable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    readonly displaySection?: CandidateSourceDisplaySectionEnum;
    /**
     * 
     * @type {number}
     * @memberof CandidateSource
     */
    readonly displayOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateSource
     */
    readonly requiresDisclaimer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateSource
     */
    readonly enabledByDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateSource
     */
    readonly supportsCreatingExternalJob?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateSource
     */
    readonly supportsExistingExternalJob?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    externalJobIdentifierLabel: string | null;
    /**
     * 
     * @type {CandidateSourceReadyForUseResults}
     * @memberof CandidateSource
     */
    readyForUseInfo?: CandidateSourceReadyForUseResults;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    readonly atsType?: CandidateSourceAtsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    readonly pricingLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    sourceDetailsUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    readonly encodedSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateSource
     */
    readonly additionalInfoFieldLabel?: string | null;
    /**
     * 
     * @type {Array<CandidateSourcePricingTier>}
     * @memberof CandidateSource
     */
    readonly pricingTiers?: Array<CandidateSourcePricingTier>;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateSource
     */
    readonly shouldAutoRenewDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateSource
     */
    readonly adminOnly?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CandidateSourceDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}/**
* @export
* @enum {string}
*/
export enum CandidateSourceFeaturesEnum {
    InboundApplicationsSupported = 'INBOUND_APPLICATIONS_SUPPORTED',
    BudgetedBilling = 'BUDGETED_BILLING',
    DoverIntegration = 'DOVER_INTEGRATION',
    RecommendedByDover = 'RECOMMENDED_BY_DOVER',
    UserCredentialIntegrated = 'USER_CREDENTIAL_INTEGRATED',
    AtsIntegrated = 'ATS_INTEGRATED',
    ThirdPartyAllowed = 'THIRD_PARTY_ALLOWED',
    SupportsAdditionalInfo = 'SUPPORTS_ADDITIONAL_INFO',
    AgencyPostingNotAllowed = 'AGENCY_POSTING_NOT_ALLOWED'
}/**
* @export
* @enum {string}
*/
export enum CandidateSourceDisplaySectionEnum {
    Inbound = 'INBOUND',
    InboundAts = 'INBOUND_ATS',
    CareersPage = 'CAREERS_PAGE',
    Outbound = 'OUTBOUND',
    Referral = 'REFERRAL'
}/**
* @export
* @enum {string}
*/
export enum CandidateSourceAtsTypeEnum {
    Greenhouse = 'GREENHOUSE',
    Lever = 'LEVER',
    Ashby = 'ASHBY',
    GoogleHire = 'GOOGLE_HIRE',
    NoAts = 'NO_ATS',
    Other = 'OTHER',
    NotRequired = 'NOT_REQUIRED'
}

export function CandidateSourceFromJSON(json: any): CandidateSource {
    return CandidateSourceFromJSONTyped(json, false);
}

export function CandidateSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'internalName': !exists(json, 'internal_name') ? undefined : json['internal_name'],
        'direction': !exists(json, 'direction') ? undefined : json['direction'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'features': !exists(json, 'features') ? undefined : json['features'],
        'externallyEditable': !exists(json, 'externally_editable') ? undefined : json['externally_editable'],
        'displaySection': !exists(json, 'display_section') ? undefined : json['display_section'],
        'displayOrder': !exists(json, 'display_order') ? undefined : json['display_order'],
        'requiresDisclaimer': !exists(json, 'requires_disclaimer') ? undefined : json['requires_disclaimer'],
        'enabledByDefault': !exists(json, 'enabled_by_default') ? undefined : json['enabled_by_default'],
        'supportsCreatingExternalJob': !exists(json, 'supports_creating_external_job') ? undefined : json['supports_creating_external_job'],
        'supportsExistingExternalJob': !exists(json, 'supports_existing_external_job') ? undefined : json['supports_existing_external_job'],
        'externalJobIdentifierLabel': json['external_job_identifier_label'],
        'readyForUseInfo': !exists(json, 'ready_for_use_info') ? undefined : CandidateSourceReadyForUseResultsFromJSON(json['ready_for_use_info']),
        'atsType': !exists(json, 'ats_type') ? undefined : json['ats_type'],
        'pricingLabel': !exists(json, 'pricing_label') ? undefined : json['pricing_label'],
        'sourceDetailsUrl': !exists(json, 'source_details_url') ? undefined : json['source_details_url'],
        'encodedSlug': !exists(json, 'encoded_slug') ? undefined : json['encoded_slug'],
        'additionalInfoFieldLabel': !exists(json, 'additional_info_field_label') ? undefined : json['additional_info_field_label'],
        'pricingTiers': !exists(json, 'pricing_tiers') ? undefined : ((json['pricing_tiers'] as Array<any>).map(CandidateSourcePricingTierFromJSON)),
        'shouldAutoRenewDefault': !exists(json, 'should_auto_renew_default') ? undefined : json['should_auto_renew_default'],
        'adminOnly': !exists(json, 'admin_only') ? undefined : json['admin_only'],
    };
}

export function CandidateSourceToJSON(value?: CandidateSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'external_job_identifier_label': value.externalJobIdentifierLabel,
        'ready_for_use_info': CandidateSourceReadyForUseResultsToJSON(value.readyForUseInfo),
        'source_details_url': value.sourceDetailsUrl,
    };
}


