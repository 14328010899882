import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import { useContext, useMemo } from "react";

import {
  useListApplicationsViaAIQuery,
  useListSaapReviewApplicationsQuery,
} from "services/doverapi/endpoints/applicationReview";
import {
  AIConversation,
  ListApplicationsReturn,
  SaapReviewAdditionalFiltersCriteriaMatchEnum,
  SaapReviewApplication,
} from "services/openapi";
import { isChatOpenAtom } from "views/candidates/ApplicationReview/atoms/chat";
import {
  listApplicationsViaAiArgsAtom,
  useListSaapReviewApplicationArgs,
} from "views/candidates/ApplicationReview/atoms/listApplicationArgs";
import { selectedApplicantListTabAtom } from "views/candidates/ApplicationReview/atoms/selectedApplicantListTab";
import { FormLoadStateContext } from "views/sourcing/Search/context/FilterToggleContext";

interface UseApplicationsReturn {
  conversation?: AIConversation[];
  applications?: SaapReviewApplication[];
  unreviewedCount?: number;
  bookmarkedCount?: number;
  matchingCount?: number;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
}

// In this hook we have 2 very similar api calls that we switch between, one that uses the normal saap form
// and one that uses the ai chat as input.  They both should return a list of applications,
// they just represent different forms of user interaction.  For naming I refer to the ai chat based
// route as the "chat api" and the normal saap form as the "form api".
export const useApplications = (): UseApplicationsReturn => {
  // We check this to switch between which api to use
  const isChatOpen = useAtomValue(isChatOpenAtom);

  const formLoadState = useContext(FormLoadStateContext);
  const initialFormValuesLoaded = formLoadState?.loaded;

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Form API
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  // Should only run the normal api if the ai chat is not open and the search id exists
  const listSaapReviewApplicationArgs = useListSaapReviewApplicationArgs();

  const selectedTab = useAtomValue(selectedApplicantListTabAtom);

  const listUnreviewedAppsArgs = listSaapReviewApplicationArgs
    ? {
        ...listSaapReviewApplicationArgs,
        bookmarkedFilter: false,
        limit: selectedTab === "Unreviewed" ? listSaapReviewApplicationArgs.limit : 0,
      }
    : undefined;

  const listBookmarkAppsArgs = listSaapReviewApplicationArgs
    ? {
        ...listSaapReviewApplicationArgs,
        bookmarkedFilter: true,
        limit: selectedTab === "Bookmarked" ? listSaapReviewApplicationArgs.limit : 0,
      }
    : undefined;

  const listMatchingAppsArgs = listSaapReviewApplicationArgs
    ? {
        ...listSaapReviewApplicationArgs,
        bookmarkedFilter: false,
        limit: selectedTab === "Matching" ? listSaapReviewApplicationArgs.limit : 0,
        additionalFilters: {
          ...listSaapReviewApplicationArgs.additionalFilters,
          criteriaMatch: [SaapReviewAdditionalFiltersCriteriaMatchEnum.BestMatch],
        },
      }
    : undefined;

  const {
    currentData: unreviewedAppsResult,
    isUninitialized: unreviewedAppsUninitialized,
    isLoading: isUnreviewedAppsLoading,
    isFetching: isUnreviewedAppsFetching,
    isError: isisUnreviewedAppsError,
  } = useListSaapReviewApplicationsQuery(listUnreviewedAppsArgs ?? skipToken);

  const {
    currentData: bookmarkedAppsResult,
    isLoading: isBookmarkedAppsLoading,
    isUninitialized: isBookmarkedAppsUninitialized,
    isFetching: isBookmarkedAppsFetching,
    isError: isBookmarkedAppsError,
  } = useListSaapReviewApplicationsQuery(listBookmarkAppsArgs ?? skipToken);

  const {
    currentData: matchingAppsResult,
    isLoading: isMatchingAppsLoading,
    isUninitialized: isMatchingAppsUninitialized,
    isFetching: isMatchingAppsFetching,
    isError: isMatchingAppsError,
  } = useListSaapReviewApplicationsQuery(listMatchingAppsArgs ?? skipToken);

  let formResults: ListApplicationsReturn | undefined;
  let isFormError = false;
  let isFormUninitialized = true;
  let isFormFetching = false;
  let isFormLoading = false;

  if (selectedTab === "Unreviewed") {
    formResults = unreviewedAppsResult;
    isFormUninitialized = unreviewedAppsUninitialized;
    isFormError = isisUnreviewedAppsError;
    isFormFetching = isUnreviewedAppsFetching;
    isFormLoading = isUnreviewedAppsLoading;
  } else if (selectedTab === "Bookmarked") {
    formResults = bookmarkedAppsResult;
    isFormUninitialized = isBookmarkedAppsUninitialized;
    isFormError = isBookmarkedAppsError;
    isFormFetching = isBookmarkedAppsFetching;
    isFormLoading = isBookmarkedAppsLoading;
  } else if (selectedTab === "Matching") {
    formResults = matchingAppsResult;
    isFormUninitialized = isMatchingAppsUninitialized;
    isFormError = isMatchingAppsError;
    isFormFetching = isMatchingAppsFetching;
    isFormLoading = isMatchingAppsLoading;
  }

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Chat API
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  const listApplicationsViaAiArgs = useAtomValue(listApplicationsViaAiArgsAtom);

  const {
    currentData: chatResults,
    isFetching: isChatFetching,
    isLoading: isChatLoading,
    isError: isChatError,
  } = useListApplicationsViaAIQuery(listApplicationsViaAiArgs ?? skipToken);

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Combined results
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */

  // This memo is necessary
  const results = useMemo(
    () =>
      isChatOpen
        ? {
            conversation: chatResults?.conversation,
            applications: chatResults?.results.applications,
            unreviewedCount: chatResults?.results.totalCount,
            isFetching: isChatFetching,
            isLoading: isChatLoading, // TODO: include initialFormValuesLoading?
            isError: isChatError,
          }
        : {
            conversation: undefined,
            applications: formResults?.applications,
            unreviewedCount: unreviewedAppsResult?.totalCount,
            bookmarkedCount: bookmarkedAppsResult?.totalCount,
            matchingCount: matchingAppsResult?.totalCount,
            // if the formvalues haven't loaded yet, then we don't yet want to call the form api
            // because if we do we'll be calling it with wrong params
            // so when formvalues are loading, we are essentially preparing the form api call
            // also if no applications is undefined but theres no error, its still loading/hasn't been called yet.
            // this is a bit of a hotfix to account for the lag between when params are set (ie initialFormValuesLoaded) and when the api call is made
            // which comes from 1 debouncing the params 2 converting from formState to api params is not instantaneous
            isFetching: isFormFetching || !initialFormValuesLoaded || isFormUninitialized,
            isLoading: !!(isFormLoading || !initialFormValuesLoaded || isFormUninitialized),
            isError: isFormError,
          },
    [
      bookmarkedAppsResult?.totalCount,
      chatResults?.conversation,
      chatResults?.results.applications,
      chatResults?.results.totalCount,
      formResults?.applications,
      initialFormValuesLoaded,
      isChatError,
      isChatFetching,
      isChatLoading,
      isChatOpen,
      isFormError,
      isFormFetching,
      isFormLoading,
      isFormUninitialized,
      matchingAppsResult?.totalCount,
      unreviewedAppsResult?.totalCount,
    ]
  );

  return results;
};
