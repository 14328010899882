import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import { colors } from "styles/theme";

/**
 * @description - The best Switch we currently have(?) but seems to change width depending on usage
 */
export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 18,
  padding: 0,
  display: "flex",
  ".Mui-disabled": {
    "& + .MuiSwitch-track": {
      cursor: "not-allowed !important",
      opacity: "0.75 !important",
    },
  },
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    left: 16,
    color: `${colors.white} !important`,

    "&.Mui-checked": {
      left: 12,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: colors.primary.base,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 100,
    opacity: 1,
    backgroundColor: colors.grayscale.gray300,
    boxSizing: "border-box",
  },
}));

const SwitchV2: React.FC<{
  label: string;
  checked: boolean;
  onChange: (event: React.SyntheticEvent) => void;
  disabled?: boolean;
}> = ({ label, checked, onChange, disabled }) => {
  return (
    <FormControlLabel
      checked={checked}
      disabled={disabled}
      control={<StyledSwitch onChange={onChange} />}
      label={label}
      labelPlacement="start"
      sx={{ margin: 0 }}
    />
  );
};

export default SwitchV2;
