/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IRRPerson
 */
export interface IRRPerson {
    /**
     * 
     * @type {string}
     * @memberof IRRPerson
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof IRRPerson
     */
    readonly linkedinUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof IRRPerson
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof IRRPerson
     */
    readonly cleanFirstName?: string;
}

export function IRRPersonFromJSON(json: any): IRRPerson {
    return IRRPersonFromJSONTyped(json, false);
}

export function IRRPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRRPerson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'cleanFirstName': !exists(json, 'clean_first_name') ? undefined : json['clean_first_name'],
    };
}

export function IRRPersonToJSON(value?: IRRPerson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


