import { CandidateBioSocialLinkLinkTypeEnum } from "services/openapi";
import { QuickFilterEnum, ReviewFilterEnum } from "views/candidates/types";

export interface NeedsActionFilter {
  label: string;
  quickFilter: QuickFilterEnum;
}

export enum UserFacingNextUpLabel {
  All = "Needs Action",
  RespondedInterested = "Interested",
  HaveQuestions = "Have questions",
  CompletedInterview = "Completed interview",
  Mentions = "@ Mentions",
}

export const NeedsActionFilters: NeedsActionFilter[] = [
  {
    label: UserFacingNextUpLabel.All,
    quickFilter: QuickFilterEnum.AllNeedsAction,
  },
  {
    label: UserFacingNextUpLabel.RespondedInterested,
    quickFilter: QuickFilterEnum.RespondedAsInterested,
  },
  {
    label: UserFacingNextUpLabel.HaveQuestions,
    quickFilter: QuickFilterEnum.PendingCustomerResponse,
  },
  {
    label: UserFacingNextUpLabel.CompletedInterview,
    quickFilter: QuickFilterEnum.CompletedInterview,
  },
  { label: UserFacingNextUpLabel.Mentions, quickFilter: QuickFilterEnum.Mentions },
];

export const socialLinksToInclude = [
  CandidateBioSocialLinkLinkTypeEnum.Linkedin,
  CandidateBioSocialLinkLinkTypeEnum.Resume,
];

export const ReviewTypeSectionLabels: { [key in ReviewFilterEnum]?: string } = {
  [ReviewFilterEnum.RespondedAsInterested]: `✉️ ${UserFacingNextUpLabel.RespondedInterested}`,
  [ReviewFilterEnum.PendingCustomerResponse]: `🙋 ${UserFacingNextUpLabel.HaveQuestions}`,
  [ReviewFilterEnum.CompletedInterview]: `🗓️ ${UserFacingNextUpLabel.CompletedInterview}`,
  [ReviewFilterEnum.Mentions]: "@ Mentions (Last 30 days)",
};

export const ACTION_FILTER_HEIGHT = "48px";
export const CANDIDATE_RESPONSE_MAX_CHAR_LENGTH = 250;
